import React, { memo, useCallback, useState, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Autocomplete from 'components/common/autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Wrapper, Placeholder, ArrowIcon, PlaceholderText } from './styles';


const AutocompleteField = memo((props) => {
  const { initialValue, onSubmit, inputProps, getOptionLabel, disabled } = props;

  // const [value, setValue] = useState(() => initialValue); // breaking waterflow concept. can't handle value change from parent
  const [isSelectActive, setIsSelectActive] = useState(false);

  const setSelectActive = useCallback(() => {
    if (!isSelectActive && !disabled) {
      setIsSelectActive(true);
    }
  }, [isSelectActive, disabled]);

  const setSelectClosed = useCallback(() => {
    if (isSelectActive) {
      setIsSelectActive(false);
    }
  }, [isSelectActive]);

  const handleChange = useCallback((nextValue) => {
    // const nextOption = options.find((option) => getOptionValue(option) === nextValue);
    // setValue(nextOption);
    onSubmit(nextValue);
  }, [onSubmit]);

  const extendedInputProps = useMemo(() => ({
    ...(inputProps || {}),
    autoFocus: true,
  }), [inputProps]);

  return (
    <Wrapper
      onClick={setSelectActive}
      as={Stack}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      $isDisabled={disabled}
    >
      {isSelectActive ? (
        <Autocomplete
          {...props}
          onClose={setSelectClosed}
          onOpen={setSelectActive}
          onSubmit={handleChange}
          inputProps={extendedInputProps}
        />
      ) : (
        <Placeholder>
          <PlaceholderText>
            {getOptionLabel(initialValue)}
          </PlaceholderText>

          <ArrowIcon as={ArrowDropDownIcon} />
        </Placeholder>
      )}
    </Wrapper>
  );
});


AutocompleteField.propTypes = {
  ...Autocomplete.propTypes,
};

AutocompleteField.defaultProps = {
  ...Autocomplete.defaultProps,
};


export default AutocompleteField;

import { CHANNEL_KEYS_LIST, MAPPED_CHANNEL_KEYS, CHANNEL_KEYS } from './constants';


export const convertData = (selectedValue, additionalProps) => {
  const [key, value] = selectedValue;
  const isChannel = CHANNEL_KEYS_LIST.includes(key);
  const isGoalRoas = key === CHANNEL_KEYS.GOAL_ROAS;
  const isChannelStatus = key === CHANNEL_KEYS.CHANNEL_STATUS;
  const channelKey = MAPPED_CHANNEL_KEYS[key] || key;
  // TODO: find better solution for isChannel, isGoalRoas

  return [
    isChannel ? channelKey : key,
    value,
    {
      isChannel,
      isGoalRoas,
      isChannelStatus,
      ...(additionalProps?.[key] ? additionalProps[key] : {}),
    },
  ];
};

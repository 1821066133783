import React, { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Wrapper } from './styles';


const Loader = memo(({ ...otherProps }) => (
  <Wrapper>
    <CircularProgress
      {...otherProps}
    />
  </Wrapper>
));


Loader.propTypes = {

};

Loader.defaultProps = {

};


export default Loader;

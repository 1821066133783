import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'campaignsV4',
  storage,
  blacklist: [
    'params',
    'list',
    'total',
    'isFetching',
    'isCampaignUpdateFetching',
  ],
};


export default persistConfig;

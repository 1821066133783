import * as yup from 'yup';
import memoize from 'lodash.memoize';

import { PLATFORMS, PLATFORM_KEYS } from 'core/games/constants';
import { KEYS, LABELS } from './constants';


const REQUIRED_TEXT = 'Required field';
const MIN_LENGTH_TEXT = 'Value must be at least ${min} characters'; // eslint-disable-line no-template-curly-in-string
const ANDROID_BUNDLE_TEXT = 'Android play store ids must contain periods, like com.applovin.mygame';
const INVALID_STORE_ID_TEXT = 'Such store ID already exists';

yup.addMethod(yup.string, 'storeId', function getValidator (errorMessage, ids, isEdit) {
  return this.test('test-store-id', errorMessage, function validate (value) {
    if (isEdit) {
      return true;
    }

    const { path, createError } = this;
    const isInvalid = ids.includes(value);

    return !isInvalid || createError({ path, message: errorMessage });
  });
});

export const getValidationSchema = memoize((ids, isEdit) => yup.object().shape({
  [KEYS.NAME]: yup.string().min(5, MIN_LENGTH_TEXT).required(REQUIRED_TEXT).label(LABELS[KEYS.NAME]),
  [KEYS.STORE_ID]: yup.string().storeId(INVALID_STORE_ID_TEXT, ids, isEdit).required(REQUIRED_TEXT).label(LABELS[KEYS.STORE_ID])
    .when([KEYS.PLATFORM], {
      is: PLATFORMS.ANDROID,
      then: (schema) => schema.matches(/^(?:[a-zA-Z]+(?:\d*[a-zA-Z_]*)*)(?:\.[a-zA-Z]+(?:\d*[a-zA-Z_]*)*)+$/, ANDROID_BUNDLE_TEXT),
      otherwise: (schema) => schema.min(4, MIN_LENGTH_TEXT),
    }),
  [KEYS.PLATFORM]: yup.string().oneOf(PLATFORM_KEYS).required(REQUIRED_TEXT).label(LABELS[KEYS.PLATFORM]),
  [KEYS.LION_APP]: yup.object().nullable().required(REQUIRED_TEXT).label(LABELS[KEYS.LION_APP]),
  [KEYS.GROWTH_MANAGER]: yup.object().nullable().required(REQUIRED_TEXT).label(LABELS[KEYS.GROWTH_MANAGER]),
}), (...args) => JSON.stringify(args));

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiPaper from '@mui/material/Paper';

import { StyledPaper } from './styles';


const Paper = memo(({ children, ...otherProps }) => (
  <StyledPaper
    as={MuiPaper}
    elevation={0}
    {...otherProps}
  >
    {children}
  </StyledPaper>
));


Paper.propTypes = {
  children: PropTypes.node.isRequired,
};

Paper.defaultProps = {

};


export default Paper;

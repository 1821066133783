import { takeEvery, take, fork, cancel, select, put } from 'redux-saga/effects';

import { resolved } from 'utils/actions';

import { RESPONSE_STATUSES } from 'constants/http';

import { types as appTypes } from 'core/app/actions';
import authActions, { types as authTypes } from 'core/auth/actions';
import { getIsTokenValid } from 'core/auth/selectors';

/*
 * Sagas
 */

function* commonErrorHandler () {
  // TODO: handle somehow
}


function* httpErrorHandler ({ payload: { response } }) {
  if (response && (response.status === RESPONSE_STATUSES.UNAUTHORIZED)) {
    yield put(authActions.logout());
  }
}

export function* subscribeSagaToUserSession (saga, ...params) {
  while (true) {
    yield take(authTypes.AUTH_SUCCESS);

    const task = yield fork(saga, ...params);

    yield take(resolved(authTypes.LOGOUT));

    yield cancel(task);
  }
}

export function* waitForUserSession () {
  const isTokenValid = yield select(getIsTokenValid);

  if (!isTokenValid) {
    yield take(authTypes.AUTH_SUCCESS);
  }
}

/*
 * Watchers
 */


function* commonErrorHandlerWatcher () {
  yield takeEvery(appTypes.HANDLE_ERROR, commonErrorHandler);
}

function* httpErrorHandlerWatcher () {
  yield takeEvery(appTypes.HANDLE_HTTP_ERROR, httpErrorHandler);
}


export default [
  commonErrorHandlerWatcher,
  httpErrorHandlerWatcher,
];

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AdaptiveText from 'components/common/adaptive-text';

import { isValueExists } from 'utils';

import { PathElement } from './styles';


const Path = memo(({ path, pathText }) => (
  <AdaptiveText
    size={AdaptiveText.SIZE.SM}
    value={pathText}
  >
    {
      Object.entries(path)
        .filter(([key, value]) => isValueExists(value)) // eslint-disable-line no-unused-vars
        .map(([key, value], index, array) => (
          <PathElement
            key={key}
            $key={key}
          >
            {value}
            {index !== array.length - 1 ? '/' : ''}
          </PathElement>
        ))
    }
  </AdaptiveText>
));


Path.propTypes = {
  path: PropTypes.shape({
    channel: PropTypes.string,
    campaign: PropTypes.string,
    geo: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  pathText: PropTypes.string.isRequired,
};


export default Path;

import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GlobalStyles from '@mui/material/GlobalStyles';

import { GAME_STATUSES, ACTIVE_CHANNEL_STATUSES } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import { isValueExists } from 'utils';
import { managerPropTypes } from 'models/manager';

import Select from 'components/common/select';
import Autocomplete from 'components/common/autocomplete';
import GoalRoasField from 'components/games/goal-roas-field';
import ConfirmationModal from 'components/modals/confirmation';

import { KEYS, TITLES } from './constants';
import { convertData } from './utils';
import { StyledMenuItem, SelectWrapper, StyledAutocomplete, globalStyles, SUBMENU_CLASS_NAME } from './styles';


const GamesSelectedRowsActions = memo(({ anchor, close, managersList, onSubmit, disabled, isAppStatusReady, ...otherProps }) => {
  const [activeControl, setActiveControl] = useState(null);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [additionalSubmitProps, setAdditionalSubmitProps] = useState({});

  const handleControlClick = useCallback((event, key) => {
    event.stopPropagation();
    setActiveControl(key);
  }, []);

  const handlePopoverOpen = useCallback((event, key) => {
    handleControlClick(event, key);
    setPopoverAnchor(event.currentTarget);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePopoverClose = useCallback(() => {
    setPopoverAnchor(null);
  }, []);

  const handleControlChange = useCallback((value, event, controlProps, props) => {
    const { key } = controlProps;

    if (!isValueExists(value)) {
      return;
    }

    setSelectedValue([key, value]);
    setAdditionalSubmitProps({ [key]: props });
    setIsConfirmationModalOpen(true);
  }, []);

  const handleGoalRoasUpdate = useCallback((value, props, event, controlProps) => {
    handleControlChange(value, event, controlProps, props);
  }, [handleControlChange]);

  const resetControlsState = useCallback(() => {
    setActiveControl(null);
    handlePopoverClose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackdropClick = useCallback((event) => {
    event.stopPropagation();
    resetControlsState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    const data = convertData(selectedValue, additionalSubmitProps);
    onSubmit(...data);
    setAdditionalSubmitProps({});
    resetControlsState();
    closeModal(); // TODO: await for success and close modal ?
    close();
  }, [selectedValue, additionalSubmitProps, onSubmit, close]); // eslint-disable-line react-hooks/exhaustive-deps

  const popoverProps = useMemo(() => ({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    classes: {
      paper: SUBMENU_CLASS_NAME,
    },
    disablePortal: false,
    transitionDuration: 0,
    onBackdropClick: handleBackdropClick,
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectPopoverProps = useMemo(() => ({
    ...popoverProps,
    transformOrigin: {
      vertical: 1,
      horizontal: 0,
    },
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  const appStatusSelectProps = useMemo(() => ({
    key: KEYS.STATUS,
    open: activeControl === KEYS.STATUS,
    MenuProps: selectPopoverProps,
  }), [selectPopoverProps, activeControl]);

  const channelStatusSelectProps = useMemo(() => ({
    key: KEYS.CHANNEL_STATUS,
    open: activeControl === KEYS.CHANNEL_STATUS,
    MenuProps: selectPopoverProps,
  }), [selectPopoverProps, activeControl]);

  const goalRoasInputProps = useMemo(() => ({
    key: KEYS.GOAL_ROAS,
  }), []);

  const managersAutocompleteInputProps = useMemo(() => ({
    autoFocus: true,
    placeholder: 'Search here...',
    stopKeyPropagation: true,
  }), []);

  const managersAutocompleteProps = useMemo(() => ({
    key: KEYS.GROWTH_MANAGER,
  }), []);

  const managersAutocomplete = useMemo(() => (
    <StyledAutocomplete
      as={Autocomplete}
      options={managersList}
      inputProps={managersAutocompleteInputProps}
      autocompleteProps={managersAutocompleteProps}
      onSubmit={handleControlChange}
      open={Boolean(popoverAnchor)}
    />
  ), [popoverAnchor, managersList]); // eslint-disable-line react-hooks/exhaustive-deps

  const goalRoasInput = useMemo(() => (
    <GoalRoasField
      isEditMode
      initialValue={null}
      onSubmit={handleGoalRoasUpdate}
      inputProps={goalRoasInputProps}
      withConfirmation={false}
      category={AnalyticsService.CATEGORIES.GAMES}
    />
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  const popover = useMemo(() => {
    let control = null;

    if (activeControl === KEYS.GROWTH_MANAGER) {
      control = managersAutocomplete;
    }

    if (activeControl === KEYS.GOAL_ROAS) {
      control = goalRoasInput;
    }

    return (
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        {...popoverProps}
      >
        {control}
      </Popover>
    );
  }, [popoverAnchor, activeControl, managersAutocomplete, goalRoasInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmationModalDescription = useMemo(() => {
    if (!selectedValue) {
      return null;
    }

    const [key] = selectedValue;

    return `Please, confirm that you want to edit field '${TITLES[key]}' for selected games`;
  }, [selectedValue]);

  return (
    <>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={close}
        {...otherProps}
      >
        <StyledMenuItem
          as={MenuItem}
          onClick={(event) => handleControlClick(event, KEYS.STATUS)}
          disabled={disabled || !isAppStatusReady}
          disableRipple
        >
          {TITLES[KEYS.STATUS]}

          <ArrowRightIcon />

          <SelectWrapper
            $isActive={appStatusSelectProps.open}
          >
            <Select
              value={null}
              options={GAME_STATUSES}
              onChange={handleControlChange}
              selectProps={appStatusSelectProps}
            />
          </SelectWrapper>
        </StyledMenuItem>

        <StyledMenuItem
          as={MenuItem}
          onClick={(event) => handleControlClick(event, KEYS.CHANNEL_STATUS)}
          disabled={disabled || !isAppStatusReady}
          disableRipple
        >
          {TITLES[KEYS.CHANNEL_STATUS]}

          <ArrowRightIcon />

          <SelectWrapper
            $isActive={channelStatusSelectProps.open}
          >
            <Select
              value={null}
              options={ACTIVE_CHANNEL_STATUSES}
              onChange={handleControlChange}
              selectProps={channelStatusSelectProps}
            />
          </SelectWrapper>
        </StyledMenuItem>

        <StyledMenuItem
          as={MenuItem}
          onClick={(event) => handlePopoverOpen(event, KEYS.GROWTH_MANAGER)}
          disabled={disabled}
          disableRipple
        >
          {TITLES[KEYS.GROWTH_MANAGER]}

          <ArrowRightIcon />
        </StyledMenuItem>

        <StyledMenuItem
          as={MenuItem}
          onClick={(event) => handlePopoverOpen(event, KEYS.GOAL_ROAS)}
          disabled={disabled || !isAppStatusReady}
          disableRipple
        >
          {TITLES[KEYS.GOAL_ROAS]}

          <ArrowRightIcon />
        </StyledMenuItem>

        {popover}

        <GlobalStyles styles={globalStyles} />
      </Menu>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={closeModal}
        title="Edit Confirmation"
        description={confirmationModalDescription}
      />
    </>
  );
});


GamesSelectedRowsActions.propTypes = {
  close: PropTypes.func.isRequired,
  anchor: PropTypes.instanceOf(Element),
  managersList: PropTypes.arrayOf(managerPropTypes).isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isAppStatusReady: PropTypes.bool,
};

GamesSelectedRowsActions.defaultProps = {
  anchor: null,
  disabled: false,
  isAppStatusReady: true,
};


export default GamesSelectedRowsActions;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiCheckbox from '@mui/material/Checkbox';

import { StyledCheckbox } from './styles';


const UncontrolledCheckbox = memo(({ value, ...otherProps }) => (
  <StyledCheckbox
    as={MuiCheckbox}
    color="secondary"
    checked={value}
    {...otherProps}
  />
));


UncontrolledCheckbox.propTypes = {
  value: PropTypes.bool,
};

UncontrolledCheckbox.defaultProps = {
  value: false,
};


export default UncontrolledCheckbox;

import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import settingsActions from 'core/settings/actions';

import {
  getChangeLimit,
  getIsChangeLimitFetching,
  getIsChangeLimitDeleteFetching,
  getIsChangeLimitUpdateFetching,
} from 'core/settings/selectors';

import { getFilteredList } from 'utils/grid';

import DataGrid from 'components/common/data-grid';
import ConfirmationModal from 'components/modals/confirmation';

import { ACTIVE_FILTERS } from './constants';
import { getConfig } from './config';


const ChangeLimitsList = memo((props) => {
  const { onEdit } = props;
  const dispatch = useDispatch();

  const changeLimits = useSelector(getChangeLimit);
  const isChangeLimitFetching = useSelector(getIsChangeLimitFetching);
  const isChangeLimitDeleteFetching = useSelector(getIsChangeLimitDeleteFetching);
  const isChangeLimitUpdateFetching = useSelector(getIsChangeLimitUpdateFetching);

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [deletableChangeLimitId, setDeletableChangeLimitId] = useState(null);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState({});

  const handleRowDelete = useCallback((changeLimitId) => {
    setIsDeleteConfirmationModalOpen(true);
    setDeletableChangeLimitId(changeLimitId);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    dispatch(settingsActions.deleteChangeLimit({
      id: deletableChangeLimitId,
    }));

    setLastEditedRow(deletableChangeLimitId);
    setDeletableChangeLimitId(null);
  }, [changeLimits, deletableChangeLimitId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleRowUpdate = useCallback((nextData) => {
    onEdit(nextData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const config = useMemo(() => getConfig(
    list,
    handleRowUpdate,
    handleRowDelete,
  ), [list, handleRowUpdate, handleRowDelete]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isChangeLimitUpdateFetching || isChangeLimitDeleteFetching) && id === lastEditedRow
  ), [isChangeLimitUpdateFetching, isChangeLimitDeleteFetching, lastEditedRow]);

  const getIsRowSelectable = useCallback(({ row: { isEmpty } }) => !isEmpty, []);

  const deletableChannelId = useMemo(() => deletableChangeLimitId ? changeLimits.find(({ id }) => id === deletableChangeLimitId).id : null, [changeLimits, deletableChangeLimitId]);
  useEffect(() => {
    setList(getFilteredList(changeLimits, listFilter));
  }, [changeLimits, listFilter]);

  return (
    <>
      <DataGrid
        config={config}
        getIsRowFetching={getIsRowFetching}
        isRowSelectable={getIsRowSelectable}
        isFetching={isChangeLimitFetching}
        checkboxSelection={false}
        onFilterChange={setListFilter}
        activeFilters={ACTIVE_FILTERS}
        useFilters
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Delete Confirmation"
        description={`Please, confirm that you want to delete this Limit Setting (id: ${deletableChannelId})`}
      />
    </>
  );
});


ChangeLimitsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

export default ChangeLimitsList;


import React, { memo, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import update from 'immutability-helper';
import classnames from 'classnames';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DataGridPro, LicenseInfo, gridClasses } from '@mui/x-data-grid-pro';

import appConfig from 'config';

import HeaderSearchInput from './header-search-input';
import NoRows from './no-rows';

import {
  Wrapper,
  StyledDataGrid,
  SelectedRowsActionsWrapper,
  HEADER_HEIGHT,
  HEADER_WITH_FILTERS_HEIGHT,
  GRID_ROW_FETCHING_CLASS,
} from './styles';
import { PAGE_SIZES, DEFAULT_PAGE_SIZE, DEFAULT_PAGE } from './constants';


LicenseInfo.setLicenseKey(appConfig.MUI_PRO_LICENSE_KEY);

const DataGrid = memo(({
  config,
  isFetching,
  onSelectionChange,
  selection,
  filter,
  onFilterChange,
  useFilters,
  getIsRowFetching,
  renderSelectedRowsActions,
  getRowClassName,
  activeFilters,
  checkboxSelection,
  onPageSizeChange,
  onPageChange,
  isServerPagination,
  rowCount,
  initialPage,
  initialPageSize,
  ...otherProps
}) => {
  const { rows, columns } = config;

  const selectedRowsActionsWrapperRef = useRef(null);

  const [selectionModel, setSelectionModel] = useState(() => selection);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [page, setPage] = useState(initialPage);
  const [filterModel, setFilterModel] = useState({});
  const [selectedRowsActionsAnchor, setSelectedRowsActionsAnchor] = useState(null);

  const getUpdatedFilter = useCallback((key, value) => {
    if (!value) {
      return update(filterModel, {
        $unset: [key],
      });
    }

    return update(filterModel, {
      [key]: { $set: value },
    });
  }, [filterModel]);

  const handleFilterChange = useCallback((value, field) => {
    const nextFilter = getUpdatedFilter(field, value?.trim ? value.trim() : value);

    setFilterModel(nextFilter);
    onFilterChange(nextFilter);
  }, [getUpdatedFilter, onFilterChange]);

  const handleFilterChangeDebounced = useDebouncedCallback(handleFilterChange, 500);

  const handleInputClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const columnsWithCustomHeader = useMemo(() => {
    if (!useFilters) {
      return columns;
    }

    return columns.map((column) => {
      const { renderHeader, headerName, options, tooltipText, clearableFilter, isMultipleOptions, renderFilterValue } = column;

      if (renderHeader) {
        return column;
      }

      return {
        ...column,
        renderHeader: ({ field }) => (
          <HeaderSearchInput
            field={field}
            options={options}
            isMultipleOptions={isMultipleOptions}
            headerName={headerName}
            initialValue={filterModel[field]}
            activeFilters={activeFilters}
            onFilterChange={handleFilterChangeDebounced}
            onInputClick={handleInputClick}
            tooltipText={tooltipText}
            clearableFilter={clearableFilter}
            renderFilterValue={renderFilterValue}
          />
        ),
      };
    });
  }, [columns, handleFilterChangeDebounced, useFilters, activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRowClassNameForGrid = useCallback((props) => {
    if (!getRowClassName && !getIsRowFetching) {
      return undefined;
    }

    const classes = [];

    if (getRowClassName) {
      classes.push(getRowClassName(props));
    }

    if (getIsRowFetching) {
      classes.push(getIsRowFetching(props) ? GRID_ROW_FETCHING_CLASS : '');
    }

    return classes.join(' ');
  }, [getRowClassName, getIsRowFetching]);

  const selectedRowsActionsClick = useCallback((event) => {
    event.stopPropagation();

    if (!selectedRowsActionsAnchor) {
      setSelectedRowsActionsAnchor(event.currentTarget);
    }
  }, [selectedRowsActionsAnchor]);

  const selectedRowsActionsClose = useCallback(() => {
    setSelectedRowsActionsAnchor(null);
  }, []);

  // TODO: move to separated component
  const selectedRowsActions = useMemo(() => renderSelectedRowsActions ? (
    <SelectedRowsActionsWrapper
      ref={selectedRowsActionsWrapperRef}
      onClick={selectedRowsActionsClick}
    >
      {selectedRowsActionsAnchor ? (
        <ArrowDropUpIcon />
      ) : (
        <ArrowDropDownIcon />
      )}

      {renderSelectedRowsActions({
        anchor: selectedRowsActionsAnchor,
        close: selectedRowsActionsClose,
      })}
    </SelectedRowsActionsWrapper>
  ) : null, [selectedRowsActionsAnchor, selectedRowsActionsClick, renderSelectedRowsActions]); // eslint-disable-line react-hooks/exhaustive-deps

  const appendSelectedRowsActions = useCallback(() => {
    const checkboxContainer = document.querySelector(`.${gridClasses.columnHeaderCheckbox}`);

    if (!renderSelectedRowsActions || !checkboxContainer) {
      return;
    }

    checkboxContainer.appendChild(selectedRowsActionsWrapperRef.current);
  }, [renderSelectedRowsActions]);

  const rowsPerPageOptions = useMemo(() => {
    if (rows.length > PAGE_SIZES[PAGE_SIZES.length - 1]) {
      return [...PAGE_SIZES, rows.length];
    }

    return PAGE_SIZES;
  }, [rows.length]);

  const gridComponents = useMemo(() => ({
    NoRowsOverlay: NoRows,
  }), []);

  useEffect(() => {
    onSelectionChange(selectionModel);
  }, [selectionModel, onSelectionChange]);

  useEffect(() => {
    setFilterModel(filter);
  }, [filter]);

  useEffect(() => {
    onPageChange(page);
  }, [page, onPageChange]);

  useEffect(() => {
    onPageSizeChange(pageSize);
  }, [pageSize, onPageSizeChange]);

  useEffect(() => {
    setTimeout(appendSelectedRowsActions); // NOTE: we have to do it async because there is no '.MuiDataGrid-columnHeaderCheckbox' at time of first render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const serverPaginationProps = useMemo(() => {
    if (!isServerPagination) {
      return {};
    }

    return {
      paginationMode: 'server',
      onPageChange: setPage,
      page,
      rowCount: rowCount || 0,
    };
  }, [isServerPagination, page, rowCount]);

  return (
    <Wrapper
      className={classnames({ 'data-grid--with-filters': useFilters })}
      $hasSelectedRowsActions={Boolean(renderSelectedRowsActions)}
      $rowsExist={!!rows.length}
    >
      <StyledDataGrid
        as={DataGridPro}
        rows={rows}
        columns={columnsWithCustomHeader}
        pageSize={pageSize}
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={setPageSize}
        loading={isFetching}
        headerHeight={useFilters ? HEADER_WITH_FILTERS_HEIGHT : HEADER_HEIGHT}
        getRowClassName={getRowClassNameForGrid}
        checkboxSelection={checkboxSelection}
        pagination
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        disableColumnReorder
        components={gridComponents}
        disableMultipleColumnsSorting
        {...serverPaginationProps}
        {...otherProps}
      />

      {selectedRowsActions}
    </Wrapper>
  );
});


DataGrid.propTypes = {
  config: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  isFetching: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  selection: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  filter: PropTypes.shape({}),
  onFilterChange: PropTypes.func,
  useFilters: PropTypes.bool,
  getIsRowFetching: PropTypes.func,
  getRowClassName: PropTypes.func,
  renderSelectedRowsActions: PropTypes.func,
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  checkboxSelection: PropTypes.bool,
  onPageSizeChange: PropTypes.func,
  onPageChange: PropTypes.func,
  isServerPagination: PropTypes.bool,
  rowCount: PropTypes.number,
  initialPage: PropTypes.number,
  initialPageSize: PropTypes.number,
};

DataGrid.defaultProps = {
  isFetching: false,
  onSelectionChange: () => {},
  onFilterChange: () => {},
  onPageSizeChange: () => {},
  onPageChange: () => {},
  selection: [],
  filter: {},
  useFilters: false,
  getIsRowFetching: undefined,
  getRowClassName: undefined,
  renderSelectedRowsActions: null,
  activeFilters: null,
  checkboxSelection: true,
  isServerPagination: false,
  rowCount: null,
  initialPage: DEFAULT_PAGE,
  initialPageSize: DEFAULT_PAGE_SIZE,
};


export default DataGrid;

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Input from 'components/common/input';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { isValueExists } from 'utils';

import Checkbox from 'components/common/checkbox/uncontrolled';

import { StyledSelect, StyledMenuItem, StackStyles } from './styles';


const Select = memo(({ options, value, onChange, renderItem, selectProps, indicator: IndicatorComponent, disabledOptionTooltip, clearable, ...otherProps }) => {
  const muiSelectProps = useMemo(() => ({
    displayEmpty: true,
    ...selectProps,
  }), [selectProps]);

  const selectOptions = useMemo(() => {
    const isMultiple = muiSelectProps.multiple;

    return options.map((option) => {
      const { id, name, disabled } = option;

      const content = (
        <StyledMenuItem
          as={MenuItem}
          key={id}
          value={id}
          disabled={!!disabled}
        >
          {isMultiple && (
            <Checkbox checked={value.includes(id)} />
          )}

          {renderItem ? renderItem(option) : name}
        </StyledMenuItem>
      );

      if (disabled) {
        return (
          <Tooltip
            placement="top"
            title={disabledOptionTooltip}
            disableFocusListener
            disableTouchListener
            arrow
            key={id}
          >
            <div>
              {content}
            </div>
          </Tooltip>
        );
      }

      return content;
    });
  }, [options, renderItem, muiSelectProps, value, disabledOptionTooltip]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={StackStyles}
      className="select-wrapper"
    >
      {
        IndicatorComponent && (
          <IndicatorComponent
            status={value}
          />
        )
      }

      <StyledSelect
        as={Input}
        select
        value={isValueExists(value) ? value : ''}
        onChange={onChange}
        SelectProps={muiSelectProps}
        $withIndicator={!!IndicatorComponent}
        {...otherProps}
      >
        {selectOptions}
      </StyledSelect>
    </Stack>

  );
});


Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}).isRequired,
    ]),
    name: PropTypes.string.isRequired,
  })).isRequired,
  renderItem: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  selectProps: PropTypes.shape({}),
  indicator: PropTypes.shape({}),
  clearable: PropTypes.bool,
  disabledOptionTooltip: PropTypes.string,
};

Select.defaultProps = {
  renderItem: null,
  value: null,
  selectProps: {},
  indicator: null,
  clearable: false,
  disabledOptionTooltip: '',
};


export default Select;

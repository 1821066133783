export const USERS_URL = 'users';
export const INVITE_URL = 'invite';
export const ACTIVITY_LOG_URL = 'activity';

export const ACTIVITY_LOG_PATHS = {
  CHANNEL: 'channel',
  CAMPAIGN: 'campaign',
  GEO: 'geo',
  SOURCE: 'source',
};

export const ACTIVITY_LOG_COLORS = {
  [ACTIVITY_LOG_PATHS.CHANNEL]: '#3A76E8',
  [ACTIVITY_LOG_PATHS.CAMPAIGN]: '#73BC24',
  [ACTIVITY_LOG_PATHS.GEO]: '#ED6C02',
  [ACTIVITY_LOG_PATHS.SOURCE]: '#F50057',
};

export const ACTIVITY_LOG_NAMES = {
  [ACTIVITY_LOG_PATHS.CHANNEL]: 'Channels',
  [ACTIVITY_LOG_PATHS.CAMPAIGN]: 'Campaigns',
  [ACTIVITY_LOG_PATHS.GEO]: 'Geos',
  [ACTIVITY_LOG_PATHS.SOURCE]: 'Sources',
};

import React, { memo, useCallback, useState, useMemo } from 'react';

import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { gameDetailsPropTypes } from 'models/game';
import AnalyticsService from 'services/analytics';

import Title from 'components/common/title';
import GameModal from 'components/games/game-modal';
import GameStatus from 'components/games/game-status';

import { GAME_STATUS_KEYS } from 'core/games/constants';

import { getTitle } from './utils';
import { INFO_TEXT } from './constants';
import {
  Wrapper,
  StyledTitle,
  StatusWrapper,
} from './styles';


const GameDetailsForm = memo(({ data }) => {
  const [isGameModalOpen, setIsGameModalOpen] = useState(false);

  const handleEditGameClick = useCallback(() => {
    setIsGameModalOpen(true);
  }, []);

  const handleEditGameCancel = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.UPDATE_GAME_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsGameModalOpen(false);
  }, []);

  const title = useMemo(() => getTitle(data), [data]);
  const ActionIcon = data.status === GAME_STATUS_KEYS.ARCHIVED ? RemoveRedEyeIcon : CreateIcon;

  return (
    <>
      <Wrapper>
        <StyledTitle as={Title}>
          {title}
        </StyledTitle>

        <IconButton
          aria-label="edit"
          size="small"
          color="secondary"
          onClick={handleEditGameClick}
        >
          <ActionIcon
            fontSize="inherit"
          />
        </IconButton>
      </Wrapper>

      <StatusWrapper>
        <GameStatus
          status={data.status}
        />
      </StatusWrapper>


      <GameModal
        isOpen={isGameModalOpen}
        onCancel={handleEditGameCancel}
        isEdit
        tooltipText={INFO_TEXT}
        initialValues={data}
        withDeletion
      />
    </>
  );
});


GameDetailsForm.propTypes = {
  data: gameDetailsPropTypes.isRequired,
};

GameDetailsForm.defaultProps = {

};


export default GameDetailsForm;

export const CAMPAIGN_STATUS = {
  STOPPED: 'STOPPED',
  CAMPAIGN_STOPPED: 'CAMPAIGN_STOPPED',
  RUNNING: 'RUNNING',
};

export const CAMPAIGN_STATUS_OPTIONS = Object.entries(CAMPAIGN_STATUS).map(([id, name]) => ({
  id,
  name,
}));

export const FIELDS = {
  CHANNEL: 'channel',
  CAMPAIGN_NAME: 'campaignName',
  CAMPAIGN_TYPE: 'campaignType',
  BILLING_TYPE: 'billingType',
  COUNTRY_FULL: 'countryFull',
  COUNTRY: 'country',
  WEEK_SPEND: 'weekSpend',
  WEEKLY_INSTALLS: 'weekInstalls',
  GOAL_ROAS: 'goalRoas',
  SUGGESTED_ROAS: 'suggestedRoas',
  PLATFORM: 'platform',
  GAME_NAME: 'gameName',
  CAMPAIGN_STATUS: 'campaignStatus',
  BUDGET: 'budget',
  BUDGET_TYPE: 'budgetType',
  BIDDER_STATUS: 'bidderStatus',
  RETURN_WINDOW: 'returnWindow',
  RW_RPI_FORECAST: 'rwRpiForecast',
  RW_ROAS_TARGET: 'rwRoasTarget',
  CPA_ROAS_SETTING_TYPE: 'cpaRoasSettingType',
  CPA_ROAS_SETTING: 'cpaRoasSetting',
  DELTA_CPA_ROAS_SETTING: 'deltaCpaRoasSetting',
};

export const ACTIVE_FILTERS = [
  FIELDS.CAMPAIGN_NAME,
  FIELDS.CHANNEL,
  FIELDS.CAMPAIGN_STATUS,
];

export const FILTERS_KEYS = {
  PAGE: 'page',
  LIMIT: 'limit',
  GAME_IDS: 'game_ids',
  CAMPAIGN_IDS: 'campaign_ids',
  [FIELDS.CAMPAIGN_NAME]: 'CAMPAIGN_NAME',
  [FIELDS.CHANNEL]: 'CHANNEL',
  [FIELDS.CAMPAIGN_STATUS]: 'CAMPAIGN_STATUS',
};

export const FILTERS_KEY_OPERATORS = {
  [FIELDS.NAME]: 'contains',
  [FIELDS.STATUS]: 'in',
  [FIELDS.TYPE]: 'in',
  [FIELDS.CAMPAIGN_NAME]: 'contains',
  [FIELDS.CHANNEL]: 'in',
  [FIELDS.CAMPAIGN_STATUS]: 'in',
};

export const DEFAULT_FILTERS_VALUES = {
  [FILTERS_KEYS.PAGE]: 0,
  [FILTERS_KEYS.LIMIT]: 50, // TODO: use grid default value
};

export const QUERY_PARAMS = {
  [FILTERS_KEYS.PAGE]: 'p',
  [FILTERS_KEYS.LIMIT]: 'l',
};

export const EXTENDED_QUERY_PARAMS = {
  ...QUERY_PARAMS,
  [FILTERS_KEYS.SORT]: 'sort',
};

export const CAMPAIGNS_LIST_QUERY_PARAMS = {
  NAME: 'c_n',
  STATUS: 'c_s',
  TYPE: 'c_t',
  CAMPAIGN_NAME: 'c_n',
  CHANNEL: 'ch',
  CAMPAIGN_STATUS: 'c_s',
};


export const GEOS_LIST_QUERY_PARAMS_BY_FIELDS = {
  [FIELDS.CAMPAIGN_NAME]: CAMPAIGNS_LIST_QUERY_PARAMS.CAMPAIGN_NAME,
  [FIELDS.CHANNEL]: CAMPAIGNS_LIST_QUERY_PARAMS.CHANNEL,
  [FIELDS.CAMPAIGN_STATUS]: CAMPAIGNS_LIST_QUERY_PARAMS.CAMPAIGN_STATUS,
};

import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'gamesV4',
  storage,
  blacklist: [
    'isListFetching',
    'isGameFetching',
    'isGameUpdateFetching',
    'isGameDeleteFetching',
    'isGameCreateFetching',
    'isGoalRoasUpdateFetching',
    'isGoalRoasResetFetching',
    'overrideList',
  ],
};


export default persistConfig;

import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Button from 'components/common/button';
import Text from 'components/common/text';

import HistoricalBidsFilterForm from 'components/historical-bids/filter-form';

import { getConfirmationText } from './utils';
import { ControlWrapper, StyledButton, StyledText } from './styles';


const HistoricalBidsHeader = memo(({ isFetching, total, filter, onFilterChange, onSubmit }) => {
  const [isConfirmMode, setIsConfirmMode] = useState(false);

  const handleFilterChange = useCallback((nextFilter) => {
    onFilterChange(nextFilter);
    setIsConfirmMode(false);
  }, [onFilterChange]);

  const handlePushClick = useCallback(() => {
    if (isConfirmMode) {
      onSubmit();
      return;
    }

    setIsConfirmMode(true);
  }, [isConfirmMode, onSubmit]);

  return (
    <Stack
      direction="row"
      alignItems="flex-end"
    >
      <ControlWrapper>
        <HistoricalBidsFilterForm
          initialValues={filter}
          onSubmit={handleFilterChange}
          isFetching={isFetching}
        />
      </ControlWrapper>

      <ControlWrapper>
        <Stack
          flexDirection="row"
          alignItems="center"
        >
          <ControlWrapper>
            <StyledButton
              as={Button}
              onClick={handlePushClick}
              isFetching={isFetching}
              disabled={!total}
              {...(isConfirmMode ? {
                variant: 'outlined',
                color: 'error',
              } : {})}
            >
              Push
            </StyledButton>
          </ControlWrapper>

          {
            isConfirmMode && (
              <ControlWrapper>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                >
                  <ErrorOutlineIcon color="error" />

                  <StyledText
                    as={Text}
                    variant="body2"
                    color="error"
                  >
                    {getConfirmationText(total, filter.timePeriod)}
                  </StyledText>
                </Stack>
              </ControlWrapper>
            )
          }
        </Stack>
      </ControlWrapper>
    </Stack>
  );
});


HistoricalBidsHeader.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  filter: PropTypes.shape({
    timePeriod: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]).isRequired,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default HistoricalBidsHeader;

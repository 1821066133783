import { isValueExists } from 'utils';


export const listModel = (response) => response
  .map(({
    id,
    channel,
    channel_id: channelId,
    campaign_id: campaignId,
    campaign_name: campaignName,
    campaign_type: campaignType,
    billing_type: billingType,
    country_full: countryFull,
    country,
    week_spend: weekSpend,
    week_installs: weekInstalls,
    goal_roas: goalRoas,
    override,
    roasSuggest,
    platform,
    game_name: gameName,
    campaign_status: campaignStatus,
    budget,
    budget_type: budgetType,
    bidder_status: bidderStatus,
    return_window: returnWindow,
    rw_rpi_forecast: rwRpiForecast,
    rw_roas_target: rwRoasTarget,
    cpa_roas_setting_type: cpaRoasSettingType,
    cpa_roas_setting: cpaRoasSetting,
    delta_cpa_roas_setting: deltaCpaRoasSetting,
  }) => ({
    id,
    channelId,
    campaignId,
    channel,
    campaignName,
    campaignType,
    billingType,
    countryFull,
    country,
    weekSpend,
    weekInstalls,
    goalRoas: goalRoasModel(goalRoas),
    override: overrideModel(override),
    suggestedRoas: suggestedRoasModel(roasSuggest),
    platform,
    gameName,
    campaignStatus,
    budget: budgetModel(budget),
    budgetType,
    bidderStatus,
    returnWindow,
    rwRpiForecast,
    rwRoasTarget: decimalToPercentage(rwRoasTarget),
    cpaRoasSettingType,
    cpaRoasSetting,
    deltaCpaRoasSetting,
  }));

export const goalRoasModel = (value) => isValueExists(value) ? Math.round(Number(value) * 100) : value;
export const decimalToPercentage = (value) => isValueExists(value) ? Math.round(Number(value) * 100) : value;

export const overrideModel = ({ parentGoal, goal, ...rest }) => ({
  parentGoal: goalRoasModel(parentGoal),
  goal: goalRoasModel(goal),
  ...rest,
});

export const suggestedRoasModel = ({ description, goal, diff, createdAt } = {}) => ({
  description,
  goal: goalRoasModel(goal),
  diff: goalRoasModel(diff),
  createdAt,
});

export const budgetModel = (budget) => ({
  daily: budget?.daily || 0,
  total: budget?.total || 0,
});

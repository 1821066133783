export const FIELDS = {
  EMAIL: 'emailAddress',
  EMAIL_STATUS: 'emailStatus',
  LAST_LOGIN_TIME: 'lastSeenAt',
  IS_SUPER_ADMIN: 'isSuperAdmin',
  IS_MANAGER: 'isManager',
  ACTIONS: 'actions',
  CHANNEL: 'CHANNEL',
  CAMPAIGN_TYPE: 'CAMPAIGN_TYPE',
  COOLDOWN_LIMIT: 'COOLDOWN_LIMIT',
};


export const ACTIVE_FILTERS = [FIELDS.CHANNEL];

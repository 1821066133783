import { createSelector } from 'reselect';

import { APP_STATUSES } from './constants';


const getLocalState = (state) => state.settings;
const getConfigState = (state) => getLocalState(state).config;
const getIsConfigFetchingState = (state) => getLocalState(state).isConfigFetching;
const getIsConfigUpdateFetchingState = (state) => getLocalState(state).isConfigUpdateFetching;
const getIsAppStatusFetchingState = (state) => getLocalState(state).isAppStatusFetching;
const getAppStatusState = (state) => getLocalState(state).appStatus;
const getCountriesState = (state) => getLocalState(state).countries;
const getIsCountriesFetchingState = (state) => getLocalState(state).isCountriesFetching;
const getPushFrequenciesState = (state) => getLocalState(state).pushFrequencies;
const getIsPushFrequenciesFetchingState = (state) => getLocalState(state).isPushFrequenciesFetching;
const getIsPushFrequencyDeleteFetchingState = (state) => getLocalState(state).isPushFrequencyDeleteFetching;
const getIsPushFrequencyUpdateFetchingState = (state) => getLocalState(state).isPushFrequencyUpdateFetching;
const getChangeLimitState = (state) => getLocalState(state).changeLimit;
const getIsChangeLimitFetchingState = (state) => getLocalState(state).isChangeLimitFetching;
const getIsChangeLimitDeleteFetchingState = (state) => getLocalState(state).isChangeLimitDeleteFetching;
const getIsChangeLimitUpdateFetchingState = (state) => getLocalState(state).isChangeLimitUpdateFetching;

export const getConfig = createSelector(
  getConfigState,
  (config) => config,
);

export const getIsConfigFetching = createSelector(
  getIsConfigFetchingState,
  (isFetching) => isFetching,
);

export const getIsConfigUpdateFetching = createSelector(
  getIsConfigUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsAppStatusFetching = createSelector(
  getIsAppStatusFetchingState,
  (isFetching) => isFetching,
);

export const getAppStatus = createSelector(
  getAppStatusState,
  (appStatus) => appStatus,
);

export const getIsCountriesFetching = createSelector(
  getIsCountriesFetchingState,
  (isFetching) => isFetching,
);

export const getCountries = createSelector(
  getCountriesState,
  (countries) => countries,
);

export const getIsAppStatusReady = createSelector(
  getAppStatusState,
  (status) => status?.status === APP_STATUSES.READY,
);

export const getPushFrequencies = createSelector(
  getPushFrequenciesState,
  (pushFrequencies) => pushFrequencies,
);

export const getIsPushFrequenciesFetching = createSelector(
  getIsPushFrequenciesFetchingState,
  (isFetching) => isFetching,
);

export const getIsPushFrequencyDeleteFetching = createSelector(
  getIsPushFrequencyDeleteFetchingState,
  (isFetching) => isFetching,
);

export const getIsPushFrequencyUpdateFetching = createSelector(
  getIsPushFrequencyUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getChangeLimit = createSelector(
  getChangeLimitState,
  (changeLimit) => changeLimit,
);

export const getIsChangeLimitFetching = createSelector(
  getIsChangeLimitFetchingState,
  (isFetching) => isFetching,
);

export const getIsChangeLimitDeleteFetching = createSelector(
  getIsChangeLimitDeleteFetchingState,
  (isFetching) => isFetching,
);

export const getIsChangeLimitUpdateFetching = createSelector(
  getIsChangeLimitUpdateFetchingState,
  (isFetching) => isFetching,
);

import { styled } from '@mui/system';

import { inputBaseClasses } from '@mui/material/InputBase';
import { checkboxClasses } from '@mui/material/Checkbox';
import { svgIconClasses } from '@mui/material/SvgIcon';


export const StyledSelect = styled('input')(({ theme, $withIndicator }) => ({
  width: '100%',
  background: 'inherit',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  textAlign: 'left',

  [`.${inputBaseClasses.formControl}`]: {
    background: 'inherit',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
  },

  [`div.${inputBaseClasses.input}`]: {
    paddingLeft: $withIndicator ? theme.spacing(3) : theme.spacing(1),
    lineHeight: '22px',
  },
}));

export const StyledMenuItem = styled('li')(({ theme }) => ({
  [`& .${svgIconClasses.root}`]: {
    fontSize: '25px',
  },

  [`& .${checkboxClasses.root}`]: {
    padding: theme.spacing(1, 0),
    marginRight: theme.spacing(1),
  },
}));

export const StackStyles = {
  width: '100%',
  position: 'relative',
};

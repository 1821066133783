import { parseQueryParams } from 'utils/url';

import { TYPES, ALLOWED_COMPLEX_TYPES } from './constants';
import { ComplexType } from './models';


const setStringParam = (params, [key, value]) => ({
  ...params,
  [key]: String(value),
});

const setNumberParam = (params, [key, value]) => ({
  ...params,
  [key]: Number(value),
});

const setBooleanParam = (params, [key, value]) => {
  const valueNumber = Number(value);
  const isTrue = value === 'true' || valueNumber === 1;
  const isFalse = value === 'false' || valueNumber === 0;

  return {
    ...params,
    [key]: isTrue || !isFalse,
  };
};

const setArrayParam = (params, [key, value]) => {
  if (!params[key]) {
    return {
      ...params,
      [key]: [value],
    };
  }

  return {
    ...params,
    [key]: [
      ...params[key],
      value,
    ],
  };
};

const TYPES_HANDLERS = {
  [TYPES.ARRAY]: setArrayParam,
  [TYPES.STRING]: setStringParam,
  [TYPES.NUMBER]: setNumberParam,
  [TYPES.BOOLEAN]: setBooleanParam,
};

export const parseParams = (searchParams, userParams) => {
  const paramsList = parseQueryParams(searchParams);

  return paramsList.reduce((result, param) => {
    const [key] = param;
    const paramType = userParams[key];

    if (!paramType) {
      return result;
    }

    const handler = TYPES_HANDLERS[paramType];

    if (!handler && paramType instanceof ComplexType) {
      const { baseType, valueType } = paramType;
      const baseTypeHandler = TYPES_HANDLERS[baseType];
      const valueTypeHandler = TYPES_HANDLERS[valueType];
      const convertedValue = (valueTypeHandler({}, param))[key];

      return baseTypeHandler(result, [key, convertedValue]);
    }

    return handler(result, param);
  }, {});
};

export const withType = (baseType, valueType) => {
  if (!ALLOWED_COMPLEX_TYPES.includes(baseType)) {
    throw new Error(`Base type should be one of types: ${ALLOWED_COMPLEX_TYPES.join(', ')}`);
  }

  return new ComplexType(baseType, valueType);
};

import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import { getIsGameCreateFetching, getLionApps, getStoreIds, getIsGameDeleteFetching, getIsGameUpdateFetching } from 'core/v4/games/selectors';
import { getManagersList } from 'core/managers/selectors';
import { GAME_STATUSES, GAME_STATUS_KEYS, PLATFORMS_OPTIONS, CHANNEL_STATUSES_CREATE } from 'core/v4/games/constants';
import gamesActions from 'core/v4/games/actions';
import AnalyticsService from 'services/analytics';

import useFormik from 'hooks/use-formik';

import Title from 'components/common/title';
import Input from 'components/common/input';
import Select from 'components/common/uncontrolled-select';
import LionAppAutocomplete from 'components/games/lion-app-autocomplete';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import ConfirmationModal from 'components/modals/confirmation';
import UncontrolledAutocomplete from 'components/common/uncontrolled-autocomplete';
import Text from 'components/common/text';
import Grid from '@mui/material/Grid';
import networksActions from 'core/v4/networks/actions';


import { isValueExists, getDifference } from 'utils';
import { getManagersOptionLabel } from 'utils/grid';

import { KEYS, LABELS, STORE_ID_INPUT_PROPS } from './constants';
import { getValidationSchema } from './validation';
import { getTitle, getInitialValues, getListWithAllChannels } from './utils';
import {
  FieldWrapper,
  StyledButton,
  Wrapper,
  InfoIcon,
  TitleWrapper,
} from './styles';


const GameForm = memo(({ initialValues, isEdit, tooltipText, withDeletion, onCancel }) => {
  const dispatch = useDispatch();
  const isCreateFetching = useSelector(getIsGameCreateFetching);
  const isDeleteFetching = useSelector(getIsGameDeleteFetching);
  const isUpdateFetching = useSelector(getIsGameUpdateFetching);
  const lionApps = useSelector(getLionApps);
  const storeIds = useSelector(getStoreIds);
  const managersList = useSelector(getManagersList);

  const isGameArchived = initialValues?.[KEYS.STATUS] === GAME_STATUS_KEYS.ARCHIVED;

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [listNetwork, setListNetwork] = useState([]);

  const handleFormSubmit = useCallback((formData) => {
    if (isEdit) {
      const newFormData = { ...formData };
      delete newFormData.networks;

      const transformedInitialValues = getInitialValues(initialValues, managersList);
      const dataToSubmit = getDifference(newFormData, transformedInitialValues);

      if (Object.keys(dataToSubmit).length) {
        AnalyticsService.trackEvent({
          category: AnalyticsService.CATEGORIES.GAMES,
          name: AnalyticsService.NAMES.UPDATE_GAME,
          action: AnalyticsService.ACTIONS.SUBMIT,
        });

        dispatch(gamesActions.updateGame(
          {
            id: initialValues.id,
            ...dataToSubmit,
            [KEYS.GROWTH_MANAGER]: isValueExists(dataToSubmit[KEYS.GROWTH_MANAGER]?.id) ? dataToSubmit[KEYS.GROWTH_MANAGER]?.id : undefined,
          },
          { refetchGamesList: true },
        ));
      }

      // update networks
      const formNetwork = formData.networks;
      const listChannel = getListWithAllChannels(initialValues?.campaignSets ?? [], values[KEYS.PLATFORM]);
      const listUpdate = {};
      const listCreate = {};

      listChannel.forEach((item) => {
        if (item.KEY === item.ID) {
          listUpdate[item.CHANNEL] = {
            ID: item.ID,
            STATUS: item.CHANNEL_STATUS,
          };
        } else {
          listCreate[item.CHANNEL] = {
            STATUS: item.CHANNEL_STATUS,
            CHANNEL: item.CHANNEL,
          };
        }
      });

      Object.keys(formNetwork).forEach((item) => {
        if (formNetwork[item].CAMPAIGN_SET_ID.length || formNetwork[item].STATUS.length) {
          if (listUpdate[item]) {
            listUpdate[item].CAMPAIGN_SET_ID = formNetwork[item].CAMPAIGN_SET_ID;
            // select on
            if (formNetwork[item].STATUS === 'PENDING') {
              if (listUpdate[item].STATUS === 'PAUSED') {
                listUpdate[item].STATUS = 'PENDING';
              }
            } else {
              listUpdate[item].STATUS = formNetwork[item].STATUS;
            }
          }
        }

        if (listCreate[item]) {
          if (formNetwork[item].STATUS.length === 0 || formNetwork[item].CAMPAIGN_SET_ID.length === 0) {
            delete listCreate[item];
            return;
          }
          listCreate[item].STATUS = formNetwork[item].STATUS;
          listCreate[item].CAMPAIGN_SET_ID = formNetwork[item].CAMPAIGN_SET_ID;
        }
      });

      // update
      if (Object.keys(listUpdate).length) {
        const transformListUpdate = {
          networks: listUpdate,
        };

        dispatch(networksActions.updateNetworkMultiple({
          gameId: Number(initialValues.id),
          ...transformListUpdate,
        }));
      }

      // create
      if (Object.keys(listCreate).length) {
        const transformListCreate = {
          networks: listCreate,
        };

        dispatch(networksActions.addNetworkMultiple({
          gameId: Number(initialValues.id),
          ...transformListCreate,
        }));
      }

      onCancel();
      return;
    }

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.ADD_NEW_GAME,
      action: AnalyticsService.ACTIONS.SUBMIT,
    });

    dispatch(gamesActions.createGame({
      ...formData,
      [KEYS.GROWTH_MANAGER]: isValueExists(formData[KEYS.GROWTH_MANAGER]?.id) ? formData[KEYS.GROWTH_MANAGER]?.id : null,
    }));
    onCancel();
  }, [isEdit, managersList]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    isValid,
    touched,
    handleBlurAndTrimValue,
  } = useFormik({
    initialValues: getInitialValues(initialValues, managersList),
    onSubmit: handleFormSubmit,
    validationSchema: getValidationSchema(storeIds, isEdit),
  });

  const handleDelete = useCallback(() => {
    setIsDeleteConfirmationModalOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    dispatch(gamesActions.deleteGame({ id: initialValues.id }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleAutocompleteChange = useCallback((value, event, { name }, option) => {
    setFieldTouched(name);
    setFieldValue(name, option);
  }, [setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const lionAppAutocompleteProps = useMemo(() => ({
    name: KEYS.LION_APP,
  }), []);

  const lionAppAutocompleteInputProps = useMemo(() => ({
    label: 'Lion App',
    error: touched[KEYS.LION_APP] && errors[KEYS.LION_APP],
  }), [touched, errors]);

  const growthManagerAutocompleteProps = useMemo(() => ({
    name: KEYS.GROWTH_MANAGER,
  }), []);

  const growthManagerAutocompleteInputProps = useMemo(() => ({
    label: LABELS[KEYS.GROWTH_MANAGER],
    error: touched[KEYS.GROWTH_MANAGER] && errors[KEYS.GROWTH_MANAGER],
  }), [touched, errors]);

  useEffect(() => {
    if (!isEdit) {
      setFieldValue(KEYS.STORE_ID, '');
    }

    const listChannel = getListWithAllChannels(initialValues?.campaignSets ?? [], values[KEYS.PLATFORM]);
    setListNetwork(listChannel);
  }, [values[KEYS.PLATFORM]]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Wrapper as={Paper}>
        <TitleWrapper>
          <Title>
            {getTitle(isEdit, isGameArchived)}
          </Title>

          {
            tooltipText && !isGameArchived && (
              <Tooltip
                title={tooltipText}
                placement="right"
                arrow
              >
                <InfoIcon
                  as={InfoOutlinedIcon}
                  color="secondary"
                  size="small"
                />
              </Tooltip>
            )
          }
        </TitleWrapper>

        <FieldWrapper>
          <Text variant="body2">
            Please provide the Account ID to each network, if you would like to activate the network for a game.
            You can configure networks by providing the Account ID later from the Edit Game window.
          </Text>
        </FieldWrapper>

        <form onSubmit={handleSubmit}>
          {
            !isGameArchived && (
              <FieldWrapper>
                <UncontrolledAutocomplete
                  name={KEYS.GROWTH_MANAGER}
                  value={values[KEYS.GROWTH_MANAGER]}
                  options={managersList}
                  onSubmit={handleAutocompleteChange}
                  inputProps={growthManagerAutocompleteInputProps}
                  getOptionLabel={getManagersOptionLabel}
                  autocompleteProps={growthManagerAutocompleteProps}
                  disabled={isGameArchived}
                />
              </FieldWrapper>
            )
          }

          <FieldWrapper>
            <Select
              name={KEYS.PLATFORM}
              value={values[KEYS.PLATFORM]}
              options={PLATFORMS_OPTIONS}
              onChange={handleChange}
              label="Platform"
              disabled={isEdit || isGameArchived}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Input
              name={KEYS.NAME}
              value={values[KEYS.NAME]}
              error={touched[KEYS.NAME] && errors[KEYS.NAME]}
              onBlur={handleBlurAndTrimValue}
              onChange={handleChange}
              label="App Name"
              disabled={isGameArchived}
            />
          </FieldWrapper>

          {
            isEdit && !isGameArchived && (
              <FieldWrapper>
                <Select
                  name={KEYS.STATUS}
                  value={values[KEYS.STATUS]}
                  options={GAME_STATUSES}
                  onChange={handleChange}
                  label="App Status"
                  disabled={isGameArchived}
                />
              </FieldWrapper>
            )
          }

          <FieldWrapper>
            <Input
              name={KEYS.STORE_ID}
              value={values[KEYS.STORE_ID]}
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
              error={touched[KEYS.STORE_ID] && errors[KEYS.STORE_ID]}
              disabled={isEdit || isGameArchived}
              {...STORE_ID_INPUT_PROPS[values[KEYS.PLATFORM]]}
              {...(isEdit ? { description: null } : {})}
            />
          </FieldWrapper>

          {
            isEdit ? (
              <FieldWrapper>
                <Input
                  value={values?.[KEYS.LION_APP]?.name ?? initialValues?.lionAppName ?? ''}
                  onBlur={handleBlurAndTrimValue}
                  onChange={handleChange}
                  label="Lion App"
                  disabled={isEdit}
                />
              </FieldWrapper>
            ) : (
              <FieldWrapper>
                <LionAppAutocomplete
                  autocompleteProps={lionAppAutocompleteProps}
                  inputProps={lionAppAutocompleteInputProps}
                  onSubmit={handleAutocompleteChange}
                  options={lionApps}
                  initialValue={values[KEYS.LION_APP]}
                  disabled={isGameArchived}
                />
              </FieldWrapper>
            )
          }

          <FieldWrapper>
            <Input
              name={KEYS.ADJUST_APP_TOKEN}
              value={values[KEYS.ADJUST_APP_TOKEN]}
              error={touched[KEYS.ADJUST_APP_TOKEN] && errors[KEYS.ADJUST_APP_TOKEN]}
              onBlur={handleBlurAndTrimValue}
              onChange={handleChange}
              label="Adjust App Token"
              disabled={isGameArchived}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Title
              style={{ fontSize: '1.3rem', color: 'grey' }}
            >
              Networks Configuration
            </Title>
          </FieldWrapper>

          {
            listNetwork.length && listNetwork.map((item) => (
              <div
                key={item.KEY}
                style={{ marginTop: '1.5rem' }}
              >
                <Title
                  style={{ fontSize: '.9rem', color: 'grey', marginBottom: '.5rem' }}
                >
                  {item.CHANNEL}
                </Title>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <Input
                      name={`${[KEYS.NETWORKS]}.${[item.CHANNEL]}.CAMPAIGN_SET_ID`}
                      value={values?.[KEYS.NETWORKS]?.[item.CHANNEL]?.CAMPAIGN_SET_ID ?? ''}
                      // error={touched[KEYS.NETWORKS][item.CHANNEL]['accountId'] && errors[KEYS.NETWORKS][item.CHANNEL]['accountId']}
                      onBlur={handleBlurAndTrimValue}
                      onChange={handleChange}
                      label="Account ID"
                      disabled={isGameArchived}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                  >
                    <Select
                      name={`${[KEYS.NETWORKS]}.${[item.CHANNEL]}.STATUS`}
                      value={values?.[KEYS.NETWORKS]?.[item.CHANNEL]?.STATUS ?? ''}
                      options={CHANNEL_STATUSES_CREATE}
                      onChange={handleChange}
                      label="Channel Status"
                      disabled={isGameArchived}
                    />
                  </Grid>
                </Grid>
              </div>
            ))
          }

          {
            !isGameArchived && (
              <FieldWrapper>
                <StyledButton
                  as={Button}
                  type="submit"
                  disabled={!isValid}
                  isFetching={isEdit ? isUpdateFetching : isCreateFetching}
                >
                  {isEdit ? 'Save' : 'Add Game'}
                </StyledButton>

                {
                  withDeletion && (
                    <StyledButton
                      as={Button}
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                      isFetching={isDeleteFetching}
                    >
                      Archive
                    </StyledButton>
                  )
                }

                {/* {
                  !isEdit && (
                    <Text variant="body2">
                      You still need to set your App Status to &quot;Live&quot; from the status &quot;Paused&quot; after you create it. Remember to change the channel status for the newly added channel from &quot;Pending&quot; to &quot;Live&quot; after the end of the first cycle.
                    </Text>
                  )
                } */}
              </FieldWrapper>
            )
          }
        </form>
      </Wrapper>

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Game Archive Confirmation"
        description="Please, confirm that you want to archive this Game"
      />
    </>
  );
});


GameForm.propTypes = {
  isEdit: PropTypes.bool,
  tooltipText: PropTypes.string,
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lionAppName: PropTypes.string,
    lionAppId: PropTypes.number,
    status: PropTypes.oneOf(Object.values(GAME_STATUS_KEYS)).isRequired,
    campaignSets: PropTypes.arrayOf(PropTypes.shape({
      // TODO: use model
    })),
  }),
  withDeletion: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

GameForm.defaultProps = {
  isEdit: false,
  tooltipText: null,
  initialValues: null,
  withDeletion: false,
};


export default GameForm;

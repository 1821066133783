import { isValueExists, isString } from 'utils';

import { ELEMENTS } from './constants';


export const getTransformedData = (data) => data.map(({ path, prevValue, nextValue, element, ...rest }) => ({
  ...rest,
  path,
  prevValue,
  nextValue,
  element,
  pathText: isString(path) ? path : [path.channel, path.campaign, path.geo, path.source].filter(isValueExists).join('/'),
  valueText: formatValue(element, prevValue, nextValue),
}));

export const formatValue = (element, prevValue, nextValue) => {
  const delimiter = isValueExists(prevValue) && isValueExists(nextValue) ? ' / ' : '';
  let prevValueFormatted;
  let nextValueFormatted;
  switch (element) {
    case ELEMENTS.GOAL_ROAS:
    case ELEMENTS.ROAS_OVERRIDE:
      prevValueFormatted = isValueExists(prevValue) ? `${Math.round(Number(prevValue) * 100)}%` : '';
      nextValueFormatted = isValueExists(nextValue) ? `${Math.round(Number(nextValue) * 100)}%` : '';
      break;
    default:
      prevValueFormatted = isValueExists(prevValue) ? prevValue : '';
      nextValueFormatted = isValueExists(nextValue) ? nextValue : '';
  }

  return `${prevValueFormatted}${delimiter}${nextValueFormatted}`;
};

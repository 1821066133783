import { createSelector } from 'reselect';


const getLocalState = (state) => state.auth;
const getTokenState = (state) => getLocalState(state).token;
const getIsTokenValidState = (state) => getLocalState(state).isTokenValid;
const getIsFetchingState = (state) => getLocalState(state).isFetching;
const getRedirectUrlState = (state) => getLocalState(state).redirectUrl;
const getIsRecoverPasswordFetchingState = (state) => getLocalState(state).isRecoverPasswordFetching;
const getIsResetPasswordFetchingState = (state) => getLocalState(state).isResetPasswordFetching;


export const getToken = createSelector(
  getTokenState,
  (token) => token,
);

export const getIsFetching = createSelector(
  getIsFetchingState,
  (isFetching) => isFetching,
);

export const getIsAuthorized = createSelector(
  getToken,
  (token) => Boolean(token),
);

export const getIsTokenValid = createSelector(
  getIsTokenValidState,
  (isTokenValid) => isTokenValid,
);

export const getRedirectUrl = createSelector(
  getRedirectUrlState,
  (redirectUrl) => redirectUrl,
);

export const getIsRecoverPasswordFetching = createSelector(
  getIsRecoverPasswordFetchingState,
  (isFetching) => isFetching,
);

export const getIsResetPasswordFetching = createSelector(
  getIsResetPasswordFetchingState,
  (isFetching) => isFetching,
);

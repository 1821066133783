import { styled } from '@mui/system';

import { switchClasses } from '@mui/material/Switch';


export const StyledSwitch = styled('div')(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',

  '&:active': {
    [`.${switchClasses.thumb}`]: {
      width: 15,
    },

    [`.${switchClasses.switchBase}.Mui-checked`]: {
      transform: 'translateX(9px)',
    },
  },

  [`.${switchClasses.switchBase}`]: {
    padding: 2,

    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: theme.palette.secondary.light,

      [`+ .${switchClasses.track}`]: {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },

  [`.${switchClasses.thumb}`]: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },

  [`.${switchClasses.track}`]: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.secondary.dark,
  },
}));

import { styled } from '@mui/system';


export const Wrapper = styled('div')(({ $isDisabled, theme }) => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  ...($isDisabled ? { backgroundColor: theme.palette.background.lighter } : {}),
}));

export const Placeholder = styled('div')(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(1.75),
  paddingRight: theme.spacing(4),
  position: 'relative',

  ...theme.typography.body2,
}));

export const PlaceholderText = styled('div')(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const ArrowIcon = styled('div')(() => ({
  pointerEvents: 'none',
  position: 'absolute',
  right: '4px',
  top: 'calc(50% - 0.5em)',
  color: 'rgba(0,0,0,0.54)',
}));

import LionstudiosApi from 'services/lionstudios-api';

import { listModel, updateCampaignModel } from './models';
import { CAMPAIGNS_URL } from './constants';


const CampaignsService = {
  fetchList: async (params) => {
    const response = await LionstudiosApi.get(CAMPAIGNS_URL, {
      params,
    });

    return {
      campaigns: listModel(response.data.campaigns),
      total: response.data.total,
    };
  },

  updateCampaign: async (id, payload) => {
    const data = updateCampaignModel(payload);

    await LionstudiosApi.post(`${CAMPAIGNS_URL}/${id}`, data);

    return null;
  },
};


export default CampaignsService;

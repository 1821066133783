import React from 'react';

import { ENV } from 'constants';
import Text from 'components/common/text';

import { Badge, BadgeText } from './styles';


const EnvBadge = () => {
  if (process.env.REACT_APP_CONFIG_ENV === ENV.PROD) {
    return null;
  }

  return (
    <Badge>
      <BadgeText as={Text}>
        Staging
      </BadgeText>
    </Badge>
  );
};


EnvBadge.propTypes = {

};

EnvBadge.defaultProps = {

};


export default EnvBadge;

import { isValueExists } from 'utils';


export const networkModel = ({
  status: STATUS,
  goalRoas,
  newAccountId: CAMPAIGN_SET_ID,
  channel: CHANNEL,
  gameId: GAME,
}) => ({
  STATUS,
  CAMPAIGN_SET_ID,
  CHANNEL,
  GAME,
  ...(isValueExists(goalRoas) ? {
    GOAL_ROAS: Number(goalRoas / 100).toFixed(2),
  } : {}),
});

import React, { useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Select from 'components/common/uncontrolled-select';
import Input from 'components/common/input';
import Button from 'components/common/button';

import { OPERATORS_LIST, KEYS, CPI_LIST, CPE_LIST, ROAS_LIST } from './constants';

import {
  StyledFilter, StyledButton,
} from './styles';


const FilterList = (props) => {
  const { values, handleChange, errors, touched, filterValues, removeFormFields, addFormFields } = props;
  const inputRequired = true;

  const unit = useMemo(() => {
    const { campaignType } = values;
    let result = 'Range of Values Under Limit (in %)';

    if (campaignType.length) {
      if (CPI_LIST.includes(campaignType)) {
        // rule 1
        result = 'Range of Values Under Limit (in $)';
      }
      if (ROAS_LIST.includes(campaignType)) {
        // rule 2
        result = 'Range of Values Under Limit (in %)';
      }
      if (CPE_LIST.includes(campaignType)) {
        // rule 3
        result = 'Range of Values Under Limit (in %)';
      }
    }

    return result;
  }, [values.campaignType]);

  return (
    <>
      <StyledButton>
        <Button
          color="success"
          onClick={() => addFormFields()}
        >
          Add
        </Button>
      </StyledButton>

      <StyledFilter>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
        >
          <Grid
            item
            xs={7}
          >
            {unit}
          </Grid>
          <Grid
            item
            xs={1}
          />
          <Grid
            item
            xs={4}
          >
            Max Change Limit in %
          </Grid>
        </Grid>
      </StyledFilter>
      {filterValues.map((item, index) => (
        <StyledFilter
          key={`filterValues--${index * 2}`}
        >
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
          >
            <Grid
              item
              xs={3}
            >
              <Select
                name={`filterList[${index}].operator`}
                value={values?.filterList?.[index]?.operator ?? ''}
                options={OPERATORS_LIST}
                onChange={handleChange}
                error={touched[KEYS.FILTER_LIST] && errors[KEYS.FILTER_LIST]}
                required={inputRequired}
              />
            </Grid>

            {((values?.filterList?.[index]?.operator ?? '') === 'between') ? (
              <>
                <Grid
                  item
                  xs={2}
                >
                  <Input
                    name={`filterList[${index}].value1`}
                    value={values?.filterList?.[index]?.value1 ?? ''}
                    onChange={handleChange}
                    error={touched[KEYS.FILTER_LIST] && errors[KEYS.FILTER_LIST]}
                    type="string"
                    required={inputRequired}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <Input
                    name={`filterList[${index}].value2`}
                    value={values?.filterList?.[index]?.value2 ?? ''}
                    onChange={handleChange}
                    error={touched[KEYS.FILTER_LIST] && errors[KEYS.FILTER_LIST]}
                    type="string"
                    required={inputRequired}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={4}
              >
                <Input
                  name={`filterList[${index}].value`}
                  value={values?.filterList?.[index]?.value ?? ''}
                  onChange={handleChange}
                  error={touched[KEYS.FILTER_LIST] && errors[KEYS.FILTER_LIST]}
                  type="string"
                  required={inputRequired}
                />
              </Grid>
            )
            }

            <Grid
              item
              xs={1}
            />
            <Grid
              item
              xs={3}
            >
              <Input
                name={`filterList[${index}].max`}
                value={values?.filterList?.[index]?.max ?? ''}
                onChange={handleChange}
                error={touched[KEYS.FILTER_LIST] && errors[KEYS.FILTER_LIST]}
                type="number"
                required={inputRequired}
              />
            </Grid>
            {(index) ? (
              <Grid
                item
                xs={1}
              >
                <IconButton
                  onClick={() => removeFormFields(index)}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            ) : null
            }
          </Grid>
        </StyledFilter>
      ))}
    </>
  );
};

FilterList.propTypes = {
  values: PropTypes.shape({
    filterList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
    campaignType: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  addFormFields: PropTypes.func.isRequired,
  removeFormFields: PropTypes.func.isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

FilterList.defaultProps = {
  values: null,
  errors: null,
  touched: null,
};

export default FilterList;

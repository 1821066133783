import { styled } from '@mui/system';
import { popoverClasses } from '@mui/material/Popover';
import { inputBaseClasses } from '@mui/material/InputBase';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { buttonClasses } from '@mui/material/Button';
import { svgIconClasses } from '@mui/material/SvgIcon';

import { BUTTON_MIN_WIDTH } from 'components/common/button/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  flexWrap: 'wrap',
  padding: theme.spacing(2, 1, 0, 1),
}));

export const FilterItemWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 1, 2, 1),
  maxWidth: BUTTON_MIN_WIDTH,
  minWidth: BUTTON_MIN_WIDTH,
  flex: 1,
}));

export const FilterField = styled('div')(() => ({

}));

export const FilterLabel = styled('div')(({ theme }) => ({
  color: theme.palette.textPrimary.dark,
  fontSize: '12px',
  lineHeight: '24px',
  marginBottom: theme.spacing(0.5),
}));

export const FilterButton = styled('div')(({ theme, $active, $isEmpty, $isCustomButton }) => ({
  borderColor: $active ? theme.palette.input.active : theme.palette.input.main,
  color: $isEmpty ? theme.palette.textPrimary.dark : theme.palette.textPrimary.secondary,
  fontWeight: 400,
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block',
  position: 'relative',
  paddingRight: $isCustomButton ? 0 : theme.spacing(4),
  textTransform: 'none',

  [`& .${buttonClasses.endIcon}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${inputBaseClasses.formControl}`]: {
    color: $isEmpty ? theme.palette.textPrimary.dark : theme.palette.textPrimary.secondary,
    fontWeight: 400,
    borderRadius: '8px',
  },
}));

export const FilterWrapper = styled('div')(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    width: '365px',
    borderRadius: '8px',
    padding: theme.spacing(1),
  },

  [`& .${inputBaseClasses.focused} .${outlinedInputClasses.notchedOutline}, & .${outlinedInputClasses.root}:hover .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.input.active,
  },
}));

export const InputsWrapper = styled('div')(() => ({
  [`& .${inputBaseClasses.root}`]: {
    borderRadius: '8px',
  },
}));

export const OperatorSelectWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(1),
}));

export const ControlWrapper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.inputRoot}, & .${autocompleteClasses.root} .${outlinedInputClasses.root}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: '8px',
  },

  [`& .${autocompleteClasses.input}`]: {
    height: '24px',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${autocompleteClasses.tag}`]: {
    fontSize: '12px',
  },
}));

export const FilterResetButton = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.25),

  [`& .${svgIconClasses.root}`]: {
    fontSize: '20px',
    color: theme.palette.primary.light,
  },
}));

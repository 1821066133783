import { put, call, takeLatest, all } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import ManagersService from './service';
import managersActions, { types as managersTypes } from './actions';


/*
 * Sagas
 */

function* init () {
  yield all([
    put(managersActions.fetchList()),
  ]);
}

function* fetchList () {
  try {
    const data = yield call(ManagersService.fetchList);

    yield put(resolvedAction(managersTypes.FETCH_LIST, { data }));
  } catch (error) {
    yield put(rejectedAction(managersTypes.FETCH_LIST, { message: error.message }));
  }
}

/*
 * Watchers
 */

function* fetchListWatcher () {
  yield takeLatest(managersTypes.FETCH_LIST, fetchList);
}

function* initWatcher () {
  yield takeLatest(authTypes.AUTH_SUCCESS, init);
}


export default [
  fetchListWatcher,
  initWatcher,
];

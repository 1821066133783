export const CAMPAIGNS_URL = 'v4/campaigns';

export const BIDDER_STATUSES = {
  READY: 'READY',
  COOLDOWN: 'COOLDOWN',
  CALCULATING: 'CALCULATING',
  ERROR: 'ERROR',
  UNDEFINED_COOLDOWN_LIMIT: 'UNDEFINED_COOLDOWN_LIMIT',
};

export const BIDDER_STATUS_TEXTS = {
  [BIDDER_STATUSES.READY]: 'Ready to Push',
  [BIDDER_STATUSES.COOLDOWN]: 'At Cooldown',
  [BIDDER_STATUSES.CALCULATING]: 'Calculating New Bids',
  [BIDDER_STATUSES.ERROR]: 'Error',
  [BIDDER_STATUSES.UNDEFINED_COOLDOWN_LIMIT]: 'Cooldown Limit Not Defined',
};

export const CPA_ROAS_SETTING_TYPES = {
  ALL: 'ALL',
  GEO: 'GEO',
};

export const CPA_ROAS_SETTING_TYPE_TEXTS = {
  [CPA_ROAS_SETTING_TYPES.ALL]: 'Setting for All GEOs',
  [CPA_ROAS_SETTING_TYPES.GEO]: 'Setting by GEO',
};

import { put, call, takeLatest, take } from 'redux-saga/effects';

import { resolved, resolvedAction, rejectedAction } from 'utils/actions';

import gamesActions, { types as gamesTypes } from 'core/games/actions';

import SnackbarService from 'services/snackbar';

import NetworksService from './service';
import { types as networksTypes } from './actions';


/*
 * Sagas
 */

function* addNetwork ({ payload, meta }) {
  try {
    const { gameId } = payload;
    const { refetchGamesList } = meta;

    const data = yield call(NetworksService.addNetwork, payload);

    yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));

    yield take(resolved(gamesTypes.FETCH_GAME)); // TODO: do we need it ?

    if (refetchGamesList) {
      yield put(gamesActions.fetchList({ useIsFetching: false })); // TODO: extract it to list watcher ?
    }

    yield put(resolvedAction(networksTypes.ADD_NETWORK, { data }));
    SnackbarService.showSuccess('Channel has been added successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(networksTypes.ADD_NETWORK, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* updateNetwork ({ payload, meta }) {
  try {
    const { id, gameId, ...rest } = payload;
    const { refetchGame, refetchGamesList } = meta;

    const data = yield call(NetworksService.updateNetwork, id, rest);

    if (refetchGame) {
      yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));
      yield take(resolved(gamesTypes.FETCH_GAME)); // TODO: do we need it ?
    }

    if (refetchGamesList) {
      yield put(gamesActions.fetchList({ useIsFetching: false })); // TODO: extract it to list watcher ?
    }

    yield put(resolvedAction(networksTypes.UPDATE_NETWORK, { data }));
    SnackbarService.showSuccess('Channel has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(networksTypes.UPDATE_NETWORK, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* deleteNetwork ({ payload: { id, gameId } }) {
  try {
    const data = yield call(NetworksService.deleteNetwork, id);

    yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));

    yield take(resolved(gamesTypes.FETCH_GAME)); // TODO: do we need it ?

    yield put(resolvedAction(networksTypes.DELETE_NETWORK, { data }));
    SnackbarService.showSuccess('Channel has been deleted successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(networksTypes.DELETE_NETWORK, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

/*
 * Watchers
 */

function* addNetworkWatcher () {
  yield takeLatest(networksTypes.ADD_NETWORK, addNetwork);
}

function* updateNetworkWatcher () {
  yield takeLatest(networksTypes.UPDATE_NETWORK, updateNetwork);
}

function* deleteNetworkWatcher () {
  yield takeLatest(networksTypes.DELETE_NETWORK, deleteNetwork);
}


export default [
  addNetworkWatcher,
  updateNetworkWatcher,
  deleteNetworkWatcher,
];

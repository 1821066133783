import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DefaultChannelIcon } from 'assets/images/default-channel-icon.svg';

import { CHANNEL_ICONS } from 'core/v4/games/constants';


const ChannelIcon = memo(({ channel, color }) => {
  const IconComponent = CHANNEL_ICONS[channel] || DefaultChannelIcon;
  return (
    <IconComponent color={color} />
  );
});


ChannelIcon.propTypes = {
  channel: PropTypes.string,
  color: PropTypes.string,
};

ChannelIcon.defaultProps = {
  channel: null,
  color: null,
};


export default ChannelIcon;

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import Button from '@mui/material/Button';

import { StyledTab, SelectedIndicator, ClearButton } from './styles';


const Tab = memo(({ label, renderIcon, value, onClick, isActive, selectedCount, onSelectionChange, tabKey, color }) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  const handleResetSelection = useCallback((event) => {
    event.stopPropagation();
    onSelectionChange([], tabKey);
  }, [onSelectionChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTab
      as={Button}
      onClick={handleClick}
      $isActive={isActive}
      $color={color}
    >
      {
        renderIcon ? renderIcon(color) : null
      }

      {label}

      {
        selectedCount !== 0 && (
          <SelectedIndicator
            $isActive={isActive}
            $color={color}
          >
            {selectedCount}
            {' '}
            selected

            <ClearButton
              as={IconButton}
              aria-label="clear"
              $isActive={isActive}
              onClick={handleResetSelection}
              component="div"
            >
              <CancelIcon
                fontSize="inherit"
              />
            </ClearButton>
          </SelectedIndicator>
        )
      }
    </StyledTab>
  );
});


Tab.propTypes = {
  renderIcon: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  selectedCount: PropTypes.number,
  onSelectionChange: PropTypes.func.isRequired,
  tabKey: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Tab.defaultProps = {
  renderIcon: null,
  isActive: false,
  selectedCount: 0,
};


export default Tab;

import { styled } from '@mui/system';


export const StyledTitle = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

export const modalStyles = {
  maxWidth: '95vw',
};

import { styled } from '@mui/system';

import { inputBaseClasses } from '@mui/material/InputBase';


export const Wrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),

  '&.buttons-wrapper': {
    marginTop: theme.spacing(5),
  },

  [`.${inputBaseClasses.input}`]: {
    padding: theme.spacing(1.4, 1),
  },
}));

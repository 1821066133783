import { styled } from '@mui/system';
import { keyframes, css, alpha } from '@mui/material/styles';

import { COLORS } from 'containers/theme-provider/constants';


const pulseBoxShadow = keyframes`
50% {
  box-shadow: 1px 1px 15px 5px ${alpha(COLORS.SECONDARY, 0.5)};
}
`;


export const PopperContainer = styled('div')(({ theme }) => ({
  background: '#fff',
  borderRadius: 4,
  boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(1),
  width: '350px',

  '&:hover': {
    animation: 'none',
  },
}), () => css`animation: ${pulseBoxShadow} 1.5s infinite`);

export const PopperStyles = {
  zIndex: 1500,
};

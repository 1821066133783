export const BIDS_URL = 'bids';
export const PUSH_BIDS_URL = 'tasks/trigger/push-historical-bids';

export const FIELDS = {
  INDEX: 'index',
  DAY: 'day',
  GAME: 'game',
  CHANNEL: 'channel',
  CAMPAIGN: 'campaign',
  COUNTRY: 'country',
  SPEND: 'spend',
  INSTALLS: 'installs',
  TARGET_BID: 'targetBid',
  DELTA: 'delta',
  DELTA_PCT: 'deltaPct',
  EXECUTED_BID: 'executedBid',
  YESTERDAY_BID: 'originalBid',
  BID_OVERRIDE: 'bidOverride',
  GROWTH_MANAGER: 'growthManager',
  RPI: 'rpi',
  ROAS_DELTA: 'roasDelta',
  GOAL_ROAS: 'goalRoas',
  STORE_ID: 'storeId',
  OVERRIDE_MANAGER: 'overrideManager',
  OVERRIDE_DATE: 'overrideDate',
  OVERRIDE_EXPIRES: 'overrideExpires',
  EXECUTE_STATUS: 'status',
  DATE_RANGE: 'dateRange',
  CHANNEL_STATUS: 'channelStatus',
  LION_APP_ID: 'lionAppId',
  CAMPAIGN_STATUS: 'campaignStatus',
  BIDDER_CAMPAIGN_STATUS: 'bidderCampaignStatus',
  PLATFORM: 'platform',
};

export const DEFAULT_HIDDEN_COLUMNS = [
  FIELDS.DATE_RANGE,
  FIELDS.ROAS_DELTA,
  FIELDS.CHANNEL_STATUS,
  FIELDS.CAMPAIGN_STATUS,
  FIELDS.GROWTH_MANAGER,
  FIELDS.OVERRIDE_MANAGER,
  FIELDS.OVERRIDE_DATE,
  FIELDS.OVERRIDE_EXPIRES,
  FIELDS.STORE_ID,
  FIELDS.LION_APP_ID,
  FIELDS.EXECUTE_STATUS,
];

export const FILTERS_KEYS = {
  PAGE: 'page',
  LIMIT: 'limit',
  GAME: 'GAME_NAME',
  CAMPAIGN: 'CAMPAIGN_NAME',
  COUNTRY: 'COUNTRY',
  CHANNEL: 'CHANNEL',
  GROWTH_MANAGER: 'GROWTH_MANAGER',
  SPEND: 'SPEND_7D',
  STATUS: 'EXECUTE_STATUS',
  INSTALLS: 'INSTALLS_7D',
  DELTA: 'DELTA',
  SORT: 'sort',
  ID: 'id',
  DAY: 'DAY_KEY',
  BID: 'BID',
  TARGET_BID: 'TARGET_BID',
  EXECUTED_BID: 'TARGET_BID_LIMIT',
  BID_OVERRIDE: 'BID_OVERRIDE',
  DELTA_PCT: 'DELTA_PCT',
  RPI: 'RPI',
  ROAS_DELTA: 'ROAS_DELTA',
  GOAL_ROAS: 'ROAS',
  STORE_ID: 'STORE_ID',
  DATE_RANGE: 'DATE_RANGE',
  OVERRIDE_MANAGER: 'OVERRIDE_MANAGER',
  OVERRIDE_DATE: 'OVERRIDE_DATE_KEY',
  OVERRIDE_EXPIRES: 'OVERRIDE_EXPIRES_KEY',
  CHANNEL_STATUS: 'CHANNEL_STATUS',
  LION_APP_ID: 'LION_APP_ID',
  BIDDER_CAMPAIGN_STATUS: 'BIDDER_CAMPAIGN_STATUS',
  PLATFORM: 'PLATFORM',
};

export const OPERATORS = {
  IS_LESS: '<',
  IS_LESS_OR_EQUAL: '<=',
  IS_GREATER: '>',
  IS_GREATER_OR_EQUAL: '>=',
  IS_EQUAL: '=',
  IS_NOT_EQUAL: '!=',
  IS_IN: 'in',
};

export const OPERATORS_LIST = [
  {
    id: OPERATORS.IS_GREATER_OR_EQUAL,
    name: 'is >=',
  },
  {
    id: OPERATORS.IS_GREATER,
    name: 'is >',
  },
  {
    id: OPERATORS.IS_LESS_OR_EQUAL,
    name: 'is <=',
  },
  {
    id: OPERATORS.IS_LESS,
    name: 'is <',
  },
  {
    id: OPERATORS.IS_EQUAL,
    name: 'is equal',
  },
  {
    id: OPERATORS.IS_NOT_EQUAL,
    name: 'is not equal',
  },
];

export const OPERATORS_VALUES = OPERATORS_LIST.map(({ id }) => id);

export const [DEFAULT_FILTER_OPERATOR] = OPERATORS_VALUES;

export const DEFAULT_ARRAY_OPERATOR = OPERATORS.IS_IN;

export const DEFAULT_OPERATOR = OPERATORS.IS_EQUAL;

export const EXECUTE_STATUS_KEYS = {
  READY: 'READY',
  PENDING: 'PENDING',
  SKIPPED: 'SKIPPED',
};

export const EXECUTE_STATUS_OPTIONS = [
  {
    id: EXECUTE_STATUS_KEYS.READY,
    name: 'Ready',
  },
  {
    id: EXECUTE_STATUS_KEYS.PENDING,
    name: 'Pending',
  },
  {
    id: EXECUTE_STATUS_KEYS.SKIPPED,
    name: 'Skipped',
  },
];

export const BIDDER_CAMPAIGN_STATUS_KEYS = {
  ENABLED: '1',
  DISABLED: '0',
};

export const BIDDER_CAMPAIGN_STATUS_OPTIONS = [
  {
    id: BIDDER_CAMPAIGN_STATUS_KEYS.ENABLED,
    name: 'Enabled',
  },
  {
    id: BIDDER_CAMPAIGN_STATUS_KEYS.DISABLED,
    name: 'Disabled',
  },
];

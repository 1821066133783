import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'AUTH';

export const types = createConstants([
  'LOAD_AUTH',
  'SIGN_IN',
  'SIGN_IN_WITH_OKTA',
  'AUTH_SUCCESS',
  'LOGOUT',
  'RECOVER_PASSWORD',
  'RESET_PASSWORD',
], PREFIX);

export const actions = createActions({
  [types.LOAD_AUTH]: () => null,
  [types.LOGOUT]: () => null,
  [types.AUTH_SUCCESS]: () => null,
  [types.SIGN_IN]: ({ email, password }) => ({ email, password }),
  [types.SIGN_IN_WITH_OKTA]: ({ token, withRedirect = true }) => ({ token, withRedirect }),
  [types.RECOVER_PASSWORD]: ({ email }) => ({ email }),
  [types.RESET_PASSWORD]: ({ password, confirmPassword, code }) => ({ password, confirmPassword, code }),
}, {
  prefix: PREFIX,
});


export default actions;

import { TYPES } from 'hooks/use-query-params';


export const FIELDS = {
  TIMESTAMP: 'timestamp',
  USER: 'user',
  GAME: 'game',
  CHANNEL: 'channel',
  PATH: 'pathText',
  ELEMENT: 'element',
  STATUS: 'type',
  VALUE: 'valueText',
};

export const ACTIVE_FILTERS = [
  FIELDS.USER,
  FIELDS.GAME,
  FIELDS.CHANNEL,
  FIELDS.PATH,
  FIELDS.ELEMENT,
  FIELDS.STATUS,
  FIELDS.VALUE,
];


export const QUERY_PARAMS = {
  GAME: 'n',
  USER: 'u',
  CHANNEL: 'ch',
  PATH: 'p',
  ELEMENT: 'el',
  STATUS: 'st',
  VALUE: 'vl',
};

export const QUERY_PARAMS_BY_FIELDS = {
  [FIELDS.GAME]: QUERY_PARAMS.GAME,
  [FIELDS.USER]: QUERY_PARAMS.USER,
  [FIELDS.CHANNEL]: QUERY_PARAMS.CHANNEL,
  [FIELDS.PATH]: QUERY_PARAMS.PATH,
  [FIELDS.ELEMENT]: QUERY_PARAMS.ELEMENT,
  [FIELDS.STATUS]: QUERY_PARAMS.STATUS,
  [FIELDS.VALUE]: QUERY_PARAMS.VALUE,
};

export const QUERY_PARAMS_WITH_TYPES = {
  [QUERY_PARAMS.GAME]: TYPES.STRING,
  [QUERY_PARAMS.USER]: TYPES.STRING,
  [QUERY_PARAMS.CHANNEL]: TYPES.STRING,
  [QUERY_PARAMS.PATH]: TYPES.STRING,
  [QUERY_PARAMS.ELEMENT]: TYPES.STRING,
  [QUERY_PARAMS.STATUS]: TYPES.STRING,
  [QUERY_PARAMS.VALUE]: TYPES.STRING,
};

export const ELEMENTS = {
  GOAL_ROAS: 'Goal ROAS',
  ROAS_OVERRIDE: 'ROAS Override',
};

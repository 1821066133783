import axios from 'axios';

import { API_URL } from './constants';


const instance = axios.create({
  baseURL: API_URL,
});

export const authorizeRequests = (token) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setResponseHandler = (handler) => {
  instance.interceptors.response.use((response) => response, (error) => {
    if (handler) {
      handler(error.response);
    }

    error.message = error.response?.data?.message || error.message; // eslint-disable-line no-param-reassign

    return Promise.reject(error);
  });
};


export default instance;

import { isValueExists } from 'utils';


export const listModel = (response) => response
  .map(({
    id,
    channel,
    channel_id: channelId,
    game_id: gameId,
    game_name: gameName,
    game_platform: gamePlatform,
    status,
    link,
    name,
    campaign_type: campaignType,
    campaign_status: campaignStatus,
    goal_roas: goalRoas,
    week_installs: weekInstalls,
    week_spend: weekSpend,
    enabled,
    override,
    roasSuggest,
    budget,
    bidder_status: bidderStatus,
    return_window: returnWindow,
    rw_rpi_forecast: rwRpiForecast,
    rw_roas_target: rwRoasTarget,
    rw_roas_target_override_count: rwRoasTargetOverrideCount,
    cpa_roas_setting_type: cpaRoasSettingType,
    cpa_roas_setting: cpaRoasSetting,
    delta_cpa_roas_setting: deltaCpaRoasSetting,
  }) => ({
    id,
    networkId: Number(channelId),
    channelId,
    gameId,
    gameName,
    gamePlatform,
    status,
    goalRoas: goalRoasModel(goalRoas),
    channel,
    link,
    name,
    campaignType,
    campaignStatus,
    weekInstalls,
    weekSpend,
    enabled: enabled === 1,
    override: overrideModel(override),
    suggestedRoas: suggestedRoasModel(roasSuggest),
    budget: budgetModel(budget),
    bidderStatus,
    returnWindow,
    rwRpiForecast,
    rwRoasTarget: decimalToPercentage(rwRoasTarget),
    rwRoasTargetOverrideCount,
    cpaRoasSettingType,
    cpaRoasSetting,
    deltaCpaRoasSetting: decimalToPercentage(deltaCpaRoasSetting),
  }));

export const decimalToPercentage = (value) => isValueExists(value) ? Math.round(Number(value) * 100) : value;
export const goalRoasModel = (value) => decimalToPercentage(value);

export const overrideModel = ({ parentGoal, goal, ...rest }) => ({
  parentGoal: goalRoasModel(parentGoal),
  goal: goalRoasModel(goal),
  ...rest,
});

export const suggestedRoasModel = ({ description, goal, diff, createdAt } = {}) => ({
  description,
  goal: goalRoasModel(goal),
  diff: goalRoasModel(diff),
  createdAt,
});

export const budgetModel = (budget) => ({
  daily: budget?.daily || 0,
  total: budget?.total || 0,
});

export const updateCampaignModel = (payload) => Object.entries(payload).reduce((result, [key, value]) => {
  const nextResult = {};
  switch (key) {
    case 'enabled':
      nextResult.ENABLED = value ? 1 : 0;
      break;
    case 'returnWindow':
      nextResult.RETURN_WINDOW = value;
      break;
    case 'rwRoasTarget':
      nextResult.RW_ROAS_TARGET = value ? (value / 100) : value;
      break;
    case 'cpaRoasSetting':
      nextResult.CPA_ROAS_SETTING = value;
      break;
    default:
      break;
  }
  return {
    ...result,
    ...nextResult,
  };
}, {});

import { styled } from '@mui/system';


export const StyledTitle = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1.25),
}));

export const Wrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StatusWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

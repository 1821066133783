import * as yup from 'yup';

import { KEYS, LABELS, MIN_DATE } from './constants';


const REQUIRED_TEXT = 'Required field';
const INVALID_DATE = 'Invalid Date';
const MIN_DATE_ERROR = `${LABELS[KEYS.TIME_PERIOD]} is 2 weeks maximum`;

export const ValidationSchema = yup.object().shape({
  [KEYS.TIME_PERIOD]: yup.date().nullable().typeError(INVALID_DATE).required(REQUIRED_TEXT).min(MIN_DATE, MIN_DATE_ERROR),
});

import * as yup from 'yup';
import memoize from 'lodash.memoize';
import { CHANNELS_KEYS, CAMPAIGN_TYPES_KEYS } from 'core/games/constants';

import { KEYS, LABELS } from './constants';


const REQUIRED_TEXT = 'Required field';

export const getValidationSchema = memoize(() => yup.object().shape({
  [KEYS.COOLDOWN_LIMIT]: yup.string().nullable().required(REQUIRED_TEXT).label(LABELS[KEYS.COOLDOWN_LIMIT]),
  [KEYS.CAMPAIGN_TYPE]: yup.string().oneOf(CAMPAIGN_TYPES_KEYS).required(REQUIRED_TEXT).label(LABELS[KEYS.CAMPAIGN_TYPE]),
  [KEYS.CHANNEL_NAME]: yup.string().oneOf(CHANNELS_KEYS).required(REQUIRED_TEXT).label(LABELS[KEYS.CHANNEL_NAME]),
}), (...args) => JSON.stringify(args));

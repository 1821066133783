import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'APP';

export const types = createConstants([
  'INIT',
  'HANDLE_ERROR',
  'HANDLE_HTTP_ERROR',
], PREFIX);

export const actions = createActions({
  [types.INIT]: null,
  [types.HANDLE_ERROR]: null,
  [types.HANDLE_HTTP_ERROR]: null,
}, {
  prefix: PREFIX,
});

export default actions;

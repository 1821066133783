import React from 'react';

import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


export const Input = styled('input')(({ theme }) => ({
  display: 'block',
  width: '100%',
  border: 'none',
  background: 'inherit',
  padding: 0,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body2.fontSize,

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&[type=number]': {
    MozAppearance: 'textfield',
  },

  '&:focus': {
    outline: 'none',
  },
}));

export const StyledText = styled('p')(({ $isPlaceholder, theme }) => ({
  display: 'block',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...($isPlaceholder ? { color: theme.palette.textPrimary.light } : {}),
}));

export const StyledStack = styled('div')(({ $isDisabled, $isEdit, $isError, theme }) => {
  let borderColor = 'transparent';
  let backgroundColor = 'transparent';

  if ($isEdit) {
    borderColor = $isError ? theme.palette.error.main : theme.palette.secondary.main;
  }

  if ($isDisabled) {
    backgroundColor = theme.palette.background.lighter;
  }

  if ($isError) {
    backgroundColor = theme.palette.error.customLight;
  }

  return {
    padding: theme.spacing(0, 1),
    border: `1px solid ${borderColor}`,
    backgroundColor,
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.standard,
    }),
  };
});

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 1.5rem)',
  height: '100%',
  cursor: 'pointer',
}));

export const StyledTooltip = styled(({ className, isError, ...props }) => (
  <Tooltip
    {...props}
    classes={isError ? { popper: className } : {}}
  />
))(({ theme }) => ({
  [`.${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`.${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}));

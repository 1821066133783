export const FIELDS = {
  CHANNEL: 'channel',
  CAMPAIGN_NAME: 'campaignName',
  CAMPAIGN_TYPE: 'campaignType',
  BILLING_TYPE: 'billingType',
  COUNTRY_FULL: 'countryFull',
  COUNTRY: 'country',
  SOURCE_NAME: 'sourceName',
  WEEK_SPEND: 'weekSpend',
  WEEKLY_INSTALLS: 'weekInstalls',
  GOAL_ROAS: 'goalRoas',
};

import React from 'react';
import { useParams } from 'react-router-dom';


export const withParams = (Component) => {
  const Wrapper = (props) => { // eslint-disable-line react/function-component-definition
    const params = useParams();

    return (
      <Component
        params={params}
        {...props}
      />
    );
  };

  return Wrapper;
};


import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';

import config from 'config';

import { getIsAuthorized } from 'core/auth/selectors';

import Button from 'components/common/button';
import AuthLayout from 'components/auth/layout';

import Background from 'assets/images/reset-password-bg.png';
import FormBackground from 'assets/images/sign-in-okta-bg.svg';
import { ReactComponent as OctaIcon } from 'assets/images/okta-icon.svg';

import { StyledButton } from './styles';


const SignInOkta = memo(() => {
  const navigate = useNavigate();

  const isAuthorized = useSelector(getIsAuthorized);

  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthLayout
      image={Background}
      formImage={FormBackground}
    >
      <StyledButton
        as={Button}
        component={Link}
        href={config.OKTA_LOGIN_URL}
        startIcon={(<OctaIcon />)}
      >
        Sign In with Okta
      </StyledButton>
    </AuthLayout>
  );
});


export default SignInOkta;

import React, { memo, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import TextField from '@mui/material/TextField';

import { isValueExists } from 'utils';

import { StyledInput, Label, Description, Wrapper } from './styles';


const Input = memo(({
  label,
  description,
  error,
  className,
  name,
  stopKeyPropagation,
  clearable,
  InputProps,
  value,
  onChange,
  ...otherProps
}) => {
  const ref = useRef(null);

  useEffect(() => { // TODO: remove when the issue will be fixed: https://github.com/mui-org/material-ui-x/issues/3599
    if (!stopKeyPropagation) {
      return () => {};
    }

    const inpt = ref.current.querySelector('input');

    const listener = inpt.addEventListener('keydown', (event) => {
      event.stopPropagation();
    });

    return () => {
      inpt.removeEventListener('keydown', listener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const inputProps = useMemo(() => ({
    ...(stopKeyPropagation ? { ref } : {}),
  }), [stopKeyPropagation]);

  const handleClearClick = useCallback((event) => {
    ref.current.value = '';
    if (onChange) {
      onChange({
        nativeEvent: {
          target: {
            value: '',
          },
        },
        target: {
          value: '',
        },
      });
    }
    event.stopPropagation();
  }, [onChange]);

  const CustomizedInputProps = useMemo(() => ({
    ...InputProps,
    endAdornment: (
      <>
        {
          clearable && isValueExists(value) && !!value.length && (
            <IconButton
              aria-label="clear"
              onClick={handleClearClick}
              component="div"
              size="small"
            >
              <ClearIcon
                fontSize="inherit"
              />
            </IconButton>
          )
        }

        {InputProps?.endAdornment || null}
      </>
    ),
  }), [clearable, InputProps, value, handleClearClick]);

  return (
    <Wrapper
      className={className}
    >
      {label && (
        <Label htmlFor={name}>
          {label}
        </Label>
      )}

      {description && (
        <Description>
          {description}
        </Description>
      )}

      <StyledInput
        as={TextField}
        id={name}
        name={name}
        error={Boolean(error)}
        helperText={error}
        variant="outlined"
        fullWidth
        InputProps={CustomizedInputProps}
        value={value}
        onChange={onChange}
        {...inputProps}
        {...otherProps}
      />
    </Wrapper>
  );
});


Input.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.shape({}),
  stopKeyPropagation: PropTypes.bool,
  clearable: PropTypes.bool,
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.element,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
};

Input.defaultProps = {
  name: undefined,
  label: null,
  description: null,
  error: null,
  className: null,
  inputProps: {},
  stopKeyPropagation: false,
  clearable: false,
  InputProps: null,
  value: null,
  onChange: null,
};


export default Input;

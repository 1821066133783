import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CreateIcon from '@mui/icons-material/Create';

import { PLATFORM_ICONS, PLATFORMS_OPTIONS, CHANNELS_OPTIONS } from 'core/games/constants';

import EditableTextField from 'components/common/editable-text-field';
import DataGridEditableField from 'components/common/data-grid-editable-field';
import AutocompleteField from 'components/common/autocomplete-field';
import { getValidationSchema } from 'components/games/game-form/validation';

import ChannelIcon from 'components/v4/games/channel-icon';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import {
  getManagersOptionLabel,
  getManagersInitialValue,
} from 'utils/grid';
import { extractRowKey } from 'utils/extracters';
import { IconWrapper } from './styles';

import { renderMultipleFilterValue } from './utils';
import { FIELDS } from './constants';


const ValidationSchema = getValidationSchema([]);

export const getConfig = (data, onGameUpdate, onNetworkUpdate, onGoalRoasUpdate, isAppStatusReady, onEdit, onDelete, onActivityLogsClick, managers = []) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.APP_ID,
      headerName: 'Game Id',
      flex: 0,
    },
    {
      field: FIELDS.GROWTH_MANAGER,
      headerName: 'Growth Manager',
      flex: 1,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ row }) => {
        const { appId } = row;
        const value = getManagersInitialValue(managers, row, FIELDS.GROWTH_MANAGER);

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GROWTH_MANAGER)}
            valueKey={FIELDS.GROWTH_MANAGER}
            onSubmit={onGameUpdate}
            component={AutocompleteField}
            propsToSubmit={{
              id: appId,
            }}
            componentProps={{
              options: managers,
              initialValue: value,
              getOptionLabel: getManagersOptionLabel,
              disableClearable: true,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.PLATFORM,
      headerName: 'Platform',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      options: PLATFORMS_OPTIONS,
      renderCell: ({ value }) => {
        const Icon = PLATFORM_ICONS[value];

        return (
          <Icon />
        );
      },
    },
    {
      field: FIELDS.NAME,
      headerName: 'Game',
      flex: 2,
      type: 'string',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const { appId } = row;

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.NAME)}
            valueKey={FIELDS.NAME}
            onSubmit={onGameUpdate}
            component={EditableTextField}
            propsToSubmit={{ id: appId }}
            componentProps={{
              // to: String(appId),
              initialValue: value,
              validationSchema: ValidationSchema.fields[FIELDS.NAME],
              disabled: !isAppStatusReady,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.CAMPAIGN_SETS,
      headerName: 'Channels',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      options: CHANNELS_OPTIONS,
      isMultipleOptions: true,
      renderFilterValue: renderMultipleFilterValue,
      renderCell: ({ value }) => (
        <IconWrapper>
          {
            value.map((item) => (
              <ChannelIcon
                key={`icon-${item.KEY}-${item.CHANNEL}`}
                channel={item.CHANNEL}
                color={item.COLOR}
              />
            ))
          }
        </IconWrapper>
      ),
    },
    {
      field: 'Activity',
      headerName: 'Activity',
      flex: 0,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => onActivityLogsClick(row)}
        >
          <TimerOutlinedIcon
            fontSize="small"
            color="secondary"
          />
        </IconButton>
      ),
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => onEdit(row)}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            onClick={() => onDelete(row)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </>
      ),
    },
  ],
});

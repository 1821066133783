import React, { memo, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import LoopIcon from '@mui/icons-material/Loop';

import { dataGridFiltersPropTypes } from 'models/data-grid-filters';

import { isValueExists } from 'utils';

import { OPERATORS_LIST, OPERATORS_VALUES } from 'core/bids/constants';

import Button from 'components/common/button';
import Select from 'components/common/select';

import { getIsEmpty, isFilterValue } from './utils';
import { TYPES } from './constants';
import {
  FilterButton,
  FilterWrapper,
  InputsWrapper,
  FilterField,
  FilterLabel,
  ControlWrapper,
  OperatorSelectWrapper,
  FilterResetButton,
} from './styles';


const DataGridFilter = memo(({ onChange, config, filterValue: initialFilterValue }) => {
  const { type, renderControl, renderSelectedValue, renderFilterButton, extractControlValue, label } = config;

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [filterValue, setFilterValue] = useState(() => initialFilterValue);

  const isActive = Boolean(popoverAnchor);

  const handleValueChange = useCallback((value, ...rest) => {
    const nextValue = extractControlValue ? extractControlValue(value, ...rest) : value;

    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      value: nextValue,
    }));
  }, [extractControlValue]);

  const handleOperatorChange = useCallback((operator) => {
    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      operator,
    }));
  }, []);

  const handleChange = useCallback((overrideFilterValue) => {
    const filter = overrideFilterValue || filterValue;
    const canBeEmpty = Boolean(overrideFilterValue);
    const { operator, value } = filter;
    const nextValue = value || null;
    const nextFilterValue = {
      ...filter,
      value: nextValue,
    };

    // TODO: add deep equal for filterValue ?

    switch (type) {
      case TYPES.CONTROL:
        return onChange(config, nextFilterValue);

      case TYPES.FILTER:
        if (!operator || (!canBeEmpty && !isValueExists(value))) {
          return null;
        }

        return onChange(config, nextFilterValue);

      default:
        return null;
    }
  }, [config, onChange, filterValue, type]);

  const handleCustomFilterButtonChange = useCallback((data) => {
    if (isFilterValue(data)) {
      handleChange(data);
    } else {
      handleChange();
    }
  }, [handleChange]);

  const handleFilterClick = useCallback((event) => {
    setPopoverAnchor(event.currentTarget);
  }, []);

  const handleResetClick = useCallback(() => {
    const { operator } = filterValue;

    setFilterValue({
      operator,
      value: null,
    });
    handleChange({
      operator,
      value: null,
    });
  }, [handleChange, filterValue]);

  const handlePopoverClose = useCallback(() => {
    handleChange();
    setPopoverAnchor(null);
  }, [handleChange]);

  const popoverProps = useMemo(() => ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    disablePortal: true,
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  const buttonIcon = useMemo(() => {
    const ButtonIcon = isActive ? ArrowDropUpIcon : ArrowDropDownIcon;

    return (
      <ButtonIcon />
    );
  }, [isActive]);

  const control = useMemo(() => {
    if (!renderControl) {
      return null;
    }

    switch (type) {
      case TYPES.CONTROL:
        return renderControl({
          onChange: handleValueChange,
          filterValue,
        });

      case TYPES.FILTER:
        return (
          <InputsWrapper
            as={Stack}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <OperatorSelectWrapper>
              <Select
                options={OPERATORS_LIST}
                initialValue={filterValue.operator}
                onChange={handleOperatorChange}
              />
            </OperatorSelectWrapper>

            {renderControl({
              onChange: handleValueChange,
              filterValue,
            })}
          </InputsWrapper>
        );

      default:
        return null;
    }
  }, [type, renderControl, handleValueChange, handleOperatorChange, filterValue]);

  const isEmpty = useMemo(() => getIsEmpty(filterValue, type), [type, filterValue]);

  const placeholder = useMemo(() => {
    const { operator } = filterValue;
    const defaultPrefix = 'is';
    const defaultPlaceholder = `${defaultPrefix} any value`;

    if (isEmpty) {
      return defaultPlaceholder;
    }

    const operatorOption = operator ? OPERATORS_LIST.find(({ id }) => id === operator) : null;

    return renderSelectedValue(filterValue, operatorOption, { defaultPlaceholder, defaultPrefix });
  }, [isEmpty, filterValue, renderSelectedValue]);

  const filterButton = useMemo(() => {
    if (renderFilterButton) {
      return (
        <FilterButton
          $active={isActive}
          $isEmpty={isEmpty}
          $isCustomButton
        >
          {renderFilterButton({
            isActive,
            isEmpty,
            placeholder,
            filterValue,
            onChange: handleValueChange,
            onSubmit: handleCustomFilterButtonChange,
          })}
        </FilterButton>
      );
    }

    return (
      <FilterButton
        as={Button}
        onClick={handleFilterClick}
        endIcon={buttonIcon}
        variant="outlined"
        $active={isActive}
        $isEmpty={isEmpty}
      >
        {placeholder}
      </FilterButton>
    );
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    renderFilterButton,
    isActive,
    isEmpty,
    handleValueChange,
    handleCustomFilterButtonChange,
    placeholder,
    buttonIcon,
    filterValue,
  ]);

  return (
    <FilterWrapper>
      <FilterField>
        <FilterLabel
          as={Stack}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {label}

          {!isEmpty && (
            <FilterResetButton
              as={IconButton}
              aria-label="Reset"
              onClick={handleResetClick}
            >
              <LoopIcon />
            </FilterResetButton>
          )}
        </FilterLabel>

        {filterButton}
      </FilterField>

      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        {...popoverProps}
      >
        <ControlWrapper>
          {control}
        </ControlWrapper>
      </Popover>
    </FilterWrapper>
  );
});


DataGridFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  config: dataGridFiltersPropTypes.isRequired,
  filterValue: PropTypes.shape({
    operator: PropTypes.oneOf(OPERATORS_VALUES),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.array,
    ]),
  }).isRequired,
};

DataGridFilter.defaultProps = {

};


export default DataGridFilter;

import { userModel } from 'core/users/models';


export const signInModel = ({
  token,
  user,
}) => ({
  token,
  user: userModel(user),
});

export const recoverPasswordModel = ({
  email,
}) => ({
  emailAddress: email,
});


export const resetPasswordModel = ({
  password,
  confirmPassword,
  code,
}) => ({
  password,
  confirmPassword,
  token: code,
});

import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

import { isString, isValueExists } from 'utils';

import AnalyticsService from 'services/analytics';
import { BASE } from 'containers/theme-provider/constants';

import Text from 'components/common/text';

import { COPY_TEXT, COPIED_TEXT, COPY_TEXT_FAIL } from './constants';
import { AbsoluteText, Wrapper, DEFAULT_PADDING_ABSOLUTE_TEXT, PAGE_OFFSET } from './styles';



// TODO: add this component to Link somehow
const AdaptiveText = memo(({ children, value, rawText, disableTooltip, ...otherProps }) => {
  const ref = useRef(null);

  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState(COPY_TEXT);
  const [offset, setOffset] = useState(DEFAULT_PADDING_ABSOLUTE_TEXT * BASE.PADDING);

  const showFullText = useCallback(() => setIsFullTextVisible(true), []);

  const hideFullText = useCallback(() => {
    setIsFullTextVisible(false);
    setTooltipText(COPY_TEXT);
  }, []);

  const handleClick = useCallback(async () => {
    if (!disableTooltip) {
      AnalyticsService.trackEvent({
        category: AnalyticsService.CATEGORIES.COMMON,
        name: AnalyticsService.NAMES.COPY_TEXT,
        action: AnalyticsService.ACTIONS.CLICK,
      });
    }

    try {
      if (!isString(children) && !isString(value)) {
        throw new Error();
      }

      await navigator.clipboard.writeText(isValueExists(value) ? value : children);

      setTooltipText(COPIED_TEXT);
    } catch (error) {
      setTooltipText(COPY_TEXT_FAIL);
    }
  }, [children, value, disableTooltip]);

  useEffect(() => {
    const { current } = ref;
    const { offsetWidth, parentElement } = current;
    const { offsetWidth: parentOffsetWidth } = parentElement;

    const { x, width } = current.getBoundingClientRect();
    if (document.body.clientWidth - (PAGE_OFFSET * BASE.PADDING) < x + width) {
      const nextOffset = x + width;
      setOffset(Math.abs(document.body.clientWidth - nextOffset - PAGE_OFFSET * BASE.PADDING));
    }

    setIsTooltipDisabled(offsetWidth <= parentOffsetWidth); // TODO: add window resize handler
  }, []);

  if (isTooltipDisabled) {
    return (
      <Wrapper
        onMouseEnter={showFullText}
        onMouseLeave={hideFullText}
      >
        {isFullTextVisible ? (
          <Tooltip
            title={tooltipText}
            open={!disableTooltip}
            placement="top"
            arrow
          >
            <Text
              {...otherProps}
              onClick={handleClick}
              ref={ref}
            >
              {children}
            </Text>
          </Tooltip>
        ) : (
          <Text
            {...otherProps}
            ref={ref}
          >
            {children}
          </Text>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper
      onMouseEnter={showFullText}
      onMouseLeave={hideFullText}
      $isFullTextVisible={isFullTextVisible}
    >
      {
        isFullTextVisible && (
          <Tooltip
            title={tooltipText}
            open={!disableTooltip}
            placement="top"
            arrow
          >
            <AbsoluteText
              as={Text}
              onClick={handleClick}
              $offset={offset}
              {...otherProps}
            >
              {children}
            </AbsoluteText>
          </Tooltip>
        )
      }

      <Text
        {...otherProps}
        ref={ref}
      >
        {children}
      </Text>
    </Wrapper>
  );
});


AdaptiveText.propTypes = {
  ...Text.propTypes,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disableTooltip: PropTypes.bool,
};

AdaptiveText.defaultProps = {
  value: null,
  disableTooltip: false,
};


AdaptiveText.SIZE = Text.SIZE;


export default AdaptiveText;

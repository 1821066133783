import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PLATFORM, CHANNELS_KEYS, CHANNEL_STATUS_KEYS, PLATFORMS, CHANNELS } from 'core/v4/games/constants';
import { findObjectByProp, isValueExists } from 'utils';

import { KEYS } from './constants';


export const getTitle = (isEdit, isGameArchived) => {
  if (isGameArchived) {
    return 'Archived Game';
  }

  if (isEdit) {
    return 'Edit Game';
  }

  return 'Add Game';
};

const adjustNetworkStatus = (status) => {
  let result = '';

  if (status.length === 0) {
    return result;
  }
  if (['LIVE', 'READY', 'PENDING'].includes(status)) {
    result = 'PENDING';
  }
  if (['PAUSED'].includes(status)) {
    result = 'PAUSED';
  }
  return result;
};

export const generateInitialNetworks = (values) => {
  const result = {};

  if (values) {
    values.campaignSets.forEach((item) => {
      result[item.CHANNEL] = {
        CAMPAIGN_SET_ID: item?.ACCOUNT_ID ?? '',
        STATUS: adjustNetworkStatus(item?.CHANNEL_STATUS ?? ''),
        ORIGINAL_STATUS: item?.CHANNEL_STATUS ?? '',
      };
    });
  } else {
    CHANNELS_KEYS.forEach((key) => {
      result[key] = {
        CAMPAIGN_SET_ID: '',
        STATUS: '',
      };
    });
  }


  return result;
};

export const getInitialValues = (values, managersList) => ({
  [KEYS.NAME]: values?.[KEYS.NAME] || '',
  [KEYS.STORE_ID]: values?.[KEYS.STORE_ID] || '',
  [KEYS.PLATFORM]: values?.[KEYS.PLATFORM] || DEFAULT_PLATFORM.id,
  [KEYS.LION_APP]: values?.lionAppId ? {
    name: values.lionAppName,
    id: values.lionAppId,
  } : null,
  [KEYS.STATUS]: values?.status || null,
  [KEYS.GROWTH_MANAGER]: isValueExists(values?.[KEYS.GROWTH_MANAGER]) ? findObjectByProp(managersList, 'id', values[KEYS.GROWTH_MANAGER]) : null,
  [KEYS.ADJUST_APP_TOKEN]: values?.[KEYS.ADJUST_APP_TOKEN] || '',
  [KEYS.NETWORKS]: generateInitialNetworks(values),
});

const generateEmptyChannel = (CHANNEL) => ({
  CHANNEL,
  ID: uuidv4(),
  KEY: uuidv4(),
  CHANNEL_STATUS: CHANNEL_STATUS_KEYS.PENDING,
  NEW_ACCOUT_ID: null,
});

export const getListWithAllChannels = (list, platform) => {
  const existingChannels = list.map(({ CHANNEL }) => CHANNEL);


  if (existingChannels.length === CHANNELS_KEYS.length) {
    const result = list.filter((item) => {
      if (platform === PLATFORMS.IOS && item.CHANNEL === CHANNELS.ADJOE) {
        return false;
      }
      return item;
    });

    return result;
  }

  const emptyChannelsIds = CHANNELS_KEYS.filter((CHANNEL) => {
    if (platform === PLATFORMS.IOS && CHANNEL === CHANNELS.ADJOE) {
      return false;
    }

    return !existingChannels.includes(CHANNEL);
  });

  return list.concat(emptyChannelsIds.map(generateEmptyChannel));
};

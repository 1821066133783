export const FIELDS = {
  ID: 'id',
  ACCOUNT_ID: 'accountId',
  STATUS: 'status',
  CHANNEL: 'channel',
  WEEK_SPEND: 'weekSpend',
  WEEKLY_INSTALLS: 'weekInstalls',
  GOAL_ROAS: 'goalRoas',
  ACTIONS: 'actions',
  NEW_ACCOUNT_ID: 'newAccountId',
};

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import { lionAppPropTypes } from 'models/game';

import Autocomplete from 'components/common/autocomplete';
import Text from 'components/common/text';

import { isValueExists } from 'utils';

import { filter } from './utils';

import { NewLionAppTitle, OptionName, OptionId, ListBoxStyles } from './styles';


const ListboxProps = {
  sx: ListBoxStyles,
};

const LionAppAutocomplete = memo(({ onSubmit, isFetching, options, ...otherProps }) => {
  const handleChange = useCallback((nextValue, event, props, option) => {
    onSubmit(nextValue || null, event, props, option);
  }, [onSubmit]);

  const getOptionLabel = useCallback((option) => option.name, []);
  const getOptionId = useCallback((option) => option.id, []);

  const renderOptionContent = useCallback((option) => {
    if (!isValueExists(option.id)) {
      return (
        <>
          Add&nbsp;
          <NewLionAppTitle>
            &quot;
            {option.name}
            &quot;
          </NewLionAppTitle>
        </>
      );
    }

    return (
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        paddingRight={1}
        paddingLeft={1}
      >
        <OptionName as={Text}>{option.name}</OptionName>
        <OptionId as={Text}>{option.id}</OptionId>
      </Stack>
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterOptions = useCallback((autocompleteOptions, params) => {
    const filtered = filter(autocompleteOptions, params);

    const { inputValue } = params;

    if (!filtered.length) {
      filtered.push({
        id: null,
        name: inputValue,
      });
    }

    return filtered;
  }, []);

  return (
    <Autocomplete
      onSubmit={handleChange}
      options={options}
      extractKey={getOptionId}
      getOptionLabel={getOptionLabel}
      renderOptionContent={renderOptionContent}
      filterOptions={filterOptions}
      freeSolo
      ListboxProps={ListboxProps}
      {...otherProps}
    />
  );
});


LionAppAutocomplete.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  options: PropTypes.arrayOf(lionAppPropTypes).isRequired,
};

LionAppAutocomplete.defaultProps = {
  isFetching: false,
};


export default LionAppAutocomplete;

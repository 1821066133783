import React, { memo, useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LoopIcon from '@mui/icons-material/Loop';

import { getConfig } from 'core/settings/selectors';
import AnalyticsService from 'services/analytics';

import { isValueChanged, isValueExists } from 'utils';

import ConfirmationModal from 'components/modals/confirmation';
import EditableTextField from 'components/common/editable-text-field';
import CommentPopper from 'components/games/comment-popper';

import { goalRoasValidator } from './validation';
import { getOverrideText, getIsOverrideVisible, getResetOverrideConfirmationText, getTooltipTitle, getOverrideConfirmationText } from './utils';
import { OverrideIndicator, Wrapper } from './styles';


const GoalRoasField = memo(({ override, onReset, onSubmit, initialValue, withConfirmation, disabled, withoutComment, category, fromBulkActions, disableTooltip, ...otherProps }) => {
  const config = useSelector(getConfig);

  const wrapperRef = useRef();
  const [value, setValue] = useState(initialValue);
  const [isResetConfirmationModalOpen, setIsResetConfirmationModalOpen] = useState(false);
  const [overrideValue, setOverrideValue] = useState(null);
  const [isCommentPopperOpen, setIsCommentPopperOpen] = useState(false);
  const [inputEventProps, setInputEventProps] = useState({});
  const [comment, setComment] = useState(null);
  const [isResetMode, setIsResetMode] = useState(false);

  const handleSubmit = useCallback((nextComment, options = {}) => {
    const { useOptions } = options;

    const values = useOptions ? options : {
      isResetMode,
      value,
      inputEventProps,
    };

    if (!useOptions) {
      AnalyticsService.trackEvent({
        category,
        name: AnalyticsService.NAMES.SAVE_COMMENT_GOAL_ROAS,
        action: AnalyticsService.ACTIONS.CLICK,
      });
    }

    setComment(nextComment);
    setIsCommentPopperOpen(false);

    if (values.isResetMode) {
      setIsResetConfirmationModalOpen(true);
      return;
    }

    if (!withConfirmation) {
      onSubmit(values.value, { comment: nextComment }, ...values.inputEventProps);
      return;
    }

    if (Math.abs(values.value - initialValue) > config?.roasChangingWarning) {
      AnalyticsService.trackEvent({
        category,
        name: AnalyticsService.NAMES.WARNING_GOAL_ROAS_MODAL,
        action: AnalyticsService.ACTIONS.CLICK,
      });

      setOverrideValue(values.value);
      return;
    }

    if (!overrideValue) {
      AnalyticsService.trackEvent({
        category,
        name: AnalyticsService.NAMES.CHANGE_GOAL_ROAS,
        action: fromBulkActions ? AnalyticsService.ACTIONS.BULK_CHANGE : AnalyticsService.ACTIONS.CHANGE,
      });

      onSubmit(values.value, { comment: nextComment, initialValue });
    }
  }, [config?.roasChangingWarning, initialValue, onSubmit, inputEventProps, overrideValue, value, withConfirmation, isResetMode, category, fromBulkActions]);

  const handleEdit = useCallback(() => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.EDIT_MODE_GOAL_ROAS,
      action: AnalyticsService.ACTIONS.CLICK,
    });
  }, [category]);

  const handleResetOverrideButtonClick = useCallback(() => {
    setIsResetMode(true);

    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.RESET_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    if (withoutComment) {
      handleSubmit(null, {
        useOptions: true,
        isResetMode: true,
      });
    } else {
      setIsCommentPopperOpen(true);
    }
  }, [withoutComment, handleSubmit, category]);

  const handleResetOverrideModalClose = useCallback(() => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.DECLINE_RESET_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsResetConfirmationModalOpen(false);
    setIsResetMode(false);
  }, [category]);

  const handleConfirmReset = useCallback(() => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.CONFIRM_RESET_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    onReset({
      comment,
      initialValue,
    });

    setIsResetConfirmationModalOpen(false);
    setIsResetMode(false);
  }, [onReset, comment, category, initialValue]);

  const handleOverride = useCallback((nextValue, ...props) => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.SAVE_GOAL_ROAS,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setValue(nextValue);
    setInputEventProps(props);

    if (withoutComment) {
      handleSubmit(null, {
        useOptions: true,
        value: nextValue,
        inputEventProps: props,
      });
    } else {
      setIsCommentPopperOpen(true);
    }
  }, [handleSubmit, withoutComment, category]);

  const handleCommentPopperCancel = useCallback(() => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.DECLINE_COMMENT_GOAL_ROAS,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setValue(initialValue);
    setOverrideValue(null);
    setIsCommentPopperOpen(false);
    setInputEventProps({});
    setIsResetMode(false);
  }, [initialValue, category]);

  const handleOverrideModalClose = useCallback(() => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.DECLINE_WARNING_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setValue(initialValue);
    setOverrideValue(null);
    setComment(null);
  }, [initialValue, category]);

  const handleConfirmSubmit = useCallback(() => {
    AnalyticsService.trackEvent({
      category,
      name: AnalyticsService.NAMES.CONFIRM_WARNING_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    onSubmit(overrideValue, { comment, initialValue });
    setOverrideValue(null);
  }, [overrideValue, onSubmit, comment, category, initialValue]);

  const isOverrideVisible = getIsOverrideVisible(override);

  const tooltipTitle = disableTooltip ? '' : getTooltipTitle(override);

  const beforeElement = useMemo(() => {
    if (!isOverrideVisible) {
      return null;
    }

    const tooltipText = override ? getOverrideText(override) : undefined;

    return (
      <Tooltip
        placement="top"
        title={tooltipText}
        disableFocusListener
        disableTouchListener
        arrow
      >
        <OverrideIndicator />
      </Tooltip>

    );
  }, [override, isOverrideVisible]);

  const afterElement = useMemo(() => {
    if (!isOverrideVisible) {
      return null;
    }

    const button = (
      <IconButton
        aria-label="Reset override Goal ROAS"
        size="small"
        onClick={handleResetOverrideButtonClick}
        disabled={disabled}
      >
        <LoopIcon />
      </IconButton>
    );

    if (disabled) {
      return button;
    }

    return (
      <Tooltip
        placement="top"
        title="Reset override Goal ROAS"
        disableFocusListener
        disableTouchListener
        arrow
      >
        {button}
      </Tooltip>
    );
  }, [handleResetOverrideButtonClick, isOverrideVisible, disabled]);

  return (
    <Wrapper ref={wrapperRef}>
      <EditableTextField
        type="number"
        validationSchema={goalRoasValidator}
        isValueChanged={isValueChanged}
        beforeElement={beforeElement}
        afterElement={afterElement}
        tooltipTitle={tooltipTitle}
        onSubmit={handleOverride}
        initialValue={value}
        valuePostfix={isValueExists(value) ? '%' : ''}
        disabled={disabled}
        onEdit={handleEdit}
        {...otherProps}
      />

      {
        isCommentPopperOpen && (
          <CommentPopper
            anchorRef={wrapperRef.current}
            isOpen={isCommentPopperOpen}
            title={isResetMode ? 'Describe the reason for the reset override' : 'Describe the reason for the override'}
            onCancel={handleCommentPopperCancel}
            onSubmit={handleSubmit}
          />
        )
      }

      {
        !!overrideValue && (
          <ConfirmationModal
            isOpen={!!overrideValue}
            onConfirm={handleConfirmSubmit}
            onCancel={handleOverrideModalClose}
            title={`Roas % Changing Warning (more than ${config?.roasChangingWarning}%)`}
            description={getOverrideConfirmationText(initialValue, overrideValue)}
          />
        )
      }

      {
        isOverrideVisible && (
          <ConfirmationModal
            isOpen={isResetConfirmationModalOpen}
            onConfirm={handleConfirmReset}
            onCancel={handleResetOverrideModalClose}
            title="Reset Confirmation"
            description={getResetOverrideConfirmationText(override)}
          />
        )
      }
    </Wrapper>
  );
});


GoalRoasField.propTypes = {
  override: PropTypes.shape({
    manager: PropTypes.string,
    createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    parentType: PropTypes.string,
    parentGoal: PropTypes.number,
  }),
  onReset: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  withConfirmation: PropTypes.bool,
  disabled: PropTypes.bool,
  withoutComment: PropTypes.bool,
  category: PropTypes.string.isRequired,
  fromBulkActions: PropTypes.bool,
  disableTooltip: PropTypes.bool,
};

GoalRoasField.defaultProps = {
  override: null,
  onReset: null,
  initialValue: null,
  withConfirmation: true,
  disabled: undefined,
  withoutComment: false,
  fromBulkActions: false,
  disableTooltip: false,
};


export default GoalRoasField;

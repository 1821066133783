import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { formatNumber } from 'utils/formatters';

import { Text } from './styles';


const DeltaSpendText = memo(({ value, ...otherProps }) => (
  <Text
    $isNegative={value < 0}
    {...otherProps}
  >
    {formatNumber(value)}
  </Text>
));


DeltaSpendText.propTypes = {
  value: PropTypes.number.isRequired,
};

DeltaSpendText.defaultProps = {

};


export default DeltaSpendText;

import React, { memo, useCallback, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { useTheme } from '@mui/material/styles';

import OuterElement, { OuterElementContext } from './outer-element';
import { useResetCache } from './hooks';
import { renderRow } from './utils';


const VirtualizedListboxComponent = memo(forwardRef(({ children, visibleItemsCount, ...otherProps }, ref) => {
  const items = children.reduce((result, child) => {
    result.push(child);
    result.push(...(child.children || []));

    return result;
  }, []);

  const itemsCount = items.length;

  const theme = useTheme();
  const gridRef = useResetCache(itemsCount);

  const { height } = theme.components.MuiAutocomplete?.styleOverrides?.option || {};

  const getChildSize = useCallback(() => height, [height]);

  const listHeight = useMemo(() => {
    if (itemsCount > visibleItemsCount) {
      return visibleItemsCount * height;
    }
    return items.map(getChildSize).reduce((result, itemHeight) => result + itemHeight, 0);
  }, [visibleItemsCount, itemsCount, items, getChildSize, height]);

  const getItemSize = useCallback((index) => getChildSize(items[index]), [items, getChildSize]);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={otherProps}>
        <VariableSizeList
          ref={gridRef}
          itemData={items}
          height={listHeight}
          outerElementType={OuterElement}
          itemSize={getItemSize}
          itemCount={itemsCount}
          overscanCount={visibleItemsCount}
          innerElementType="ul"
          width="100%"
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
}));


VirtualizedListboxComponent.propTypes = {
  children: PropTypes.node.isRequired,
  visibleItemsCount: PropTypes.number,
};


VirtualizedListboxComponent.defaultProps = {
  visibleItemsCount: 8,
};


export default VirtualizedListboxComponent;

import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { CYCLE_RUNNING_ERROR } from 'constants/text';

import { getIsGameUpdateFetching, getIsGoalRoasUpdateFetching, getGamesList, getIsGamesFetching, getIsGameCreateFetching } from 'core/v4/games/selectors';
import { getManagersList } from 'core/managers/selectors';
import { getIsNetworkUpdateFetching } from 'core/networks/selectors';
import { getIsNetworkAddMultipleFetching, getIsNetworkUpdateMultipleFetching } from 'core/v4/networks/selectors';
import { getIsAppStatusReady } from 'core/settings/selectors';
import { getAuthorizedUser } from 'core/users/selectors';
import { GOAL_ROAS_PATHS } from 'core/v4/games/constants';
import gamesActions from 'core/v4/games/actions';
import networksActions from 'core/networks/actions';
import ConfirmationModal from 'components/modals/confirmation';

import AnalyticsService from 'services/analytics';

import { getUniqueElementsByProp, isArray, isValueExists } from 'utils';

import Button from 'components/common/button';
import GamesList from 'components/v4/games/list';
import GameModal from 'components/v4/games/game-modal';
import GamesSelectedRowsActions from 'components/v4/games/games-selected-rows-actions';

import { getGamesbyIds } from './utils';

import { ButtonWrapper } from './styles';


const Games = memo(({
  selection: selectedGamesIds,
  onSelectionChange,
  type,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getGamesList);
  const managersList = useSelector(getManagersList);
  const user = useSelector(getAuthorizedUser);

  const isFetching = useSelector(getIsGamesFetching);
  const isGameUpdateFetching = useSelector(getIsGameUpdateFetching);
  const isNetworkUpdateFetching = useSelector(getIsNetworkUpdateFetching);
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);
  const isAppStatusReady = useSelector(getIsAppStatusReady);
  const isUpdateFetching = isGameUpdateFetching || isNetworkUpdateFetching || isGoalRoasUpdateFetching;
  const isNetworkAddMultipleFetching = useSelector(getIsNetworkAddMultipleFetching);
  const isGameCreateFetching = useSelector(getIsGameCreateFetching);
  const isNetworkUpdateMultipleFetching = useSelector(getIsNetworkUpdateMultipleFetching);
  const isUpdateNetworkMultipleFetching = isGameCreateFetching || isGameUpdateFetching || isNetworkAddMultipleFetching || isNetworkUpdateMultipleFetching;

  const [isGameModalOpen, setIsGameModalOpen] = useState(false);
  const [selectedRowsActionsAnchor, setSelectedRowsActionsAnchor] = useState(null);
  // const [selectedGamesIds, setSelectedGamesIds] = useState([]);
  const [lastEditedRows, setLastEditedRows] = useState([]);

  const [formProps, setFormProps] = useState({ isEdit: false, initialValues: null, isDelete: false });
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);

  const handleEdit = useCallback((nextData) => {
    const currentManager = managersList.filter((item) => item.name === nextData.growthManager);

    const selectedData = {
      id: nextData.id,
      name: nextData.name,
      status: nextData.status,
      platform: nextData.platform,
      storeId: nextData.storeId,
      lionAppId: nextData.lionAppId || 0,
      lionAppName: nextData.lionAppName,
      growthManager: currentManager?.[0]?.id ?? 0,
      adjustAppToken: nextData.adjustAppToken,
      campaignSets: nextData.campaignSets,
    };

    setFormProps({
      isEdit: true,
      initialValues: selectedData,
    });

    setIsGameModalOpen(true);
  }, []);

  const handleDelete = useCallback((nextData) => {
    setFormProps({
      isDelete: true,
      initialValues: nextData,
    });

    setIsDeleteConfirmationModalOpen(true);
  }, []);

  const handleDeleteConfirm = () => {
    setIsDeleteConfirmationModalOpen(false);

    dispatch(gamesActions.deleteGame({ id: formProps.initialValues.id }));
    setFormProps({
      isDelete: false,
      initialValues: null,
    });
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    setFormProps({
      isDelete: false,
      initialValues: null,
    });
  }, []);

  const addToLastEditedRows = useCallback((rows, shouldSetEditedRows = true) => {
    if (!shouldSetEditedRows) {
      return;
    }

    setLastEditedRows((prevRows) => {
      const nextRows = isArray(rows) ? rows : [rows];

      return [...prevRows, ...nextRows];
    });
  }, []);

  const handleAddNewGameClick = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.NEW_GAME_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsGameModalOpen(true);
  }, []);

  const handleAddNewGameCancel = useCallback(() => {
    setIsGameModalOpen(false);
    setFormProps({
      isEdit: false,
      initialValues: null,
    });
  }, []);

  const selectedRowsActionsClose = useCallback(() => {
    setSelectedRowsActionsAnchor(null);
  }, []);

  const handleGameUpdate = useCallback((nextData, shouldSetEditedRows = true) => {
    const { id } = nextData;

    let eventName;
    if (isValueExists(nextData.status)) {
      eventName = AnalyticsService.NAMES.CHANGE_APP_STATUS;
    } else if (isValueExists(nextData.name)) {
      eventName = AnalyticsService.NAMES.CHANGE_APP_NAME;
    } else {
      eventName = AnalyticsService.NAMES.CHANGE_GROWTH_MANAGER;
    }

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: eventName,
      action: shouldSetEditedRows ? AnalyticsService.ACTIONS.CHANGE : AnalyticsService.ACTIONS.BULK_CHANGE,
    });

    dispatch(gamesActions.updateGame(nextData, { refetchGamesList: true }));
    addToLastEditedRows(id, shouldSetEditedRows);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNetworkUpdate = useCallback((nextData, shouldSetEditedRows = true) => {
    const { rowId } = nextData;

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.CHANGE_CHANNEL_STATUS,
      action: shouldSetEditedRows ? AnalyticsService.ACTIONS.CHANGE : AnalyticsService.ACTIONS.BULK_CHANGE,
    });

    dispatch(networksActions.updateNetwork(nextData, { refetchGamesList: true, refetchGame: false }));
    addToLastEditedRows(rowId, shouldSetEditedRows);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoalRoasUpdate = useCallback((nextData, fromBulkActions = false) => {
    const { rowId } = nextData;

    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      path: GOAL_ROAS_PATHS.NETWORK,
    }, {
      refetchGame: false,
      refetchGamesList: true,
      fromBulkActions,
    }));

    if (!fromBulkActions) {
      addToLastEditedRows(rowId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedRowsActionsClick = useCallback((event) => {
    setSelectedRowsActionsAnchor(event.currentTarget);
  }, []);

  const handleMultiChangeSubmit = useCallback((key, value, { isChannel }) => {
    const gamesByIds = getGamesbyIds(selectedGamesIds, data);
    const selectedGames = isChannel ? gamesByIds : getUniqueElementsByProp(gamesByIds, 'appId');

    const editedRows = selectedGames.map(({ appId }) => appId);

    addToLastEditedRows(editedRows);

    selectedGames.forEach(({ appId }) => {
      handleGameUpdate({
        id: appId,
        [key]: value,
      }, false);
    });
  }, [selectedGamesIds, data, handleGameUpdate, handleNetworkUpdate, handleGoalRoasUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLastEditedRows([]);
  }, [data]);

  useEffect(() => {
    dispatch(gamesActions.fetchList());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.GAMES,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buttons = useMemo(() => {
    const BulkActionsButtonIcon = selectedRowsActionsAnchor ? ArrowDropUpIcon : ArrowDropDownIcon;

    let addNewGameButton;
    if (isAppStatusReady) {
      addNewGameButton = (
        <Button
          onClick={handleAddNewGameClick}
          startIcon={<AddIcon />}
        >
          New Game
        </Button>
      );
    } else {
      addNewGameButton = (
        <Tooltip
          placement="left"
          title={CYCLE_RUNNING_ERROR}
          disableFocusListener
          disableTouchListener
          arrow
        >
          <span>
            <Button
              onClick={handleAddNewGameClick}
              startIcon={<AddIcon />}
              disabled
            >
              New Game
            </Button>
          </span>

        </Tooltip>
      );
    }

    return (
      <ButtonWrapper
        as={Stack}
        direction="row"
        justifyContent="space-between"
      >
        <Button
          onClick={selectedRowsActionsClick}
          variant="outlined"
          disabled={!selectedGamesIds.length || isUpdateFetching}
          endIcon={<BulkActionsButtonIcon />}
        >
          Bulk Actions
        </Button>

        {addNewGameButton}
      </ButtonWrapper>
    );
  }, [selectedGamesIds.length, selectedRowsActionsAnchor, isUpdateFetching, isAppStatusReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const gamesSelectedRowsActionsStyles = useMemo(() => ({
    mt: 0.5,
  }), []);

  const gamesSelectedRowsActions = useMemo(() => (
    <GamesSelectedRowsActions
      anchor={selectedRowsActionsAnchor}
      managersList={managersList}
      close={selectedRowsActionsClose}
      onSubmit={handleMultiChangeSubmit}
      sx={gamesSelectedRowsActionsStyles}
      isAppStatusReady={isAppStatusReady}
    />
  ), [selectedRowsActionsAnchor, managersList, handleMultiChangeSubmit, isAppStatusReady]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {buttons}

      <GamesList
        data={data}
        managersList={managersList}
        isFetching={isFetching || isUpdateNetworkMultipleFetching}
        isGameUpdateFetching={isGameUpdateFetching}
        isNetworkUpdateFetching={isNetworkUpdateFetching}
        isGoalRoasUpdateFetching={isGoalRoasUpdateFetching}
        selectedGamesIds={selectedGamesIds}
        lastEditedRows={lastEditedRows}
        onSelectedGamesIdsChange={handleSelectionChange}
        onGameUpdate={handleGameUpdate}
        onNetworkUpdate={handleNetworkUpdate}
        onGoalRoasUpdate={handleGoalRoasUpdate}
        isAppStatusReady={isAppStatusReady}
        user={user}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <GameModal
        isOpen={isGameModalOpen}
        onCancel={handleAddNewGameCancel}
        {...formProps}
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Game Archive Confirmation"
        description="Please, confirm that you want to archive this Game"
      />

      {gamesSelectedRowsActions}
    </>
  );
});

Games.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  type: PropTypes.string.isRequired,
};

export default Games;

import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import { types } from './actions';
import persistConfig from './persist';


const defaultState = {
  list: null,
  collection: {},
  overrideList: null,

  isListFetching: false,
  isGameFetching: false,
  isGameUpdateFetching: false,
  isGameDeleteFetching: false,
  isGameCreateFetching: false,
  isGoalRoasUpdateFetching: false,
  isGoalRoasResetFetching: false,
};

const reducer = handleActions({
  [types.FETCH_LIST]: (state, { payload: { useIsFetching } }) => {
    if (!useIsFetching) {
      return state;
    }

    return update(state, {
      isListFetching: { $set: true },
    });
  },
  [resolved(types.FETCH_LIST)]: (state, { payload: { data } }) => (
    update(state, {
      list: { $set: data },
      isListFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_LIST)]: (state) => (
    update(state, {
      isListFetching: { $set: false },
    })
  ),

  [types.UPDATE_GAME]: (state) => (
    update(state, {
      isGameUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_GAME)]: (state) => (
    update(state, {
      isGameUpdateFetching: { $set: false },
    })
  ),
  [rejected(types.UPDATE_GAME)]: (state) => (
    update(state, {
      isGameUpdateFetching: { $set: false },
    })
  ),

  [types.FETCH_GAME]: (state, { payload: { useIsFetching } }) => {
    if (!useIsFetching) {
      return state;
    }

    return update(state, {
      isGameFetching: { $set: true },
    });
  },
  [resolved(types.FETCH_GAME)]: (state, { payload: { data } }) => (
    update(state, {
      collection: {
        [data.id]: { $set: data },
      },
      isGameFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_GAME)]: (state) => (
    update(state, {
      isGameFetching: { $set: false },
    })
  ),

  [resolved(types.FETCH_GAMES)]: (state, { payload: { data } }) => {
    const collectionSchema = data.reduce((result, game) => {
      result[game.id] = { $set: game }; // eslint-disable-line no-param-reassign

      return result;
    }, {});

    return update(state, {
      collection: collectionSchema,
    });
  },

  [types.DELETE_GAME]: (state) => (
    update(state, {
      isGameDeleteFetching: { $set: true },
    })
  ),
  [resolved(types.DELETE_GAME)]: (state) => (
    update(state, {
      isGameDeleteFetching: { $set: false },
    })
  ),
  [rejected(types.DELETE_GAME)]: (state) => (
    update(state, {
      isGameDeleteFetching: { $set: false },
    })
  ),

  [types.CREATE_GAME]: (state) => (
    update(state, {
      isGameCreateFetching: { $set: true },
    })
  ),
  [resolved(types.CREATE_GAME)]: (state) => (
    update(state, {
      isGameCreateFetching: { $set: false },
    })
  ),
  [rejected(types.CREATE_GAME)]: (state) => (
    update(state, {
      isGameCreateFetching: { $set: false },
    })
  ),

  [types.UPDATE_GOAL_ROAS]: (state) => (
    update(state, {
      isGoalRoasUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_GOAL_ROAS)]: (state, { payload: { overrideList } }) => (
    update(state, {
      isGoalRoasUpdateFetching: { $set: false },
      overrideList: { $set: overrideList },
    })
  ),
  [rejected(types.UPDATE_GOAL_ROAS)]: (state) => (
    update(state, {
      isGoalRoasUpdateFetching: { $set: false },
    })
  ),

  [types.RESET_OVERRIDE_GOAL_ROAS_LIST]: (state) => (
    update(state, {
      isGoalRoasResetFetching: { $set: true },
    })
  ),
  [resolved(types.RESET_OVERRIDE_GOAL_ROAS_LIST)]: (state) => (
    update(state, {
      isGoalRoasResetFetching: { $set: false },
      overrideList: { $set: null },
    })
  ),
  [rejected(types.RESET_OVERRIDE_GOAL_ROAS_LIST)]: (state) => (
    update(state, {
      isGoalRoasResetFetching: { $set: false },
    })
  ),

  [types.RESET_OVERRIDE_GOAL_ROAS]: (state) => (
    update(state, {
      isGoalRoasResetFetching: { $set: true },
    })
  ),
  [resolved(types.RESET_OVERRIDE_GOAL_ROAS)]: (state, { payload: { overrideList } }) => (
    update(state, {
      isGoalRoasResetFetching: { $set: false },
      overrideList: { $set: overrideList },
    })
  ),
  [rejected(types.RESET_OVERRIDE_GOAL_ROAS)]: (state) => (
    update(state, {
      isGoalRoasResetFetching: { $set: false },
    })
  ),

  [types.CANCEL_OVERRIDE_GOAL_ROAS]: (state) => (
    update(state, {
      overrideList: { $set: null },
    })
  ),

  [authTypes.LOGOUT]: () => defaultState,
}, defaultState);


export default persistReducer(persistConfig, reducer);

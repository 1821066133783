import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import gamesActions from 'core/games/actions';
import { getIsGoalRoasUpdateFetching } from 'core/games/selectors';
import AnalyticsService from 'services/analytics';

import { GOAL_ROAS_PATHS } from 'core/games/constants';

import DataGrid from 'components/common/data-grid';

import { getConfig } from './config';


const GameSourcesList = memo(({ list, gameId, onSelectionChange, type, selection, isGameArchived }) => {
  const dispatch = useDispatch();

  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);

  const [lastEditedRow, setLastEditedRow] = useState(null);

  const handleRowUpdate = useCallback((nextRow) => {
    console.log(nextRow); // eslint-disable-line no-console

    // TODO: call API here
  }, []);

  const handleGoalRoasUpdate = useCallback((nextData) => {
    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.SOURCE,
    }));

    setLastEditedRow(nextData.id);
  }, [dispatch, gameId]);

  const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);

  const config = useMemo(() => getConfig(list, handleRowUpdate, handleGoalRoasUpdate, isGameArchived), [list, handleRowUpdate, handleGoalRoasUpdate, isGameArchived]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    isGoalRoasUpdateFetching && id === lastEditedRow
  ), [isGoalRoasUpdateFetching, lastEditedRow]);

  useEffect(() => {
    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.SOURCES,
    });
  }, []);

  return (
    <DataGrid
      config={config}
      getIsRowFetching={getIsRowFetching}
      selectionModel={selection}
      onSelectionModelChange={handleSelectionChange}
    />
  );
});


GameSourcesList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  gameId: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  isGameArchived: PropTypes.bool.isRequired,
};

GameSourcesList.defaultProps = {
  list: [],
};


export default GameSourcesList;

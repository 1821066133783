import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'auth',
  storage,
  blacklist: [
    'isFetching',
    'isTokenValid',
    'isRecoverPasswordFetching',
    'isResetPasswordFetching',
  ],
};


export default persistConfig;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/common/link';

import { StyledLink } from './styles';


const LinkButton = memo(({ children, to, isInline }) => (
  <StyledLink
    as={Link}
    to={to}
    $isInline={isInline}
  >
    {children}
  </StyledLink>
));


LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  isInline: PropTypes.bool,
};

LinkButton.defaultProps = {
  isInline: false,
};


export default LinkButton;

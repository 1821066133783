export const COLORS = {
  PRIMARY: '#2D2D2D',
  SECONDARY: '#3A76E8',
  BASE: '#ffffff',
  GREY: '#C6C6C6',
  GREY_DARK: '#8F8F8F',
  BACKGROUND: '#E8EAEE',
  BACKGROUND_LIGHT: '#F9F9F9',
  HOVER: '#E6EFFF',
  RED: '#F50057',
  RED_LIGHT: '#FEECEB',
  GREEN: '#73BC24',
};

export const BASE = {
  PADDING: 8,
  BORDER_RADIUS: 12,
};

export const TRANSITION = {
  DURATION: 300,
  EASING: 'cubic-bezier(0.4, 0, 0.2, 1)',
};

export const MENU = {
  ITEM_HEIGHT: '52px',
};

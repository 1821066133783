import { styled } from '@mui/system';


export const GroupButton = styled('button')(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 0,

  '&:hover, &:focus': {
    backgroundColor: 'inherit',
  },
}));

export const IconWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  gap: '.4rem',
  alignItems: 'center',
}));

import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'networksV4',
  storage,
  blacklist: [
    'isNetworkUpdateFetching',
    'isNetworkAddFetching',
    'isNetworkDeleteFetching',
  ],
};


export default persistConfig;

import React, { memo, useCallback } from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';

import { ROUTES } from 'containers/router/constants';
import { getAppStatus } from 'core/settings/selectors';
import authActions from 'core/auth/actions';

import { APP_STATUSES_COLORS } from 'core/settings/constants';

import Logo from 'components/common/logo';
import GamesSearch from 'components/games/search';
import StatusClock from 'components/common/status-clock';
import EnvBadge from 'components/common/env-badge';
import CommonMenuItem from 'components/common/menu-item';

import { ReactComponent as OperationalIcon } from 'assets/images/all-system-operational.svg';

import { Wrapper, DescriptionText, Navigation, StyledLink, SearchWrapper } from './styles';


const Header = memo(({ isAuthorized, isAuthorizedUserAdmin }) => {
  const dispatch = useDispatch();
  const appStatus = useSelector(getAppStatus);

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <Wrapper
      as={Stack}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to={ROUTES.GAMES}>
          <Logo />
        </Link>

        {
          appStatus?.lastPush && appStatus.nextPush && (
            <StatusClock
              lastPush={appStatus.lastPush}
              nextPush={appStatus.nextPush}
            />
          )
        }


        {isAuthorized && appStatus && (
          <DescriptionText
            as={Stack}
            direction="row"
            alignItems="center"
          >
            <OperationalIcon
              fill={APP_STATUSES_COLORS[appStatus.status]}
            />

            {appStatus.text}
          </DescriptionText>
        )}

        <EnvBadge />
      </Stack>

      {isAuthorized && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SearchWrapper>
            <GamesSearch />
          </SearchWrapper>

          <Navigation>
            {isAuthorizedUserAdmin && (

            /*
              <StyledLink
                to={`/${ROUTES.SETTINGS}`}
                as={NavLink}
              >
                Settings
              </StyledLink>
            */

              <CommonMenuItem
                lastPush={appStatus.lastPush}
                nextPush={appStatus.nextPush}
              />

            )}

            <StyledLink
              to={`/${ROUTES.GAMES}`}
              as={NavLink}
            >
              Games
            </StyledLink>

            <StyledLink
              to={`/${ROUTES.BIDS}`}
              as={NavLink}
            >
              Bids
            </StyledLink>

            <StyledLink
              onClick={handleLogout}
            >
              Sign Out
            </StyledLink>
          </Navigation>
        </Stack>
      )}
    </Wrapper>
  );
});


Header.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isAuthorizedUserAdmin: PropTypes.bool.isRequired,
};

Header.defaultProps = {

};


export default Header;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { isObjectChanged } from 'utils';


const DataGridFilterField = memo(({ component: Component, staticProps, ...props }) => (
  <Component
    {...staticProps}
    {...props}
  />
), (prevProps, nextProps) => {
  const { staticProps: prevStaticProps, component: prevComponent, ...prevOtherProps } = prevProps;
  const { staticProps: nextStaticProps, component: nextComponent, ...nextOtherProps } = nextProps;

  return !isObjectChanged(prevOtherProps, nextOtherProps);
});


DataGridFilterField.propTypes = {
  component: PropTypes.elementType.isRequired,
  staticProps: PropTypes.shape({}),
};

DataGridFilterField.defaultProps = {
  staticProps: {},
};


export default DataGridFilterField;

import React, { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

import { isObjectChanged } from 'utils';

import { bidPropTypes } from 'models/bids';
import { gamePropTypes } from 'models/game';
import { managerPropTypes } from 'models/manager';

import { FILTERS_KEYS } from 'core/bids/constants';
import AnalyticsService from 'services/analytics';

import { useQueryParams } from 'hooks/use-query-params';
import useIsDataInited from 'hooks/use-is-data-inited';

import DataGrid from 'components/common/data-grid';
import DataGridFilter from 'components/common/data-grid-filter';
import Button from 'components/common/button';
import ListColumnsSelector from 'components/bids/list-columns-selector';
import CsvButton from 'components/bids/csv-button';

import { getConfig, getFiltersConfig } from './config';
import { DEFAULT_FILTERS_VALUES, QUERY_PARAMS_WITH_TYPES } from './constants';
import {
  getActiveFilters,
  getFiltersValueForSubmit,
  getColumnVisibilityModel,
  getQueryParamsByFilters,
  getFiltersByQueryParams,
  getSortingByQueryParams,
} from './utils';
import {
  StyledDataGrid,
  Wrapper,
  DataGridFilterWrapper,
  ActionButtonsWrapper,
  ActionButtonWrapper,
} from './styles';


const BidsList = memo(({
  total,
  isFetching,
  data,
  games,
  campaigns,
  countries,
  managers,
  isGamesFetching,
  isCampaignsFetching,
  isCountriesFetching,
  isManagersFetching,
  isOverrideFetching,
  onFiltersAndSortingStateChange,
  onBidOverride,
  onColumnVisibilityChange,
  onLookerClick,
  hiddenColumns: initialHiddenColumns,
  onPushHistoricalBidsClick,
  isAdmin,
  isAppStatusReady,
}) => {
  const prevFiltersStateRef = useRef(null);
  const prevSortingRef = useRef(null);

  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAMS_WITH_TYPES);
  const isFilterDataInited = useIsDataInited(managers, campaigns, countries, games);

  const [list, setList] = useState(() => []);
  const [filtersState, setFiltersState] = useState(() => ({
    ...DEFAULT_FILTERS_VALUES,
    ...getFiltersByQueryParams(queryParams),
  }));
  const [hiddenColumns, setHiddenColumns] = useState(initialHiddenColumns);
  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [sorting, setSorting] = useState(() => getSortingByQueryParams(queryParams));

  const handleOverrideConfirm = useCallback((overrideData) => {
    const { bidKey } = overrideData;
    setLastEditedRow(bidKey);
    onBidOverride(overrideData);
  }, [onBidOverride]);

  const handleColumnsSelectorChange = useCallback((columns) => {
    setHiddenColumns(columns);
    onColumnVisibilityChange(columns);
  }, [onColumnVisibilityChange]);

  const handleGameLinkClick = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.BIDS,
      name: AnalyticsService.NAMES.OPEN_GAME_DETAILS,
      action: AnalyticsService.ACTIONS.CLICK,
    });
  }, []);

  const handleCampaignLinkClick = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.BIDS,
      name: AnalyticsService.NAMES.OPEN_CAMPAIGN,
      action: AnalyticsService.ACTIONS.CLICK,
    });
  }, []);

  const config = useMemo(() => getConfig(
    list,
    handleOverrideConfirm,
    isAppStatusReady,
    handleGameLinkClick,
    handleCampaignLinkClick,
  ), [list, handleOverrideConfirm, isAppStatusReady]);

  const countriesList = useMemo(() => countries.map((country) => ({ id: country, name: country })), [countries]);

  const filtersConfig = useMemo(() => getFiltersConfig({
    games,
    campaigns,
    managers,
    isGamesFetching,
    isCampaignsFetching,
    isCountriesFetching,
    isManagersFetching,
    countries: countriesList,
  }), [
    games,
    campaigns,
    countriesList,
    managers,
    isGamesFetching,
    isCampaignsFetching,
    isCountriesFetching,
    isManagersFetching,
  ]);

  const getIsRowFetching = useCallback(({ row: { bidKey } }) => (
    (isOverrideFetching) && bidKey === lastEditedRow
  ), [isOverrideFetching, lastEditedRow]);

  const handleSortingChange = useCallback((nextSorting) => {
    setSorting(nextSorting);
  }, []);

  const handlePaginationStateChange = useCallback((key, value) => {
    if (value === filtersState[key]) {
      return;
    }

    setFiltersState({
      ...filtersState,
      [key]: value,
    });
  }, [filtersState]);

  const handlePageChange = useCallback((value) => {
    handlePaginationStateChange(FILTERS_KEYS.PAGE, value);
  }, [handlePaginationStateChange]);

  const handlePageSizeChange = useCallback((value) => {
    handlePaginationStateChange(FILTERS_KEYS.LIMIT, value);
  }, [handlePaginationStateChange]);

  const handleFilterChange = useCallback((filtersValue) => {
    setFiltersState((prevFiltersState) => {
      const activeFilters = getActiveFilters(filtersValue, prevFiltersState);

      return {
        ...prevFiltersState,
        ...activeFilters,
      };
    });
  }, []);

  const columnVisibilityModel = useMemo(() => getColumnVisibilityModel(hiddenColumns), [hiddenColumns]);

  useEffect(() => {
    // TODO: add deep equal for filterValue ?
    if (
      (!isObjectChanged(prevFiltersStateRef.current || {}, filtersState, true) && sorting === prevSortingRef.current) || !isFilterDataInited
    ) {
      return;
    }

    const filtersValue = getFiltersValueForSubmit(filtersState, filtersConfig, sorting);

    onFiltersAndSortingStateChange(filtersValue);

    prevFiltersStateRef.current = filtersState;
    prevSortingRef.current = sorting;
  }, [filtersState, onFiltersAndSortingStateChange, filtersConfig, sorting, isFilterDataInited]);

  useEffect(() => {
    setQueryParams(getQueryParamsByFilters(filtersState, sorting));
  }, [filtersState, sorting, setQueryParams]);

  useEffect(() => {
    setList(data);
  }, [data]);

  return (
    <Wrapper>
      <ActionButtonsWrapper
        as={Stack}
        direction="row"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
        >
          <ActionButtonWrapper>
            <ListColumnsSelector
              columns={config.columns}
              hiddenColumns={hiddenColumns}
              onChange={handleColumnsSelectorChange}
            />
          </ActionButtonWrapper>

          <ActionButtonWrapper>
            <CsvButton />
          </ActionButtonWrapper>

          <ActionButtonWrapper>
            <Button
              onClick={onLookerClick}
              variant="outlined"
            >
              Go to the Looker
            </Button>
          </ActionButtonWrapper>
        </Stack>

        {
          isAdmin && (
            <ActionButtonWrapper>
              <Button
                onClick={onPushHistoricalBidsClick}
                variant="outlined"
              >
                Push Historical Bids
              </Button>
            </ActionButtonWrapper>
          )
        }
      </ActionButtonsWrapper>

      <DataGridFilterWrapper>
        <DataGridFilter
          config={filtersConfig}
          values={filtersState}
          onChange={handleFilterChange}
        />
      </DataGridFilterWrapper>

      <StyledDataGrid
        as={DataGrid}
        config={config}
        isFetching={!isFilterDataInited || isFetching} // TODO: fix flashing placeholder
        getIsRowFetching={getIsRowFetching}
        checkboxSelection={false}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        columnVisibilityModel={columnVisibilityModel}
        onSortModelChange={handleSortingChange}
        sortModel={sorting}
        sortingMode="server"
        rowCount={total}
        autoHeight={false}
        initialPage={filtersState[FILTERS_KEYS.PAGE]}
        initialPageSize={filtersState[FILTERS_KEYS.LIMIT]}
        isServerPagination
        disableVirtualization
      />
    </Wrapper>
  );
});


BidsList.propTypes = {
  data: PropTypes.arrayOf(bidPropTypes),
  games: PropTypes.arrayOf(gamePropTypes),
  campaigns: PropTypes.arrayOf(PropTypes.shape({
    // TODO: use model
  })),
  countries: PropTypes.arrayOf(PropTypes.string),
  managers: PropTypes.arrayOf(managerPropTypes),
  isFetching: PropTypes.bool.isRequired,
  isOverrideFetching: PropTypes.bool.isRequired,
  onFiltersAndSortingStateChange: PropTypes.func.isRequired,
  onBidOverride: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  isGamesFetching: PropTypes.bool.isRequired,
  isCampaignsFetching: PropTypes.bool.isRequired,
  isCountriesFetching: PropTypes.bool.isRequired,
  isManagersFetching: PropTypes.bool.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onLookerClick: PropTypes.func.isRequired,
  onPushHistoricalBidsClick: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
};

BidsList.defaultProps = {
  data: [],
  games: [],
  campaigns: [],
  countries: [],
  managers: [],
};


export default BidsList;

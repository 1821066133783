import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'NETWORKS';

export const types = createConstants([
  'ADD_NETWORK',
  'UPDATE_NETWORK',
  'DELETE_NETWORK',
  'ADD_NETWORK_MULTIPLE',
  'UPDATE_NETWORK_MULTIPLE',
], PREFIX);

export const actions = createActions({
  [types.ADD_NETWORK]: [
    ({ gameId, channel, goalRoas, status, newAccountId }) => ({ gameId, channel, goalRoas, status, newAccountId }),
    (payload, { refetchGamesList = true } = {}) => ({ refetchGamesList }),
  ],
  [types.UPDATE_NETWORK]: [
    ({ gameId, id, status, newAccountId }) => ({ gameId, id, status, newAccountId }),
    (payload, { refetchGame = true, refetchGamesList = true } = {}) => ({ refetchGame, refetchGamesList }),
  ],
  [types.DELETE_NETWORK]: ({ id, gameId }) => ({ id, gameId }),
  [types.ADD_NETWORK_MULTIPLE]: [
    ({ gameId, networks }) => ({ gameId, networks }),
    (payload, { refetchGame = true, refetchGamesList = true } = {}) => ({ refetchGame, refetchGamesList }),
  ],
  [types.UPDATE_NETWORK_MULTIPLE]: [
    ({ gameId, networks }) => ({ gameId, networks }),
    (payload, { refetchGame = true, refetchGamesList = true } = {}) => ({ refetchGame, refetchGamesList }),
  ],
  // [types.UPDATE_NETWORK_MULTIPLE]: ({ gameId, networks }) => ({ gameId, networks }),
}, {
  prefix: PREFIX,
});


export default actions;

import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'MANAGERS';

export const types = createConstants([
  'FETCH_LIST',
], PREFIX);

export const actions = createActions({
  [types.FETCH_LIST]: () => null,
}, {
  prefix: PREFIX,
});


export default actions;

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { configPropTypes } from 'models/settings';

import useFormik from 'hooks/use-formik';

import Paper from 'components/common/paper';
import Title from 'components/common/title';
import Input from 'components/common/input';
import Button from 'components/common/button';

import { KEYS } from './constants';
import { ValidationSchema } from './validation';
import {
  StyledButton,
  StyledForm,
  StyledPaper,
} from './styles';


const SettingsForm = memo(({ data, onSubmit, isUpdateFetching }) => {
  const handleFormSubmit = useCallback((formData) => {
    onSubmit(formData);
  }, [onSubmit]);

  const { handleSubmit, handleChange, values, errors, touched, isValid, submitForm, handleBlurAndTrimValue } = useFormik({
    initialValues: data,
    onSubmit: handleFormSubmit,
    validationSchema: ValidationSchema,
  });

  return (
    <Paper
      sx={StyledPaper}
    >
      <Title>
        Settings
      </Title>

      <StyledForm onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
        >
          <Grid
            item
            xs={3}
          >
            <Input
              type="number"
              name={KEYS.MINIMUM_BID_THRESHOLD}
              value={values[KEYS.MINIMUM_BID_THRESHOLD]}
              error={touched[KEYS.MINIMUM_BID_THRESHOLD] && errors[KEYS.MINIMUM_BID_THRESHOLD]}
              label="Minimum Bid Threshold"
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <Input
              type="number"
              name={KEYS.TOTAL_INSTALLS}
              value={values[KEYS.TOTAL_INSTALLS]}
              error={touched[KEYS.TOTAL_INSTALLS] && errors[KEYS.TOTAL_INSTALLS]}
              label="Total Installs"
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <Input
              type="number"
              name={KEYS.ROAS_CHANGING_WARNING}
              value={values[KEYS.ROAS_CHANGING_WARNING]}
              error={touched[KEYS.ROAS_CHANGING_WARNING] && errors[KEYS.ROAS_CHANGING_WARNING]}
              label="Roas % Changing Warning"
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <StyledButton
              as={Button}
              onClick={submitForm}
              disabled={!isValid}
              isFetching={isUpdateFetching}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          mt={2}
        >
          <Grid
            item
            xs={3}
          >
            <Input
              type="number"
              name={KEYS.MINIMUM_DAILY_SOURCES_INSTALLS}
              value={values[KEYS.MINIMUM_DAILY_SOURCES_INSTALLS]}
              error={touched[KEYS.MINIMUM_DAILY_SOURCES_INSTALLS] && errors[KEYS.MINIMUM_DAILY_SOURCES_INSTALLS]}
              label="Total Sources Installs"
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <Input
              type="number"
              name={KEYS.BID_EXPIRES_DAYS}
              value={values[KEYS.BID_EXPIRES_DAYS]}
              error={touched[KEYS.BID_EXPIRES_DAYS] && errors[KEYS.BID_EXPIRES_DAYS]}
              label="Bid Expires Days"
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
            />
          </Grid>
        </Grid>
      </StyledForm>
    </Paper>
  );
});


SettingsForm.propTypes = {
  data: configPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isUpdateFetching: PropTypes.bool.isRequired,
};

SettingsForm.defaultProps = {

};


export default SettingsForm;

import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CreateIcon from '@mui/icons-material/Create';

import AdaptiveText from 'components/common/adaptive-text';

import { FIELDS } from './constants';


export const getConfig = (data, onEdit, onDelete) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.ID,
      headerName: 'ID',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CAMPAIGN_TYPE,
      headerName: 'Campaign Type',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.LIMIT_TYPE,
      headerName: 'Limit Type',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.ACTIONS,
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => onEdit(row)}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            onClick={() => onDelete(row.id)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </>
      ),
    },
  ],
});

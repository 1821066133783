import { GAME_STATUS_KEYS } from 'core/games/constants';

import { withType, TYPES } from 'hooks/use-query-params';


export const FIELDS = {
  ID: 'id',
  APP_ID: 'appId',
  NAME: 'name',
  PLATFORM: 'platform',
  STORE_ID: 'storeId',
  GROWTH_MANAGER: 'growthManager',
  STATUS: 'status',
  CHANNEL: 'channel',
  CHANNEL_STATUS: 'channelStatus',
  CHANNEL_STATUS_TO_SUBMIT: 'status',
  GOAL_ROAS: 'goalRoas',
  BUSINESS_ACCOUNT: 'businessAccount',
  CAMPAIGN_SETS: 'campaignSets',
};

export const QUERY_PARAMS = {
  NAME: 'n',
  STORE_ID: 'st_id',
  GROWTH_MANAGER: 'm',
  SELECTION: 's',
  GOAL_ROAS: 'g_r',
  PLATFORM: 'p',
};

export const QUERY_PARAMS_BY_FIELDS = {
  [FIELDS.NAME]: QUERY_PARAMS.NAME,
  [FIELDS.STORE_ID]: QUERY_PARAMS.STORE_ID,
  [FIELDS.GROWTH_MANAGER]: QUERY_PARAMS.GROWTH_MANAGER,
  [FIELDS.GOAL_ROAS]: QUERY_PARAMS.GOAL_ROAS,
  [FIELDS.PLATFORM]: QUERY_PARAMS.PLATFORM,
};

export const QUERY_PARAMS_WITH_TYPES = {
  [QUERY_PARAMS.NAME]: TYPES.STRING,
  [QUERY_PARAMS.STORE_ID]: TYPES.STRING,
  [QUERY_PARAMS.GROWTH_MANAGER]: TYPES.STRING,
  [QUERY_PARAMS.SELECTION]: withType(TYPES.ARRAY, TYPES.STRING),
  [QUERY_PARAMS.GOAL_ROAS]: TYPES.STRING,
  [QUERY_PARAMS.PLATFORM]: TYPES.STRING,
};

export const DEFAULT_FILTER = {
  [FIELDS.STATUS]: [GAME_STATUS_KEYS.LIVE, GAME_STATUS_KEYS.PAUSED],
};

export const ACTIVE_FILTERS = [
  FIELDS.CHANNEL,
  FIELDS.PLATFORM,
  FIELDS.NAME,
  FIELDS.GROWTH_MANAGER,
  FIELDS.APP_ID,
];

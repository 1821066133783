import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { EMAIL_STATUS_KEYS, INDICATOR_COLORS } from 'core/settings/constants';

import StatusIndicator from 'components/common/status-indicator';


const Indicator = memo(({ status }) => (
  <StatusIndicator
    status={status}
    colors={INDICATOR_COLORS}
  />
));


Indicator.propTypes = {
  status: PropTypes.oneOf(Object.values(EMAIL_STATUS_KEYS)),
};

Indicator.defaultProps = {
  status: null,
};


export default Indicator;

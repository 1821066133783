import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isObjectChanged } from 'utils';


const DataGridEditableField = memo(({
  component: Component,
  onSubmit,
  onReset,
  handlerResetKey,
  valueKey,
  handlerKey,
  propsToSubmit,
  componentProps,
  passSubmitPropsToComponent,
}) => {
  const [propsToSubmitState, setPropsToSubmitState] = useState({});

  const handleSubmit = useCallback((value, otherProps) => {
    onSubmit({
      ...propsToSubmit,
      [valueKey]: value,
      ...otherProps,
    });
  }, [propsToSubmit, valueKey, onSubmit]);

  const handleReset = useCallback((otherProps) => {
    onReset(propsToSubmit, otherProps);
  }, [propsToSubmit, onReset]);

  const props = {
    ...componentProps,
    ...(passSubmitPropsToComponent ? { propsToSubmit: propsToSubmitState } : {}),
    [handlerKey]: handleSubmit,
    [handlerResetKey]: handleReset,
  };

  useEffect(() => {
    if (passSubmitPropsToComponent) {
      setPropsToSubmitState(propsToSubmit);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Component {...props} />
  );
}, (prevProps, nextProps) => {
  const { propsToSubmit: prevPropsToSubmit, componentProps: prevComponentProps, ...prevOtherProps } = prevProps;
  const { propsToSubmit: nextPropsToSubmit, componentProps: nextComponentProps, ...nextOtherProps } = nextProps;

  return !(isObjectChanged(prevComponentProps, nextComponentProps) || isObjectChanged(prevOtherProps, nextOtherProps) || isObjectChanged(prevPropsToSubmit, nextPropsToSubmit));
});


DataGridEditableField.propTypes = {
  valueKey: PropTypes.string.isRequired,
  propsToSubmit: PropTypes.shape({}).isRequired,
  component: PropTypes.elementType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handlerKey: PropTypes.string,
  componentProps: PropTypes.shape({}),
  onReset: PropTypes.func,
  handlerResetKey: PropTypes.string,
  passSubmitPropsToComponent: PropTypes.bool,
};

DataGridEditableField.defaultProps = {
  handlerKey: 'onSubmit',
  handlerResetKey: 'onReset',
  onReset: null,
  componentProps: {},
  passSubmitPropsToComponent: false,
};


export default DataGridEditableField;

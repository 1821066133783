import { isValueExists } from 'utils';


export const getOverrideText = (override) => `${new Date(override.overrideDate).toLocaleDateString([], { timeZone: 'UTC' })} by ${override.overrideManager}. Expires on ${new Date(override.overrideExpires).toLocaleDateString([], { timeZone: 'UTC' })}`;

export const getIsOverrideVisible = (override) => {
  if (!override || !Object.keys(override).length) {
    return false;
  }

  if (isValueExists(override.bidOverride)) {
    return true;
  }

  return false;
};

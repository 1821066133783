import { styled } from '@mui/system';


export const Title = styled('p')(({ theme }) => ({
  ...theme.typography.smallTitle,
  textAlign: 'center',
  marginBottom: theme.spacing(6),
}));

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

export const StyledButton = styled('div')(({ theme }) => ({
  '& + &': {
    marginLeft: theme.spacing(3.5),
  },
}));

import { createSelector } from 'reselect';


const getLocalState = (state) => state.campaignsV4;
const getCampaingsListState = (state) => getLocalState(state).list;
const getCampaingsListTotalState = (state) => getLocalState(state).total;
const getIsListFetchingState = (state) => getLocalState(state).isFetching;
const getIsCampaignUpdateFetchingState = (state) => getLocalState(state).isCampaignUpdateFetching;


export const getIsListFetching = createSelector(
  getIsListFetchingState,
  (isFetching) => isFetching,
);

export const getCampaingsList = createSelector(
  getCampaingsListState,
  (list) => list,
);

export const getCampaingsListTotal = createSelector(
  getCampaingsListTotalState,
  (total) => total,
);

export const getIsCampaignUpdateFetching = createSelector(
  getIsCampaignUpdateFetchingState,
  (isFetching) => isFetching,
);

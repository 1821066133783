import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { StyledEngineProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterLuxon';

// https://github.com/remix-run/react-router/issues/8481 , update react-router-dom lib when this feature will be stable
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';

import configureStore from 'core';

import Router from 'containers/router';
import history from 'containers/router/history';
import AppStateProvider from 'containers/app-state-provider';
import ThemeProvider from 'containers/theme-provider';
import Snackbar from 'containers/snackbar';
import AnlyticsInstance from 'services/analytics';

import GoalRoasOverrideModal from 'components/games/goal-roas-override-modal';

import './index.css';


const { store, persistor } = configureStore();

const App = memo(() => (
  <MatomoProvider value={AnlyticsInstance}>
    <HistoryRouter history={history}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <Provider store={store}>
            <PersistGate
              loading={null}
              persistor={persistor}
            >
              <LocalizationProvider dateAdapter={DateAdapter}>
                <AppStateProvider>
                  <Router />

                  <Snackbar />

                  <GoalRoasOverrideModal />
                </AppStateProvider>
              </LocalizationProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HistoryRouter>
  </MatomoProvider>
));


export default App;

import React from 'react';

import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Stack from '@mui/material/Stack';

import { extractRowKey } from 'utils/extracters';

import { GOAL_ROAS_PATHS, CHANNELS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import Switch from 'components/common/switch';
import DataGridEditableField from 'components/common/data-grid-editable-field';
import Indicator from 'components/games/status-indicator';
import AdaptiveText from 'components/common/adaptive-text';
import GoalRoasField from 'components/games/goal-roas-field';
import TextWithIndicator from 'components/games/text-with-indicator';
import ChannelIcon from 'components/games/channel-icon';
// import SuggestedRoas from 'components/games/suggested-roas';
import Budget from 'components/games/budget';

import { getOptionsFromArray } from 'utils';
import { getNumberFormatter, getPriceFormatter } from 'utils/formatters';
// import { suggestedRoasSortComparator } from 'utils/grid';

import { DEFAULT_FILTER, DEFAULT_CAMPAIGN_STATUS_FILTER_OPTION, FIELDS } from './constants';
// import { SUGGESTED_ROAS_CLASS_NAME } from './styles';
import { renderMultipleFilterValue } from './utils';


export const getConfig = (data, onSubmit, onLinkPress, onGoalRoasUpdate, initialData, tooltipDateRange, onGoalRoasReset, isGameArchived, isAppStatusReady) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.BIDDER_STATUS,
      headerName: 'Bidder status',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          valueKey={FIELDS.BIDDER_STATUS}
          onSubmit={onSubmit}
          component={Switch}
          handlerKey="onChange"
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
            disabled: isGameArchived || !isAppStatusReady,
          }}
        />
      ),
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.NAME,
      headerName: 'Campaign Name',
      flex: 3,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.STATUS,
      headerName: 'Campaign Status',
      flex: 1.5,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      isMultipleOptions: true,
      renderFilterValue: renderMultipleFilterValue,
      options: [
        ...DEFAULT_CAMPAIGN_STATUS_FILTER_OPTION,
        ...getOptionsFromArray(initialData, FIELDS.STATUS).filter(({ id }) => !DEFAULT_FILTER[FIELDS.STATUS].includes(id)),
      ],
      clearableFilter: false,
      renderCell: ({ row }) => (
        <TextWithIndicator
          value={row[FIELDS.STATUS]}
          indicator={Indicator}
        />
      ),
    },
    {
      field: FIELDS.TYPE,
      headerName: 'Campaign Type',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      options: getOptionsFromArray(initialData, FIELDS.TYPE),
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
      // TODO: make editable ?
    },
    {
      field: FIELDS.WEEK_SPEND,
      headerName: 'Last 7 days spend',
      flex: 1,
      type: 'number',
      valueFormatter: getPriceFormatter(),
      headerAlign: 'left',
      align: 'left',
      tooltipText: tooltipDateRange,
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.WEEKLY_INSTALLS,
      headerName: 'Last 7 days installs',
      flex: 1,
      type: 'number',
      valueFormatter: getNumberFormatter(0),
      headerAlign: 'left',
      align: 'left',
      tooltipText: tooltipDateRange,
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.BUDGET,
      headerName: 'Budget',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: ({ value }) => (
        <Budget
          {...value}
        />
      ),
    },
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal ROAS',
      flex: 1,
      type: 'number',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const isAppLovin = row.channel === CHANNELS.APPLOVIN;
        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GOAL_ROAS)}
            valueKey={FIELDS.GOAL_ROAS}
            onSubmit={onGoalRoasUpdate}
            component={GoalRoasField}
            onReset={onGoalRoasReset}
            propsToSubmit={{
              id: row.id,
              channel: row.channel,
              channelId: row.channelId,
              campaignId: row.id,
              path: GOAL_ROAS_PATHS.CAMPAIGN,
              goalRoas: row?.override.parentGoal,
              isAppLovin,
            }}
            componentProps={{
              initialValue: value,
              override: row.override,
              disabled: isGameArchived || !isAppStatusReady,
              withoutComment: isAppLovin,
              disableTooltip: isAppLovin,
              category: AnalyticsService.CATEGORIES.CAMPAIGNS,
            }}
          />
        );
      },
      headerAlign: 'left',
      align: 'left',
    },
    // {
    //   field: FIELDS.SUGGESTED_ROAS,
    //   headerName: 'Suggested ROAS',
    //   flex: 0.8,
    //   type: 'number',
    //   headerAlign: 'left',
    //   align: 'left',
    //   cellClassName: SUGGESTED_ROAS_CLASS_NAME,
    //   headerClassName: SUGGESTED_ROAS_CLASS_NAME,
    //   sortComparator: suggestedRoasSortComparator,
    //   renderCell: ({ value }) => (
    //     <SuggestedRoas
    //       {...value}
    //     />
    //   ),
    // },
    {
      field: FIELDS.ACTIONS,
      headerName: 'Actions',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          spacing={2}
        >
          <IconButton
            onClick={() => onLinkPress(row.link)}
          >
            <OpenInNewIcon />
          </IconButton>
        </Stack>
      ),
    },
  ],
});

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import Text from 'components/common/text';

import { FILTER_BUTTONS } from './constants';
import {
  Wrapper,
  StyledButton,
  StyledText,
} from './styles';
import { getDatesFromFilter } from './utils';


const PredefinedFilterButtons = memo(({ onClick }) => {
  const handleClick = useCallback((id) => {
    const { from, to } = getDatesFromFilter(id);

    onClick({
      from,
      to,
    });
  }, [onClick]);

  return (
    <Wrapper>
      {
        FILTER_BUTTONS.map(({ label, id }) => (
          <StyledButton
            as={Button}
            key={label}
            variant="outline"
            onClick={() => handleClick(id)}
          >
            <StyledText as={Text}>{label}</StyledText>
          </StyledButton>
        ))
      }
    </Wrapper>
  );
});


PredefinedFilterButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
};


export default PredefinedFilterButtons;

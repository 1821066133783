import { styled } from '@mui/system';

import Text from 'components/common/text';


export const Title = styled(Text)(({ theme }) => ({
  ...theme.typography.smallTitle,
}));

export const Description = styled(Text)(({ theme }) => ({
  ...theme.typography.body2,
}));


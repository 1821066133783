import { isValueExists } from 'utils';
import { PLATFORMS } from './constants';


export const channelModel = ({
  NAME: name,
  STATUS: status,
  GOAL_ROAS: goalRoas,
}) => ({
  status,
  name,
  goalRoas: goalRoasModel(goalRoas),
});

export const networkModel = ({
  id,
  channel_id: channelId,
  account_id: accountId,
  channel,
  status,
  link,
  goal_roas: goalRoas,
  week_installs: weekInstalls,
  week_spend: weekSpend,
}) => ({
  id,
  status,
  goalRoas: goalRoasModel(goalRoas),
  channelId,
  accountId,
  channel,
  link,
  weekInstalls,
  weekSpend,
});

export const campaignModel = ({
  id,
  channel,
  channel_id: channelId,
  status,
  link,
  name,
  campaign_type: campaignType,
  campaign_status: campaignStatus,
  goal_roas: goalRoas,
  week_installs: weekInstalls,
  week_spend: weekSpend,
  enabled,
  override,
  roasSuggest,
  budget,
}, networkId) => ({
  id,
  networkId: Number(networkId),
  channelId,
  status,
  goalRoas: goalRoasModel(goalRoas),
  channel,
  link,
  name,
  campaignType,
  campaignStatus,
  weekInstalls,
  weekSpend,
  bidderStatus: enabled === 1,
  override: overrideModel(override),
  suggestedRoas: suggestedRoasModel(roasSuggest),
  budget: budgetModel(budget),
});

export const geoModel = ({
  id,
  channel,
  channel_id: channelId,
  campaign_id: campaignId,
  campaign_name: campaignName,
  campaign_type: campaignType,
  billing_type: billingType,
  country_full: countryFull,
  country,
  week_spend: weekSpend,
  week_installs: weekInstalls,
  goal_roas: goalRoas,
  override,
  roasSuggest,
}) => ({
  id,
  channelId,
  campaignId,
  channel,
  campaignName,
  campaignType,
  billingType,
  countryFull,
  country,
  weekSpend,
  weekInstalls,
  goalRoas: goalRoasModel(goalRoas),
  override: overrideModel(override),
  suggestedRoas: suggestedRoasModel(roasSuggest),
});

export const sourceModel = ({
  id,
  channel,
  channel_id: channelId,
  campaign_id: campaignId,
  campaign_name: campaignName,
  campaign_type: campaignType,
  billing_type: billingType,
  country_full: countryFull,
  country,
  source_name: sourceName,
  week_spend: weekSpend,
  week_installs: weekInstalls,
  goal_roas: goalRoas,
  override,
}) => ({
  id,
  campaignId,
  channel,
  channelId,
  campaignName,
  campaignType,
  billingType,
  countryFull,
  country,
  sourceName,
  weekSpend,
  weekInstalls,
  goalRoas: goalRoasModel(goalRoas),
  override: overrideModel(override),
});

export const updateGameModel = ({
  status: STATUS,
  name: NAME,
  growthManager: GROWTH_MANAGER,
  lionApp,
  adjustAppToken: ADJUST_APP_TOKEN,
}) => ({
  STATUS,
  NAME,
  GROWTH_MANAGER,
  ...(lionApp ? {
    LION_APP_ID: lionApp.id,
    LION_APP_NAME: lionApp.name,
  } : {}),
  ADJUST_APP_TOKEN,
});

export const gameModel = ({
  id,
  status,
  name,
  store_id: storeId,
  platform,
  growth_manager: growthManager,
  networks,
  campaigns,
  geos,
  sources,
  sync_range: syncRange,
  lion_app_id: lionAppId,
  lion_app_name: lionAppName,
}) => ({
  id: Number(id),
  status,
  name,
  storeId,
  platform,
  growthManager,
  networks: networks.map(networkModel),
  campaigns: Object.entries(campaigns).map(([key, campaignsList]) => campaignsList.map((campaign) => campaignModel(campaign, key))).flat().sort(sortBySuggestedRoas),
  geos: geos.map(geoModel).sort(sortBySuggestedRoas),
  sources: Object.entries(sources).map(([key, sourcesList]) => sourcesList.map((source, index) => sourceModel(source, key, index))).flat(),
  isIos: platform === PLATFORMS.IOS,
  lionAppId,
  lionAppName,
  syncRange: {
    start: syncRange.start * 1000,
    end: syncRange.end * 1000,
  },
});

export const listModel = (response) => response
  .map(({
    APP_ID: appId,
    CHANNEL: channel,
    ID: channelId,
    // CHANNEL_STATUS: channelStatus,
    // CAMPAIGN_SET_ID: campaignSetId,
    STATUS: status,
    NAME: name,
    STORE_ID: storeId,
    PLATFORM: platform,
    GROWTH_MANAGER: growthManager,
    // GOAL_ROAS: goalRoas,
    LION_APP_ID: lionAppId,
    LION_APP_NAME: lionAppName,
    CAMPAIGN_SETS: campaignSets,
    ADJUST_APP_TOKEN: adjustAppToken,
    override,
  }) => ({
    // TODO: remove campaignSetId ?
    // id: `${appId}-${channel || index}-${campaignSetId}`,
    id: `${appId}`,
    channelId,
    appId,
    status,
    name,
    storeId,
    platform,
    growthManager,
    channel,
    // channelStatus,
    // campaignSetId,
    isIos: platform === PLATFORMS.IOS,
    // goalRoas: goalRoasModel(goalRoas),
    lionAppId,
    lionAppName,
    override,
    campaignSets,
    adjustAppToken,
  }));

export const createGameModel = ({
  name,
  storeId,
  platform,
  lionApp,
  growthManager,
  adjustAppToken,
  networks,
}) => ({
  NAME: name,
  PLATFORM: platform,
  STORE_ID: storeId,
  LION_APP_ID: lionApp?.id || null,
  LION_APP_NAME: lionApp?.name || null,
  GROWTH_MANAGER: growthManager,
  ADJUST_APP_TOKEN: adjustAppToken,
  NETWORKS: networks,
});

export const createGameResponseModel = ({ // TODO: use model of game from list when ids will be fixed
  ID: id,
  STATUS: status,
  NAME: name,
  STORE_ID: storeId,
  PLATFORM: platform,
  GROWTH_MANAGER: growthManager,
  ADJUST_APP_TOKEN: adjustAppToken,
}) => ({
  id,
  status,
  name,
  storeId,
  platform,
  growthManager,
  adjustAppToken,
});

export const updateGoalRoasModel = ({
  gameId,
  channelId,
  channel,
  campaignId = '',
  countryCode = '',
  source = '',
  path,
  goalRoas,
  comment,
  initialValue,
}) => ({
  gameId,
  channelId,
  channel,
  campaignId,
  countryCode,
  source,
  path,
  goalRoas: Number(goalRoas / 100).toFixed(2),
  oldGoalRoas: initialValue ? Number(initialValue / 100).toFixed(2) : null,
  comment,
});

export const fetchOverrideListModel = ({
  channelId,
  campaignId,
}) => ({
  channel_id: channelId,
  campaign_id: campaignId,
});

export const overrideListModel = (response, goalRoas, comment) => response.map(({
  ACCOUNT_ID: accountId,
  BID_OVERRIDE: goalRoasOverride,
  CAMPAIGN_ID: campaignId,
  GEO_KEY: geoId,
  OVERRIDE_TYPE: path,
  SOURCE_KEY: sourceId,
  GAME: gameId,
  id,
}) => ({
  accountId,
  goalRoasOverride: goalRoasModel(goalRoasOverride),
  campaignId,
  geoId,
  path,
  sourceId,
  id,
  gameId,
  goalRoas,
  comment,
}));

export const resetOverrideGoalRoasModel = ({
  campaignId,
  channelId,
  countryCode,
  path,
  sourceId: source,
  comment,
  initialValue,
  goalRoas,
}) => ({
  campaignId,
  channelId,
  countryCode: countryCode || '',
  path,
  source: source || '',
  comment,
  goalRoas: goalRoas ? Number(goalRoas / 100).toFixed(2) : null,
  oldGoalRoas: initialValue ? Number(initialValue / 100).toFixed(2) : null,
});

export const goalRoasModel = (value) => isValueExists(value) ? Math.round(Number(value) * 100) : value;

export const overrideModel = ({ parentGoal, goal, ...rest }) => ({
  parentGoal: goalRoasModel(parentGoal),
  goal: goalRoasModel(goal),
  ...rest,
});

export const suggestedRoasModel = ({ description, goal, diff, createdAt } = {}) => ({
  description,
  goal: goalRoasModel(goal),
  diff: goalRoasModel(diff),
  createdAt,
});

export const sortBySuggestedRoas = (itemA, itemB) => {
  const { suggestedRoas: { diff: diffA } } = itemA;
  const { suggestedRoas: { diff: diffB } } = itemB;

  if (diffA === null) {
    return 1;
  }

  if (diffB === null) {
    return -1;
  }

  return diffB - diffA;
};

export const budgetModel = (budget) => ({
  daily: budget?.daily || 0,
  total: budget?.total || 0,
});

import { styled } from '@mui/system';


export const Wrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));


export const StyledText = styled('p')(({ theme }) => ({
  ...theme.typography.title,
  marginTop: theme.spacing(7.5),
  marginBottom: theme.spacing(3.75),
}));

import React from 'react';

// import IconButton from '@mui/material/IconButton';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Stack from '@mui/material/Stack';
import { Link } from '@mui/material';

import { extractRowKey } from 'utils/extracters';

import { BIDDER_STATUS_TEXTS, CPA_ROAS_SETTING_TYPES, CPA_ROAS_SETTING_TYPE_TEXTS } from 'core/v4/campaigns/constants';
import { GOAL_ROAS_PATHS, CHANNELS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import Switch from 'components/common/switch';
import DataGridEditableField from 'components/v4/common/data-grid-editable-field';
import Indicator from 'components/games/status-indicator';
import AdaptiveText from 'components/common/adaptive-text';
import GoalRoasField from 'components/games/goal-roas-field';
import TextWithIndicator from 'components/games/text-with-indicator';
import ChannelIcon from 'components/games/channel-icon';
// import SuggestedRoas from 'components/games/suggested-roas';
import Budget from 'components/games/budget';
import EditableTextField from 'components/common/editable-text-field';
import ReturnWindowField from 'components/v4/games/return-window-field';

import { isValueExists } from 'utils';
import { getPriceFormatter } from 'utils/formatters';
// import { suggestedRoasSortComparator } from 'utils/grid';

import { FIELDS, CAMPAIGN_STATUS_OPTIONS, CAMPAIGN_TYPES_OPTIONS } from './constants';
// import { SUGGESTED_ROAS_CLASS_NAME } from './styles';
import { renderMultipleFilterValue, wrappedPriceFormatter } from './utils';



export const getConfig = (data, onSubmit, onLinkPress, onGoalRoasUpdate, initialData, tooltipDateRange, onGoalRoasReset, isGameArchived, isAppStatusReady) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.ID,
      headerName: 'Campaign ID',
      width: 120,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.ENABLED,
      headerName: 'On/Off',
      // flex: 0.5,
      width: 60,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          valueKey={FIELDS.ENABLED}
          onSubmit={onSubmit}
          component={Switch}
          handlerKey="onChange"
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
            disabled: isGameArchived || !isAppStatusReady,
          }}
        />
      ),
    },
    {
      field: FIELDS.GAME_NAME,
      headerName: 'Game',
      sortable: false,
      width: 120,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.GAME_PLATFORM,
      headerName: 'Platform',
      sortable: false,
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      sortable: false,
      // flex: 0.5,
      width: 70,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.NAME,
      headerName: 'Campaign Name',
      // flex: 3,
      width: 200,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.STATUS,
      headerName: 'Campaign Status',
      // flex: 1.5,
      width: 150,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      isMultipleOptions: true,
      renderFilterValue: renderMultipleFilterValue,
      options: CAMPAIGN_STATUS_OPTIONS,
      clearableFilter: false,
      renderCell: ({ row }) => (
        <TextWithIndicator
          value={row[FIELDS.STATUS]}
          indicator={Indicator}
        />
      ),
    },
    {
      field: FIELDS.TYPE,
      headerName: 'Campaign Type',
      // flex: 1,
      width: 135,
      headerAlign: 'center',
      align: 'center',
      options: CAMPAIGN_TYPES_OPTIONS,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
      // TODO: make editable ?
    },
    {
      field: FIELDS.BUDGET,
      headerName: 'Budget',
      // flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: ({ value }) => (
        <Budget
          {...value}
        />
      ),
    },
    {
      field: FIELDS.WEEK_SPEND,
      headerName: 'Last 7 days spend',
      // flex: 1,
      width: 150,
      type: 'number',
      valueFormatter: getPriceFormatter(),
      headerAlign: 'left',
      align: 'left',
      // tooltipText: tooltipDateRange,
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    /*
    {
      field: FIELDS.WEEKLY_INSTALLS,
      headerName: 'Last 7 days installs',
      // flex: 1,
      width: 150,
      type: 'number',
      valueFormatter: getNumberFormatter(0),
      headerAlign: 'left',
      align: 'left',
      tooltipText: tooltipDateRange,
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    */

    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal ROAS',
      sortable: false,
      // flex: 1,
      width: 120,
      type: 'number',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const isAppLovin = row.channel === CHANNELS.APPLOVIN;
        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GOAL_ROAS)}
            valueKey={FIELDS.GOAL_ROAS}
            onSubmit={onGoalRoasUpdate}
            component={GoalRoasField}
            onReset={onGoalRoasReset}
            propsToSubmit={{
              id: row.id,
              gameId: row.gameId,
              channel: row.channel,
              channelId: row.channelId,
              campaignId: row.id,
              path: GOAL_ROAS_PATHS.CAMPAIGN,
              goalRoas: row?.override.parentGoal,
              isAppLovin,
            }}
            componentProps={{
              initialValue: value,
              override: row.override,
              disabled: isGameArchived || !isAppStatusReady,
              withoutComment: isAppLovin,
              disableTooltip: isAppLovin,
              category: AnalyticsService.CATEGORIES.CAMPAIGNS,
            }}
          />
        );
      },
      headerAlign: 'left',
      align: 'left',
    },
    // {
    //   field: FIELDS.SUGGESTED_ROAS,
    //   headerName: 'Suggested ROAS',
    //   flex: 0.8,
    //   type: 'number',
    //   headerAlign: 'left',
    //   align: 'left',
    //   cellClassName: SUGGESTED_ROAS_CLASS_NAME,
    //   headerClassName: SUGGESTED_ROAS_CLASS_NAME,
    //   sortComparator: suggestedRoasSortComparator,
    //   renderCell: ({ value }) => (
    //     <SuggestedRoas
    //       {...value}
    //     />
    //   ),
    // },
    {
      field: FIELDS.BIDDER_STATUS,
      headerName: 'Bidder Status',
      sortable: false,
      // flex: 1,
      width: 120,
      renderCell: ({ value }) => <AdaptiveText size={AdaptiveText.SIZE.SM}>{BIDDER_STATUS_TEXTS[value] ?? value}</AdaptiveText>,
    },
    {
      field: FIELDS.RETURN_WINDOW,
      headerName: 'Return Window',
      sortable: false,
      // flex: 1,
      width: 130,
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          key={FIELDS.RETURN_WINDOW}
          valueKey={FIELDS.RETURN_WINDOW}
          onSubmit={onSubmit}
          component={ReturnWindowField}
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
          }}
        />
      ),
    },
    {
      field: FIELDS.RW_RPI_FORECAST,
      headerName: 'RW RPI Forecast',
      sortable: false,
      // flex: 1,
      width: 140,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: getPriceFormatter(),
      renderCell: ({ formattedValue, value }) => value ? (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ) : '-',
    },
    {
      field: FIELDS.RW_ROAS_TARGET,
      headerName: 'RW ROAS Target',
      sortable: false,
      // flex: 1,
      width: 140,
      type: 'number',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          valueKey={FIELDS.RW_ROAS_TARGET}
          component={EditableTextField}
          onSubmit={onSubmit}
          mergeSubmitOtherProps={false}
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
            // disabled: isGameArchived || !isAppStatusReady,
            valuePostfix: (isValueExists(value) ? '%' : ''),
            type: 'number',
          }}
        />
      ),
    },
    {
      field: FIELDS.RW_ROAS_TARGET_OVERRIDE_COUNT,
      headerName: '# RW ROAS Target Overrides',
      sortable: false,
      // flex: 1,
      width: 210,
    },
    {
      field: FIELDS.CPA_ROAS_SETTING_TYPE,
      headerName: 'CPA/ROAS Setting Type',
      sortable: false,
      // flex: 1,
      width: 180,
      renderCell: ({ value }) => CPA_ROAS_SETTING_TYPE_TEXTS[value] ?? value,
    },
    {
      field: FIELDS.CPA_ROAS_SETTING,
      headerName: 'CPA/ROAS Setting',
      sortable: false,
      // flex: 1,
      width: 150,
      cellClassName: ({ row }) => row.cpaRoasSettingType === CPA_ROAS_SETTING_TYPES.ALL ? 'data-grid__editable-cell data-grid__editable-cell--with-text-field' : '',
      renderCell: ({ value, row }) => row.cpaRoasSettingType === CPA_ROAS_SETTING_TYPES.ALL ? (
        <DataGridEditableField
          valueKey={FIELDS.CPA_ROAS_SETTING}
          component={EditableTextField}
          onSubmit={onSubmit}
          mergeSubmitOtherProps={false}
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
            // disabled: isGameArchived || !isAppStatusReady,
            // valuePostfix: (isValueExists(value) ? '%' : ''),
            type: 'number',
            valueFormatter: wrappedPriceFormatter,
          }}
        />
      ) : (CPA_ROAS_SETTING_TYPE_TEXTS[row.cpaRoasSettingType] ?? ''),
    },
    {
      field: FIELDS.DELTA_CPA_ROAS_SETTING,
      headerName: 'Delta CPA/ROAS Setting',
      sortable: false,
      // flex: 1,
      width: 190,
      renderCell: ({ value, row }) => {
        if (row.cpaRoasSettingType === CPA_ROAS_SETTING_TYPES.ALL) {
          return value ? `${value}%` : '';
        }
        return (CPA_ROAS_SETTING_TYPE_TEXTS[row.cpaRoasSettingType] ?? '');
      },
    },
    {
      field: FIELDS.LINKS,
      headerName: 'Useful Links',
      // flex: 1,
      width: 100,
      sortable: false,
      renderCell: ({ row }) => row.link && (
        <Link
          href={row.link}
          target="_blank"
          rel="noreferrer"
        >
          Network
        </Link>
      ),
    },
    {
      field: FIELDS.ACTIVITY,
      headerName: 'Activity',
      // flex: 0.5,
      width: 70,
      sortable: false,
    },
    {
      field: FIELDS.ACTIONS,
      headerName: 'Actions',
      // flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: () => (
        <Stack
          direction="row"
          spacing={2}
        >
          {/*
          <IconButton
            onClick={() => onLinkPress(row.link)}
          >
            <OpenInNewIcon />
          </IconButton>
          */}
        </Stack>
      ),
    },
  ],
});

import { styled } from '@mui/system';


export const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const IFrame = styled('iframe')({
  width: '100%',
  height: '100%',
});

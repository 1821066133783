import { styled } from '@mui/system';


export const Badge = styled('header')(({ theme }) => ({
  backgroundColor: '#FFEE6E',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 50,
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(2),
}));

export const BadgeText = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 700,
}));

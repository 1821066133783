import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

import ActivityLogsFilterForm from 'components/activity-logs/filter-form';
import ActivityLogsLegend from 'components/activity-logs/legend';
import CsvButton from 'components/activity-logs/csv-button';
import Paper from 'components/common/paper';
import Title from 'components/common/title';

import {
  StyledTitle,
} from './styles';


const ActivityLogsHeader = memo(({ onSubmit, isFetching }) => (
  <Paper>
    <Stack
      flexDirection="row"
      justifyContent="space-between"
    >
      <div>
        <Stack
          direction="row"
        >
          <Title sx={StyledTitle}>
            Activity Log
          </Title>

          <CsvButton />
        </Stack>

        <ActivityLogsFilterForm
          onSubmit={onSubmit}
          isFetching={isFetching}
        />
      </div>

      <ActivityLogsLegend />
    </Stack>
  </Paper>
));


ActivityLogsHeader.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default ActivityLogsHeader;

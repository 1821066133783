import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledText } from './styles';


const Title = memo(({ children, ...otherProps }) => (
  <StyledText
    {...otherProps}
  >
    {children}
  </StyledText>
));


Title.propTypes = {
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
};


export default Title;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { INDICATOR_COLORS } from 'core/games/constants';

import StatusIndicator from 'components/common/status-indicator';


const Indicator = memo(({ status }) => (
  <StatusIndicator
    status={status}
    colors={INDICATOR_COLORS}
  />
));


Indicator.propTypes = {
  status: PropTypes.string,
};

Indicator.defaultProps = {
  status: null,
};


export default Indicator;

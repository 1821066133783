import { createSelector } from 'reselect';

import { getUniqueElementsByProp } from 'utils';


const getLocalState = (state) => state.gamesV4;
const getIsListFetchingState = (state) => getLocalState(state).isListFetching;
const getListState = (state) => getLocalState(state).list;
const getCollectionState = (state) => getLocalState(state).collection;
const getIsGameFetchingState = (state) => getLocalState(state).isGameFetching;
const getIsGameUpdateFetchingState = (state) => getLocalState(state).isGameUpdateFetching;
const getIsGameDeleteFetchingState = (state) => getLocalState(state).isGameDeleteFetching;
const getIsGameCreateFetchingState = (state) => getLocalState(state).isGameCreateFetching;
const getIsGoalRoasUpdateFetchingState = (state) => getLocalState(state).isGoalRoasUpdateFetching;
const getOverrideListState = (state) => getLocalState(state).overrideList;
const getIsGoalRoasResetFetchingState = (state) => getLocalState(state).isGoalRoasResetFetching;


export const getIsGamesFetching = createSelector(
  getIsListFetchingState,
  (isFetching) => isFetching,
);

export const getGamesList = createSelector(
  getListState,
  (list) => list,
);

export const getGamesCollection = createSelector(
  getCollectionState,
  (collection) => collection,
);

export const getGameByIdFromList = (id) => createSelector(
  getGamesList,
  (list) => list && list.find((game) => game.id === id),
);

export const getGameById = (id) => createSelector(
  getGamesCollection,
  (collection) => collection[id],
);

export const getGamesByIds = (ids) => createSelector(
  getGamesCollection,
  (collection) => ids.reduce((result, id) => {
    result[id] = collection[id]; // eslint-disable-line no-param-reassign
    return result;
  }, {}),
);

export const getIsGameUpdateFetching = createSelector(
  getIsGameUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsGameFetching = createSelector(
  getIsGameFetchingState,
  (isFetching) => isFetching,
);

export const getIsGameDeleteFetching = createSelector(
  getIsGameDeleteFetchingState,
  (isFetching) => isFetching,
);

export const getIsGameCreateFetching = createSelector(
  getIsGameCreateFetchingState,
  (isFetching) => isFetching,
);

export const getIsGoalRoasResetFetching = createSelector(
  getIsGoalRoasResetFetchingState,
  (isFetching) => isFetching,
);

export const getIsGoalRoasUpdateFetching = createSelector(
  getIsGoalRoasUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getStoreIds = createSelector(
  getGamesList,
  (list) => list ? getUniqueElementsByProp(list, 'storeId').map(({ storeId }) => storeId) : [],
);

export const getLionApps = createSelector(
  getGamesList,
  (list) => list ? getUniqueElementsByProp(list, 'lionAppId').filter((item) => item.lionAppId).map(({ lionAppName, lionAppId }) => ({ id: lionAppId, name: lionAppName })) : [],
);

export const getOverrideList = createSelector(
  getOverrideListState,
  getGamesCollection,
  (overrideList, games = {}) => {
    if (!overrideList?.length) {
      return null;
    }

    const game = games[overrideList?.[0].gameId] || null;

    if (!game) {
      return null;
    }

    return overrideList.map((override) => {
      let countryCode = null;
      if (override.geoId) {
        countryCode = game.geos.find((geo) => `${geo.campaignId}_${geo.country}` === override.geoId)?.country;

        if (!countryCode) {
          return null;
        }
      }

      return ({
        ...override,
        channelId: game.networks.find((network) => network.accountId === override.accountId).channelId,
        campaignName: game.campaigns.find((campaign) => campaign.id === override.campaignId).name,
        countryCode,
      });
    }).filter((override) => !!override);
  },
);

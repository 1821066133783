import { getDaysAgo } from 'utils/date';


export const KEYS = {
  TIME_PERIOD: 'timePeriod',
};

export const LABELS = {
  [KEYS.TIME_PERIOD]: 'Time period',
};

export const MIN_DATE = getDaysAgo(14);

export const GAME_KEYS = {
  GROWTH_MANAGER: 'growthManager',
  STATUS: 'status',
};

export const CHANNEL_KEYS = {
  CHANNEL_STATUS: 'channelStatus',
  GOAL_ROAS: 'goalRoas',
};

export const CHANNEL_KEYS_LIST = Object.values(CHANNEL_KEYS);

export const KEYS = {
  ...GAME_KEYS,
  ...CHANNEL_KEYS,
};

export const MAPPED_CHANNEL_KEYS = {
  [CHANNEL_KEYS.CHANNEL_STATUS]: GAME_KEYS.STATUS,
};

export const TITLES = {
  [KEYS.GROWTH_MANAGER]: 'Growth Manager',
  [KEYS.STATUS]: 'App Status',
  [KEYS.CHANNEL_STATUS]: 'Channel Status',
  [KEYS.GOAL_ROAS]: 'Goal ROAS',
};

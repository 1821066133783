import React, { memo } from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import UncontrolledCheckbox from './uncontrolled';
import { StyledFormControlLabel } from './styles';


const Checkbox = memo(({ label, containerProps, value, onChange, ...otherProps }) => (
  <StyledFormControlLabel
    as={FormControlLabel}
    label={label}
    control={(
      <UncontrolledCheckbox
        value={value}
        onChange={onChange}
        {...otherProps}
      />
    )}
    {...containerProps}
  />
));


Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  containerProps: PropTypes.shape({}),
};

Checkbox.defaultProps = {
  value: false,
  label: '',
  containerProps: {},
};


export default Checkbox;

export const KEYS = {
  CHANNEL_NAME: 'channel',
  CAMPAIGN_TYPE: 'campaignType',
  COOLDOWN_LIMIT: 'cooldownLimit',
};

export const LABELS = {
  [KEYS.CHANNEL_NAME]: 'Channel Name',
  [KEYS.CAMPAIGN_TYPE]: 'Campaign Type',
  [KEYS.COOLDOWN_LIMIT]: 'Cooldown Limit',
};

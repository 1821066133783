import { styled } from '@mui/system';
import { svgIconClasses } from '@mui/material/SvgIcon';


export const StyledFormControlLabel = styled('div')(() => ({
  width: '100%',
}));

export const StyledCheckbox = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.dark,

  [`.${svgIconClasses.root}`]: {
    fontSize: 27,
  },
}));

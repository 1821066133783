import PropTypes from 'prop-types';

import { PLATFORMS, CHANNELS, GAME_STATUS_KEYS, CHANNEL_STATUS_KEYS } from 'core/games/constants';


export const channelPropTypes = PropTypes.shape({
  name: PropTypes.oneOf(Object.values(CHANNELS)).isRequired,
  status: PropTypes.oneOf(Object.values(CHANNEL_STATUS_KEYS)).isRequired,
  goalRoas: PropTypes.number.isRequired,
});

export const gamePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  channelId: PropTypes.number,
  appId: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(GAME_STATUS_KEYS)).isRequired,
  name: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(Object.values(PLATFORMS)).isRequired,
  growthManager: PropTypes.string,
  channel: PropTypes.string,
  channelStatus: PropTypes.string,
  isIos: PropTypes.bool.isRequired,
});

export const gameDetailsPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(GAME_STATUS_KEYS)).isRequired,
  name: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(Object.values(PLATFORMS)).isRequired,
  growthManager: PropTypes.number,
  isIos: PropTypes.bool.isRequired,
  networks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape({})),
  geos: PropTypes.arrayOf(PropTypes.shape({})),
  sources: PropTypes.arrayOf(PropTypes.shape({})),
  syncRange: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
});

export const lionAppPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});


import { find, propEq, equals, pick } from 'ramda';


export const createConstants = (constants, constantType) => constants.reduce((acc, constant) => {
  const prefix = constantType ? `${constantType}/` : '';

  acc[constant] = `${prefix}${constant}`;

  return acc;
}, {});

export const normalize = (scheme) => (data, ...args) => Object.entries(scheme).reduce((result, [schemeKey, dataKey]) => {
  result[schemeKey] = typeof dataKey === 'function' ? dataKey.call(null, data, ...args) : data[dataKey]; // eslint-disable-line no-param-reassign
  return result;
}, {});

export const reverseObject = (obj) => (
  Object.keys(obj).reduce((result, key) => {
    result[obj[key]] = key; // eslint-disable-line no-param-reassign
    return result;
  }, {})
);

export const isValueExists = (value) => value !== null && value !== undefined;

export const isString = (value) => typeof value === 'string';
export const isArray = (value) => Array.isArray(value);
export const isDate = (value) => value instanceof Date;
export const isObject = (value) => typeof value === 'object' && value !== null;
export const isNumber = (value) => !Number.isNaN(parseInt(value, 10));
export const isInteger = (value) => value % 1 === 0;

export const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }

  return Object.entries(obj).length === 0;
};

export const shuffleArray = (originalArray) => {
  const array = [...originalArray];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

export const findObjectByProp = (array, key, value) => find(propEq(key, value))(array);

export const getClosestValueInArray = (array, target) => array.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev));

export const trimObjectValues = (formData) => Object.entries(formData).map(([key, value]) => ([key, value.trim ? value.trim() : value])).reduce((res, [key, value]) => {
  res[key] = value;
  return res;
}, {});

export const getUniqueElementsByProp = (data, prop) => {
  const checkedValues = {};

  return data.reduce((result, item) => {
    const propValue = item[prop];

    if (!checkedValues[propValue]) {
      result.push(item);

      checkedValues[propValue] = true;
    }

    return result;
  }, []);
};

export const isObjectChanged = (prevObject, nextObject, useDeepEqual = false) => {
  const prevObjectKeys = Object.keys(prevObject);
  const nextObjectKeys = Object.keys(nextObject);

  if ((prevObjectKeys.length !== nextObjectKeys.length) || (JSON.stringify(prevObjectKeys) !== JSON.stringify(nextObjectKeys))) {
    return true;
  }

  const prevObjectEntries = Object.entries(prevObject);

  for (let i = 0; i < prevObjectEntries.length; i++) {
    const [prevKey, prevValue] = prevObjectEntries[i];
    const nextValue = nextObject[prevKey];

    if (useDeepEqual && isObject(nextValue) && isObject(prevValue)) {
      const isDeepObjectChanged = isObjectChanged(prevValue, nextValue);

      if (!isDeepObjectChanged) {
        continue;
      }

      return isDeepObjectChanged;
    }

    if (nextValue !== prevValue) {
      return true;
    }
  }

  return false;
};

export const isValueChanged = (nextValue, prevValue) => {
  const isNextValueExists = isValueExists(nextValue) && nextValue !== '';
  const isChanged = String(nextValue) !== (isValueExists(prevValue) ? String(prevValue) : prevValue);

  return isNextValueExists && isChanged;
};

export const getOptionsFromArray = (array, keyName) => Object.values(array.reduce((res, item) => {
  if (!res[item[keyName]]) {
    res[item[keyName]] = item[keyName];
  }

  return res;
}, {})).map((value) => ({
  id: value,
  name: value,
}));

export const capitalize = (value) => {
  const string = value.toLowerCase();

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getDifference = (objA, objB) => {
  const differenceKeys = Object.keys(objA).filter(
    (key) => !equals(objA[key], objB[key]),
  );

  return pick(differenceKeys, objA);
};

export const getUniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomString = Math.random().toString(36).substr(2);
  return dateString + randomString;
};

import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import AnalyticsService from 'services/analytics';


const NoOptions = memo(({ searchText }) => {
  useEffect(() => {
    AnalyticsService.trackSiteSearch({
      keyword: searchText,
      count: 0,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return 'No games found';
});


NoOptions.propTypes = {
  searchText: PropTypes.string.isRequired,
};


export default NoOptions;

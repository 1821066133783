import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';

import Link from 'components/common/link';
import Text from 'components/common/text';

import { isValueExists as getIsValueExists } from 'utils';

import { Input, StyledText, StyledStack, ContentWrapper, StyledTooltip } from './styles';


const EditableTextField = memo(({
  to,
  initialValue,
  onSubmit,
  placeholder,
  disabled,
  disabledText,
  canBeEmpty,
  validationSchema,
  isValueChanged,
  beforeElement,
  afterElement,
  isEditMode,
  inputProps,
  tooltipTitle,
  valuePostfix,
  valueFormatter,
  onEdit,
  ...otherProps
}) => {
  const [value, setValue] = useState(initialValue);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleSubmit = useCallback((event) => {
    const { target: { value: nextValue } } = event;
    const isEqual = isValueChanged ? !isValueChanged(nextValue, value) : nextValue === String(value);

    if (isEqual) {
      setIsEdit(false);
      setIsError(false);
      return;
    }

    if (!nextValue.length && !canBeEmpty) {
      setError('Required field');
      setIsError(true);
      return;
    }

    try {
      const trimmedNextValue = nextValue.trim ? nextValue.trim() : nextValue;

      if (validationSchema && validationSchema.validateSync) {
        validationSchema.validateSync(trimmedNextValue);
      }

      setValue(trimmedNextValue);
      onSubmit(trimmedNextValue, event, inputProps);
      setIsError(false);
      setIsEdit(false);
      setError('');
    } catch (validationError) {
      setIsError(true);
      setError(validationError.message);
    }
  }, [canBeEmpty, value, onSubmit, validationSchema, isValueChanged, inputProps]);

  const handleEdit = useCallback(() => {
    if (!disabled) {
      setIsEdit(true);
      onEdit();
    }
  }, [disabled, onEdit]);

  const handleKeyPress = useCallback((event) => {
    switch (event.keyCode) {
      case 13:
        handleSubmit(event);
        event.preventDefault();
        break;

      case 27:
        event.preventDefault();
        setIsEdit(false);
        setIsError(false);
        setError('');
        break;

      default:
        break;
    }
  }, [handleSubmit]);

  const handleMouseEnterTooltip = useCallback(() => setIsTooltipOpen(true), []);
  const handleMouseLeaveTooltip = useCallback(() => setIsTooltipOpen(false), []);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const isValueExists = getIsValueExists(value);

  let renderedValue;
  if (disabled && disabledText) {
    renderedValue = disabledText;
  } else if (isValueExists) {
    renderedValue = `${valueFormatter ? valueFormatter(value) : value}${valuePostfix}`;
  } else {
    renderedValue = placeholder;
  }

  return (
    <StyledTooltip
      placement="top"
      title={error || tooltipTitle}
      isError={isError}
      onMouseEnter={handleMouseEnterTooltip}
      onMouseLeave={handleMouseLeaveTooltip}
      open={isError || isTooltipOpen}
      disableFocusListener
      disableTouchListener
      disableHoverListener
      arrow
    >
      <StyledStack
        as={Stack}
        direction="row"
        justifyContent="space-between"
        flex="1"
        alignItems="center"
        $isEdit={isEdit || isEditMode}
        $isDisabled={disabled}
        $isError={isError}
        className={classNames(
          'editable-text-field',
          {
            'editable-text-field--valid': !isError,
            'editable-text-field--invalid': isError,
          },
        )}
      >
        {beforeElement}

        {
          isEdit || isEditMode ? (
            <Input
              defaultValue={value}
              autoFocus
              onBlur={handleSubmit}
              components={{ Input }}
              onKeyDown={handleKeyPress}
              placeholder={placeholder}
              {...otherProps}
            />
          ) : (
            <ContentWrapper
              onClick={handleEdit}
            >
              <StyledText
                $isPlaceholder={placeholder && !isValueExists}
                {...(
                  to ? {
                    as: Link,
                    to,
                  } : {
                    as: Text,
                    size: 'body2',
                  }
                )}
              >
                {
                  renderedValue
                }
              </StyledText>
            </ContentWrapper>
          )
        }

        {
          isEdit || isEditMode ? (
            <IconButton
              aria-label="save"
              size="small"
            >
              <SaveIcon
                fontSize="inherit"
                color="secondary"
              />
            </IconButton>
          ) : (
            <IconButton
              aria-label="save"
              onClick={handleEdit}
              size="small"
              disabled={disabled}
            >
              <CreateIcon
                fontSize="inherit"
              />
            </IconButton>
          )
        }

        {afterElement}
      </StyledStack>
    </StyledTooltip>
  );
});


EditableTextField.propTypes = {
  to: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  canBeEmpty: PropTypes.bool,
  validationSchema: PropTypes.shape({
    validateSync: PropTypes.func.isRequired,
  }),
  isValueChanged: PropTypes.func,
  beforeElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
  isEditMode: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  afterElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
  tooltipTitle: PropTypes.string,
  valuePostfix: PropTypes.string,
  valueFormatter: PropTypes.func,
  onEdit: PropTypes.func,
};

EditableTextField.defaultProps = {
  to: null,
  onSubmit: () => null,
  initialValue: '',
  placeholder: 'Type here',
  disabled: false,
  disabledText: null,
  canBeEmpty: false,
  validationSchema: null,
  isValueChanged: null,
  beforeElement: null,
  isEditMode: false,
  inputProps: {},
  afterElement: null,
  tooltipTitle: '',
  valuePostfix: '',
  valueFormatter: null,
  onEdit: () => null,
};


export default EditableTextField;

import * as yup from 'yup';

import { KEYS, LABELS } from './constants';


const REQUIRED_TEXT = 'Required field';

export const ValidationSchema = yup.object().shape({
  [KEYS.MINIMUM_BID_THRESHOLD]: yup.number().min(0).required(REQUIRED_TEXT).label(LABELS[KEYS.MINIMUM_BID_THRESHOLD]),
  [KEYS.TOTAL_INSTALLS]: yup.number().integer().min(0).required(REQUIRED_TEXT).label(LABELS[KEYS.TOTAL_INSTALLS]),
  [KEYS.ROAS_CHANGING_WARNING]: yup.number().integer().min(0).max(200).required(REQUIRED_TEXT).label(LABELS[KEYS.ROAS_CHANGING_WARNING]),
  [KEYS.MINIMUM_DAILY_SOURCES_INSTALLS]: yup.number().integer().min(0).required(REQUIRED_TEXT).label(LABELS[KEYS.MINIMUM_DAILY_SOURCES_INSTALLS]),
  [KEYS.BID_EXPIRES_DAYS]: yup.number().integer().min(0).required(REQUIRED_TEXT).label(LABELS[KEYS.BID_EXPIRES_DAYS]),
});

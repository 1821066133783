import { styled } from '@mui/system';

import { tabsClasses } from '@mui/material/Tabs';


export const StyledTab = styled('button')(({ theme, $isActive, $color }) => ({
  maxWidth: '100%',
  minHeight: 0,
  flex: 1,
  textTransform: 'none',
  position: 'relative',
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.75, 2.5),
  fontSize: theme.typography.body1.fontSize,
  fontWeight: $isActive ? '700' : '400',
  lineHeight: 1.25,

  color: $isActive ? $color : theme.palette.textPrimary.dark,
  backgroundColor: $isActive ? theme.palette.background.light : theme.palette.background.lighter,

  '&:hover': {
    ...($isActive ? { backgroundColor: theme.palette.background.light } : {}),
  },

  '& > svg': {
    marginBottom: 0,
    lineHeight: 1,
    width: '24px',
    height: '24px',
    fontSize: '24px',
    marginRight: theme.spacing(2),
  },
}));

export const StyledTabs = styled('button')(({ theme }) => ({
  [`.${tabsClasses.flexContainer}`]: {
    padding: theme.spacing(0, 2),
    justifyContent: 'space-between',
  },

  [`.${tabsClasses.indicator}`]: {
    display: 'none',
  },

  '.Mui-disabled': {
    cursor: 'not-allowed',
  },

  [StyledTab]: {
    marginRight: theme.spacing(2),

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export const SelectedIndicator = styled('div')(({ theme, $isActive, $color }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: theme.spacing(2.5),
  top: '50%',
  transform: 'translateY(-50%)',
  padding: theme.spacing(0.5, 1.5),
  backgroundColor: $isActive ? $color : '#E0E0E0',
  color: $isActive ? theme.palette.textSecondary.main : theme.palette.textSecondary.primary,
  fontSize: 13,
  borderRadius: 16,
  fontWeight: '400',
}));

export const ClearButton = styled('div')(({ theme, $isActive }) => ({
  fontSize: '1rem',
  padding: 0,
  marginLeft: theme.spacing(1),

  '& > svg': {
    color: $isActive ? theme.palette.textSecondary.main : theme.palette.textSecondary.primary,
  },
}));

import { createSelector } from 'reselect';


const getLocalState = (state) => state.geosV4;
const getGeosListState = (state) => getLocalState(state).list;
const getGeosListTotalState = (state) => getLocalState(state).total;
const getIsListFetchingState = (state) => getLocalState(state).isFetching;


export const getIsListFetching = createSelector(
  getIsListFetchingState,
  (isFetching) => isFetching,
);

export const getGeosList = createSelector(
  getGeosListState,
  (list) => list,
);

export const getGeosListTotal = createSelector(
  getGeosListTotalState,
  (total) => total,
);

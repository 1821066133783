import LionstudiosApi from 'services/lionstudios-api';

import { listModel } from './models';
import { GEOS_URL } from './constants';


const GeosService = {
  fetchList: async (params) => {
    const response = await LionstudiosApi.get(GEOS_URL, {
      params,
    });

    return {
      geos: listModel(response.data.geos),
      total: response.data.total,
    };
  },
};


export default GeosService;

export const FIELDS = {
  EMAIL: 'emailAddress',
  EMAIL_STATUS: 'emailStatus',
  LAST_LOGIN_TIME: 'lastSeenAt',
  IS_SUPER_ADMIN: 'isSuperAdmin',
  IS_MANAGER: 'isManager',
  ACTIONS: 'actions',
};


export const ACTIVE_FILTERS = [FIELDS.EMAIL];

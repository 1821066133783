import LionstudiosApi from 'services/lionstudios-api';

import { listModel } from './models';
import { MANAGERS_URL } from './constants';


const ManagersService = {
  fetchList: async () => {
    const response = await LionstudiosApi.get(`${MANAGERS_URL}`);

    return listModel(response.data);
  },
};


export default ManagersService;

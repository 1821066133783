export const PAGES = {
  GAMES: 'Games',
  CHANNELS: 'Channels',
  CAMPAIGNS: 'Campaigns',
  GEOS: 'Geos',
  SOURCES: 'Sources',
  SETTINGS: 'Settings',
  BIDS: 'Bids',
  ACTIVITY_LOGS: 'Activity Logs',
};

export const CATEGORIES = {
  GAMES: 'Games',
  CHANNELS: 'Channels',
  CAMPAIGNS: 'Campaigns',
  GEOS: 'Geos',
  SOURCES: 'Sources',
  BIDS: 'Bids',
  SEARCH: 'Search',
  COMMON: 'Common',
};

export const ACTIONS = {
  CLICK: 'Click',
  SUBMIT: 'Submit',
  CHANGE: 'Change',
  BULK_CHANGE: 'Bulk Change',
};

export const NAMES = {
  NEW_GAME_MODAL: 'New Game Modal',
  UPDATE_GAME_MODAL: 'Update Game Modal',
  ADD_NEW_GAME: 'Add New Game',
  UPDATE_GAME: 'Update Game',

  CHANGE_APP_STATUS: 'Change App Status',
  CHANGE_APP_NAME: 'Change App Name',
  CHANGE_GROWTH_MANAGER: 'Change Growth Manager',

  CHANGE_CHANNEL_STATUS: 'Change Channel Status',
  CHANGE_GOAL_ROAS: 'Change Goal ROAS',
  CHANGE_CHANNEL_NAME: 'Change Channel Name',

  EDIT_MODE_GOAL_ROAS: 'Edit Mode Goal ROAS',
  SAVE_GOAL_ROAS: 'Save Goal ROAS',
  DECLINE_COMMENT_GOAL_ROAS: 'Decline Comment Goal ROAS',
  SAVE_COMMENT_GOAL_ROAS: 'Save Comment Goal ROAS',
  WARNING_GOAL_ROAS_MODAL: 'Warning Goal ROAS Modal',
  DECLINE_WARNING_GOAL_ROAS_MODAL: 'Decline Warning Goal ROAS Modal',
  CONFIRM_WARNING_GOAL_ROAS_MODAL: 'Confirm Warning Goal ROAS Modal',
  RESET_GOAL_ROAS_MODAL: 'Reset Goal ROAS Modal',
  DECLINE_RESET_GOAL_ROAS_MODAL: 'Decline Reset Goal ROAS Modal',
  CONFIRM_RESET_GOAL_ROAS_MODAL: 'Confirm Reset Goal ROAS Modal',

  DECLINE_OVERRIDE_LIST_GOAL_ROAS_MODAL: 'Decline Override List Goal ROAS Modal',
  CONFIRM_OVERRIDE_LIST_GOAL_ROAS_MODAL: 'Confirm Override List Goal ROAS Modal',

  DELETE_CHANNEL_MODAL: 'Delete Channel Modal',
  DECLINE_DELETE_CHANNEL_MODAL: 'Decline Delete Channel Modal',
  CONFIRM_DELETE_CHANNEL_MODAL: 'Confirm Delete Channel Modal',
  ADD_CHANNEL: 'Add Channel',
  OPEN_CHANNEL_LINK: 'Open Channel Link',

  CHANGE_BIDDER_STATUS: 'Change Bidder Status',
  OPEN_CAMPAIGN_LINK: 'Open Campaign Link',

  SEARCH_FOCUS: 'Search Focus',
  OPEN_GAME_DETAILS: 'Open Game Details',
  OPEN_CAMPAIGN: 'Open Campaign',

  COPY_TEXT: 'Copy Text',
};

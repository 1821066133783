import { styled } from '@mui/system';
import { buttonClasses } from '@mui/material/Button';


export const Wrapper = styled('div')({
  height: '100%',
});

export const ListWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),

  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
  },
}));

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import settingsActions from 'core/settings/actions';
import AnalyticsService from 'services/analytics';

import { withParams } from 'hoc/with-params';

import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';

import ChangeLimitsList from 'components/settings/change-limits/list';
import Button from 'components/common/button';
import Modal from 'components/settings/change-limits/modal';

import { Wrapper, ListWrapper, ButtonWrapper } from './styles';
import { sanitizeFilterList } from './utils';


const ChangeLimits = memo(() => {
  const dispatch = useDispatch();

  const [isGameModalOpen, setIsGameModalOpen] = useState(false);
  const [formProps, setFormProps] = useState({ isEdit: false, initialValues: null });

  const handleAddNewGameClick = useCallback(() => {
    setFormProps({
      isEdit: false,
      initialValues: null,
    });
    setIsGameModalOpen(true);
  }, []);

  const handleAddNewGameCancel = useCallback(() => {
    setFormProps({
      isEdit: false,
      initialValues: null,
    });
    setIsGameModalOpen(false);
  }, []);

  const handleEdit = useCallback((nextData) => {
    const selectedData = {
      id: nextData.id,
      campaignType: nextData.CAMPAIGN_TYPE,
      channel: nextData.CHANNEL,
      filterList: sanitizeFilterList(JSON.parse(nextData.FILTER_LIST || '[]')),
      limitType: nextData.LIMIT_TYPE,
    };
    setFormProps({
      isEdit: true,
      initialValues: selectedData,
    });

    setIsGameModalOpen(true);
  }, []);

  useEffect(() => {
    dispatch(settingsActions.fetchPushFrequencies());
    dispatch(settingsActions.fetchChangeLimit());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.SETTINGS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Wrapper>
      <ButtonWrapper
        as={Stack}
        direction="row"
        justifyContent="end"
      >
        <Button
          onClick={handleAddNewGameClick}
          startIcon={<AddIcon />}
        >
          Add New Limits
        </Button>
      </ButtonWrapper>
      <ListWrapper>
        <ChangeLimitsList
          onEdit={handleEdit}
        />
      </ListWrapper>

      <Modal
        isOpen={isGameModalOpen}
        onCancel={handleAddNewGameCancel}
        {...formProps}
      />
    </Wrapper>
  );
});


ChangeLimits.propTypes = {

};

ChangeLimits.defaultProps = {

};


export default withParams(ChangeLimits);

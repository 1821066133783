import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';

import DataGrid from 'components/common/data-grid';
import ConfirmationModal from 'components/modals/confirmation';

import networksActions from 'core/networks/actions';
import gamesActions from 'core/games/actions';
import { getIsNetworkUpdateFetching, getIsNetworkAddFetching, getIsNetworkDeleteFetching } from 'core/networks/selectors';
import { getIsGoalRoasUpdateFetching, getGameById } from 'core/games/selectors';

import { GOAL_ROAS_PATHS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import { isValueExists } from 'utils';

import { getConfig } from './config';
import { getListWithAllChannels } from './utils';


const GameNetworksList = memo(({
  list,
  onSelectionChange,
  selection,
  type,
  gameId,
  isGameArchived,
  isAppStatusReady,
  isMounted,
}) => {
  const dispatch = useDispatch();

  const game = useSelector(getGameById(gameId));
  const isNetworkUpdateFetching = useSelector(getIsNetworkUpdateFetching);
  const isNetworkAddFetching = useSelector(getIsNetworkAddFetching);
  const isNetworkDeleteFetching = useSelector(getIsNetworkDeleteFetching);
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [data, setData] = useState(() => getListWithAllChannels(list));
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [deletableNetworkId, setDeletableNetworkId] = useState(null);
  const [deletableAccountId, setDeletableAccountId] = useState(null);

  const handleRowDelete = useCallback((networkId, accountId) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CHANNELS,
      name: AnalyticsService.NAMES.DELETE_CHANNEL_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsDeleteConfirmationModalOpen(true);
    setDeletableNetworkId(networkId);
    setDeletableAccountId(accountId);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CHANNELS,
      name: AnalyticsService.NAMES.CONFIRM_DELETE_CHANNEL_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsDeleteConfirmationModalOpen(false);

    dispatch(networksActions.deleteNetwork({
      gameId,
      id: deletableNetworkId,
    }));

    setLastEditedRow(deletableNetworkId);
    setDeletableNetworkId(null);
    setDeletableAccountId(null);
  }, [deletableNetworkId, dispatch, gameId]);

  const handleDeleteCancel = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CHANNELS,
      name: AnalyticsService.NAMES.DECLINE_DELETE_CHANNEL_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleRowUpdate = useCallback((nextData) => {
    const { id } = nextData;

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CHANNELS,
      name: isValueExists(nextData.newAccountId) ? AnalyticsService.NAMES.CHANGE_CHANNEL_NAME : AnalyticsService.NAMES.CHANGE_CHANNEL_STATUS,
      action: AnalyticsService.ACTIONS.CHANGE,
    });

    dispatch(networksActions.updateNetwork({
      gameId,
      ...nextData,
    }));
    setLastEditedRow(id);
  }, [dispatch, gameId]);
  const handleRowLinkPress = useCallback((link) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CAMPAIGNS,
      name: AnalyticsService.NAMES.OPEN_CHANNEL_LINK,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    window.open(link, '_blank');
  }, []);

  const handleNetworkAdd = useCallback((nextData) => {
    setLastEditedRow(nextData.id);

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CHANNELS,
      name: AnalyticsService.NAMES.ADD_CHANNEL,
      action: AnalyticsService.ACTIONS.SUBMIT,
    });

    dispatch(networksActions.addNetwork({
      gameId,
      ...nextData,
    }));
  }, [dispatch, gameId]);

  const handleGoalRoasUpdate = useCallback((nextData) => {
    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.NETWORK,
    }));

    setLastEditedRow(nextData.id);
  }, [dispatch, gameId]);

  const handleEmptyNetworkEdit = useCallback((nextData) => {
    const index = data.findIndex(({ id }) => id === nextData.id);

    setData(update(data, {
      [index]: { $merge: nextData },
    }));
  }, [data]);

  const config = useMemo(() => getConfig(
    data,
    handleRowUpdate,
    handleRowDelete,
    handleRowLinkPress,
    handleNetworkAdd,
    handleEmptyNetworkEdit,
    handleGoalRoasUpdate,
    isGameArchived,
    isAppStatusReady,
  ), [
    data,
    handleRowUpdate,
    handleRowDelete,
    handleRowLinkPress,
    handleNetworkAdd,
    handleEmptyNetworkEdit,
    handleGoalRoasUpdate,
    isGameArchived,
    isAppStatusReady,
  ]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isNetworkUpdateFetching || isNetworkDeleteFetching || isNetworkAddFetching || isGoalRoasUpdateFetching) && id === lastEditedRow
  ), [isNetworkUpdateFetching, isNetworkAddFetching, isNetworkDeleteFetching, isGoalRoasUpdateFetching, lastEditedRow]);

  const getIsRowClassName = useCallback(({ row: { channel } }) => `networks-grid__row--${channel}`, []);

  const getIsRowSelectable = useCallback(({ row: { isEmpty } }) => !isEmpty, []);

  const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);

  useEffect(() => {
    setData(getListWithAllChannels(list, game));
  }, [list, game]);

  useEffect(() => {
    if (isMounted) {
      AnalyticsService.trackPageView({
        documentTitle: AnalyticsService.PAGES.CHANNELS,
      });
    }
  }, []);

  return (
    <>
      <DataGrid
        config={config}
        onSelectionModelChange={handleSelectionChange}
        selectionModel={selection}
        getIsRowFetching={getIsRowFetching}
        getRowClassName={getIsRowClassName}
        isRowSelectable={getIsRowSelectable}
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Delete Confirmation"
        description={`Please, confirm that you want to delete this Channel (${deletableAccountId})`}
      />
    </>
  );
});


GameNetworksList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  gameId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  isGameArchived: PropTypes.bool.isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
  isMounted: PropTypes.bool.isRequired,
};

GameNetworksList.defaultProps = {
  list: [],
};


export default GameNetworksList;

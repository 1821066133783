import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import Input from 'components/common/input';
import SelectField from 'components/common/select-field';

import {
  HeaderCellWrapper,
  InputWrapper,
  StyledInput,
  SearchIconWrapper,
} from './styles';


const HeaderSearchInput = memo(({
  field,
  initialValue,
  headerName,
  options,
  activeFilters,
  onFilterChange,
  onInputClick,
  tooltipText,
  clearableFilter,
  isMultipleOptions,
  renderFilterValue,
}) => {
  const [value, setValue] = useState(() => initialValue);

  const handleChange = useCallback((nextValue) => {
    setValue(nextValue);
    onFilterChange(nextValue, field);
  }, [onFilterChange, field]);

  const filterContent = useMemo(() => {
    if (activeFilters && !activeFilters.includes(field)) {
      return null;
    }

    let content;

    const additionalProps = {
      ...(renderFilterValue ? {
        renderValue: renderFilterValue,
      } : {}),
    };

    if (options) {
      const fieldInitialValue = isMultipleOptions ? (value || []) : value;

      content = (
        <SelectField
          initialValue={fieldInitialValue}
          options={options}
          isMultiple={isMultipleOptions}
          onChange={(nextValue) => onFilterChange(nextValue, field)}
          clearable={clearableFilter}
          {...additionalProps}
        />
      );
    } else {
      content = (
        <StyledInput
          as={Input}
          value={value}
          onChange={(event) => handleChange(event.nativeEvent.target.value)}
          stopKeyPropagation
          clearable
        />
      );
    }

    return (
      <>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        {content}
      </>
    );
  }, [
    activeFilters,
    clearableFilter,
    field,
    handleChange,
    onFilterChange,
    options,
    value,
    isMultipleOptions,
    renderFilterValue,
  ]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const WrapperComponent = tooltipText ? Tooltip : 'div';
  const wrapperComponentProps = tooltipText ? {
    arrow: true,
    disableFocusListener: true,
    disableTouchListener: true,
    placement: 'top',
    title: tooltipText,
  } : {};

  return (
    <HeaderCellWrapper>
      <WrapperComponent
        {...wrapperComponentProps}
      >
        <div
          className="MuiDataGrid-columnHeaderTitle"
        >
          {headerName}
        </div>
      </WrapperComponent>

      <InputWrapper
        direction="row"
        alignItems="center"
        as={Stack}
        onClick={onInputClick}
      >
        {filterContent}
      </InputWrapper>
    </HeaderCellWrapper>
  );
});


HeaderSearchInput.propTypes = {
  field: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  onFilterChange: PropTypes.func.isRequired,
  onInputClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  clearableFilter: PropTypes.bool,
  isMultipleOptions: PropTypes.bool,
  renderFilterValue: PropTypes.func,
};

HeaderSearchInput.defaultProps = {
  options: null,
  activeFilters: null,
  tooltipText: null,
  initialValue: '',
  clearableFilter: true,
  isMultipleOptions: false,
  renderFilterValue: null,
};


export default HeaderSearchInput;

export const POPPER_OPTIONS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-16, 23],
      },
    },
  ],
};

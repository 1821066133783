import LionstudiosApi from 'services/lionstudios-api';

import {
  configModel, serverConfigModel, statusModel,
  createPushFrequencyModel, createPushFrequencyResponseModel,
  createChangeLimitModel, createChangeLimitResponseModel,
} from './models';
import { SETTINGS_URL } from './constants';


const SettingsService = {
  fetchConfig: async () => {
    const response = await LionstudiosApi.get(SETTINGS_URL);

    return configModel(response.data);
  },

  updateConfig: async (data) => {
    const response = await LionstudiosApi.post(SETTINGS_URL, serverConfigModel(data));

    return response.data;
  },

  fetchAppStatus: async () => {
    const response = await LionstudiosApi.get(`${SETTINGS_URL}/status`);

    return statusModel(response.data);
  },

  fetchCountries: async () => {
    const response = await LionstudiosApi.get(`${SETTINGS_URL}/countries`);

    return response.data;
  },

  fetchPushFrequencies: async () => {
    const response = await LionstudiosApi.get(`${SETTINGS_URL}/push-frequencies`);

    return response.data;
  },

  createPushFrequency: async (payload) => {
    const data = createPushFrequencyModel(payload);
    const response = await LionstudiosApi.post(`${SETTINGS_URL}/push-frequency`, data);

    return createPushFrequencyResponseModel(response.data);
  },

  deletePushFrequency: async (id) => {
    const response = await LionstudiosApi.delete(`${SETTINGS_URL}/push-frequency/${id}`);

    return response.data;
  },

  updatePushFrequency: async (id, data) => {
    const response = await LionstudiosApi.post(`${SETTINGS_URL}/push-frequency/${id}`, data);

    return response.data;
  },

  fetchChangeLimit: async () => {
    const response = await LionstudiosApi.get(`${SETTINGS_URL}/change-limit`);

    return response.data;
  },

  createChangeLimit: async (payload) => {
    const data = createChangeLimitModel(payload);
    const response = await LionstudiosApi.post(`${SETTINGS_URL}/change-limit`, data);

    return createChangeLimitResponseModel(response.data);
  },

  deleteChangeLimit: async (id) => {
    const response = await LionstudiosApi.delete(`${SETTINGS_URL}/change-limit/${id}`);

    return response.data;
  },

  updateChangeLimit: async (id, data) => {
    const response = await LionstudiosApi.post(`${SETTINGS_URL}/change-limit/${id}`, data);

    return response.data;
  },

};


export default SettingsService;

import React, { memo, useCallback } from 'react';

import { ReactComponent as LogoImage } from 'assets/images/logo.svg';

import { LogoElement } from './styles';


const Logo = memo(() => {
  const handleClick = useCallback(() => {}, []);

  return (
    <LogoElement
      onClick={handleClick}
    >
      <LogoImage />
    </LogoElement>
  );
});


Logo.propTypes = {

};

Logo.defaultProps = {

};


export default Logo;

import memoize from 'lodash.memoize';

import { FILTERS_KEYS } from 'core/bids/constants';
import { getPlatformNameById } from 'core/games/utils';
import { capitalize, isValueExists, isArray, isObject } from 'utils';

import { FIELDS_TO_FILTER_KEYS, QUERY_PARAMS, EXTENDED_QUERY_PARAMS } from './constants';


export const getSelectedOption = (value, options) => (options || []).find(({ id }) => id === value);

export const getSelectedOptions = (value, options) => {
  if (!isValueExists(value) || !options?.length) {
    return [];
  }

  return value.map((selectedId) => getSelectedOption(selectedId, options));
};

export const getSelectedOptionsNames = (value, options) => {
  const test = getSelectedOptions(value, options);

  return test.map(({ name }) => name);
};

export const getSelectedGamesNameWithPlatform = (value, options) => getSelectedOptions(value, options).map(({ name, platform }) => `${name} - ${getPlatformNameById(platform)}`);

export const getSelectedOptionsForSubmit = (value, options) => {
  const selected = getSelectedOptionsNames(value, options);

  return selected.length ? selected : null;
};

export const getSelectedGamesOptionsForSubmit = (value, options) => {
  const selected = getSelectedOptions(value, options).map(({ storeId }) => storeId);

  return selected.length ? selected : null;
};

export const getGameOptionLabel = (option) => `${option.name} - ${getPlatformNameById(option.platform)}`;

export const renderSelectedArrayValue = (value, options, { defaultPrefix, defaultPlaceholder }) => {
  if (!options.length) {
    return defaultPlaceholder;
  }

  const selectedNames = getSelectedOptionsNames(value, options);

  return `${defaultPrefix} ${selectedNames.join(', ')}`;
};

export const renderSelectedGamesValue = (value, options, defaultPrefix) => {
  const selectedNames = getSelectedGamesNameWithPlatform(value, options);

  return `${defaultPrefix} ${selectedNames.join(', ')}`;
};

export const renderMultiSelectValue = ({ value }, operator, { defaultPrefix, defaultPlaceholder }) => {
  if (!value?.length) {
    return defaultPlaceholder;
  }

  return `${defaultPrefix} ${value.map(capitalize).join(', ')}`;
};

export const renderSelectValue = (value, options, { defaultPrefix, defaultPlaceholder }) => {
  if (!isValueExists(value)) {
    return defaultPlaceholder;
  }

  const { name } = getSelectedOption(value, options) || {};

  return `${defaultPrefix} ${name}`;
};

export const extractInputValue = ({ nativeEvent: { target: { value } } }) => value || '';

export const renderInputValue = ({ value }, { name }) => `${name} ${value}`;

export const extractKey = ({ id }) => id;

export const extractGameKey = ({ storeId }) => storeId;

export const getSelectProps = (placeholder, onClose, multiple = true) => ({
  renderValue: () => placeholder,
  multiple,
  onClose,
});

export const getHandleMultiSelectChange = memoize((onChange) => ({ target: { value } }) => onChange(value));

export const getActiveFilters = (filtersValue, filtersState) => Object.entries(filtersValue).reduce((result, [key, filterValue]) => {
  if (!isObject(filterValue)) {
    return result;
  }

  const { operator, value } = filterValue;
  const isExists = isValueExists(value);
  const isValueArray = isExists && isArray(value);
  const nextValue = isValueArray && !value.length ? null : value;
  const prevValue = filtersState[key];

  if ((operator === prevValue.operator) && (nextValue === prevValue.value)) {
    return result;
  }

  return {
    ...result,
    [key]: {
      operator,
      value: nextValue,
    },
  };
}, {});

export const getFiltersValueForSubmit = (gridState, filtersConfig, sorting) => {
  const filters = Object.entries(gridState).reduce((result, [key, filterValue]) => {
    if (!isObject(filterValue)) {
      return {
        ...result,
        [key]: filterValue,
      };
    }

    const { operator, value } = filterValue;
    const { extractFilterValue } = filtersConfig.find((filterConfig) => filterConfig.key === key);

    return {
      ...result,
      [key]: {
        operator,
        value: extractFilterValue ? extractFilterValue(value) : value,
      },
    };
  }, {});

  if (sorting.length) {
    const [sort] = sorting;
    filters[FILTERS_KEYS.SORT] = {
      field: FIELDS_TO_FILTER_KEYS[sort.field],
      sort: sort.sort,
    };
  }

  return filters;
};

export const getColumnVisibilityModel = (hiddenColumns) => hiddenColumns.reduce((result, column) => {
  result[column] = false; // eslint-disable-line no-param-reassign

  return result;
}, {});

export const getQueryParamsByFilters = (filters, sorting) => {
  const queryParams = Object.entries(filters).reduce((result, [filterKey, filterValue]) => {
    const queryParamKey = QUERY_PARAMS[filterKey];

    if (!isObject(filterValue)) {
      result[queryParamKey] = filterValue; // eslint-disable-line no-param-reassign

      return result;
    }

    const { value } = filterValue;

    if (isValueExists(value)) {
      result[queryParamKey] = JSON.stringify(filterValue); // eslint-disable-line no-param-reassign
    }

    return result;
  }, {});

  queryParams[EXTENDED_QUERY_PARAMS[FILTERS_KEYS.SORT]] = JSON.stringify(sorting || []);

  return queryParams;
};

export const getFiltersByQueryParams = (queryParams) => Object.entries(queryParams).reduce((result, [queryParamKey, queryParamValue]) => {
  const [filterKey] = Object.entries(QUERY_PARAMS).find((queryParam) => queryParam[1] === queryParamKey) || [];

  if (!filterKey) {
    return result;
  }

  result[filterKey] = JSON.parse(queryParamValue); // eslint-disable-line no-param-reassign

  return result;
}, {});

export const getSortingByQueryParams = (queryParams) => {
  const sortingParam = queryParams[EXTENDED_QUERY_PARAMS[FILTERS_KEYS.SORT]];

  if (!sortingParam) {
    return [];
  }

  return JSON.parse(sortingParam);
};

import { createSelector } from 'reselect';


const getLocalState = (state) => state.networks;
const getIsNetworkUpdateFetchingState = (state) => getLocalState(state).isNetworkUpdateFetching;
const getIsNetworkAddFetchingState = (state) => getLocalState(state).isNetworkAddFetching;
const getIsNetworkDeteleFetchingState = (state) => getLocalState(state).isNetworkDeleteFetching;


export const getIsNetworkUpdateFetching = createSelector(
  getIsNetworkUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsNetworkAddFetching = createSelector(
  getIsNetworkAddFetchingState,
  (isFetching) => isFetching,
);

export const getIsNetworkDeleteFetching = createSelector(
  getIsNetworkDeteleFetchingState,
  (isFetching) => isFetching,
);

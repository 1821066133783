import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Select from 'components/common/select';

import { isValueExists } from 'utils';

import { Wrapper, Placeholder, ArrowIcon, ClearButton, PlaceholderText } from './styles';


const SelectField = memo((props) => {
  const { initialValue, options, onChange, indicator: IndicatorComponent, disabled, disabledText, clearable, readOnly } = props;
  const { isMultiple, renderValue, ...componentProps } = props;

  const [value, setValue] = useState(() => initialValue);
  const [isSelectActive, setIsSelectActive] = useState(false);

  const setSelectActive = useCallback(() => {
    if (!isSelectActive && !disabled && !readOnly) {
      setIsSelectActive(true);
    }
  }, [isSelectActive, disabled, readOnly]);

  const setSelectClosed = useCallback(() => {
    if (isSelectActive) {
      setIsSelectActive(false);
    }
  }, [isSelectActive]);

  const handleChange = useCallback((nextValue) => {
    setValue(nextValue);
    onChange(nextValue);
  }, [onChange]);

  const handleClearClick = useCallback((event) => {
    event.stopPropagation();

    setValue('');
    onChange('');
  }, [onChange]);

  const selectedItem = useMemo(() => {
    if (isMultiple && value) {
      return {
        name: value.map((selectedId) => {
          const { name } = options.find(({ id }) => id === selectedId) || {};

          return name;
        }).filter(isValueExists).join(', '),
      };
    }

    return options.find(({ id }) => id === value) || {};
  }, [value, options, isMultiple]);

  const selectProps = useMemo(() => ({
    open: isSelectActive,
    onClose: setSelectClosed,
    onOpen: setSelectActive,
    multiple: isMultiple,
    renderValue,
  }), [isSelectActive, setSelectClosed, setSelectActive, isMultiple, renderValue]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const selectValue = isMultiple ? (value || []) : value;

  const isClearButtonVisible = clearable && Boolean(isMultiple ? value?.length : value);

  return (
    <Wrapper
      onClick={setSelectActive}
      as={Stack}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      $isDisabled={disabled}
      $readOnly={readOnly}
    >
      {isSelectActive ? (
        <Select
          {...componentProps}
          value={selectValue}
          selectProps={selectProps}
          onChange={handleChange}
        />
      ) : (
        <Placeholder
          $withIndicator={!!IndicatorComponent && !(disabled && disabledText)}
          $isDisabled={disabled}
        >
          {
            IndicatorComponent && !(disabled && disabledText) && (
              <IndicatorComponent
                status={value}
              />
            )
          }

          <PlaceholderText>
            {disabled && disabledText ? disabledText : selectedItem.name}
          </PlaceholderText>

          {
            isClearButtonVisible ? (
              <ClearButton
                as={IconButton}
                aria-label="clear"
                onClick={handleClearClick}
              >
                <CloseIcon
                  fontSize="inherit"
                />
              </ClearButton>
            ) : (
              !readOnly && <ArrowIcon as={ArrowDropDownIcon} />
            )
          }

        </Placeholder>
      )}
    </Wrapper>
  );
});


SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}).isRequired,
    ]),
    name: PropTypes.string.isRequired,
  })).isRequired,
  renderItem: PropTypes.func,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  inputProps: PropTypes.shape({}),
  indicator: PropTypes.shape({}),
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  clearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  isMultiple: PropTypes.bool,
  renderValue: PropTypes.func,
};

SelectField.defaultProps = {
  renderItem: null,
  initialValue: null,
  inputProps: {},
  indicator: null,
  disabled: false,
  disabledText: null,
  clearable: false,
  readOnly: false,
  isMultiple: false,
  renderValue: null,
};


export default SelectField;

import { styled } from '@mui/system';

import { inputBaseClasses } from '@mui/material/InputBase';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { selectClasses } from '@mui/material/Select';
import { popoverClasses } from '@mui/material/Popover';

import baseTheme from 'containers/theme-provider/styles';


export const SUBMENU_CLASS_NAME = 'submenu-popover';

const WIDTH = '160px';

export const globalStyles = {
  [`.${SUBMENU_CLASS_NAME}`]: {
    minWidth: WIDTH,

    '& .editable-text-field': {
      width: WIDTH,
      height: baseTheme.utils.menu.itemHeight,

      '&.editable-text-field--valid': {
        border: `1px solid ${baseTheme.palette.background.light}`,
      },
    },
  },
};

export const StyledMenuItem = styled('div')(({ theme }) => ({
  minWidth: WIDTH,
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',

  [`.${svgIconClasses.root}`]: {
    color: theme.palette.primary.light,
  },
}));

export const SelectWrapper = styled('div')(({ $isActive }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  pointerEvents: $isActive ? 'auto' : 'none',

  '.select-wrapper': {
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',

    [`.${inputBaseClasses.formControl}`]: {
      cursor: 'pointer',
    },

    [`.${inputBaseClasses.input}`]: {
      height: '100%',
    },

    [`.${outlinedInputClasses.notchedOutline}, .${inputBaseClasses.input}, .${selectClasses.icon}`]: {
      opacity: 0,
      cursor: 'pointer',
    },
  },

  [`.${popoverClasses.root}`]: {
    pointerEvents: 'auto',
  },
}));

export const StyledAutocomplete = styled('div')(() => ({
  width: WIDTH,

  [`.${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
}));

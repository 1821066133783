import { isArray, isObject } from 'utils';

import { TYPES } from './constants';


export const getIsEmpty = (filterValue, type) => {
  const { value, operator } = filterValue;
  const isNotEmpty = value && value.length;
  const result = isArray(value) ? (!value || !isNotEmpty) : !value;

  return type === TYPES.CONTROL ? result : (result || !operator);
};

export const isFilterValue = (data) => isObject(data) && Object.prototype.hasOwnProperty.call(data, 'operator') && Object.prototype.hasOwnProperty.call(data, 'value');

import { put, call, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { resolvedAction, rejectedAction, resolved, rejected } from 'utils/actions';

import SnackbarService from 'services/snackbar';

import { waitForUserSession } from 'core/app/sagas';
import gamesActions, { types as gamesTypes } from 'core/games/actions';

import CampaignsService from './service';
import { types as campaignsTypes } from './actions';


/*
 * Sagas
 */

function* fetchList () {
  try {
    yield call(waitForUserSession);

    const data = yield call(CampaignsService.fetchList);

    yield put(resolvedAction(campaignsTypes.FETCH_LIST, { data }));
  } catch (error) {
    yield put(rejectedAction(campaignsTypes.FETCH_LIST, { message: error.message }));
  }
}

function* updateCampaign ({ payload, meta }) {
  try {
    const { id, gameId, ...rest } = payload;
    const { showSuccessMessage, refetchGame } = meta;

    const data = yield call(CampaignsService.updateCampaign, id, rest);

    if (refetchGame) {
      yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));
      yield take([resolved(gamesTypes.FETCH_GAME), rejected(gamesTypes.FETCH_GAME)]);
    }

    yield put(resolvedAction(campaignsTypes.UPDATE_CAMPAIGN, { data }));

    if (showSuccessMessage) {
      SnackbarService.showSuccess('Campaign has been updated successfully'); // TODO: move text somewhere
    }
  } catch (error) {
    yield put(rejectedAction(campaignsTypes.UPDATE_CAMPAIGN, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}


/*
 * Watchers
 */

function* fetchListWatcher () {
  yield takeLatest(campaignsTypes.FETCH_LIST, fetchList);
}

function* updateCampaignWatcher () {
  yield takeEvery(campaignsTypes.UPDATE_CAMPAIGN, updateCampaign);
}


export default [
  updateCampaignWatcher,
  fetchListWatcher,
];

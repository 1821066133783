import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import { types } from './actions';
import persistConfig from './persist';
import { DEFAULT_HIDDEN_COLUMNS } from './constants';


const defaultState = {
  list: [],
  params: {},
  total: 0,
  hiddenGridColumns: DEFAULT_HIDDEN_COLUMNS,

  historicalBidsList: [],
  historicalBidsTotal: 0,

  isFetching: false,
  isOverrideFetching: false,
  isHistoricalBidsFetching: false,
  isPushHistoricalBidsFetching: false,
};

const reducer = handleActions({
  [types.FETCH_LIST]: (state, { payload, meta: { useIsFetching } }) => {
    if (!useIsFetching) {
      return state;
    }

    return update(state, {
      params: { $set: payload },
      isFetching: { $set: true },
    });
  },
  [resolved(types.FETCH_LIST)]: (state, { payload: { total, bids } }) => (
    update(state, {
      list: { $set: bids },
      total: { $set: total },
      isFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_LIST)]: (state) => (
    update(state, {
      isFetching: { $set: false },
    })
  ),

  [types.OVERRIDE]: (state) => (
    update(state, {
      isOverrideFetching: { $set: true },
    })
  ),
  [resolved(types.OVERRIDE)]: (state) => (
    update(state, {
      isOverrideFetching: { $set: false },
    })
  ),
  [rejected(types.OVERRIDE)]: (state) => (
    update(state, {
      isOverrideFetching: { $set: false },
    })
  ),

  [types.FETCH_HISTORICAL_BIDS]: (state) => (
    update(state, {
      isHistoricalBidsFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_HISTORICAL_BIDS)]: (state, { payload: { total, bids } }) => (
    update(state, {
      historicalBidsList: { $set: bids },
      historicalBidsTotal: { $set: total },
      isHistoricalBidsFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_HISTORICAL_BIDS)]: (state) => (
    update(state, {
      isHistoricalBidsFetching: { $set: false },
    })
  ),

  [types.PUSH_HISTORICAL_BIDS]: (state) => (
    update(state, {
      isPushHistoricalBidsFetching: { $set: true },
    })
  ),
  [resolved(types.PUSH_HISTORICAL_BIDS)]: (state) => (
    update(state, {
      isPushHistoricalBidsFetching: { $set: false },
    })
  ),
  [rejected(types.PUSH_HISTORICAL_BIDS)]: (state) => (
    update(state, {
      isPushHistoricalBidsFetching: { $set: false },
    })
  ),

  [types.SET_HIDDEN_GRID_COLUMNS]: (state, { payload }) => (
    update(state, {
      hiddenGridColumns: { $set: payload },
    })
  ),

  [combineActions(
    rejected(authTypes.LOAD_AUTH),
    rejected(authTypes.SIGN_IN),
    authTypes.LOGOUT,
  )]: (state) => ({
    ...defaultState,
    hiddenGridColumns: [...state.hiddenGridColumns],
  }),
}, defaultState);


export default persistReducer(persistConfig, reducer);

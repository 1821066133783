import { ENV } from 'constants';

import dev from './dev';
import prod from './prod';


const config = {
  dev,
  prod,
};

export default config[process.env.REACT_APP_CONFIG_ENV || ENV.DEV];

import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import authActions from 'core/auth/actions';
import { getIsResetPasswordFetching, getIsAuthorized } from 'core/auth/selectors';

import useFormik from 'hooks/use-formik';
import { useQueryParams, TYPES } from 'hooks/use-query-params';

import { ROUTES } from 'containers/router/constants';

import Button from 'components/common/button';
import PasswordInput from 'components/common/password-input';
import AuthLayout from 'components/auth/layout';
import FormField from 'components/auth/form-field';
import LinkButton from 'components/auth/link-button';
import Text from 'components/common/text';

import { FORM_KEYS } from 'pages/auth/constants';
import { ResetPasswordValidationSchema } from 'pages/auth/validation';

import Background from 'assets/images/reset-password-bg.png';

import { QUERY_PARAMS_KEYS } from './constants';
import { WrapperButton, StyledText } from './styles';


const ResetPassword = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthorized = useSelector(getIsAuthorized);
  const isFetching = useSelector(getIsResetPasswordFetching);

  const handleFormSubmit = useCallback((data) => {
    dispatch(authActions.resetPassword(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [queryParams] = useQueryParams({
    [QUERY_PARAMS_KEYS.CODE]: TYPES.STRING,
  });

  const { handleSubmit, handleBlur, values, errors, isValid, touched, handleChangeAndTrimValue } = useFormik({
    initialValues: {
      [FORM_KEYS.CODE]: queryParams[QUERY_PARAMS_KEYS.CODE],
      [FORM_KEYS.PASSWORD]: '',
      [FORM_KEYS.CONFIRM_PASSWORD]: '',
    },
    onSubmit: handleFormSubmit,
    validationSchema: ResetPasswordValidationSchema,
  });

  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }

    if (!queryParams[QUERY_PARAMS_KEYS.CODE]) {
      navigate(`/${ROUTES.SIGN_IN}`, { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthLayout
      image={Background}
      title="Reset Password"
    >
      <form
        onSubmit={handleSubmit}
      >
        <FormField>
          <PasswordInput
            name={FORM_KEYS.PASSWORD}
            value={values[FORM_KEYS.PASSWORD]}
            error={touched[FORM_KEYS.PASSWORD] && errors[FORM_KEYS.PASSWORD]}
            onChange={handleChangeAndTrimValue}
            onBlur={handleBlur}
            label="Password"
          />
        </FormField>

        <FormField>
          <PasswordInput
            name={FORM_KEYS.CONFIRM_PASSWORD}
            value={values[FORM_KEYS.CONFIRM_PASSWORD]}
            error={touched[FORM_KEYS.CONFIRM_PASSWORD] && errors[FORM_KEYS.CONFIRM_PASSWORD]}
            onChange={handleChangeAndTrimValue}
            onBlur={handleBlur}
            label="Confirm Password"
          />
        </FormField>

        <FormField
          className="buttons-wrapper"
        >
          <Button
            type="submit"
            disabled={!isValid}
            isFetching={isFetching}
            fullWidth
          >
            Submit
          </Button>
        </FormField>

        <WrapperButton>
          <StyledText as={Text}>Remember your password?</StyledText>
          <LinkButton
            to={`/${ROUTES.SIGN_IN}`}
            isInline
          >
            Log In
          </LinkButton>
        </WrapperButton>
      </form>
    </AuthLayout>
  );
});


export default ResetPassword;

import React, { memo, useCallback } from 'react';

import Stack from '@mui/material/Stack';

import config from 'config';

import IFrame from 'components/common/iframe';
import Button from 'components/common/button';

import { Wrapper, ButtonWrapper, StyledButton } from './styles';


const Bids = memo(() => {
  const handleLookerClick = useCallback(() => {
    window.open(config.DIRECT_LOOKER_URL, '_blank').focus();
  }, []);

  return (
    <>
      <ButtonWrapper
        as={Stack}
        direction="row"
        alignItems="center"
      >
        <StyledButton
          as={Button}
          onClick={handleLookerClick}
        >
          Go to the Looker
        </StyledButton>
      </ButtonWrapper>

      <Wrapper>
        <IFrame
          title="Bids"
          src={config.LOOKER_URL}
        />
      </Wrapper>
    </>

  );
});

export default Bids;

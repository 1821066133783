import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'hooks/use-query';

import { getIsAuthorized } from 'core/auth/selectors';
import authActions from 'core/auth/actions';
import { ROUTES } from 'containers/router/constants';


const SignInOktaRedirect = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useQuery();
  const token = params.get('token');

  const isAuthorized = useSelector(getIsAuthorized);

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.SIGN_IN_OKTA);
    } else {
      dispatch(authActions.signInWithOkta({ token }));
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
});


export default SignInOktaRedirect;

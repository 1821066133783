import { createSelector } from 'reselect';


const getLocalState = (state) => state.bids;
const getListState = (state) => getLocalState(state).list;
const getParamsState = (state) => getLocalState(state).params;
const getTotalState = (state) => getLocalState(state).total;
const getHiddenGridColumnsState = (state) => getLocalState(state).hiddenGridColumns;
const getHistoricalBidsListState = (state) => getLocalState(state).historicalBidsList;
const getHistoricalBidsTotalState = (state) => getLocalState(state).historicalBidsTotal;

const getIsFetchingState = (state) => getLocalState(state).isFetching;
const getIsOverrideFetchingState = (state) => getLocalState(state).isOverrideFetching;
const getIsHistoricalBidsFetchingState = (state) => getLocalState(state).isHistoricalBidsFetching;
const getIsPushHistoricalBidsFetchingState = (state) => getLocalState(state).isPushHistoricalBidsFetching;


export const getBidsList = createSelector(
  getListState,
  (list) => list,
);

export const getIsBidsFetchingFetching = createSelector(
  getIsFetchingState,
  (isFetching) => isFetching,
);

export const getIsOverrideFetching = createSelector(
  getIsOverrideFetchingState,
  (isFetching) => isFetching,
);

export const getParams = createSelector(
  getParamsState,
  (params) => params,
);

export const getTotal = createSelector(
  getTotalState,
  (total) => total,
);

export const getHiddenGridColumns = createSelector(
  getHiddenGridColumnsState,
  (hiddenGridColumns) => hiddenGridColumns,
);

export const getHistoricalBidsList = createSelector(
  getHistoricalBidsListState,
  (list) => list,
);

export const getHistoricalBidsTotal = createSelector(
  getHistoricalBidsTotalState,
  (total) => total,
);

export const getIsHistoricalBidsFetching = createSelector(
  getIsHistoricalBidsFetchingState,
  (isFetching) => isFetching,
);

export const getIsPushHistoricalBidsFetching = createSelector(
  getIsPushHistoricalBidsFetchingState,
  (isFetching) => isFetching,
);

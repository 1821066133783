import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Text from 'components/common/text';
import Checkbox from 'components/common/checkbox';

import { isValueExists } from 'utils';

import { StyledTableContainer, TableHeadLabel, TableCellLabel, StyledTable, TableCellWithCheckbox } from './styles';


const GoalRoasOverrideTable = memo(({
  list,
  onSelectionChange,
}) => {
  const [selected, setSelected] = useState([]);

  const handleSelectAll = useCallback((event) => {
    if (event.target.checked) {
      const nextSelected = list.map(({ id }) => id);
      setSelected(nextSelected);
      return;
    }
    setSelected([]);
  }, [list]);

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    onSelectionChange(selected);
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTableContainer as={TableContainer}>
      <StyledTable
        as={Table}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCellWithCheckbox
              as={TableCell}
              align="center"
            >
              <Checkbox
                color="secondary"
                indeterminate={selected.length > 0 && selected.length < list.length}
                checked={list.length > 0 && selected.length === list.length}
                onChange={handleSelectAll}
                inputProps={{
                  'aria-label': 'select all',
                }}
              />
            </TableCellWithCheckbox>
            <TableCell>
              <TableHeadLabel as={Text}>Campaigns</TableHeadLabel>
            </TableCell>

            <TableCell>
              <TableHeadLabel as={Text}>Geos</TableHeadLabel>
            </TableCell>

            <TableCell
              align="center"
            >
              <TableHeadLabel as={Text}>Current Goal ROAS</TableHeadLabel>
            </TableCell>

            <TableCell
              align="center"
            >
              <TableHeadLabel as={Text}>Next Goal ROAS</TableHeadLabel>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {list.map((item) => (
            <TableRow key={item.id}>
              <TableCellWithCheckbox
                as={TableCell}
                align="center"
              >
                <Checkbox
                  color="secondary"
                  onChange={() => handleSelect(item.id)}
                  checked={selected.includes(item.id)}
                />
              </TableCellWithCheckbox>

              <TableCell>
                <TableCellLabel>{item.campaignName}</TableCellLabel>
              </TableCell>

              <TableCell>
                <TableCellLabel>{item.countryCode || '-'}</TableCellLabel>
              </TableCell>

              <TableCell
                align="center"
                size="small"
              >
                <TableCellLabel>
                  {item.goalRoasOverride}
                  {isValueExists(item.goalRoasOverride) ? '%' : ''}
                </TableCellLabel>
              </TableCell>

              <TableCell
                align="center"
                size="small"
              >
                <TableCellLabel>
                  {
                    isValueExists(item.goalRoas) ? (
                      `${item.goalRoas}%`
                    ) : 'null'
                  }
                </TableCellLabel>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
});


GoalRoasOverrideTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
};


export default GoalRoasOverrideTable;

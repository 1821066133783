import { styled } from '@mui/system';


export const FieldWrapper = styled('div')(({ theme }) => ({
  '& + &': {
    marginLeft: theme.spacing(3),
  },
}));

export const StyledDatePickerInput = {
  width: 250,
};

import React from 'react';

import { Link } from '@mui/material';

import { extractRowKey } from 'utils/extracters';

import { GOAL_ROAS_PATHS, CHANNELS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import { PLATFORM_ICONS, CHANNELS_OPTIONS } from 'core/v4/games/constants';
import { BIDDER_STATUS_TEXTS, CPA_ROAS_SETTING_TYPES, CPA_ROAS_SETTING_TYPE_TEXTS } from 'core/v4/campaigns/constants';

import { ROUTES } from 'containers/router/constants';

import DataGridEditableField from 'components/common/data-grid-editable-field';
import AdaptiveText from 'components/common/adaptive-text';
// import GridHeaderWithWeekRangeTooltip from 'components/games/grid-header-with-week-range-tooltip';
import GoalRoasField from 'components/games/goal-roas-field';
import ChannelIcon from 'components/games/channel-icon';
import Budget from 'components/games/budget';
// import SuggestedRoas from 'components/games/suggested-roas';

// import { suggestedRoasSortComparator } from 'utils/grid';
import { getPriceFormatter } from 'utils/formatters';

import { FIELDS, CAMPAIGN_STATUS_OPTIONS } from './constants';
// import { SUGGESTED_ROAS_CLASS_NAME } from './styles';
import { FieldWrapper } from './styles';
import { renderMultipleFilterValue } from './utils';


export const getConfig = (data, onSubmit, onGoalRoasUpdate, onGoalRoasReset, isGameArchived, isAppStatusReady) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.GAME_NAME,
      headerName: 'Game / Platform',
      // flex: 8,
      width: 300,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ value, row }) => {
        const { platform } = row;
        const Icon = PLATFORM_ICONS[platform];

        return (
          <FieldWrapper>
            <Link
              href={`${ROUTES.GAMES}?t=0&p=${platform}&n=${value}`}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </Link>
            <Icon style={{ width: '1rem' }} />
          </FieldWrapper>
        );
      },
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      // flex: 0.5,
      renderFilterValue: renderMultipleFilterValue,
      options: CHANNELS_OPTIONS,
      isMultipleOptions: true,
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.CAMPAIGN_NAME,
      headerName: 'Campaign Name',
      // flex: 2,
      width: 200,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CAMPAIGN_STATUS,
      headerName: 'Campaign Status',
      // flex: 2,
      renderFilterValue: renderMultipleFilterValue,
      options: CAMPAIGN_STATUS_OPTIONS,
      isMultipleOptions: true,
      width: 150,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CAMPAIGN_TYPE,
      headerName: 'Campaign Type',
      // flex: 1,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: 'CAMPAIGN_SUB_TYPE',
      headerName: 'Campaign Sub-Type',
      // flex: 1,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>[D3_COMBINED]</AdaptiveText>
      ),
    },
    {
      field: FIELDS.COUNTRY,
      headerName: 'Geo 2LC',
      // flex: 0.5,
      width: 70,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.BUDGET_TYPE,
      headerName: 'Budget Type',
      // flex: 1,
      width: 180,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.BUDGET,
      headerName: 'Budget',
      // flex: 1,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>
          <Budget
            {...value}
          />
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.WEEK_SPEND,
      headerName: 'Last 7 days spend',
      // flex: 1,
      width: 150,
      type: 'number',
      valueFormatter: getPriceFormatter(),
      headerAlign: 'left',
      align: 'left',
      // renderHeader: ({ colDef }) => (
      //   <GridHeaderWithWeekRangeTooltip
      //     headerName={colDef.headerName}
      //   />
      // ),
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    /*
    {
      field: 'V3D0GOAL',
      headerName: 'v3 D0 Goal',
      // flex: 1,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>[10%]</AdaptiveText>
      ),
    },
    */
    {
      field: FIELDS.BIDDER_STATUS,
      headerName: 'Bidder Status',
      // flex: 1,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => <AdaptiveText size={AdaptiveText.SIZE.SM}>{BIDDER_STATUS_TEXTS[value] ?? value}</AdaptiveText>,
    },
    /*
    {
      field: 'PUSH_COOLDOWN',
      headerName: 'Push Cooldown',
      // flex: 1,
      width: 120,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>[3]</AdaptiveText>
      ),
    },
    */
    {
      field: FIELDS.RETURN_WINDOW,
      headerName: 'Return Window',
      // flex: 1,
      width: 120,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>
          {value}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.RW_RPI_FORECAST,
      headerName: 'RW RPI Forecast',
      // flex: 1,
      width: 120,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      valueFormatter: getPriceFormatter(),
      renderCell: ({ formattedValue, value }) => value ? (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ) : '-',
    },
    {
      field: FIELDS.RW_ROAS_TARGET,
      headerName: 'RW ROAS Target',
      // flex: 1,
      width: 120,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: ({ value }) => (value ? `${value}%` : ''),
    },
    {
      field: FIELDS.CPA_ROAS_SETTING_TYPE,
      headerName: 'CPA/ROAS Setting Type',
      // flex: 1,
      width: 160,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => CPA_ROAS_SETTING_TYPE_TEXTS[value] ?? value,
    },
    {
      field: FIELDS.CPA_ROAS_SETTING,
      headerName: 'CPA/ROAS Setting',
      // flex: 1,
      width: 160,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      valueFormatter: getPriceFormatter(),
      renderCell: ({ formattedValue, row }) => row.cpaRoasSettingType === CPA_ROAS_SETTING_TYPES.ALL ? (
        formattedValue
      ) : (CPA_ROAS_SETTING_TYPE_TEXTS[row.cpaRoasSettingType] ?? ''),
    },
    /*
    {
      field: 'PROBABILISTIC_D0_ROAS',
      headerName: 'Probabilistic D0 ROAS',
      // flex: 1,
      width: 160,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>[D0]</AdaptiveText>
      ),
    },
    */
    {
      field: FIELDS.DELTA_CPA_ROAS_SETTING,
      headerName: 'Delta CPA/ROAS Setting',
      // flex: 1,
      width: 160,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: 'USEFUL_LINKS',
      headerName: 'Useful Links',
      // flex: 1,
      width: 160,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>
          <Link
            href="https://google.com"
            target="_blank"
            rel="noreferrer"
          >
            [Data Scepes]
          </Link>
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal ROAS',
      // flex: 1,
      width: '150',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const isAppLovin = row.channel === CHANNELS.APPLOVIN;
        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GOAL_ROAS)}
            valueKey={FIELDS.GOAL_ROAS}
            onSubmit={onGoalRoasUpdate}
            component={GoalRoasField}
            onReset={onGoalRoasReset}
            propsToSubmit={{
              id: row.id,
              channelId: row.channelId,
              channel: row.channel,
              campaignId: row.campaignId,
              countryCode: row.country,
              path: GOAL_ROAS_PATHS.GEO,
              goalRoas: row?.override.parentGoal,
              isAppLovin,
            }}
            componentProps={{
              initialValue: value,
              override: row.override,
              disabled: isGameArchived || !isAppStatusReady,
              withoutComment: isAppLovin,
              category: AnalyticsService.CATEGORIES.GEOS,
              disableTooltip: isAppLovin,
            }}
          />
        );
      },
    },
    {
      field: 'ACTIVITY',
      headerName: 'Activity',
      // flex: 1,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>[]</AdaptiveText>
      ),
    },
    {
      field: 'ACTION',
      headerName: 'Action',
      // flex: 1,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: () => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>[]</AdaptiveText>
      ),
    },
    /*
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal ROAS',
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const isAppLovin = row.channel === CHANNELS.APPLOVIN;
        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GOAL_ROAS)}
            valueKey={FIELDS.GOAL_ROAS}
            onSubmit={onGoalRoasUpdate}
            component={GoalRoasField}
            onReset={onGoalRoasReset}
            propsToSubmit={{
              id: row.id,
              channelId: row.channelId,
              channel: row.channel,
              campaignId: row.campaignId,
              countryCode: row.country,
              path: GOAL_ROAS_PATHS.GEO,
              goalRoas: row?.override.parentGoal,
              isAppLovin,
            }}
            componentProps={{
              initialValue: value,
              override: row.override,
              disabled: isGameArchived || !isAppStatusReady,
              withoutComment: isAppLovin,
              category: AnalyticsService.CATEGORIES.GEOS,
              disableTooltip: isAppLovin,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.BILLING_TYPE,
      headerName: 'Billing Type',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.SUGGESTED_ROAS,
      headerName: 'Suggested ROAS',
      flex: 0.8,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      cellClassName: SUGGESTED_ROAS_CLASS_NAME,
      headerClassName: SUGGESTED_ROAS_CLASS_NAME,
      sortComparator: suggestedRoasSortComparator,
      renderCell: ({ value }) => (
        <SuggestedRoas
          {...value}
        />
      ),
    },
    */
  ],
});

import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import usersActions from 'core/users/actions';
import { getIsApproveNewUserFetching } from 'core/users/selectors';

import Button from 'components/common/button';
import Input from 'components/common/input';
import Checkbox from 'components/common/checkbox';
import Link from 'components/common/link';
import PasswordInput from 'components/common/password-input';
import AuthLayout from 'components/auth/layout';
import FormField from 'components/auth/form-field';

import { FORM_KEYS } from 'pages/auth/constants';
import { SignUpValidationSchema } from 'pages/auth/validation';

import { ROUTES } from 'containers/router/constants';

import { useQueryParams, TYPES } from 'hooks/use-query-params';
import useFormik from 'hooks/use-formik';

import Background from 'assets/images/sign-up-bg.png';

import { QUERY_PARAMS_KEYS, TERMS_URL } from './constants';
import { ArgeementCheckboxLabel } from './styles';


const SignUp = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isFetching = useSelector(getIsApproveNewUserFetching);

  const handleFormSubmit = useCallback((data) => {
    dispatch(usersActions.approveNewUser(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [queryParams] = useQueryParams({
    [QUERY_PARAMS_KEYS.EMAIL]: TYPES.STRING,
    [QUERY_PARAMS_KEYS.CODE]: TYPES.STRING,
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, isValid, touched, handleChangeAndTrimValue } = useFormik({
    initialValues: {
      [FORM_KEYS.EMAIL]: queryParams[QUERY_PARAMS_KEYS.EMAIL],
      [FORM_KEYS.CODE]: queryParams[QUERY_PARAMS_KEYS.CODE],
      [FORM_KEYS.PASSWORD]: '',
      [FORM_KEYS.CONFIRM_PASSWORD]: '',
      [FORM_KEYS.AGREEMENT]: true,
    },
    onSubmit: handleFormSubmit,
    validationSchema: SignUpValidationSchema,
  });

  const agreementCheckboxProps = useMemo(() => ({
    label: (
      <ArgeementCheckboxLabel>
        I have read & agree to the
        {' '}
        <Link
          isExternal
          to={TERMS_URL}
        >
          terms of service
        </Link>
        .
      </ArgeementCheckboxLabel>
    ),
    inputProps: {
      name: FORM_KEYS.AGREEMENT,
    },
  }), []);

  useEffect(() => {
    if (!queryParams[QUERY_PARAMS_KEYS.EMAIL] || !queryParams[QUERY_PARAMS_KEYS.CODE]) {
      navigate(`/${ROUTES.SIGN_IN}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthLayout
      image={Background}
      title="You've been invited"
    >
      <form
        onSubmit={handleSubmit}
      >
        <FormField>
          <Input
            name={FORM_KEYS.EMAIL}
            value={values[FORM_KEYS.EMAIL]}
            error={touched[FORM_KEYS.EMAIL] && errors[FORM_KEYS.EMAIL]}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Email address"
            disabled
          />
        </FormField>

        <FormField>
          <PasswordInput
            name={FORM_KEYS.PASSWORD}
            value={values[FORM_KEYS.PASSWORD]}
            error={touched[FORM_KEYS.PASSWORD] && errors[FORM_KEYS.PASSWORD]}
            onChange={handleChangeAndTrimValue}
            onBlur={handleBlur}
            label="Password"
          />
        </FormField>

        <FormField>
          <PasswordInput
            name={FORM_KEYS.CONFIRM_PASSWORD}
            value={values[FORM_KEYS.CONFIRM_PASSWORD]}
            error={touched[FORM_KEYS.CONFIRM_PASSWORD] && errors[FORM_KEYS.CONFIRM_PASSWORD]}
            onChange={handleChangeAndTrimValue}
            onBlur={handleBlur}
            label="Confirm Password"
          />
        </FormField>

        <FormField>
          <Checkbox
            {...agreementCheckboxProps}
            value={values[FORM_KEYS.AGREEMENT]}
            onChange={handleChange}
          />
        </FormField>

        <FormField
          className="buttons-wrapper"
        >
          <Button
            type="submit"
            disabled={!isValid}
            isFetching={isFetching}
            fullWidth
          >
            Sign Up
          </Button>
        </FormField>
      </form>
    </AuthLayout>
  );
});


SignUp.propTypes = {

};

SignUp.defaultProps = {

};


export default SignUp;

import { put, call, takeLatest, take } from 'redux-saga/effects';

import { resolvedAction, rejectedAction, resolved } from 'utils/actions';

import { waitForUserSession } from 'core/app/sagas';
import authActions, { types as authTypes } from 'core/auth/actions';

import SnackbarService from 'services/snackbar';
import AnalyticsService from 'services/analytics';

import UsersService from './service';
import usersActions, { types as usersTypes } from './actions';

/*
 * Sagas
 */

function* fetchAuthorizedUser () {
  try {
    const data = yield call(UsersService.fetchAuthorizedUser);

    AnalyticsService.setUserId(data.email);

    yield put(resolvedAction(usersTypes.FETCH_AUTHORIZED_USER, { data }));
  } catch ({ message }) {
    yield put(rejectedAction(usersTypes.FETCH_AUTHORIZED_USER, { message }));
  }
}

function* addUser ({ payload }) {
  try {
    yield call(UsersService.addUser, payload);

    yield put(resolvedAction(usersTypes.ADD_USER));
    SnackbarService.showSuccess('User has been added successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(usersTypes.ADD_USER, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* approveNewUser ({ payload }) {
  try {
    yield call(UsersService.approveNewUser, payload);

    yield put(authActions.signIn(payload));

    yield take(authTypes.AUTH_SUCCESS);

    yield put(resolvedAction(usersTypes.APPROVE_NEW_USER, { token: null }));
  } catch ({ message }) {
    yield put(rejectedAction(usersTypes.APPROVE_NEW_USER, { message }));
    SnackbarService.showError(
      'Your invite link is invalid or expired', // TODO: move text somewhere
      {
        vertical: 'bottom',
        horizontal: 'left',
      },
    );
  }
}

function* fetchUsers () {
  try {
    yield call(waitForUserSession);

    const data = yield call(UsersService.fetchUsers);

    yield put(resolvedAction(usersTypes.FETCH_USERS, { data }));
  } catch (error) {
    yield put(rejectedAction(usersTypes.FETCH_USERS, { message: error.message }));
  }
}

function* updateUser ({ payload: { id, data } }) {
  try {
    yield call(UsersService.updateUser, id, data);

    yield put(resolvedAction(usersTypes.UPDATE_USER, { data }));
    SnackbarService.showSuccess('User has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(usersTypes.UPDATE_USER, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* deleteUser ({ payload: { id } }) {
  try {
    yield call(UsersService.deleteUser, id);

    yield put(usersActions.fetchUsers(null, { useIsFetching: false }));

    yield take(resolved(usersTypes.FETCH_USERS));

    yield put(resolvedAction(usersTypes.DELETE_USER));
    SnackbarService.showSuccess('User has been deleted successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(usersTypes.DELETE_USER, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* fetchActivityLog ({ payload }) {
  try {
    yield call(waitForUserSession);

    const data = yield call(UsersService.fetchActivityLog, payload);

    yield put(resolvedAction(usersTypes.FETCH_ACTIVITY_LOG, { data }));
  } catch (error) {
    yield put(rejectedAction(usersTypes.FETCH_ACTIVITY_LOG, { message: error.message }));
  }
}

/*
 * Watchers
 */


function* fetchAuthorizedUserWatcher () {
  yield takeLatest(usersTypes.FETCH_AUTHORIZED_USER, fetchAuthorizedUser);
}

function* addUserWatcher () {
  yield takeLatest(usersTypes.ADD_USER, addUser);
}

function* approveNewUserWatcher () {
  yield takeLatest(usersTypes.APPROVE_NEW_USER, approveNewUser);
}

function* fetchUsersWatcher () {
  yield takeLatest(usersTypes.FETCH_USERS, fetchUsers);
}

function* updateUserWatcher () {
  yield takeLatest(usersTypes.UPDATE_USER, updateUser);
}

function* deleteUserWatcher () {
  yield takeLatest(usersTypes.DELETE_USER, deleteUser);
}

function* fetchActivityLogWatcher () {
  yield takeLatest(usersTypes.FETCH_ACTIVITY_LOG, fetchActivityLog);
}


export default [
  fetchAuthorizedUserWatcher,
  addUserWatcher,
  approveNewUserWatcher,
  fetchUsersWatcher,
  updateUserWatcher,
  deleteUserWatcher,
  fetchActivityLogWatcher,
];

import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useQueryParams } from 'hooks/use-query-params';

import DataGrid from 'components/common/data-grid';

import { getFilteredList, getQueryParamsByFilter, getFilterByQueryParams } from 'utils/grid';

import { getTransformedData } from './utils';
import { getConfig } from './config';
import { ACTIVE_FILTERS, QUERY_PARAMS_WITH_TYPES, QUERY_PARAMS_BY_FIELDS } from './constants';


const ActivityLogsList = memo(({ data, isFetching }) => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAMS_WITH_TYPES);

  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState({});

  const config = useMemo(() => getConfig(list, data), [list, data]);

  const handleListFilterChange = useCallback((filter) => {
    setListFilter(filter);
    setQueryParams(
      getQueryParamsByFilter(filter, queryParams, QUERY_PARAMS_BY_FIELDS),
      { replace: true },
    );
  }, [queryParams, setQueryParams]);

  useEffect(() => {
    const queryFilters = getFilterByQueryParams(queryParams, QUERY_PARAMS_BY_FIELDS);
    setListFilter(queryFilters);
    setQueryParams(
      getQueryParamsByFilter(queryFilters, queryParams, QUERY_PARAMS_BY_FIELDS),
      { replace: true },
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setList(getFilteredList(getTransformedData(data), listFilter));
  }, [data, listFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DataGrid
      config={config}
      isFetching={isFetching}
      checkboxSelection={false}
      onFilterChange={handleListFilterChange}
      activeFilters={ACTIVE_FILTERS}
      filter={listFilter}
      useFilters
    />
  );
});


ActivityLogsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default ActivityLogsList;

import LionstudiosApi from 'services/lionstudios-api';

import { updateCampaignModel, listModel } from './models';
import { CAMPAIGNS_URL } from './constants';


const CampaignsService = {
  fetchList: async () => {
    const response = await LionstudiosApi.get(`${CAMPAIGNS_URL}?field=CAMPAIGN_ID&field=CAMPAIGN_NAME`); // TODO: use axios params instead

    return listModel(response.data);
  },

  updateCampaign: async (id, payload) => {
    const data = updateCampaignModel(payload);

    await LionstudiosApi.post(`${CAMPAIGNS_URL}/${id}`, data);

    return null;
  },
};


export default CampaignsService;

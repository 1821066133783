import { CAMPAIGN_STATUS_KEYS } from 'core/games/constants';


export const FIELDS = {
  BIDDER_STATUS: 'bidderStatus',
  CHANNEL: 'channel',
  NAME: 'name',
  STATUS: 'campaignStatus',
  TYPE: 'campaignType',
  WEEK_SPEND: 'weekSpend',
  WEEKLY_INSTALLS: 'weekInstalls',
  GOAL_ROAS: 'goalRoas',
  ACTIONS: 'actions',
  SUGGESTED_ROAS: 'suggestedRoas',
  BUDGET: 'budget',
};

export const ACTIVE_FILTERS = [
  FIELDS.NAME,
  FIELDS.STATUS,
  FIELDS.TYPE,
];

export const DEFAULT_FILTER = {
  [FIELDS.STATUS]: [CAMPAIGN_STATUS_KEYS.RUNNING, CAMPAIGN_STATUS_KEYS.OVER_DAILY_CAP],
};

export const DEFAULT_CAMPAIGN_STATUS_FILTER_OPTION = [{
  id: CAMPAIGN_STATUS_KEYS.RUNNING,
  name: CAMPAIGN_STATUS_KEYS.RUNNING,
}, {
  id: CAMPAIGN_STATUS_KEYS.OVER_DAILY_CAP,
  name: CAMPAIGN_STATUS_KEYS.OVER_DAILY_CAP,
}];

export const getUTCTimeString = (timestamp = Date.now()) => {
  const date = new Date(timestamp);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds} UTC`;
};

export const getLocalizedTimeString = (timestamp) => {
  const date = new Date(timestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds} ${timeZone}`;
};

export const getNextPushInTimeString = (nextPush) => {
  const nowTimestamp = Date.now();
  const nextPushTimestamp = new Date(nextPush).getTime();

  let diff = (nextPushTimestamp - nowTimestamp) / 1000;
  const isPast = diff < 0;

  const hours = Math.floor(diff / 3600) % 24;
  diff -= hours * 3600;

  const minutes = Math.floor(diff / 60) % 60;
  diff -= minutes * 60;

  const seconds = Math.floor(diff) % 60;

  return {
    isPast,
    nextPushIn: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`,
  };
};

import { styled } from '@mui/system';


export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.linkPrimary.main,
  textDecoration: 'none',
  borderBottom: `1px solid ${theme.palette.linkPrimary.main}`,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: 'initial',
}));

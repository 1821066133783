import React, { memo, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import MuiAutocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';

import Input from 'components/common/input';
import Checkbox from 'components/common/checkbox/uncontrolled';

import VirtualizedListboxComponent from './virtualization';

import { StyledAutocomplete, Wrapper, OptionText, CheckboxContainer } from './styles';


const UncontrolledAutocomplete = memo(({
  options,
  value,
  onSubmit,
  getOptionValue,
  inputProps,
  autocompleteProps,
  getOptionLabel,
  extractKey,
  renderNoOptions,
  renderOptionContent,
  useCheckboxes,
  multiple,
  useVirtualization,
  loading,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState(() => '');

  const handleChange = useCallback((event, option) => {
    const nextValue = multiple ? option.map(getOptionValue) : getOptionValue(option);

    onSubmit(nextValue || null, event, autocompleteProps, option);
  }, [onSubmit, getOptionValue, autocompleteProps, multiple]);

  const handleInputChange = useCallback((event) => {
    const { nativeEvent: { target } } = event;
    const { onChange } = inputProps || {};

    setInputValue(target.value.trim());

    if (onChange) {
      onChange(event);
    }
  }, [inputProps]);

  const renderInput = useCallback((params) => {
    const props = {
      ...inputProps,
      ...(renderNoOptions ? {
        onChange: handleInputChange,
      } : {}),
      InputProps: {
        ...(params.InputProps || {}),
        ...(inputProps.InputProps || {}),
        endAdornment: (
          <>
            {loading ? (
              <CircularProgress
                color="inherit"
                size={20}
              />
            ) : null}
            {params.InputProps.endAdornment}
          </>
        ),
      },
    };

    return (
      <Input
        {...params}
        {...props}
        // TODO: add disabled prop
      />
    );
  }, [inputProps, renderNoOptions, handleInputChange, loading]);

  const renderOptionBeforeContent = useCallback((props, option, { selected }) => useCheckboxes ? (
    <CheckboxContainer>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        value={selected}
      />
    </CheckboxContainer>
  ) : null, [useCheckboxes]);

  const renderOption = useCallback((props, option, state) => {
    const extendedProps = {
      index: props['data-option-index'], // eslint-disable-line react/prop-types
      ...props,
    };
    const key = extractKey ? extractKey(option, extendedProps) : props.key; // eslint-disable-line react/prop-types
    const content = renderOptionContent ? renderOptionContent(option) : <OptionText>{getOptionLabel(option)}</OptionText>;
    const beforeContent = renderOptionBeforeContent(props, option, state);

    return (
      <li
        {...props}
        key={key}
      >
        {beforeContent}
        {content}
      </li>
    );
  }, [getOptionLabel, extractKey, renderOptionContent, renderOptionBeforeContent]);

  const isOptionEqualToValue = useCallback((option, currentValue) => getOptionValue(option) === getOptionValue(currentValue), [getOptionValue]);

  const additionalProps = useMemo(() => ({
    ...(renderNoOptions ? {
      noOptionsText: renderNoOptions(inputValue, autocompleteProps),
    } : {}),

    ...(useVirtualization ? {
      ListboxComponent: VirtualizedListboxComponent,
      disableListWrap: true,
    } : {}),
  }), [renderNoOptions, inputValue, autocompleteProps, useVirtualization]);

  return (
    <Wrapper>
      <StyledAutocomplete
        as={MuiAutocomplete}
        onChange={handleChange}
        value={value}
        renderInput={renderInput}
        options={options}
        isOptionEqualToValue={isOptionEqualToValue}
        disablePortal={false}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        multiple={multiple}
        loading={loading}
        openOnFocus
        blurOnSelect
        fullWidth
        {...additionalProps}
        {...otherProps}
      />
    </Wrapper>
  );
});


UncontrolledAutocomplete.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  inputProps: PropTypes.shape({
    InputProps: PropTypes.shape({}),
  }),
  autocompleteProps: PropTypes.shape({}),
  extractKey: PropTypes.func,
  renderNoOptions: PropTypes.func,
  renderOptionContent: PropTypes.func,
  useCheckboxes: PropTypes.bool,
  multiple: PropTypes.bool,
  useVirtualization: PropTypes.bool,
  loading: PropTypes.bool,
};

UncontrolledAutocomplete.defaultProps = {
  value: null,
  getOptionValue: (option) => option?.id,
  getOptionLabel: (option) => option?.name,
  inputProps: {},
  autocompleteProps: {},
  extractKey: null,
  renderNoOptions: null,
  renderOptionContent: null,
  useCheckboxes: false,
  multiple: false,
  useVirtualization: false,
  loading: false,
};


export default UncontrolledAutocomplete;

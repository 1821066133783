import { styled } from '@mui/system';

import { gridClasses } from '@mui/x-data-grid-pro';


export const SUGGESTED_ROAS_CLASS_NAME = 'data-grid__suggested-roas';

export const StyledDataGrid = styled('div')(() => ({ // TODO: extract to data-grid
  [`.${gridClasses.cell}.${SUGGESTED_ROAS_CLASS_NAME}, .${gridClasses.columnHeader}.${SUGGESTED_ROAS_CLASS_NAME}`]: {
    backgroundColor: '#F8F8F8',
  },
}));

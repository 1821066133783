export const FILTERS = {
  TODAY: 'TODAY',
  THREE_DAYS: 'THREE_DAYS',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const FILTER_BUTTONS = [
  {
    label: 'Today',
    id: FILTERS.TODAY,
  },
  {
    label: 'Last 3 days',
    id: FILTERS.THREE_DAYS,
  },
  {
    label: 'Last week',
    id: FILTERS.WEEK,
  },
  {
    label: 'Last month',
    id: FILTERS.MONTH,
  },
];

import { isValueExists } from 'utils';


export const formatNumberWithSeparator = (number, separator = ',', fixed = 0) => {
  if (!isValueExists(number)) {
    return '';
  }

  const parts = Number(number).toFixed(fixed).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join('.');
};

export const formatUSPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = (match[1] ? '+1' : '');
    return [intlCode, intlCode ? '-' : '', match[2], '-', match[3], '-', match[4]].join('');
  }

  return null;
};

export const getNumberFormatter = (minimumFractionDigits = 2) => ({ value }) => formatNumber(value, minimumFractionDigits);
export const getPriceFormatter = () => ({ value }) => `${value < 0 ? '-' : ''}$${new Intl.NumberFormat([], {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(Math.abs(value))}`;

export const formatNumberToPercentage = ({ value }) => `${isValueExists(value) ? Math.round(Number(value) * 100) : 0}%`;

export const getTooltipText = (obj) => Object.entries(obj).map(([key, value]) => `${key} - ${value}`).join('\n');
export const formatNumber = (value, minimumFractionDigits = 2) => new Intl.NumberFormat(undefined, { minimumFractionDigits }).format(value);

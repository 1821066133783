import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'GAMES';

export const types = createConstants([
  'FETCH_LIST',
  'FETCH_GAME',
  'FETCH_GAMES',
  'UPDATE_GAME',
  'DELETE_GAME',
  'CREATE_GAME',

  'UPDATE_GOAL_ROAS',
  'CANCEL_OVERRIDE_GOAL_ROAS',
  'RESET_OVERRIDE_GOAL_ROAS',
  'RESET_OVERRIDE_GOAL_ROAS_LIST',
], PREFIX);

export const actions = createActions({
  [types.FETCH_LIST]: ({ useIsFetching = true } = {}) => ({ useIsFetching }),
  [types.FETCH_GAME]: [
    ({ id, useIsFetching = true }) => ({ id, useIsFetching }),
    (payload, { redirectOnFail = false } = {}) => ({ redirectOnFail }),
  ],
  [types.FETCH_GAMES]: ({ ids }) => ({ ids }),
  [types.UPDATE_GAME]: [
    ({ id, status, name, growthManager, storeId, platform, lionApp }) => ({ id, status, name, growthManager, storeId, platform, lionApp }),
    (payload, { redirectToList = false, refetchGame = false, refetchGamesList = false } = {}) => ({ redirectToList, refetchGame, refetchGamesList }),
  ],
  [types.DELETE_GAME]: ({ id }) => ({ id }),
  [types.CREATE_GAME]: ({ name, storeId, platform, lionApp, growthManager }) => ({ name, storeId, platform, lionApp, growthManager }),

  [types.UPDATE_GOAL_ROAS]: [
    ({ gameId, channelId, channel, campaignId, countryCode, source, path, goalRoas, comment, isAppLovin, initialValue }) => ({ gameId, channelId, channel, campaignId, countryCode, source, path, goalRoas, comment, isAppLovin, initialValue }),
    (payload, { refetchGame = true, refetchGamesList = false, fromBulkActions = false } = {}) => ({ refetchGame, refetchGamesList, fromBulkActions }),
  ],
  [types.CANCEL_OVERRIDE_GOAL_ROAS]: null,
  [types.RESET_OVERRIDE_GOAL_ROAS]: [
    ({ campaignId, channelId, countryCode, path, source, gameId, goalRoas, comment, isAppLovin, initialValue }) => ({ campaignId, channelId, countryCode, path, source, gameId, goalRoas, comment, isAppLovin, initialValue }),
    (payload, { refetchGame = true } = {}) => ({ refetchGame }),
  ],
  [types.RESET_OVERRIDE_GOAL_ROAS_LIST]: [
    ({ list }) => ({ list }),
    (payload, { refetchGame = true } = {}) => ({ refetchGame }),
  ],
}, {
  prefix: PREFIX,
});

export default actions;

import { GAME_DETAILS_LIST } from 'core/games/constants';

// import GameNetworksList from 'components/games/networks-list';
import GamesList from 'pages/v4/games/list';
import GameCampaignsList from 'components/v4/games/campaigns-list';
import GameGeosList from 'components/v4/games/geos-list';
// import GameSourcesList from 'components/games/sources-list';

import { ReactComponent as NetworkIcon } from 'assets/images/networks-icon.svg';
import { ReactComponent as CampaignsIcon } from 'assets/images/campaigns-icon.svg';
import { ReactComponent as GeosIcon } from 'assets/images/geos-icon.svg';
// import { ReactComponent as SourcesIcon } from 'assets/images/sources-icon.svg';


export const config = {
  [GAME_DETAILS_LIST.NETWORKS]: {
    title: 'Games',
    icon: NetworkIcon,
    // list: GameNetworksList,
    list: GamesList,
    color: '#3A76E8',
  },
  [GAME_DETAILS_LIST.CAMPAIGNS]: {
    title: 'Campaigns',
    icon: CampaignsIcon,
    list: GameCampaignsList,
    color: '#73BC24',
  },
  [GAME_DETAILS_LIST.GEOS]: {
    title: 'Geos',
    icon: GeosIcon,
    list: GameGeosList,
    color: '#ED6C02',
  },
  // [GAME_DETAILS_LIST.SOURCES]: {
  //   title: 'Sources',
  //   icon: SourcesIcon,
  //   list: GameSourcesList,
  //   color: '#F50057',
  //   hidden: true, // TODO: remove when Sources tab will become active
  // },
};

import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/common/text';
import Button from 'components/common/button';

import GoalRoasOverrideTable from 'components/games/goal-roas-override-table';

import { Wrapper, Title, StyledButton, ButtonsWrapper } from './styles';


const GoalRoasOverrideForm = memo(({
  onCancel,
  onSubmit,
  overrideList,
  isFetching,
}) => {
  const [selected, setSelected] = useState([]);

  const handleSelectionChange = (nextSelected) => {
    setSelected(nextSelected);
  };

  const handleSubmit = useCallback(() => {
    const filteredList = overrideList.filter((item) => selected.includes(item.id));
    onSubmit(filteredList);
  }, [overrideList, selected, onSubmit]);

  return (
    <Wrapper>
      <Title as={Text}>Some sub-level targets overridden. Do you want to apply changes on them?</Title>

      <GoalRoasOverrideTable
        list={overrideList}
        onSelectionChange={handleSelectionChange}
      />

      <ButtonsWrapper>
        <StyledButton
          as={Button}
          onClick={handleSubmit}
          type="submit"
          disabled={!selected.length}
          isFetching={isFetching}
        >
          Apply override
        </StyledButton>

        <StyledButton
          as={Button}
          variant="outlined"
          color="error"
          onClick={onCancel}
        >
          Don&rsquo;t Apply Override
        </StyledButton>
      </ButtonsWrapper>
    </Wrapper>
  );
});


GoalRoasOverrideForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  overrideList: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool.isRequired,
};

GoalRoasOverrideForm.defaultProps = {
  overrideList: null,
};


export default GoalRoasOverrideForm;

import { KEYS } from './constants';


export const getInitialValues = (values) => ({
  [KEYS.CHANNEL_NAME]: values?.[KEYS.CHANNEL_NAME] || '',
  [KEYS.CAMPAIGN_TYPE]: values?.[KEYS.CAMPAIGN_TYPE] || '',
  [KEYS.LIMIT_TYPE]: values?.[KEYS.LIMIT_TYPE] || '',
  [KEYS.FILTER_LIST]: values?.[KEYS.FILTER_LIST] || [],
});

export const extractInputValue = ({ nativeEvent: { target: { value } } }) => value || '';

export const renderInputValue = ({ value }, { name }) => `${name} ${value}`;

export const renameKeys = (obj, newKeys) => {
  const entries = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;

    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...entries);
};


export const getTitle = (isEdit) => {
  if (isEdit) {
    return 'Edit Change Limit';
  }

  return 'Add Change Limit';
};

import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TuneIcon from '@mui/icons-material/Tune';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Button from 'components/common/button';
import Checkbox from 'components/common/checkbox/uncontrolled';

import { Wrapper, StyledButton } from './styles';


const ListColumnsSelector = memo(({ hiddenColumns, columns, onChange }) => {
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [value, setValue] = useState(() => hiddenColumns);

  const handleButtonClick = useCallback((event) => {
    setPopoverAnchor(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setPopoverAnchor(null);
    onChange(value);
  }, [onChange, value]);

  const handleChange = useCallback((column, isVisible) => {
    const nextHiddenColumns = isVisible ? [...value, column] : value.filter((hiddenColumn) => hiddenColumn !== column);
    setValue(nextHiddenColumns);
  }, [value]);

  const popoverProps = useMemo(() => ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    disablePortal: true,
  }), []);

  return (
    <Wrapper>
      <StyledButton
        as={Button}
        endIcon={<ArrowDropDownIcon />}
        startIcon={<TuneIcon />}
        onClick={handleButtonClick}
      />

      <Menu
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        {...popoverProps}
      >
        {columns.map(({ field, headerName }) => {
          const isVisible = !value.includes(field);

          return (
            <MenuItem
              key={field}
              name={field}
              onClick={() => handleChange(field, isVisible)}
              disableRipple
            >
              <Checkbox
                value={isVisible}
              />

              {headerName}
            </MenuItem>
          );
        })}
      </Menu>
    </Wrapper>
  );
});


ListColumnsSelector.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
  })).isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

ListColumnsSelector.defaultProps = {

};


export default ListColumnsSelector;

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

import { dataGridFiltersPropTypes } from 'models/data-grid-filters';

import DataGridFilter from './filter';
import { TYPES } from './constants';
import { Wrapper, FilterItemWrapper } from './styles';


const DataGridFilters = memo(({ config, values, onChange }) => {
  const handleChange = useCallback(({ key }, value) => {
    const nextValues = {
      ...values,
      [key]: value,
    };

    onChange(nextValues);
  }, [values, onChange]);

  return (
    <Wrapper
      as={Stack}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {config.map((filterConfig) => {
        const { key } = filterConfig;

        return (
          <FilterItemWrapper
            key={key}
          >
            <DataGridFilter
              onChange={handleChange}
              config={filterConfig}
              filterValue={values[key]}
            />
          </FilterItemWrapper>
        );
      })}
    </Wrapper>
  );
});


DataGridFilters.propTypes = {
  config: PropTypes.arrayOf(dataGridFiltersPropTypes).isRequired,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

DataGridFilters.defaultProps = {

};


DataGridFilters.TYPES = TYPES;


export default DataGridFilters;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal';
import HistoricalBidsList from 'components/historical-bids/list';
import Title from 'components/common/title';

import { StyledTitle, modalStyles } from './styles';


const HistoricalBidsModal = memo(({ isOpen, onCancel }) => (
  <Modal
    isOpen={isOpen}
    onCancel={onCancel}
    modalStyles={modalStyles}
  >
    <StyledTitle as={Title}>
      Push Historical Bids
    </StyledTitle>

    <HistoricalBidsList />
  </Modal>
));


HistoricalBidsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default HistoricalBidsModal;

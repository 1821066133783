import { styled } from '@mui/system';

import { inputBaseClasses } from '@mui/material/InputBase';
import { autocompleteClasses } from '@mui/material/Autocomplete';


export const Option = styled('div')(() => ({}));

export const Wrapper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: '4px !important',
  },

  [Option]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingRight: `${theme.spacing(2)} !important`,
    justifyContent: 'space-between',
    ...theme.typography.body2,
  },
}));

export const LinkText = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(1),
}));

export const StyledAutocomplete = styled('div')(() => ({
  [`.${inputBaseClasses.root}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: '9px !important',
  },
}));

export const IconWrapper = styled('div')(() => ({
  minWidth: 20,
}));

import { GAME_DETAILS_LIST } from 'core/games/constants';
import { withType, TYPES } from 'hooks/use-query-params';

import { FIELDS as CAMPAIGNS_LIST_FIELDS } from 'components/games/campaigns-list/constants';


export const QUERY_PARAMS = {
  TAB: 't',
  SELECTION_CHANNELS: 's_ch',
  SELECTION_CAMPAIGNS: 's_ca',
  SELECTION_GEOS: 's_ge',
  SELECTION_SOURCES: 's_so',
};

export const QUERY_PARAMS_BY_FIELDS = {
  [GAME_DETAILS_LIST.NETWORKS]: QUERY_PARAMS.SELECTION_CHANNELS,
  [GAME_DETAILS_LIST.CAMPAIGNS]: QUERY_PARAMS.SELECTION_CAMPAIGNS,
  [GAME_DETAILS_LIST.GEOS]: QUERY_PARAMS.SELECTION_GEOS,
  [GAME_DETAILS_LIST.SOURCES]: QUERY_PARAMS.SELECTION_SOURCES,
};

export const QUERY_PARAMS_WITH_TYPES = {
  [QUERY_PARAMS.TAB]: TYPES.NUMBER,
  [QUERY_PARAMS.SELECTION_CHANNELS]: withType(TYPES.ARRAY, TYPES.NUMBER),
  [QUERY_PARAMS.SELECTION_CAMPAIGNS]: withType(TYPES.ARRAY, TYPES.STRING),
  [QUERY_PARAMS.SELECTION_GEOS]: withType(TYPES.ARRAY, TYPES.STRING),
  [QUERY_PARAMS.SELECTION_SOURCES]: withType(TYPES.ARRAY, TYPES.STRING),
};


export const CAMPAIGNS_LIST_QUERY_PARAMS = {
  NAME: 'c_n',
  STATUS: 'c_s',
  TYPE: 'c_t',
};

export const CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS = {
  [CAMPAIGNS_LIST_FIELDS.NAME]: CAMPAIGNS_LIST_QUERY_PARAMS.NAME,
  [CAMPAIGNS_LIST_FIELDS.STATUS]: CAMPAIGNS_LIST_QUERY_PARAMS.STATUS,
  [CAMPAIGNS_LIST_FIELDS.TYPE]: CAMPAIGNS_LIST_QUERY_PARAMS.TYPE,
};

export const CAMPAIGNS_LIST_QUERY_PARAMS_WITH_TYPES = {
  [CAMPAIGNS_LIST_QUERY_PARAMS.NAME]: TYPES.STRING,
  [CAMPAIGNS_LIST_QUERY_PARAMS.STATUS]: withType(TYPES.ARRAY, TYPES.STRING),
  [CAMPAIGNS_LIST_QUERY_PARAMS.TYPE]: TYPES.STRING,
};

export const MERGED_QUERY_PARAMS_WITH_TYPES = {
  ...QUERY_PARAMS_WITH_TYPES,
  ...CAMPAIGNS_LIST_QUERY_PARAMS_WITH_TYPES,
};

import { useCallback } from 'react';
import { useFormik as useBaseFormik } from 'formik';


export default function useFormik (config) {
  const formik = useBaseFormik(config);

  const handleChangeAndTrimValue = useCallback((event) => {
    const { nativeEvent: { target } } = event;
    formik.setFieldValue(target.name, target.value.trim());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlurAndTrimValue = useCallback((event) => {
    handleChangeAndTrimValue(event);
    formik.handleBlur(event);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return {
    ...formik,
    handleChangeAndTrimValue,
    handleBlurAndTrimValue,
  };
}

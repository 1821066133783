import React, { memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usersActions from 'core/users/actions';
import { getActivityLog, getIsActivityLogFetching } from 'core/users/selectors';
import AnalyticsService from 'services/analytics';

import Loader from 'components/common/loader';
import ActivityLogsHeader from 'components/activity-logs/header';
import ActivityLogsList from 'components/activity-logs/list';

import { Wrapper, ListWrapper } from './styles';


const ActivityLog = memo(() => {
  const dispatch = useDispatch();

  const isFetching = useSelector(getIsActivityLogFetching);
  const activityLog = useSelector(getActivityLog);

  const handleFormSubmit = useCallback((formData) => {
    dispatch(usersActions.fetchActivityLog(formData));
  }, [dispatch]);

  useEffect(() => {
    dispatch(usersActions.fetchActivityLog());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.ACTIVITY_LOGS,
    });
  }, []);

  return (
    <Wrapper>
      {!activityLog ? (
        <Loader />
      ) : (
        <>
          <ActivityLogsHeader
            isFetching={isFetching}
            onSubmit={handleFormSubmit}
          />

          <ListWrapper>
            <ActivityLogsList
              data={activityLog}
              isFetching={isFetching}
            />
          </ListWrapper>
        </>
      )}
    </Wrapper>
  );
});


ActivityLog.propTypes = {

};

ActivityLog.defaultProps = {

};


export default ActivityLog;

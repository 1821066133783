import { createSelector } from 'reselect';


const getLocalState = (state) => state.networksV4;
const getIsNetworkUpdateFetchingState = (state) => getLocalState(state).isNetworkUpdateFetching;
const getIsNetworkAddFetchingState = (state) => getLocalState(state).isNetworkAddFetching;
const getIsNetworkDeteleFetchingState = (state) => getLocalState(state).isNetworkDeleteFetching;
const getIsNetworkAddMultipleFetchingState = (state) => getLocalState(state).isNetworkAddMultipleFetching;
const getIsNetworkUpdateMultipleFetchingState = (state) => getLocalState(state).isNetworkUpdateMultipleFetching;


export const getIsNetworkUpdateFetching = createSelector(
  getIsNetworkUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsNetworkAddFetching = createSelector(
  getIsNetworkAddFetchingState,
  (isFetching) => isFetching,
);

export const getIsNetworkDeleteFetching = createSelector(
  getIsNetworkDeteleFetchingState,
  (isFetching) => isFetching,
);

export const getIsNetworkAddMultipleFetching = createSelector(
  getIsNetworkAddMultipleFetchingState,
  (isFetching) => isFetching,
);

export const getIsNetworkUpdateMultipleFetching = createSelector(
  getIsNetworkUpdateMultipleFetchingState,
  (isFetching) => isFetching,
);

import { styled } from '@mui/system';


export const FieldWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center',
}));

export const StyledFilter = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  width: '100%',
}));

export const StyledButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
}));

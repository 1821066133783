import { getUTCTimestamp } from 'utils/date';


export const SETTINGS_URL = 'settings';

export const EMAIL_STATUS_KEYS = {
  ACTIVE: 'confirmed',
  INVITED: 'unconfirmed',
};

export const EMAIL_STATUSES = [
  {
    id: EMAIL_STATUS_KEYS.ACTIVE,
    name: 'Active',
  },
  {
    id: EMAIL_STATUS_KEYS.INVITED,
    name: 'Invited',
  },
];

export const INDICATOR_COLORS = {
  [EMAIL_STATUS_KEYS.ACTIVE]: '#73BC24',
  [EMAIL_STATUS_KEYS.INVITED]: '#C6C6C6',
};

export const APP_STATUSES = {
  READY: 'READY',
  PULL_COMPLETE: 'PULL_COMPLETE',
  CALC_STARTED: 'CALC_STARTED',
  PUSH_STARTED: 'PUSH_STARTED',
  PULLING_BIDS: 'PULLING_BIDS',
};

export const APP_STATUSES_COLORS = {
  [APP_STATUSES.READY]: '#73BC24',
  [APP_STATUSES.PULL_COMPLETE]: '#73BC24',
  [APP_STATUSES.CALC_STARTED]: '#FDBF00',
  [APP_STATUSES.PUSH_STARTED]: '#FDBF00',
  [APP_STATUSES.PULLING_BIDS]: '#FDBF00',
};

const DEFAULT_LAST_PUSH_TIME = {
  HOURS: 19,
  MINUTES: 30,
  SECONDS: 2,
};

export const DEFAULT_LAST_PUSH_TIME_TIMESTAMP = getUTCTimestamp({
  hours: DEFAULT_LAST_PUSH_TIME.HOURS,
  minutes: DEFAULT_LAST_PUSH_TIME.MINUTES,
  seconds: DEFAULT_LAST_PUSH_TIME.SECONDS,
});

import React, { memo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingsActions from 'core/settings/actions';
import { getConfig, getIsConfigFetching, getIsConfigUpdateFetching } from 'core/settings/selectors';
import usersActions from 'core/users/actions';
import AnalyticsService from 'services/analytics';

import { withParams } from 'hoc/with-params';

import Loader from 'components/common/loader';
import SettingsForm from 'components/settings/settings-form';
import UsersList from 'components/settings/users-list';

import { Wrapper, ListWrapper } from './styles';


const Settings = memo(() => {
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const isConfigFetching = useSelector(getIsConfigFetching);
  const isConfigUpdateFetching = useSelector(getIsConfigUpdateFetching);

  const handleFormSubmit = useCallback((data) => {
    dispatch(settingsActions.updateConfig({ data }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(settingsActions.fetchConfig());
    dispatch(usersActions.fetchUsers());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.SETTINGS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      {isConfigFetching || !config ? (
        <Loader />
      ) : (
        <>
          <SettingsForm
            data={config}
            onSubmit={handleFormSubmit}
            isUpdateFetching={isConfigUpdateFetching}
          />

          <ListWrapper>
            <UsersList />
          </ListWrapper>
        </>
      )}
    </Wrapper>
  );
});


Settings.propTypes = {

};

Settings.defaultProps = {

};


export default withParams(Settings);

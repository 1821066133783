import LionstudiosApi from 'services/lionstudios-api';

import { signInModel, recoverPasswordModel, resetPasswordModel } from './models';
import { AUTH_URL, RECOVER_PASSWORD_URL, RESET_PASSWORD_URL } from './constants';


const AuthService = {
  signIn: async (data) => {
    const response = await LionstudiosApi.post(`${AUTH_URL}/login`, data);

    return signInModel(response.data);
  },

  recoverPassword: async (data) => {
    const body = recoverPasswordModel(data);
    await LionstudiosApi.post(RECOVER_PASSWORD_URL, body);

    return true;
  },

  resetPassword: async (data) => {
    const body = resetPasswordModel(data);
    await LionstudiosApi.post(RESET_PASSWORD_URL, body);

    return true;
  },
};


export default AuthService;

import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getIsGameCreateFetching, getIsGameUpdateFetching } from 'core/games/selectors';
import { CHANNELS_OPTIONS, CAMPAIGN_TYPES_OPTIONS, CHANNELS_CAMPAIGN_TYPES } from 'core/games/constants';
import settingActions from 'core/settings/actions';

import useFormik from 'hooks/use-formik';

import Title from 'components/common/title';
import Input from 'components/common/input';
import Select from 'components/common/uncontrolled-select';
import Paper from 'components/common/paper';
import Button from 'components/common/button';

import { getDifference } from 'utils';

import { KEYS } from './constants';
import { getValidationSchema } from './validation';
import { getInitialValues } from './utils';
import {
  FieldWrapper,
  StyledButton,
  Wrapper,
  TitleWrapper,
} from './styles';


const PushFrequencyForm = memo(({ initialValues, isEdit, onCancel }) => {
  const dispatch = useDispatch();
  const isCreateFetching = useSelector(getIsGameCreateFetching);
  const isUpdateFetching = useSelector(getIsGameUpdateFetching);
  const [campaignTypesOptions, setCampaignTypesOptions] = useState([]);

  const handleFormSubmit = useCallback((formData) => {
    const transformedInitialValues = getInitialValues(initialValues);
    const dataToSubmit = getDifference(formData, transformedInitialValues);

    dispatch(settingActions.createPushFrequency({
      ...dataToSubmit,
    }));
    onCancel();
  }, [isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isValid,
    touched,
    handleBlurAndTrimValue,
  } = useFormik({
    initialValues: getInitialValues(initialValues),
    onSubmit: handleFormSubmit,
    validationSchema: getValidationSchema(),
  });

  useEffect(() => {
    const { channel } = values;

    if (channel.length) {
      values.campaignType = '';

      const specificCampaignType = CAMPAIGN_TYPES_OPTIONS.filter((item) => CHANNELS_CAMPAIGN_TYPES[channel].includes(item.id));
      setCampaignTypesOptions(specificCampaignType);
    }
  }, [values.channel]);

  return (

    <Wrapper as={Paper}>
      <TitleWrapper>
        <Title>
          Add Push Frequency Limit
        </Title>
      </TitleWrapper>

      <form onSubmit={handleSubmit}>

        <FieldWrapper>
          <Select
            name={KEYS.CHANNEL_NAME}
            value={values[KEYS.CHANNEL_NAME]}
            options={CHANNELS_OPTIONS}
            onChange={handleChange}
            label="Channel Name"
            error={touched[KEYS.CHANNEL_NAME] && errors[KEYS.CHANNEL_NAME]}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Select
            name={KEYS.CAMPAIGN_TYPE}
            value={values[KEYS.CAMPAIGN_TYPE]}
            options={campaignTypesOptions}
            error={touched[KEYS.CAMPAIGN_TYPE] && errors[KEYS.CAMPAIGN_TYPE]}
            onChange={handleChange}
            label="Campaign Type"
          />
        </FieldWrapper>

        <FieldWrapper>
          <Input
            name={KEYS.COOLDOWN_LIMIT}
            value={values[KEYS.COOLDOWN_LIMIT]}
            onBlur={handleBlurAndTrimValue}
            onChange={handleChange}
            error={touched[KEYS.COOLDOWN_LIMIT] && errors[KEYS.COOLDOWN_LIMIT]}
            label="Cooldown Limit"
            type="number"
          />
        </FieldWrapper>


        <FieldWrapper>
          <StyledButton
            as={Button}
            type="submit"
            disabled={!isValid}
            isFetching={isEdit ? isUpdateFetching : isCreateFetching}
          >
            {isEdit ? 'Save' : 'Submit'}
          </StyledButton>
        </FieldWrapper>

      </form>

    </Wrapper>

  );
});


PushFrequencyForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onCancel: PropTypes.func.isRequired,
};

PushFrequencyForm.defaultProps = {
  isEdit: false,
  initialValues: null,
};


export default PushFrequencyForm;

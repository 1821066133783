import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'core/reducer';
import rootSagas from 'core/sagas';
import { rootPersistConfig } from 'core/persist';
import appActions from 'core/app/actions';
import { setResponseHandler } from 'services/lionstudios-api';


const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  sagaMiddleware,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore () {
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
  const persistor = persistStore(store);

  // NOTE: uncomment the line below to reset the app's cache
  // persistor.purge();

  setResponseHandler((response) => {
    store.dispatch(appActions.handleHttpError({ response }));
  });

  rootSagas.map(sagaMiddleware.run);

  return {
    store,
    persistor,
  };
}

import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';

import usersActions from 'core/users/actions';
import {
  getUsers,
  getIsUsersFetching,
  getIsUserUpdateFetching,
  getIsUserDeleteFetching,
} from 'core/users/selectors';

import { getFilteredList } from 'utils/grid';

import { ROUTES } from 'containers/router/constants';

import DataGrid from 'components/common/data-grid';
import ConfirmationModal from 'components/modals/confirmation';
import Button from 'components/common/button';

import { ACTIVE_FILTERS } from './constants';
import { getConfig } from './config';
import {
  ListHeader,
  StyledIcon,
  StyledTitle,
} from './styles';


const UsersList = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector(getUsers);
  const isUsersFetching = useSelector(getIsUsersFetching);
  const isUserUpdateFetching = useSelector(getIsUserUpdateFetching);
  const isUserDeleteFetching = useSelector(getIsUserDeleteFetching);

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [deletableUserId, setDeletableUserId] = useState(null);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState({});

  const handleRowDelete = useCallback((networkId) => {
    setIsDeleteConfirmationModalOpen(true);
    setDeletableUserId(networkId);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    dispatch(usersActions.deleteUser({
      id: deletableUserId,
    }));
    setLastEditedRow(deletableUserId);
    setDeletableUserId(null);
  }, [users, deletableUserId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleRowUpdate = useCallback((nextData) => {
    const { id } = nextData;

    dispatch(usersActions.updateUser({
      id,
      data: nextData,
    }));
    setLastEditedRow(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleActivityLogClick = useCallback(() => {
    navigate(`/${ROUTES.ACTIVITY_LOG}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const config = useMemo(() => getConfig(
    list,
    handleRowUpdate,
    handleRowDelete,
  ), [list, handleRowUpdate, handleRowDelete]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isUserUpdateFetching || isUserDeleteFetching) && id === lastEditedRow
  ), [isUserUpdateFetching, isUserDeleteFetching, lastEditedRow]);

  const getIsRowSelectable = useCallback(({ row: { isEmpty } }) => !isEmpty, []);

  const deletableUserEmail = useMemo(() => deletableUserId ? users.find(({ id }) => id === deletableUserId).emailAddress : null, [users, deletableUserId]);

  useEffect(() => {
    setList(getFilteredList(users, listFilter));
  }, [users, listFilter]);

  return (
    <>
      <ListHeader
        as={Stack}
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Stack
          flexDirection="row"
          alignItems="center"
        >
          <StyledIcon as={PersonIcon} />

          <StyledTitle>Users</StyledTitle>
        </Stack>

        <Button
          onClick={handleActivityLogClick}
          startIcon={<TimerOutlinedIcon />}
          variant="outlined"
        >
          Activity Log
        </Button>
      </ListHeader>

      <DataGrid
        config={config}
        getIsRowFetching={getIsRowFetching}
        isRowSelectable={getIsRowSelectable}
        isFetching={isUsersFetching}
        checkboxSelection={false}
        onFilterChange={setListFilter}
        activeFilters={ACTIVE_FILTERS}
        useFilters
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Delete Confirmation"
        description={`Please, confirm that you want to delete this User (${deletableUserEmail})`}
      />
    </>
  );
});


UsersList.propTypes = {

};

UsersList.defaultProps = {

};


export default UsersList;

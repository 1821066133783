import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from 'components/common/button';

import Modal from 'components/common/modal';

import { Title, Description } from './styles';


const ConfirmationModal = memo(({
  title,
  description,
  confirmationText,
  cancelationText,
  onCancel,
  onConfirm,
  isOpen,
  onClose,
}) => (
  <Modal
    isOpen={isOpen}
    onCancel={onClose || onCancel}
    useCloseButton={false}
  >
    <Box
      mb={3}
    >
      <Title>
        {title}
      </Title>
    </Box>

    <Box
      mb={2}
    >
      <Description>{description}</Description>
    </Box>


    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={2}
    >
      <Button
        onClick={onConfirm}
      >
        {confirmationText}
      </Button>

      <Button
        onClick={onCancel}
        variant="outlined"
        color="error"
      >
        {cancelationText}
      </Button>
    </Stack>
  </Modal>
));


ConfirmationModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  confirmationText: PropTypes.string,
  cancelationText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  title: null,
  description: null,
  confirmationText: 'Confirm',
  cancelationText: 'Cancel',
  onCancel: null,
  onClose: null,
};


export default ConfirmationModal;

import { styled } from '@mui/system';


export const StyledIndicator = styled('div')(({ theme, $status, $colors }) => ({
  width: 8,
  height: 8,
  minWidth: 8,
  minHeight: 8,
  borderRadius: 8,
  marginLeft: theme.spacing(1),
  backgroundColor: $colors[$status] || 'transparent',
  position: 'absolute',
  left: 0,
}));

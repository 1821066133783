import * as yup from 'yup';
import YupPassword from 'yup-password';

import { FORM_KEYS } from './constants';


YupPassword(yup);

const REQUIRED_TEXT = 'Required field';
const EMAIL_TEXT = 'Invalid email';
const PASSWORD_MIN_LENGTH = 4;
const PASSWORDS_HELPER_TEXT = 'Passwords must contain at least';
const PASSWORDS_LENGTH_TEXT = `${PASSWORDS_HELPER_TEXT} ${PASSWORD_MIN_LENGTH} characters`;
const PASSWORDS_MATCH_TEXT = 'Passwords doesn\'t match';
const PASSWORD_MIN_SPECIAL_CHARACTER = 1;
const PASSWORD_MIN_LOWERCASE_TEXT = `${PASSWORDS_HELPER_TEXT} ${PASSWORD_MIN_SPECIAL_CHARACTER} lowercase letter`;
const PASSWORD_MIN_UPPERCASE_TEXT = `${PASSWORDS_HELPER_TEXT} ${PASSWORD_MIN_SPECIAL_CHARACTER} uppercase letter`;
const PASSWORD_MIN_NUMBERS_TEXT = `${PASSWORDS_HELPER_TEXT} ${PASSWORD_MIN_SPECIAL_CHARACTER} number`;
const PASSWORD_MIN_SYMBOLS_TEXT = `${PASSWORDS_HELPER_TEXT} ${PASSWORD_MIN_SPECIAL_CHARACTER} symbol`;

const EMAIL = yup
  .string()
  .email(EMAIL_TEXT)
  .required(REQUIRED_TEXT);

const PASSWORD = yup
  .string()
  .min(PASSWORD_MIN_LENGTH, PASSWORDS_LENGTH_TEXT)
  .required(REQUIRED_TEXT);

const EXTENDED_PASSWORD = PASSWORD
  .password()
  .min(PASSWORD_MIN_LENGTH, PASSWORDS_LENGTH_TEXT)
  .minLowercase(PASSWORD_MIN_SPECIAL_CHARACTER, PASSWORD_MIN_LOWERCASE_TEXT)
  .minUppercase(PASSWORD_MIN_SPECIAL_CHARACTER, PASSWORD_MIN_UPPERCASE_TEXT)
  .minNumbers(PASSWORD_MIN_SPECIAL_CHARACTER, PASSWORD_MIN_NUMBERS_TEXT)
  .minSymbols(PASSWORD_MIN_SPECIAL_CHARACTER, PASSWORD_MIN_SYMBOLS_TEXT);

const CONFIRM_PASSWORD = EXTENDED_PASSWORD
  .min(0)
  .when(FORM_KEYS.PASSWORD, {
    is: (value) => Boolean(value && value.length > 0),
    then: yup
      .string()
      .oneOf(
        [yup.ref(FORM_KEYS.PASSWORD)],
        PASSWORDS_MATCH_TEXT,
      ),
  });

const AGREEMENT = yup
  .boolean()
  .oneOf([true], REQUIRED_TEXT);

export const SignInValidationSchema = yup.object().shape({
  [FORM_KEYS.EMAIL]: EMAIL,
  [FORM_KEYS.PASSWORD]: PASSWORD,
});

export const RecoverPasswordValidationSchema = yup.object().shape({
  [FORM_KEYS.EMAIL]: EMAIL,
});

export const ResetPasswordValidationSchema = yup.object().shape({
  [FORM_KEYS.PASSWORD]: EXTENDED_PASSWORD,
  [FORM_KEYS.CONFIRM_PASSWORD]: CONFIRM_PASSWORD,
});

export const SignUpValidationSchema = yup.object().shape({
  [FORM_KEYS.EMAIL]: EMAIL,
  [FORM_KEYS.PASSWORD]: EXTENDED_PASSWORD,
  [FORM_KEYS.CONFIRM_PASSWORD]: CONFIRM_PASSWORD,
  [FORM_KEYS.AGREEMENT]: AGREEMENT,
});

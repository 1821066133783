import { createActions } from 'redux-actions';
import { createConstants } from 'utils';


export const PREFIX = 'V4_CAMPAIGNS';

export const types = createConstants([
  'FETCH_LIST',
  'UPDATE_CAMPAIGN',
], PREFIX);

export const actions = createActions({
  [types.FETCH_LIST]: (payload) => payload,
  [types.UPDATE_CAMPAIGN]: [
    (payload) => (payload),
    (payload, { refetchCampaigns = true, refetchCampaignsPayload = null } = {}) => ({ refetchCampaigns, refetchCampaignsPayload }),
  ],
}, {
  prefix: PREFIX,
});


export default actions;

import React, { memo } from 'react';

import Link from '@mui/material/Link';


const NotionLink = memo(() => (
  <Link
    href="https://www.notion.so/lionstudios/2617f59e9c594368a8f5180bf92545a1"
    target="_blank"
  >
    Click to leave a comment in Notion
  </Link>

));


export default NotionLink;

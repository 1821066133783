import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import gamesActions from 'core/games/actions';
import geosActions from 'core/v4/geos/actions';
import { getIsGoalRoasUpdateFetching, getIsGoalRoasResetFetching } from 'core/games/selectors';
import { getGeosList, getGeosListTotal, getIsListFetching } from 'core/v4/geos/selectors';

import { GAME_DETAILS_LIST, GOAL_ROAS_PATHS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';
import useIsDataInited from 'hooks/use-is-data-inited';

import DataGrid from 'components/common/data-grid';
import { getQueryParamsByFilter } from 'utils/grid';

import { MERGED_QUERY_PARAMS_WITH_TYPES } from 'pages/v4/games/list/constants';
import { useQueryParams } from 'hooks/use-query-params';
import { StyledDataGrid } from './styles';
import { getConfig } from './config';
import { DEFAULT_FILTERS_VALUES, FILTERS_KEYS, ACTIVE_FILTERS, GEOS_LIST_QUERY_PARAMS_BY_FIELDS } from './constants';
import { getFiltersByQueryParams, getFiltersValueForSubmit, getSortingByQueryParams } from './utils';


const GameGeosList = memo(({ onSelectionChange, selection, type, selected, isAppStatusReady }) => {
  const dispatch = useDispatch();
  const list = useSelector(getGeosList);
  const total = useSelector(getGeosListTotal);

  const isDataInited = useIsDataInited(list);
  const isFetching = useSelector(getIsListFetching);
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);
  const isGoalRoasResetFetching = useSelector(getIsGoalRoasResetFetching);

  // TODO: v4 changes
  const gameId = 0;
  const isGameArchived = false;

  const [queryParams, setQueryParams] = useQueryParams(MERGED_QUERY_PARAMS_WITH_TYPES);

  const selectedGameIds = useMemo(() => selected[GAME_DETAILS_LIST.NETWORKS], [selected]);

  const selectedFilter = useMemo(() => {
    if (selected[GAME_DETAILS_LIST.CAMPAIGNS].length) {
      return { [FILTERS_KEYS.CAMPAIGN_IDS]: selected[GAME_DETAILS_LIST.CAMPAIGNS] };
    }
    return { [FILTERS_KEYS.GAME_IDS]: selected[GAME_DETAILS_LIST.NETWORKS] };
  }, [selected]);

  const [filtersState, setFiltersState] = useState(() => ({
    ...DEFAULT_FILTERS_VALUES,
    ...getFiltersByQueryParams(queryParams),
  }));
  const sorting = getSortingByQueryParams(queryParams);

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [listFilter, setListFilter] = useState({});

  // const data = useMemo(() => list, [list]);

  const handleListFilterChange = useCallback((filter) => {
    setListFilter(filter);

    setQueryParams(
      getQueryParamsByFilter(filter, queryParams, GEOS_LIST_QUERY_PARAMS_BY_FIELDS),
      { replace: true },
    );
  }, [queryParams, setQueryParams]);

  const handleRowUpdate = useCallback((nextRow) => {
    console.log(nextRow); // eslint-disable-line no-console

    // TODO: call API here
  }, []);


  const handleGoalRoasUpdate = useCallback((nextData) => {
    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.GEO,
    }));

    setLastEditedRow(nextData.id);
  }, [dispatch, gameId]);

  const handleGoalRoasReset = useCallback((nextData, { comment, initialValue }) => {
    setLastEditedRow(nextData.id);
    dispatch(gamesActions.resetOverrideGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.GEO,
      comment,
      initialValue,
    }));
  }, [dispatch, gameId]);

  const config = useMemo(() => getConfig(
    list,
    handleRowUpdate,
    handleGoalRoasUpdate,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ), [
    list,
    handleRowUpdate,
    handleGoalRoasUpdate,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ]);

  // const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);
  const handleSelectionChange = useCallback((ids) => {
    if (isDataInited) {
      onSelectionChange(ids, type);
    }
  }, [onSelectionChange, type, isDataInited]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isGoalRoasUpdateFetching || isGoalRoasResetFetching) && id === lastEditedRow
  ), [isGoalRoasUpdateFetching, isGoalRoasResetFetching, lastEditedRow]);

  useEffect(() => {
    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.GEOS,
    });
  }, []);

  useEffect(() => {
    dispatch(geosActions.fetchList({
      ...filtersState,
      ...selectedFilter,
      ...getFiltersValueForSubmit(listFilter, sorting),
      [FILTERS_KEYS.GAME_IDS]: selectedGameIds,
    }));
  }, [filtersState, selectedFilter, listFilter, selectedGameIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaginationStateChange = useCallback((key, value) => {
    if (value === filtersState[key]) {
      return;
    }

    setFiltersState({
      ...filtersState,
      [key]: value,
    });
  }, [filtersState]);

  const handlePageChange = useCallback((value) => {
    handlePaginationStateChange(FILTERS_KEYS.PAGE, value);
  }, [handlePaginationStateChange]);

  const handlePageSizeChange = useCallback((value) => {
    handlePaginationStateChange(FILTERS_KEYS.LIMIT, value);
  }, [handlePaginationStateChange]);

  return (
    <StyledDataGrid
      as={DataGrid}
      config={config}
      onSelectionModelChange={handleSelectionChange}
      selectionModel={selection}
      getIsRowFetching={getIsRowFetching}
      isFetching={isFetching}
      isServerPagination
      rowCount={total}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      filter={listFilter}
      onFilterChange={handleListFilterChange}
      useFilters
      activeFilters={ACTIVE_FILTERS}
      checkboxSelection={false}
    />
  );
});


GameGeosList.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    networks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.string).isRequired,
    geos: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
};

GameGeosList.defaultProps = {
  // list: [],
};


export default GameGeosList;

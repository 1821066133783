import React, { memo, createContext, forwardRef, useContext } from 'react';


export const OuterElementContext = createContext({});

const OuterElement = memo(forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
    />
  );
}));


export default OuterElement;

import { put, call, takeLatest } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from 'utils/actions';


import { waitForUserSession } from 'core/app/sagas';

import GeosService from './service';
import { types as geosTypes } from './actions';


/*
 * Sagas
 */

function* fetchList ({ payload }) {
  try {
    yield call(waitForUserSession);

    const { geos, total } = yield call(GeosService.fetchList, payload);

    yield put(resolvedAction(geosTypes.FETCH_LIST, { geos, total }));
  } catch (error) {
    yield put(rejectedAction(geosTypes.FETCH_LIST, { message: error.message }));
  }
}


/*
 * Watchers
 */

function* fetchListWatcher () {
  yield takeLatest(geosTypes.FETCH_LIST, fetchList);
}

export default [
  fetchListWatcher,
];

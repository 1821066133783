import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import { types } from './actions';
import persistConfig from './persist';


const defaultState = {
  config: null,
  appStatus: null,
  countries: [],
  isCountriesFetching: false,
  isConfigFetching: false,
  isConfigUpdateFetching: false,
  isAppStatusFetching: false,
  isPushFrequenciesFetching: false,
  pushFrequencies: null,
  isPushFrequencyCreateFetching: false,
  isPushFrequencyDeleteFetching: false,
  isPushFrequencyUpdateFetching: false,
};

const reducer = handleActions({
  [types.FETCH_CONFIG]: (state) => (
    update(state, {
      isConfigFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_CONFIG)]: (state, { payload: { data } }) => (
    update(state, {
      config: { $set: data },
      isConfigFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_CONFIG)]: (state) => (
    update(state, {
      isConfigFetching: { $set: false },
    })
  ),

  [types.UPDATE_CONFIG]: (state) => (
    update(state, {
      isConfigUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_CONFIG)]: (state, { payload: { data } }) => (
    update(state, {
      config: { $set: data },
      isConfigUpdateFetching: { $set: false },
    })
  ),
  [rejected(types.UPDATE_CONFIG)]: (state) => (
    update(state, {
      isConfigUpdateFetching: { $set: false },
    })
  ),

  [types.FETCH_APP_STATUS]: (state) => (
    update(state, {
      isAppStatusFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_APP_STATUS)]: (state, { payload: { data } }) => (
    update(state, {
      isAppStatusFetching: { $set: false },
      appStatus: { $set: data },
    })
  ),
  [rejected(types.FETCH_APP_STATUS)]: (state) => (
    update(state, {
      isAppStatusFetching: { $set: false },
    })
  ),

  [types.FETCH_COUNTRIES]: (state) => (
    update(state, {
      isCountriesFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_COUNTRIES)]: (state, { payload: { data } }) => (
    update(state, {
      isCountriesFetching: { $set: false },
      countries: { $set: data },
    })
  ),
  [rejected(types.FETCH_COUNTRIES)]: (state) => (
    update(state, {
      isCountriesFetching: { $set: false },
    })
  ),

  [types.FETCH_PUSH_FREQUENCIES]: (state) => (
    update(state, {
      isPushFrequenciesFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_PUSH_FREQUENCIES)]: (state, { payload: { data } }) => (
    update(state, {
      isPushFrequenciesFetching: { $set: false },
      pushFrequencies: { $set: data },
    })
  ),
  [rejected(types.FETCH_PUSH_FREQUENCIES)]: (state) => (
    update(state, {
      isPushFrequenciesFetching: { $set: false },
    })
  ),

  [types.CREATE_PUSH_FREQUENCY]: (state) => (
    update(state, {
      isPushFrequencyCreateFetching: { $set: true },
    })
  ),
  [resolved(types.CREATE_PUSH_FREQUENCY)]: (state) => (
    update(state, {
      isPushFrequencyCreateFetching: { $set: false },
    })
  ),
  [rejected(types.CREATE_PUSH_FREQUENCY)]: (state) => (
    update(state, {
      isPushFrequencyCreateFetching: { $set: false },
    })
  ),

  [types.DELETE_PUSH_FREQUENCY]: (state) => (
    update(state, {
      isPushFrequencyDeleteFetching: { $set: true },
    })
  ),
  [resolved(types.DELETE_PUSH_FREQUENCY)]: (state) => (
    update(state, {
      isPushFrequencyDeleteFetching: { $set: false },
    })
  ),
  [rejected(types.DELETE_PUSH_FREQUENCY)]: (state) => (
    update(state, {
      isPushFrequencyDeleteFetching: { $set: false },
    })
  ),

  [types.UPDATE_PUSH_FREQUENCY]: (state) => (
    update(state, {
      isPushFrequencyUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_PUSH_FREQUENCY)]: (state) => (
    update(state, {
      isPushFrequencyUpdateFetching: { $set: false },
    })
  ),
  [rejected(types.UPDATE_PUSH_FREQUENCY)]: (state) => (
    update(state, {
      isPushFrequencyUpdateFetching: { $set: false },
    })
  ),

  [types.FETCH_CHANGE_LIMIT]: (state) => (
    update(state, {
      isChangeLimitFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_CHANGE_LIMIT)]: (state, { payload: { data } }) => (
    update(state, {
      isChangeLimitFetching: { $set: false },
      changeLimit: { $set: data },
    })
  ),
  [rejected(types.FETCH_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitFetching: { $set: false },
    })
  ),

  [types.CREATE_CHANGE_LIMIT]: (state) => (
    update(state, {
      isChangeLimitCreateFetching: { $set: true },
    })
  ),
  [resolved(types.CREATE_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitCreateFetching: { $set: false },
    })
  ),
  [rejected(types.CREATE_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitCreateFetching: { $set: false },
    })
  ),

  [types.DELETE_CHANGE_LIMIT]: (state) => (
    update(state, {
      isChangeLimitDeleteFetching: { $set: true },
    })
  ),
  [resolved(types.DELETE_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitDeleteFetching: { $set: false },
    })
  ),
  [rejected(types.DELETE_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitDeleteFetching: { $set: false },
    })
  ),

  [types.UPDATE_CHANGE_LIMIT]: (state) => (
    update(state, {
      isChangeLimitUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitUpdateFetching: { $set: false },
    })
  ),
  [rejected(types.UPDATE_CHANGE_LIMIT)]: (state) => (
    update(state, {
      isChangeLimitUpdateFetching: { $set: false },
    })
  ),

  [authTypes.LOGOUT]: () => defaultState,
}, defaultState);


export default persistReducer(persistConfig, reducer);

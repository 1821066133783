import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Input from 'components/common/input';

import { StyledDatePickerInput } from './styles';


const DatePicker = memo(({ value, onChange, error, name, inputStyles, onInputBlur, placeholder, resetTimeOnSubmit, ...datePickerProps }) => {
  const renderInput = useCallback(({ inputProps, ...props }) => (
    <Input
      {...props}
      sx={inputStyles || StyledDatePickerInput}
      name={name}
      error={error}
      onBlur={onInputBlur}
      inputProps={{
        ...inputProps,
        placeholder,
      }}
    />
  ), [error, inputStyles, name, onInputBlur, placeholder]);

  const handleChange = useCallback((nextValue) => {
    const transformedValue = (resetTimeOnSubmit && nextValue?.set) ? nextValue.set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      millisecond: 0,
    }) : nextValue;

    onChange(transformedValue, name);
  }, [name, onChange, resetTimeOnSubmit]);

  return (
    <DesktopDatePicker
      renderInput={renderInput}
      value={value}
      onChange={handleChange}
      {...datePickerProps}
    />
  );
});


DatePicker.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  inputStyles: PropTypes.shape({}),
  onInputBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resetTimeOnSubmit: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: null,
  error: null,
  inputStyles: null,
  placeholder: null,
  resetTimeOnSubmit: false,
};


export default DatePicker;

import { GAME_DETAILS_LIST } from 'core/games/constants';
import { QUERY_PARAMS_BY_FIELDS } from './constants';


export const getGamesbyIds = (ids, games) => ids.map((id) => games.find((game) => game.id === id));

export const getDefaultSelected = (queryParams) => Object.values(GAME_DETAILS_LIST).reduce((res, value) => {
  res[value] = (queryParams && queryParams[QUERY_PARAMS_BY_FIELDS[value]]) || [];
  return res;
}, {});

export const KEYS = {
  MINIMUM_BID_THRESHOLD: 'minimumBidThreshold',
  TOTAL_INSTALLS: 'totalInstalls',
  ROAS_CHANGING_WARNING: 'roasChangingWarning',
  MINIMUM_DAILY_SOURCES_INSTALLS: 'minimumDailySourcesInstalls',
  BID_EXPIRES_DAYS: 'bidExpiresDays',
};


export const LABELS = {
  [KEYS.MINIMUM_BID_THRESHOLD]: 'Minimum Bid Threshold',
  [KEYS.TOTAL_INSTALLS]: 'Total Installs',
  [KEYS.ROAS_CHANGING_WARNING]: 'Roas Changing Warning',
  [KEYS.MINIMUM_DAILY_SOURCES_INSTALLS]: 'Total Sources Installs',
  [KEYS.BID_EXPIRES_DAYS]: 'Bid Expires Days',
};

import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingsActions from 'core/settings/actions';

import {
  getPushFrequencies,
  getIsPushFrequenciesFetching,
  getIsPushFrequencyDeleteFetching,
  getIsPushFrequencyUpdateFetching,
} from 'core/settings/selectors';

import { getFilteredList } from 'utils/grid';

import DataGrid from 'components/common/data-grid';
import ConfirmationModal from 'components/modals/confirmation';

import { ACTIVE_FILTERS } from './constants';
import { getConfig } from './config';


const PushFrequenciesList = memo(() => {
  const dispatch = useDispatch();

  const pushFrequencies = useSelector(getPushFrequencies);
  const isPushFrequenciesFetching = useSelector(getIsPushFrequenciesFetching);
  const isPushFrequencyDeleteFetching = useSelector(getIsPushFrequencyDeleteFetching);
  const isPushFrequencyUpdateFetching = useSelector(getIsPushFrequencyUpdateFetching);

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [deletablePushFrequencyId, setDeletablePushFrequencyId] = useState(null);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState({});

  const handleRowDelete = useCallback((pushFrequencyId) => {
    setIsDeleteConfirmationModalOpen(true);
    setDeletablePushFrequencyId(pushFrequencyId);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    dispatch(settingsActions.deletePushFrequency({
      id: deletablePushFrequencyId,
    }));

    setLastEditedRow(deletablePushFrequencyId);
    setDeletablePushFrequencyId(null);
  }, [pushFrequencies, deletablePushFrequencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleRowUpdate = useCallback((nextData) => {
    const { id } = nextData;
    dispatch(settingsActions.updatePushFrequency({
      id,
      data: { COOLDOWN_LIMIT: nextData.COOLDOWN_LIMIT },
    }));
    setLastEditedRow(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const config = useMemo(() => getConfig(
    list,
    handleRowUpdate,
    handleRowDelete,
  ), [list, handleRowUpdate, handleRowDelete]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isPushFrequencyUpdateFetching || isPushFrequencyDeleteFetching) && id === lastEditedRow
  ), [isPushFrequencyUpdateFetching, isPushFrequencyDeleteFetching, lastEditedRow]);

  const getIsRowSelectable = useCallback(({ row: { isEmpty } }) => !isEmpty, []);

  const deletableChannelId = useMemo(() => deletablePushFrequencyId ? pushFrequencies.find(({ id }) => id === deletablePushFrequencyId).id : null, [pushFrequencies, deletablePushFrequencyId]);
  useEffect(() => {
    setList(getFilteredList(pushFrequencies, listFilter));
  }, [pushFrequencies, listFilter]);

  return (
    <>
      <DataGrid
        config={config}
        getIsRowFetching={getIsRowFetching}
        isRowSelectable={getIsRowSelectable}
        isFetching={isPushFrequenciesFetching}
        checkboxSelection={false}
        onFilterChange={setListFilter}
        activeFilters={ACTIVE_FILTERS}
        useFilters
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Delete Confirmation"
        description={`Please, confirm that you want to delete this Push Frequency Setting (id: ${deletableChannelId})`}
      />
    </>
  );
});


PushFrequenciesList.propTypes = {

};

PushFrequenciesList.defaultProps = {

};


export default PushFrequenciesList;


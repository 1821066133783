import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const GridHeaderWithTooltip = memo(({ headerName, ...tooltipProps }) => (
  <Tooltip
    arrow
    disableFocusListener
    disableTouchListener
    placement="top"
    {...tooltipProps}
  >
    <Typography
      fontSize={12}
      fontWeight={600}
    >
      {headerName}
    </Typography>
  </Tooltip>
));


GridHeaderWithTooltip.propTypes = {
  headerName: PropTypes.string.isRequired,
};

export default GridHeaderWithTooltip;

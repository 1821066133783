import memoize from 'lodash.memoize';

import { GAME_SEARCH_FIELDS, GOAL_ROAS_PATHS, PLATFORMS_OPTIONS } from './constants';


export const getUniqueGamesList = memoize((games) => {
  if (!games) {
    return [];
  }

  const ids = {};

  return games.filter(({ appId }) => {
    if (ids[appId]) {
      return false;
    }

    ids[appId] = true;

    return true;
  });
});

export const getSearchedGamesList = memoize((value, games) => {
  if (!value) {
    return [];
  }

  const list = getUniqueGamesList(games);

  return list.filter((game) => {
    for (let i = 0; i <= GAME_SEARCH_FIELDS.length - 1; i++) {
      const key = GAME_SEARCH_FIELDS[i];
      const fieldValue = (String(game[key]) || '').toLowerCase(); // TODO: add type checking ?
      const filterValue = (value || '').toLowerCase();

      if (fieldValue.includes(filterValue)) {
        return true;
      }
    }

    return false;
  });
});

export const filterOverrideListByPath = (overrideList, path) => overrideList.filter(({ path: overridePath }) => {
  switch (path) {
    case GOAL_ROAS_PATHS.NETWORK:
      return true;
    case GOAL_ROAS_PATHS.CAMPAIGN:
      return overridePath !== GOAL_ROAS_PATHS.CAMPAIGN;
    case GOAL_ROAS_PATHS.GEO:
      return overridePath !== GOAL_ROAS_PATHS.CAMPAIGN && overridePath !== GOAL_ROAS_PATHS.GEO;
    default:
      return false;
  }
});

export const getPlatformNameById = (id) => PLATFORMS_OPTIONS.find((platform) => platform.id === id)?.name || null;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash.memoize';

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import Tabs from 'components/common/tabs';
import Button from 'components/common/button';

import { config } from './config';
import { ButtonWrapper, StyledButton } from './styles';


const GameDetailsTabs = memo(({
  gameId,
  lists,
  selected,
  onSelectionChange,
  onTabChange,
  initialTab,
  onActivityLogsClick,
  isGameArchived,
  isAppStatusReady,
  isMounted,
}) => {
  const tabsConfig = Object.entries(config)
    .filter((entries) => !entries[1].hidden) // TODO: remove when Sources tab will become active
    .map(([key, {
      title,
      icon: IconComponent,
      list: ListComponent,
      color,
    }]) => ({
      title,
      renderIcon: memoize((fillColor) => <IconComponent fill={fillColor} />),
      selectedCount: selected[key].length,
      key,
      onSelectionChange,
      color,
      content: (
        <>
          <ButtonWrapper>
            <StyledButton
              as={Button}
              onClick={onActivityLogsClick}
              startIcon={<TimerOutlinedIcon />}
              variant="outlined"
            >
              Activity Log
            </StyledButton>
          </ButtonWrapper>
          <ListComponent
            list={lists[key]}
            onSelectionChange={onSelectionChange}
            selection={selected[key]}
            gameId={gameId}
            type={key}
            isGameArchived={isGameArchived}
            isAppStatusReady={isAppStatusReady}
            isMounted={isMounted}
          />
        </>
      ),
    }));

  const validatedInitialTab = (initialTab < tabsConfig.length && initialTab >= 0) ? initialTab : 0;

  return (
    <Tabs
      config={tabsConfig}
      onTabChange={onTabChange}
      initialTab={validatedInitialTab}
    />
  );
});


GameDetailsTabs.propTypes = {
  gameId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lists: PropTypes.shape({
    networks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    geos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    networks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.string).isRequired,
    geos: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onTabChange: PropTypes.func,
  initialTab: PropTypes.number,
  onActivityLogsClick: PropTypes.func.isRequired,
  isGameArchived: PropTypes.bool.isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
  isMounted: PropTypes.bool.isRequired,
};

GameDetailsTabs.defaultProps = {
  onTabChange: () => {},
  initialTab: 0,
};


export default GameDetailsTabs;

import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';

import Stack from '@mui/material/Stack';

import Button from 'components/common/button';
import DatePicker from 'components/common/date-picker';
import PredefinedFilterButtons from 'components/activity-logs/predefined-filter-buttons';

import { Label } from 'components/common/input/styles';

import { KEYS, LABELS } from './constants';
import { ValidationSchema } from './validation';
import {
  FieldWrapper,
} from './styles';


const ActivityLogsFilterForm = memo(({ onSubmit, isFetching }) => {
  const handleFormSubmit = useCallback(({ from, to }) => {
    onSubmit({
      from: from?.ts || null,
      to: to?.ts || null,
    });
  }, [onSubmit]);

  const { handleSubmit, handleBlur, values, errors, touched, isValid, submitForm, setFieldValue, setValues, setFieldTouched } = useFormik({
    initialValues: {
      [KEYS.FROM]: null,
      [KEYS.TO]: null,
    },
    onSubmit: handleFormSubmit,
    validationSchema: ValidationSchema,
  });

  const handlePredefinedFilterClick = useCallback(({ from, to }) => {
    setValues({
      [KEYS.FROM]: DateTime.fromJSDate(from),
      [KEYS.TO]: DateTime.fromJSDate(to),
    });
  }, [setValues]);

  const handleDatePickerChange = useCallback((nextValue, name) => {
    setFieldTouched(nextValue);
    setFieldValue(name, nextValue);
  }, [setFieldTouched, setFieldValue]);

  const fields = useMemo(() => Object.entries(KEYS).map(([key, value]) => (
    <FieldWrapper key={key}>
      <DatePicker
        value={values[value]}
        onChange={handleDatePickerChange}
        error={touched[value] && errors[value]}
        onInputBlur={handleBlur}
        name={value}
        placeholder={LABELS[value]}
        inputFormat="LL/dd/yyyy"
        allowSameDateSelection
        clearable
        mask="__/__/____"
        disableFuture
        views={['day']}
        resetTimeOnSubmit={value === KEYS.FROM}
      />
    </FieldWrapper>
  )), [errors, handleBlur, touched, values, handleDatePickerChange]);

  return (
    <form onSubmit={handleSubmit}>
      <Label>Pick time period</Label>

      <Stack
        flexDirection="row"
      >
        {fields}

        <FieldWrapper>
          <Button
            onClick={submitForm}
            disabled={!isValid}
            isFetching={isFetching}
          >
            Search
          </Button>
        </FieldWrapper>
      </Stack>

      <PredefinedFilterButtons
        onClick={handlePredefinedFilterClick}
      />
    </form>
  );
});


ActivityLogsFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};


export default ActivityLogsFilterForm;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import bidsActions from 'core/bids/actions';
import { getIsHistoricalBidsFetching, getIsPushHistoricalBidsFetching, getHistoricalBidsList, getHistoricalBidsTotal } from 'core/bids/selectors';

import DataGrid from 'components/common/data-grid';

import HistoricalBidsHeader from 'components/historical-bids/header';

import { getConfig } from './config';
import { DEFAULT_FILTER } from './constants';
import { StyledDataGrid } from './styles';


const HistoricalBidsList = () => {
  const dispatch = useDispatch();
  const isHistoricalBidsFetching = useSelector(getIsHistoricalBidsFetching);
  const isPushHistoricalBidsFetching = useSelector(getIsPushHistoricalBidsFetching);
  const list = useSelector(getHistoricalBidsList);
  const total = useSelector(getHistoricalBidsTotal);

  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const handleFilterChange = useCallback((nextFilter) => {
    setFilter(nextFilter);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(bidsActions.pushHistoricalBids(filter));
  }, [filter, dispatch]);

  const config = useMemo(() => getConfig(
    isHistoricalBidsFetching ? [] : list,
  ), [isHistoricalBidsFetching, list]);

  useEffect(() => {
    dispatch(bidsActions.fetchHistoricalBids(filter));
  }, [dispatch, filter]);

  return (
    <div>
      <HistoricalBidsHeader
        isFetching={isHistoricalBidsFetching || isPushHistoricalBidsFetching}
        total={total}
        onFilterChange={handleFilterChange}
        filter={filter}
        onSubmit={handleSubmit}
      />

      <StyledDataGrid
        as={DataGrid}
        config={config}
        isFetching={isHistoricalBidsFetching}
        checkboxSelection={false}
        autoHeight={false}
      />
    </div>
  );
};


export default HistoricalBidsList;

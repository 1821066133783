import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal';

import GameForm from 'components/v4/games/game-form';


const GameModal = memo(({
  onCancel,
  isOpen,
  ...formProps
}) => (
  <Modal
    isOpen={isOpen}
    onCancel={onCancel}
    modalStyles={{ maxHeight: '100vh', overflowY: 'scroll' }}
  >
    <GameForm
      {...formProps}
      onCancel={onCancel}
    />
  </Modal>
));


GameModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

GameModal.defaultProps = {

};


export default GameModal;

import { createInstance } from '@datapunt/matomo-tracker-react';

import config from 'config';
import { PAGES, CATEGORIES, ACTIONS, NAMES } from './constants';


const instance = createInstance({
  urlBase: config.ANALYTICS_URL,
  siteId: config.ANALYTICS_SITE_ID,
});

instance.setUserId = (userId) => instance.pushInstruction('setUserId', userId);
instance.PAGES = PAGES;
instance.CATEGORIES = CATEGORIES;
instance.ACTIONS = ACTIONS;
instance.NAMES = NAMES;


export default instance;

import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getIsCampaignUpdateFetching } from 'core/campaigns/selectors';
import { getIsGoalRoasUpdateFetching, getGameById, getIsGoalRoasResetFetching } from 'core/games/selectors';
import campaignsActions from 'core/campaigns/actions';
import gamesActions from 'core/games/actions';

import { GOAL_ROAS_PATHS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import DataGrid from 'components/common/data-grid';

import { useQueryParams } from 'hooks/use-query-params';

import { isObjectEmpty } from 'utils';
import { getFilteredList, getQueryParamsByFilter, getFilterByQueryParams } from 'utils/grid';
import { getDateRangeFromDates } from 'utils/date';

import { MERGED_QUERY_PARAMS_WITH_TYPES, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS } from 'pages/games/details/constants';

import { ACTIVE_FILTERS, DEFAULT_FILTER } from './constants';
import { getConfig } from './config';
import { StyledDataGrid } from './styles';


const GameCampaignsList = memo(({ list, gameId, onSelectionChange, selection, type, isGameArchived, isAppStatusReady }) => {
  const dispatch = useDispatch();
  const isCampaignUpdateFetching = useSelector(getIsCampaignUpdateFetching);
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);
  const isGoalRoasResetFetching = useSelector(getIsGoalRoasResetFetching);
  const { syncRange: { start, end } } = useSelector(getGameById(gameId)); // TODO: rebuild to context at the parent component

  const [queryParams, setQueryParams] = useQueryParams(MERGED_QUERY_PARAMS_WITH_TYPES);

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [listFilter, setListFilter] = useState({});
  const [data, setData] = useState(() => getFilteredList(list, listFilter));

  const handleListFilterChange = useCallback((filter) => {
    setListFilter(filter);
    setQueryParams(
      getQueryParamsByFilter(filter, queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS),
      { replace: true },
    );
  }, [queryParams, setQueryParams]);

  const handleRowUpdate = useCallback((nextData) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CAMPAIGNS,
      name: AnalyticsService.NAMES.CHANGE_BIDDER_STATUS,
      action: AnalyticsService.ACTIONS.CHANGE,
    });

    setLastEditedRow(nextData.id);
    dispatch(campaignsActions.updateCampaign({
      ...nextData,
      gameId,
    }));
  }, [dispatch, gameId]);

  const handleGoalRoasUpdate = useCallback((nextData) => {
    setLastEditedRow(nextData.id);
    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.CAMPAIGN,
    }));
  }, [dispatch, gameId]);

  const handleGoalRoasReset = useCallback((nextData, { comment, initialValue }) => {
    setLastEditedRow(nextData.id);
    dispatch(gamesActions.resetOverrideGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.CAMPAIGN,
      comment,
      initialValue,
    }));
  }, [dispatch, gameId]);

  const handleRowLinkPress = useCallback((link) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CAMPAIGNS,
      name: AnalyticsService.NAMES.OPEN_CAMPAIGN_LINK,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    window.open(link, '_blank');
  }, []);

  const tooltipDateRange = useMemo(() => getDateRangeFromDates(start, end), [start, end]);

  const config = useMemo(() => getConfig(
    data,
    handleRowUpdate,
    handleRowLinkPress,
    handleGoalRoasUpdate,
    list,
    tooltipDateRange,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ), [
    data,
    handleRowUpdate,
    handleRowLinkPress,
    handleGoalRoasUpdate,
    list,
    tooltipDateRange,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ]);

  const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isCampaignUpdateFetching || isGoalRoasUpdateFetching || isGoalRoasResetFetching) && id === lastEditedRow
  ), [isCampaignUpdateFetching, isGoalRoasUpdateFetching, isGoalRoasResetFetching, lastEditedRow]);

  useEffect(() => {
    const queryFilters = getFilterByQueryParams(queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS);
    const isEmptyQueryFilters = isObjectEmpty(queryFilters);

    if (isEmptyQueryFilters) {
      setListFilter(DEFAULT_FILTER);
      setQueryParams(
        getQueryParamsByFilter(DEFAULT_FILTER, queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS),
        { replace: true },
      );
    } else {
      setListFilter(queryFilters);
    }

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.CAMPAIGNS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setData(getFilteredList(list, listFilter));
  }, [list, listFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledDataGrid
      as={DataGrid}
      config={config}
      onSelectionModelChange={handleSelectionChange}
      selectionModel={selection}
      getIsRowFetching={getIsRowFetching}
      filter={listFilter}
      onFilterChange={handleListFilterChange}
      useFilters
      activeFilters={ACTIVE_FILTERS}
    />
  );
});


GameCampaignsList.propTypes = {
  gameId: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  isGameArchived: PropTypes.bool.isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
};

GameCampaignsList.defaultProps = {
  list: [],
};


export default GameCampaignsList;

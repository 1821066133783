import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AdaptiveText from 'components/common/adaptive-text';

import { getPriceFormatter } from 'utils/formatters';

import { Wrapper } from './styles';


const formatter = getPriceFormatter();

const Budget = memo(({ daily, total }) => {
  if (!daily && !total) {
    return '-';
  }

  return (
    <Wrapper>
      {
        daily !== 0 && (
          <AdaptiveText
            disableTooltip
            variant="caption"
          >
            {`${formatter({ value: daily })} daily`}
          </AdaptiveText>
        )
      }

      {
        total !== 0 && (
          <AdaptiveText
            disableTooltip
            variant="caption"
          >
            {`${formatter({ value: total })} total`}
          </AdaptiveText>
        )
      }
    </Wrapper>
  );
});


Budget.propTypes = {
  total: PropTypes.number.isRequired,
  daily: PropTypes.number.isRequired,
};


export default Budget;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

import Title from 'components/common/title';
import Text from 'components/common/text';

import { Wrapper, FormWrapper, BackgroundWrapper, FormInner, StyledTitle, StyledDescription } from './styles';


const AuthLayout = memo(({ image, formImage, title, description, children }) => (
  <Wrapper
    as={Stack}
    direction="row"
    justifyContent="space-between"
    alignItems="stretch"
  >
    <FormWrapper
      as={Stack}
      justifyContent="center"
      alignItems="center"
      $formImage={formImage}
    >
      <FormInner>
        {
          title && (
            <StyledTitle as={Title}>
              {title}
            </StyledTitle>
          )
        }

        {
          description && (
            <StyledDescription as={Text}>
              {description}
            </StyledDescription>
          )
        }

        {children}
      </FormInner>
    </FormWrapper>

    <BackgroundWrapper>
      <img
        src={image}
        alt=""
      />
    </BackgroundWrapper>
  </Wrapper>
));


AuthLayout.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  formImage: PropTypes.string,
};

AuthLayout.defaultProps = {
  description: null,
  formImage: null,
  title: null,
};


export default AuthLayout;

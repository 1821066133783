export const getDateRangeFromDates = (start, end) => (
  `${new Date(start).toLocaleDateString([], { timeZone: 'UTC' })} - ${new Date(end).toLocaleDateString([], { timeZone: 'UTC' })}`
);

export const getUTCTimestamp = ({
  year = null,
  month = null,
  day = null,
  hours = null,
  minutes = null,
  seconds = null,
}) => {
  const dateNow = new Date();
  const utcYear = year !== null ? year : dateNow.getUTCFullYear();
  const utcMonth = month !== null ? month : dateNow.getUTCMonth();
  const utcDate = day !== null ? day : dateNow.getUTCDate();
  const utcHours = hours !== null ? hours : dateNow.getUTCHours();
  const utcMinutes = minutes !== null ? minutes : dateNow.getUTCMinutes();
  const utcSeconds = seconds !== null ? seconds : dateNow.getUTCSeconds();

  return Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds);
};

export const getUTCDateFromTimestamp = (timestamp, endOfTheDay = false) => {
  if (!timestamp) {
    return null;
  }

  const date = new Date(timestamp);
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), ...(endOfTheDay ? [23, 59, 59, 999] : []));
};

export const getDaysAgo = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDateStringFromTimestamp = (timestamp, separator = '/') => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return [
    year,
    month > 9 ? month : `0${month}`,
    day > 9 ? day : `0${day}`,
  ].join(separator);
};

import { styled } from '@mui/system';


export const Wrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 900,
  background: theme.palette.background.light,
  padding: theme.spacing(2, 3),
  boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  borderRadius: 12,

  '&:focus-visible': {
    outline: 'none',
  },
}));

export const CloseButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  zIndex: 5,
}));

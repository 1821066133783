import LionstudiosApi from 'services/lionstudios-api';

import { overrideRequestModel, bidsModel, bidsRequestModel, pushHistoricalBidsModel, fetchHistoricalBidsModel } from './models';
import { BIDS_URL, PUSH_BIDS_URL } from './constants';


const BidsService = {
  fetchList: async (params) => {
    const response = await LionstudiosApi.get(BIDS_URL, {
      params: bidsRequestModel(params),
    });

    return bidsModel(response.data);
  },

  override: async (data) => {
    const payload = overrideRequestModel(data);
    const response = await LionstudiosApi.post(`${BIDS_URL}/override`, payload);

    return response.data; // TODO: use model ?
  },

  fetchCsv: async () => {
    const response = await LionstudiosApi.post(`${BIDS_URL}/export`, {
      responseType: 'blob',
    });

    return new Blob([response.data]);
  },

  fetchHistoricalBids: async (payload) => {
    const params = fetchHistoricalBidsModel(payload);
    const response = await LionstudiosApi.get(`${BIDS_URL}/history`, {
      params,
    });

    return bidsModel(response.data);
  },

  pushHistoricalBids: async (payload) => {
    const body = pushHistoricalBidsModel(payload);
    await LionstudiosApi.post(PUSH_BIDS_URL, body);

    return true;
  },
};


export default BidsService;

import { styled } from '@mui/system';


export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

export const StyledButton = styled('div')(({ theme }) => ({
  '& + &': {
    marginLeft: theme.spacing(2),
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));



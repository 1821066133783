import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import { StyledText } from './styles';
import { SIZE, ELEMENT_MAPPING } from './constants';


const Text = memo(forwardRef(({ children, size, color, ...otherProps }, ref) => (
  <StyledText
    ref={ref}
    as={Typography}
    variant={size}
    variantMapping={ELEMENT_MAPPING}
    color={color}
    {...otherProps}
  >
    {children}
  </StyledText>
)));


Text.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(SIZE)),
  color: PropTypes.oneOf([
    'textPrimary.main',
    'textPrimary.light',
    'error',
  ]),
};

Text.defaultProps = {
  size: SIZE.MD,
  color: 'textPrimary.main',
  children: null,
};


Text.SIZE = SIZE;


export default Text;

import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'users',
  storage,
  blacklist: [
    'isFetching',
    'isUserAddFetching',
    'isApproveNewUserFetching',
    'isUsersFetching',
    'isUserUpdateFetching',
    'isUserDeleteFetching',
    'isActivityLogFetching',
  ],
};


export default persistConfig;

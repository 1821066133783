export const KEYS = {
  CHANNEL_NAME: 'channel',
  CAMPAIGN_TYPE: 'campaignType',
  LIMIT_TYPE: 'limitType',
  FILTER_LIST: 'filterList',
};

export const UPDATE_KEYS = {
  CHANNEL: 'channel',
  CAMPAIGN_TYPE: 'campaignType',
  LIMIT_TYPE: 'limitType',
  FILTER_LIST: 'filterList',
};

export const LABELS = {
  [KEYS.CHANNEL_NAME]: 'Channel Name',
  [KEYS.CAMPAIGN_TYPE]: 'Campaign Type',
  [KEYS.LIMIT_TYPE]: 'Limit Type',
  [KEYS.FILTER_LIST]: 'Filter List',
};


// test
export const CHANNEL_STATUS_KEYS = {
  LIVE: 'LIVE',
  PAUSED: 'PAUSED',
  PENDING: 'PENDING',
  READY: 'READY',
};

export const BIDDER_CAMPAIGN_STATUS_KEYS = {
  ENABLED: '1',
  DISABLED: '0',
};

export const EXECUTE_STATUS_KEYS = {
  READY: 'READY',
  PENDING: 'PENDING',
  SKIPPED: 'SKIPPED',
};

export const OPERATORS = {
  IS_LESS: '<',
  IS_LESS_OR_EQUAL: '<=',
  IS_GREATER: '>',
  IS_GREATER_OR_EQUAL: '>=',
  IS_EQUAL: '=',
  IS_NOT_EQUAL: '!=',
  IS_IN: 'in',
};

export const OPERATORS_LIST = [
  {
    id: OPERATORS.IS_GREATER_OR_EQUAL,
    name: 'is >=',
  },
  {
    id: OPERATORS.IS_GREATER,
    name: 'is >',
  },
  {
    id: OPERATORS.IS_LESS_OR_EQUAL,
    name: 'is <=',
  },
  {
    id: OPERATORS.IS_LESS,
    name: 'is <',
  },
  {
    id: OPERATORS.IS_EQUAL,
    name: 'is equal',
  },
  {
    id: OPERATORS.IS_NOT_EQUAL,
    name: 'is not equal',
  },
];

export const OPERATORS_VALUES = OPERATORS_LIST.map(({ id }) => id);

export const [DEFAULT_FILTER_OPERATOR] = OPERATORS_VALUES;


export const FILTERS_KEYS = {
  BID_OVERRIDE: 'BID_OVERRIDE',
};

export const COLUMN_WIDTH = {
  SM: 100,
  MD: 140,
  LG: 200,
};

export const DEFAULT_CONTROL_VALUE = {
  operator: null,
  value: null,
};

export const DEFAULT_FILTER_VALUE = {
  operator: DEFAULT_FILTER_OPERATOR,
  value: null,
};

export const QUERY_PARAMS = {
  [FILTERS_KEYS.BID_OVERRIDE]: 'b_ovrd',
};

export const EXTENDED_QUERY_PARAMS = {
  ...QUERY_PARAMS,
  [FILTERS_KEYS.SORT]: 'sort',
};


export const LIMIT_TYPE = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
};

export const LIMIT_TYPE_OPTIONS = [
  {
    id: LIMIT_TYPE.INCREASE,
    name: 'INCREASE',
  },
  {
    id: LIMIT_TYPE.DECREASE,
    name: 'DECREASE',
  },
];

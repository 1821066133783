import history from 'containers/router/history';


export const extractUrlFromHistoryLocation = () => {
  const { location: { pathname, search, state } = {} } = history;

  if (state?.pathname) {
    return `${state?.pathname || ''}${state?.search || ''}`;
  }

  return `${pathname || ''}${search || ''}`;
};

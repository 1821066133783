import { useEffect, useState } from 'react';


export default function useIsDataInited (...args) {
  const [isInited, setIsInited] = useState(false);

  useEffect(() => {
    const nextIsInited = args.every((data) => data && (data.length || Object.keys(data).length));

    if (nextIsInited && !isInited) {
      setIsInited(true);
    }
  }, [args, isInited]);

  return isInited;
}

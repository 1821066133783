import React, { memo, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import update from 'immutability-helper';

import { ROUTES } from 'containers/router/constants';
import { getIsGameFetching } from 'core/games/selectors';
import { getIsAppStatusReady } from 'core/settings/selectors';
import Button from 'components/common/button';

import { withParams } from 'hoc/with-params';


import { useQueryParams } from 'hooks/use-query-params';

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import HistoryIcon from '@mui/icons-material/History';

import Loader from 'components/common/loader';
import GamesTabs from 'components/v4/games/tabs';
import Icons from 'components/v4/common/icons';

import { QUERY_PARAMS, MERGED_QUERY_PARAMS_WITH_TYPES, QUERY_PARAMS_BY_FIELDS } from './constants';
import { TopButtonWrapper, StyledButton, Wrapper, TabsWrapper } from './styles';
import { getDefaultSelected } from './utils';


const ListWitTabs = memo(() => {
  const isFetching = useSelector(getIsGameFetching);
  const navigate = useNavigate();
  // const data = useSelector(getGameById(Number(gameId)));
  const isAppStatusReady = useSelector(getIsAppStatusReady);

  const [queryParams, setQueryParams] = useQueryParams(MERGED_QUERY_PARAMS_WITH_TYPES);

  // const isGameArchived = data?.status === GAME_STATUS_KEYS.ARCHIVED;

  const isMounted = useRef(false);
  const [initialTab, setInitialTab] = useState(0);
  const [selected, setSelected] = useState(() => getDefaultSelected(queryParams));

  const handleTabChange = useCallback((tab) => {
    setQueryParams({
      ...queryParams,
      [QUERY_PARAMS.TAB]: tab,
    });
  }, [queryParams, setQueryParams]);

  const handleSelectionChange = useCallback((ids, key) => {
    const queryParamKey = QUERY_PARAMS_BY_FIELDS[key];

    setSelected((prevSelected) => update(prevSelected, {
      [key]: { $set: ids },
    }));

    setQueryParams(
      update(queryParams, {
        [queryParamKey]: { $set: ids },
      }),
      { replace: true },
    );
  }, [queryParams, setQueryParams]);

  const urlHistorycalBid = 'https://lionstudios.cloud.looker.com/explore/lionstudios_bidder/applovin_roas_history?qid=veQf1uN86aGehO3eYsL5gm&origin_space=59&toggle=fil,vis';
  const handleLinkHistorycalBid = useCallback(() => {
    window.open(urlHistorycalBid);
  }, [urlHistorycalBid]);


  useEffect(() => {
    setInitialTab(queryParams[QUERY_PARAMS.TAB] || 0);
  }, [queryParams[QUERY_PARAMS.TAB]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (queryParams[QUERY_PARAMS.TAB] !== initialTab) {
      setInitialTab(queryParams[QUERY_PARAMS.TAB]);
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const buttons = useMemo(() => (
    <TopButtonWrapper>
      <StyledButton
        as={Button}
        onClick={handleLinkHistorycalBid}
        startIcon={<HistoryIcon />}
        variant="outlined"
      >
        Historycal Bids
      </StyledButton>
      <StyledButton
        as={Button}
        onClick={() => navigate(`/${ROUTES.ACTIVITY_LOG}`)}
        startIcon={<TimerOutlinedIcon />}
        variant="outlined"
      >
        Activity Log
      </StyledButton>
    </TopButtonWrapper>
  ), []);

  return (
    <Wrapper>
      {(isFetching) ? (
        <Loader />
      ) : (
        <>
          <Icons />
          {buttons}
          <TabsWrapper>
            <GamesTabs
              // TODO: use isFetching instead of global spinner
              onTabChange={handleTabChange}
              initialTab={initialTab}
              selected={selected}
              onSelectionChange={handleSelectionChange}
              // isGameArchived={isGameArchived}
              isAppStatusReady={isAppStatusReady}
              isMounted={isMounted.current}
            />
          </TabsWrapper>
        </>
      )}
    </Wrapper>
  );
});


export default withParams(ListWitTabs);

import React from 'react';

import { GAME_STATUSES, CHANNEL_STATUSES, PLATFORM_ICONS, PLATFORMS_OPTIONS, CHANNELS, CHANNELS_OPTIONS, INFO_TOOLTIPS, CHANNEL_STATUS_KEYS, ACTIVE_CHANNEL_STATUSES } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import { DISABLED_LIVE_CHANNEL_STATUS } from 'constants/text';

import EditableTextField from 'components/common/editable-text-field';
import DataGridEditableField from 'components/common/data-grid-editable-field';
import AutocompleteField from 'components/common/autocomplete-field';
import SelectField from 'components/common/select-field';
import Indicator from 'components/games/status-indicator';
import AdaptiveText from 'components/common/adaptive-text';
import GoalRoasField from 'components/games/goal-roas-field';
import { getValidationSchema } from 'components/games/game-form/validation';
import ChannelIcon from 'components/games/channel-icon';

import {
  getStatusInitialValue,
  getManagersOptionLabel,
  getManagersInitialValue,
  ALL_OPTION,
  NOT_EMPTY_OPTION,
} from 'utils/grid';
import { extractRowKey } from 'utils/extracters';
import { isValueExists } from 'utils';

import { renderMultipleFilterValue } from './utils';
import { FIELDS } from './constants';


const ValidationSchema = getValidationSchema([]);

export const getConfig = (data, onGameUpdate, onNetworkUpdate, onGoalRoasUpdate, isAppStatusReady, managers = []) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.APP_ID,
      headerName: 'App Id',
      flex: 0,
    },
    {
      field: FIELDS.STORE_ID,
      headerName: 'Store Id',
      flex: 1,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.PLATFORM,
      headerName: 'Platform',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      options: PLATFORMS_OPTIONS,
      renderCell: ({ value }) => {
        const Icon = PLATFORM_ICONS[value];

        return (
          <Icon />
        );
      },
    },
    {
      field: FIELDS.NAME,
      headerName: 'Name',
      flex: 2,
      type: 'string',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const { appId } = row;

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.NAME)}
            valueKey={FIELDS.NAME}
            onSubmit={onGameUpdate}
            component={EditableTextField}
            propsToSubmit={{ id: appId }}
            componentProps={{
              to: String(appId),
              initialValue: value,
              validationSchema: ValidationSchema.fields[FIELDS.NAME],
              disabled: !isAppStatusReady,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.STATUS,
      headerName: 'App Status',
      flex: 1,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      options: GAME_STATUSES,
      isMultipleOptions: true,
      renderFilterValue: renderMultipleFilterValue,
      tooltipText: INFO_TOOLTIPS.APP_STATUS,
      renderCell: ({ row }) => {
        const { appId } = row;
        const value = getStatusInitialValue(GAME_STATUSES, row, FIELDS.STATUS);

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.STATUS)}
            valueKey={FIELDS.STATUS}
            onSubmit={onGameUpdate}
            component={SelectField}
            handlerKey="onChange"
            propsToSubmit={{ id: appId }}
            componentProps={{
              options: GAME_STATUSES,
              indicator: Indicator,
              initialValue: value?.id,
              disabled: !isAppStatusReady,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      options: CHANNELS_OPTIONS,
      isMultipleOptions: true,
      renderFilterValue: renderMultipleFilterValue,
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.CHANNEL_STATUS,
      headerName: 'Channel Status',
      flex: 1,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      options: CHANNEL_STATUSES,
      isMultipleOptions: true,
      renderFilterValue: renderMultipleFilterValue,
      tooltipText: INFO_TOOLTIPS.CHANNEL_STATUS,
      renderCell: ({ row }) => {
        const { appId, channelId, id } = row;
        const value = getStatusInitialValue(CHANNEL_STATUSES, row, FIELDS.CHANNEL_STATUS);
        const isChannelInactive = !value || value.id === CHANNEL_STATUS_KEYS.PENDING;

        let channelOptions = isChannelInactive ? CHANNEL_STATUSES : ACTIVE_CHANNEL_STATUSES;
        if (!isValueExists(row[FIELDS.GOAL_ROAS])) {
          channelOptions = channelOptions.map((option) => {
            if (option.id !== CHANNEL_STATUS_KEYS.LIVE || value?.id === CHANNEL_STATUS_KEYS.LIVE) {
              return option;
            }

            return {
              ...option,
              disabled: true,
            };
          });
        }

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.CHANNEL_STATUS)}
            valueKey={FIELDS.CHANNEL_STATUS_TO_SUBMIT} // NOTE: we need to use key STATUS, not CHANNEL_STATUS
            onSubmit={onNetworkUpdate}
            component={SelectField}
            handlerKey="onChange"
            propsToSubmit={{
              id: channelId,
              gameId: appId,
              rowId: id,
            }}
            componentProps={{
              options: channelOptions,
              indicator: Indicator,
              initialValue: value?.id,
              disabled: isChannelInactive || !isAppStatusReady,
              disabledText: !value ? 'Need to create Channel' : null,
              readOnly: isChannelInactive,
              disabledOptionTooltip: DISABLED_LIVE_CHANNEL_STATUS,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.GOAL_ROAS,
      align: 'left',
      headerAlign: 'left',
      headerName: 'Goal ROAS',
      flex: 0.6,
      type: 'number',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      options: [ALL_OPTION, NOT_EMPTY_OPTION],
      clearableFilter: false,
      renderCell: ({ row, value }) => {
        const { appId, channelId, id, channel, override } = row;
        const channelStatus = getStatusInitialValue(CHANNEL_STATUSES, row, FIELDS.CHANNEL_STATUS);
        const isChannelInactive = !channelStatus;

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GOAL_ROAS)}
            valueKey={FIELDS.GOAL_ROAS}
            onSubmit={onGoalRoasUpdate}
            component={GoalRoasField}
            propsToSubmit={{
              channelId,
              gameId: appId,
              rowId: id,
              channel,
              isAppLovin: row.channel === CHANNELS.APPLOVIN,
            }}
            componentProps={{
              initialValue: value,
              override,
              disabled: isChannelInactive || !isAppStatusReady,
              withoutComment: row.channel === CHANNELS.APPLOVIN,
              category: AnalyticsService.CATEGORIES.GAMES,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.GROWTH_MANAGER,
      headerName: 'Growth Manager',
      flex: 1,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ row }) => {
        const { appId } = row;
        const value = getManagersInitialValue(managers, row, FIELDS.GROWTH_MANAGER);

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GROWTH_MANAGER)}
            valueKey={FIELDS.GROWTH_MANAGER}
            onSubmit={onGameUpdate}
            component={AutocompleteField}
            propsToSubmit={{
              id: appId,
            }}
            componentProps={{
              options: managers,
              initialValue: value,
              getOptionLabel: getManagersOptionLabel,
              disableClearable: true,
            }}
          />
        );
      },
    },
  ],
});

import { ENV } from 'constants';
import { isValueExists, isObject, isArray } from 'utils';
import { getDateStringFromTimestamp } from 'utils/date';

import { DEFAULT_ARRAY_OPERATOR, DEFAULT_OPERATOR } from './constants';


export const overrideRequestModel = ({
  bidKey,
  bidOverride,
  channel,
  comment,
}) => ({
  BID_KEY: bidKey,
  NEW_VALUE: Number(bidOverride),
  CHANNEL: channel,
  comment,
});

const overrideModel = ({
  bid,
  manager,
  date,
  date_expires: dateExpires,
  is_override: isOverride,
}) => ({
  bidOverride: bid,
  overrideManager: manager,
  overrideDate: date,
  overrideExpires: dateExpires,
  isOverride,
});

export const listModel = (response) => response.map(({
  id,
  campaign,
  channel,
  country,
  day,
  delta,
  game,
  installs,
  rpi,
  spend,
  status,
  override,
  original_bid: originalBid,
  delta_pct: deltaPct,
  growth_manager: growthManager,
  date_range: dateRange,
  target_bid: targetBid,
  executed_bid: executedBid,
  game_id: gameId,
  roas_delta: roasDelta,
  goal_roas: goalRoas,
  store_id: storeId,
  bid_key: bidKey,
  channel_status: channelStatus,
  lion_app_id: lionAppId,
  campaign_status: campaignStatus,
  campaign_id: campaignId,
  bidder_campaign_status: bidderCampaignStatus,
  platform,
}, index) => ({
  id,
  index,
  campaign,
  channel,
  country,
  dateRange,
  day,
  delta,
  deltaPct,
  game,
  growthManager,
  installs,
  originalBid,
  rpi,
  spend,
  targetBid,
  status,
  executedBid,
  gameId,
  roasDelta,
  goalRoas,
  storeId,
  bidKey,
  override: overrideModel(override),
  channelStatus,
  lionAppId,
  campaignStatus,
  campaignId,
  bidderCampaignStatus: String(bidderCampaignStatus),
  platform,
  ...overrideModel(override),
}));

export const bidsModel = ({
  bids,
  total,
}) => ({
  total,
  bids: listModel(bids),
});

export const bidsRequestModel = (params) => Object.entries(params).reduce((result, [key, filter]) => {
  if (!isValueExists(filter)) {
    return result;
  }

  if (!isObject(filter)) {
    return {
      ...result,
      [key]: filter,
    };
  }

  const { operator, value, field, sort } = filter;

  if (isValueExists(field) && isValueExists(sort)) {
    return {
      ...result,
      sort: {
        [field]: sort.toUpperCase(),
      },
    };
  }

  if (!isValueExists(value)) {
    return result;
  }

  if (!operator && isArray(value)) {
    return {
      ...result,
      [key]: JSON.stringify({ [DEFAULT_ARRAY_OPERATOR]: value }),
    };
  }

  return {
    ...result,
    [key]: JSON.stringify({ [operator || DEFAULT_OPERATOR]: value }),
  };
}, {});

export const fetchHistoricalBidsModel = (params) => ({
  page: params.page || 0,
  limit: params.limit || 10000,
  DAY_KEY: getDateStringFromTimestamp(params.timePeriod, '-'),
});

export const pushHistoricalBidsModel = (params) => ({
  name: 'push-bids-history',
  isPushBidsEnabled: process.env.REACT_APP_CONFIG_ENV === ENV.PROD,
  useDayKey: getDateStringFromTimestamp(params.timePeriod, '-'),
  async: true,
});

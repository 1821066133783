import React, { memo, useCallback, useState } from 'react';

import SnackbarService from 'services/snackbar';
import { downloadBlobFile } from 'utils/files';

import BidsService from 'core/bids/service';

import Button from 'components/common/button';

import { getCsvName } from './utils';


const CsvButton = memo(() => {
  const [isFetching, setIsFetching] = useState(false);

  const handleClick = useCallback(async () => {
    setIsFetching(true);

    try {
      const blob = await BidsService.fetchCsv();

      downloadBlobFile(blob, getCsvName());
    } catch (error) {
      SnackbarService.showError('Something went wrong');
    } finally {
      setIsFetching(false);
    }
  }, []);

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      isFetching={isFetching}
    >
      Save CSV
    </Button>
  );
});


CsvButton.propTypes = {

};

CsvButton.defaultProps = {

};


export default CsvButton;

import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import EditableTextField from 'components/common/editable-text-field';
import AdaptiveText from 'components/common/adaptive-text';
import DataGridEditableField from 'components/common/data-grid-editable-field';

import { getValidationSchema } from 'components/settings/push-frequency/form/validation';

import { extractRowKey } from 'utils/extracters';
import { FIELDS } from './constants';


const ValidationSchema = getValidationSchema([]);

export const getConfig = (data, onSubmit, onDelete) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CAMPAIGN_TYPE,
      headerName: 'Campaign Type',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.COOLDOWN_LIMIT,
      headerName: 'Cooldown Limit',
      flex: 2,
      type: 'string',
      renderCell: ({ value, row }) => {
        const { id } = row;

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.COOLDOWN_LIMIT)}
            valueKey={FIELDS.COOLDOWN_LIMIT}
            onSubmit={onSubmit}
            component={EditableTextField}
            propsToSubmit={{ id }}
            componentProps={{
              initialValue: value,
              validationSchema: ValidationSchema.fields[FIELDS.COOLDOWN_LIMIT],
              disabled: false,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.ACTIONS,
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => onDelete(row.id)}
        >
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
  ],
});

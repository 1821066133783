import styled from 'styled-components';


export const Wrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(5),
}));

export const Button = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledIcon = styled('div')(({ theme, $rotate = 0, $withoutMargin }) => ({
  marginRight: $withoutMargin ? 0 : theme.spacing(1.5),
  color: theme.palette.primary.light,
  transform: `rotate(${$rotate}deg)`,
}));

export const Title = styled('p')(({ theme }) => ({
  color: theme.palette.background.main,
  minWidth: '70px',
}));

export const PopperContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  padding: theme.spacing(1.25, 2),
  width: '350px',
}));

export const StyledLink = styled('div')(({ theme }) => ({
  color: theme.palette.textSecondary.main,
  marginLeft: '26px',
  textDecoration: 'none',
  borderBottom: '2px solid transparent',
  transition: 'border 350ms ease-out',
  cursor: 'pointer',

  '&.active, &:hover': {
    borderBottomColor: theme.palette.textSecondary.main,
  },

  '&:first-child': {
    marginLeft: 0,
  },
}));

import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getIsAuthorized } from 'core/auth/selectors';
import { getIsAuthorizedUserAdmin } from 'core/users/selectors';

import PageLayout from 'components/common/page-layout';

import GamesV3 from 'pages/games/list';
import GameDetails from 'pages/games/details';
import Games from 'pages/v4/games/list/indexWithTabs';
import OldBids from 'pages/old-bids';
import Bids from 'pages/bids';
import SignIn from 'pages/auth/sign-in';
import SignInOkta from 'pages/auth/sign-in-okta';
import SignInOktaRedirect from 'pages/auth/sign-in-okta-redirect';
import SignUp from 'pages/auth/sign-up';
import RecoverPassword from 'pages/auth/recover-password';
import ResetPassword from 'pages/auth/reset-password';
import Settings from 'pages/settings';
import SettingsPushFrequency from 'pages/push-frequency';
import SettingsChangeLimits from 'pages/change-limits';
import SettingsPermissions from 'pages/permissions';
import ActivityLog from 'pages/activity-log';
import NotFound from 'pages/not-found';

import { ROUTES } from './constants';


const Router = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  const isAuthorizedUserAdmin = useSelector(getIsAuthorizedUserAdmin);

  const location = useLocation();

  return (
    <PageLayout
      isAuthorized={isAuthorized}
      isAuthorizedUserAdmin={isAuthorizedUserAdmin}
    >
      <Routes>
        {
          isAuthorized && (
            <>
              <Route
                path={ROUTES.GAMES}
                element={<Games />}
                exact
              />

              <Route
                path={`${ROUTES.GAMES}/${ROUTES.GAME_DETAILS}`}
                element={<GameDetails />}
              />

              <Route
                path={ROUTES.GAMES_V3}
                element={<GamesV3 />}
                exact
              />

              <Route
                path={`${ROUTES.GAMES_V3}/${ROUTES.GAME_DETAILS}`}
                element={<GameDetails />}
              />

              <Route
                path={ROUTES.OLD_BIDS}
                element={<OldBids />}
                exact
              />

              <Route
                path={ROUTES.BIDS}
                element={<Bids />}
                exact
              />

              <Route
                path={ROUTES.ACTIVITY_LOG}
                element={<ActivityLog />}
                exact
              />

              {isAuthorizedUserAdmin && (
                <>

                  <Route
                    path={ROUTES.SETTINGS}
                    element={<Settings />}
                    exact
                  />

                  <Route
                    path={ROUTES.SETTINGS_PUSH_FREQUENCY}
                    element={<SettingsPushFrequency />}
                    exact
                  />

                  <Route
                    path={ROUTES.SETTINGS_CHANGE_LIMITS}
                    element={<SettingsChangeLimits />}
                    exact
                  />

                  <Route
                    path={ROUTES.SETTINGS_PERMISSIONS}
                    element={<SettingsPermissions />}
                    exact
                  />

                </>
              )}

              <Route
                path={ROUTES.NOT_FOUND}
                element={<NotFound />}
                exact
              />
            </>
          )
        }

        <Route
          path={ROUTES.SIGN_IN}
          element={<SignIn />}
        />

        <Route
          path={ROUTES.SIGN_IN_OKTA}
          element={<SignInOkta />}
        />

        <Route
          path={ROUTES.SIGN_IN_OKTA_REDIRECT}
          element={<SignInOktaRedirect />}
        />

        <Route
          path={ROUTES.SIGN_UP}
          element={<SignUp />}
        />

        <Route
          path={ROUTES.RECOVER_PASSWORD}
          element={<RecoverPassword />}
        />

        <Route
          path={ROUTES.RESET_PASSWORD}
          element={<ResetPassword />}
        />

        <Route
          path="/"
          element={(
            <Navigate
              to={isAuthorized ? ROUTES.GAMES : ROUTES.SIGN_IN_OKTA}
              state={location}
            />
          )}
          exact
        />

        <Route
          path="*"
          element={(
            <Navigate
              to={isAuthorized ? ROUTES.NOT_FOUND : ROUTES.SIGN_IN_OKTA}
              state={location}
            />
          )}
        />
      </Routes>
    </PageLayout>
  );
};


export default Router;

import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Stack from '@mui/material/Stack';

import { CHANNEL_STATUSES, ACTIVE_CHANNEL_STATUSES, CHANNEL_STATUS_KEYS, CHANNELS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import { DISABLED_LIVE_CHANNEL_STATUS } from 'constants/text';

import { extractRowKey } from 'utils/extracters';

import SelectField from 'components/common/select-field';
import DataGridEditableField from 'components/common/data-grid-editable-field';
import Indicator from 'components/games/status-indicator';
import AdaptiveText from 'components/common/adaptive-text';
import GridHeaderWithWeekRangeTooltip from 'components/games/grid-header-with-week-range-tooltip';
import GoalRoasField from 'components/games/goal-roas-field';
import AccountIdField from 'components/games/account-id-field';
import ChannelIcon from 'components/games/channel-icon';

import { isValueChanged, isValueExists } from 'utils';
import { getStatusInitialValue } from 'utils/grid';
import { getNumberFormatter, getPriceFormatter } from 'utils/formatters';

import { FIELDS } from './constants';


export const getConfig = (data, onSubmit, onDelete, onLinkPress, onNetworkAdd, onEmptyNetworkEdit, onGoalRoasUpdate, isGameArchived, isAppStatusReady) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.STATUS,
      headerName: 'Channel Status',
      flex: 1,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ row }) => {
        const { id } = row;
        const handleSubmit = row.isEmpty ? onEmptyNetworkEdit : onSubmit;
        const value = getStatusInitialValue(CHANNEL_STATUSES, row, FIELDS.STATUS);
        const isChannelInactive = row.isEmpty || value.id === CHANNEL_STATUS_KEYS.PENDING;

        let channelOptions = isChannelInactive ? CHANNEL_STATUSES : ACTIVE_CHANNEL_STATUSES;
        if (!isValueExists(row[FIELDS.GOAL_ROAS])) {
          channelOptions = channelOptions.map((option) => {
            if (option.id !== CHANNEL_STATUS_KEYS.LIVE || value?.id === CHANNEL_STATUS_KEYS.LIVE) {
              return option;
            }

            return {
              ...option,
              disabled: true,
            };
          });
        }

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.STATUS)}
            valueKey={FIELDS.STATUS}
            onSubmit={handleSubmit}
            component={SelectField}
            handlerKey="onChange"
            propsToSubmit={{ id }}
            componentProps={{
              options: channelOptions,
              indicator: Indicator,
              initialValue: value?.id,
              disabled: isChannelInactive || isGameArchived || !isAppStatusReady,
              disabledText: row.isEmpty ? 'Need to create Channel' : null,
              readOnly: isChannelInactive || isGameArchived || !isAppStatusReady,
              disabledOptionTooltip: DISABLED_LIVE_CHANNEL_STATUS,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.ACCOUNT_ID,
      headerName: 'Account Id',
      flex: 2,
      type: 'string',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const { id, channel } = row;
        const handleSubmit = row.isEmpty ? onEmptyNetworkEdit : onSubmit;

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.ACCOUNT_ID)}
            valueKey={FIELDS.NEW_ACCOUNT_ID}
            onSubmit={handleSubmit}
            component={AccountIdField}
            propsToSubmit={{ id }}
            componentProps={{
              initialValue: row.isEmpty ? row[FIELDS.NEW_ACCOUNT_ID] : value,
              placeholder: 'Type here',
              channel,
              isValueChanged,
              disabled: isGameArchived || !isAppStatusReady,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.WEEK_SPEND,
      headerName: 'Last 7 days spend',
      flex: 1,
      type: 'number',
      valueFormatter: getPriceFormatter(),
      headerAlign: 'left',
      align: 'left',
      renderHeader: ({ colDef }) => (
        <GridHeaderWithWeekRangeTooltip
          headerName={colDef.headerName}
        />
      ),
      renderCell: ({ formattedValue, value, row }) => (
        <AdaptiveText
          value={row.isEmpty ? '-' : value}
          size={AdaptiveText.SIZE.SM}
        >
          {row.isEmpty ? '-' : formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.WEEKLY_INSTALLS,
      headerName: 'Last 7 days installs',
      flex: 1,
      type: 'number',
      valueFormatter: getNumberFormatter(0),
      headerAlign: 'left',
      align: 'left',
      renderHeader: ({ colDef }) => (
        <GridHeaderWithWeekRangeTooltip
          headerName={colDef.headerName}
        />
      ),
      renderCell: ({ formattedValue, value, row }) => (
        <AdaptiveText
          value={row.isEmpty ? '-' : value}
          size={AdaptiveText.SIZE.SM}
        >
          {row.isEmpty ? '-' : formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal ROAS',
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => {
        const { id, channel, override, channelId } = row;
        const handleSubmit = row.isEmpty ? onEmptyNetworkEdit : onGoalRoasUpdate;

        const isAppLovin = row.channel === CHANNELS.APPLOVIN;

        return (
          <DataGridEditableField
            key={extractRowKey(row, FIELDS.GOAL_ROAS)}
            valueKey={FIELDS.GOAL_ROAS}
            onSubmit={handleSubmit}
            component={GoalRoasField}
            propsToSubmit={{
              id,
              channelId,
              channel,
              isAppLovin: row.isEmpty ? false : isAppLovin,
            }}
            componentProps={{
              initialValue: value,
              override,
              withConfirmation: !row.isEmpty,
              disabled: isGameArchived || !isAppStatusReady,
              withoutComment: row.isEmpty || isAppLovin,
              category: AnalyticsService.CATEGORIES.CHANNELS,
              disableTooltip: row.isEmpty ? false : isAppLovin,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.ACTIONS,
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          spacing={2}
        >
          {
            row.isEmpty ? (
              <IconButton
                disabled={!row[FIELDS.NEW_ACCOUNT_ID] || !row[FIELDS.GOAL_ROAS] || isGameArchived || !isAppStatusReady}
                onClick={() => onNetworkAdd(row)}
              >
                <AddCircleOutline />
              </IconButton>
            ) : (
              <>
                <IconButton
                  onClick={() => onLinkPress(row.link)}
                >
                  <OpenInNewIcon />
                </IconButton>

                <IconButton
                  onClick={() => onDelete(row.id, row.accountId)}
                  disabled={isGameArchived || !isAppStatusReady}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </>
            )
          }
        </Stack>
      )
      ,
    },
  ],
});

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Header from 'components/common/header';

import { Wrapper, Content } from './styles';


const PageLayout = memo(({ children, isAuthorized, isAuthorizedUserAdmin }) => (
  <Wrapper>
    <Header
      isAuthorized={isAuthorized}
      isAuthorizedUserAdmin={isAuthorizedUserAdmin}
    />

    <Content
      $isAuthorized={isAuthorized}
    >
      {children}
    </Content>
  </Wrapper>
));


PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isAuthorizedUserAdmin: PropTypes.bool.isRequired,
};

PageLayout.defaultProps = {

};


export default PageLayout;

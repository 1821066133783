import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import { types } from './actions';
import persistConfig from './persist';


const defaultState = {
  list: null,
  user: null,
  activityLog: null,

  isFetching: false,
  isUserAddFetching: false,
  isApproveNewUserFetching: false,
  isUsersFetching: false,
  isUserUpdateFetching: false,
  isUserDeleteFetching: false,
  isActivityLogFetching: false,
};

const reducer = handleActions({
  [types.FETCH_AUTHORIZED_USER]: (state) => (
    update(state, {
      isFetching: { $set: true },
    })
  ),

  [resolved(types.FETCH_AUTHORIZED_USER)]: (state, { payload: { data } }) => (
    update(state, {
      isFetching: { $set: false },
      user: { $set: data },
    })
  ),

  [rejected(types.FETCH_AUTHORIZED_USER)]: (state) => (
    update(state, {
      isFetching: { $set: false },
      user: { $set: null },
    })
  ),

  [types.ADD_USER]: (state) => (
    update(state, {
      isUserAddFetching: { $set: true },
    })
  ),
  [resolved(types.ADD_USER)]: (state) => (
    update(state, {
      isUserAddFetching: { $set: false },
    })
  ),
  [rejected(types.ADD_USER)]: (state) => (
    update(state, {
      isUserAddFetching: { $set: false },
    })
  ),

  [types.APPROVE_NEW_USER]: (state) => (
    update(state, {
      isApproveNewUserFetching: { $set: true },
    })
  ),
  [resolved(types.APPROVE_NEW_USER)]: (state) => (
    update(state, {
      isApproveNewUserFetching: { $set: false },
    })
  ),
  [rejected(types.APPROVE_NEW_USER)]: (state) => (
    update(state, {
      isApproveNewUserFetching: { $set: false },
    })
  ),

  [types.FETCH_USERS]: (state, { meta: { useIsFetching } }) => {
    if (!useIsFetching) {
      return state;
    }

    return update(state, {
      isUsersFetching: { $set: true },
    });
  },
  [resolved(types.FETCH_USERS)]: (state, { payload: { data } }) => (
    update(state, {
      list: { $set: data },
      isUsersFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_USERS)]: (state) => (
    update(state, {
      isUsersFetching: { $set: false },
    })
  ),

  [types.UPDATE_USER]: (state) => (
    update(state, {
      isUserUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_USER)]: (state) => (
    update(state, {
      isUserUpdateFetching: { $set: false },
    })
  ),
  [rejected(types.UPDATE_USER)]: (state) => (
    update(state, {
      isUserUpdateFetching: { $set: false },
    })
  ),

  [types.DELETE_USER]: (state) => (
    update(state, {
      isUserDeleteFetching: { $set: true },
    })
  ),
  [resolved(types.DELETE_USER)]: (state) => (
    update(state, {
      isUserDeleteFetching: { $set: false },
    })
  ),
  [rejected(types.DELETE_USER)]: (state) => (
    update(state, {
      isUserDeleteFetching: { $set: false },
    })
  ),

  [types.FETCH_ACTIVITY_LOG]: (state) => (
    update(state, {
      isActivityLogFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_ACTIVITY_LOG)]: (state, { payload: { data } }) => (
    update(state, {
      activityLog: { $set: data },
      isActivityLogFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_ACTIVITY_LOG)]: (state) => (
    update(state, {
      isActivityLogFetching: { $set: false },
    })
  ),

  [resolved(authTypes.SIGN_IN)]: (state, { payload: { user } }) => (
    update(state, {
      user: { $set: user },
    })
  ),

  [combineActions(
    rejected(authTypes.LOAD_AUTH),
    rejected(authTypes.SIGN_IN),
    authTypes.LOGOUT,
  )]: () => defaultState,
}, defaultState);


export default persistReducer(persistConfig, reducer);

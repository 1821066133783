import { styled } from '@mui/system';


export const Wrapper = styled('div')(({ theme, $isDisabled, $readOnly }) => ({
  width: '100%',
  height: '100%',
  minHeight: 38,
  cursor: $readOnly ? 'default' : 'pointer',
  ...($isDisabled ? { backgroundColor: theme.palette.background.lighter } : {}),
}));

export const Placeholder = styled('div')(({ theme, $withIndicator, $isDisabled }) => ({
  width: '100%',
  paddingLeft: $withIndicator ? theme.spacing(3) : theme.spacing(1),
  paddingRight: theme.spacing(4),
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  ...theme.typography.body2,
  ...($isDisabled ? { color: theme.palette.textPrimary.light } : {}),
}));

export const PlaceholderText = styled('div')(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
}));

export const ArrowIcon = styled('div')(() => ({
  pointerEvents: 'none',
  position: 'absolute',
  right: '7px',
  top: '50%',
  transform: 'translateY(-50%)',
  color: 'rgba(0,0,0,0.54)',
}));

export const ClearButton = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  color: 'rgba(0,0,0,0.54)',
  zIndex: 5,
  fontSize: '1rem',
}));

import { isValueExists } from 'utils';


export const getOverrideText = (override) => `${new Date(override.createdAt).toLocaleDateString([], { timeZone: 'UTC' })} by ${override.manager}`;

export const getIsOverrideVisible = (override) => {
  if (!override || !Object.keys(override).length) {
    return false;
  }

  if (isValueExists(override.goal)) {
    return true;
  }

  return false;
};

export const getResetOverrideConfirmationText = (override) => (
  `Please, confirm that you want to update this Goal ROAS from ${isValueExists(override.goal) ? `${override.goal}%` : override.goal} to ${isValueExists(override.parentGoal) ? `${override.parentGoal}%` : override.parentGoal}`
);

export const getTooltipTitle = (override) => {
  if (!override || !Object.keys(override).length) {
    return '';
  }

  if (isValueExists(override.goal)) {
    return '';
  }

  return `This value is set by the parent ${override.parentType}`;
};

export const getOverrideConfirmationText = (initialValue, nextValue) => (
  `Please, confirm that you want to update this Goal ROAS from ${isValueExists(initialValue) ? `${initialValue}%` : initialValue} to ${isValueExists(nextValue) ? `${nextValue}%` : nextValue}`
);

import { styled } from '@mui/system';


export const OverrideIndicator = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  minWidth: 8,
  boxSizing: 'border-box',
  borderRadius: 8,
  borderWidth: 2,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'solid',
  marginRight: theme.spacing(1),
  cursor: 'pointer',
}));

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

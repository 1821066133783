import { ReactComponent as UnityIcon } from 'assets/images/unity-channel-icon.svg';
import { ReactComponent as AppLovinIcon } from 'assets/images/applovin-channel-icon.svg';
import { ReactComponent as MintegralIcon } from 'assets/images/mintegral-channel-icon.svg';
import { ReactComponent as AdjoeIcon } from 'assets/images/adjoe-channel-icon.svg';

import { ReactComponent as AppleIcon } from 'assets/images/apple-icon.svg';
import { ReactComponent as AndroidIcon } from 'assets/images/android-icon.svg';

import { getTooltipText } from 'utils/formatters';


export const GAMES_URL = 'games';
export const GOAL_ROAS_URL = 'bids/roas/override';

export const PLATFORMS = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
};

export const PLATFORMS_OPTIONS = [
  {
    id: PLATFORMS.IOS,
    name: 'iOS',
  },
  {
    id: PLATFORMS.ANDROID,
    name: 'Android',
  },
];

export const PLATFORM_KEYS = Object.values(PLATFORMS);
export const [DEFAULT_PLATFORM] = PLATFORMS_OPTIONS;

export const CHANNELS = {
  APPLOVIN: 'APPLOVIN',
  MINTEGRAL: 'MINTEGRAL',
  UNITY: 'UNITY',
  ADJOE: 'ADJOE',
};

export const CHANNELS_KEYS = Object.keys(CHANNELS);

export const CHANNELS_OPTIONS = Object.entries(CHANNELS).map(([id, name]) => ({
  id,
  name,
}));

export const CAMPAIGN_TYPES = {
  CPI: 'CPI',
  BLD_ROAS: 'BLD_ROAS',
  CPC: 'CPC',
  CPE: 'CPE',
  CPM: 'CPM',
  CPP_GOAL: 'CPP_GOAL',
  DCPM: 'DCPM',
  RET: 'RET',
  ROAS_AD_BASED: 'ROAS_AD_BASED',
  ROAS_IAP: 'ROAS_IAP',
  OCPI: 'OCPI',
};

export const CAMPAIGN_TYPES_KEYS = Object.keys(CAMPAIGN_TYPES);

export const CAMPAIGN_TYPES_OPTIONS = Object.entries(CAMPAIGN_TYPES).map(([id, name]) => ({
  id,
  name,
}));

export const CHANNELS_CAMPAIGN_TYPES = {
  ADJOE: ['CPI'],
  APPLOVIN: [
    'BLD_ROAS', 'CPC', 'CPE', 'CPI', 'CPM',
    'CPP_GOAL', 'DCPM', 'RET', 'ROAS_AD_BASED', 'ROAS_IAP',
  ],
  MINTEGRAL: ['CPI', 'OCPI'],
  UNITY: ['CPI', 'CPM'],
};

export const CHANNELS_LIMITS_CAMPAIGN_TYPES = {
  ADJOE: ['CPI'],
  APPLOVIN: [
    'BLD_ROAS', 'CPE', 'CPI', 'ROAS_AD_BASED', 'ROAS_IAP',
  ],
  MINTEGRAL: ['CPI', 'OCPI'],
  UNITY: ['CPI'],
};

export const GAME_STATUS_KEYS = {
  LIVE: 'LIVE',
  PAUSED: 'PAUSED',
  ARCHIVED: 'ARCHIVED',
};

export const GAME_STATUS_INFO = {
  [GAME_STATUS_KEYS.LIVE]: 'Enabled for bidding',
  [GAME_STATUS_KEYS.PAUSED]: 'No calculations and pushes enabled',
  [GAME_STATUS_KEYS.ARCHIVED]: 'Archived Games',
};

export const CHANNEL_STATUS_KEYS = {
  LIVE: 'LIVE',
  PAUSED: 'PAUSED',
  PENDING: 'PENDING',
  READY: 'READY',
};

export const CHANNEL_STATUS_INFO = {
  [CHANNEL_STATUS_KEYS.LIVE]: 'Bids will be PUSHED',
  [CHANNEL_STATUS_KEYS.PAUSED]: 'Just created  or Manually paused network',
  [CHANNEL_STATUS_KEYS.READY]: 'Channel\'s bids will be CALCulated but won\'t PUSH',
  [CHANNEL_STATUS_KEYS.PENDING]: 'Created network with ROAS',
};

export const GAME_STATUSES = [
  {
    id: GAME_STATUS_KEYS.LIVE,
    name: 'Live',
  },
  {
    id: GAME_STATUS_KEYS.PAUSED,
    name: 'Paused',
  },
  {
    id: GAME_STATUS_KEYS.ARCHIVED,
    name: 'Archived',
  },
];

export const CAMPAIGN_STATUS_KEYS = {
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  OVER_DAILY_CAP: 'OVER_DAILY_CAP',
};

export const CAMPAIGN_STATUSES = [
  {
    id: CAMPAIGN_STATUS_KEYS.RUNNING,
    name: 'Running',
  },
  {
    id: CAMPAIGN_STATUS_KEYS.STOPPED,
    name: 'Stopped',
  },
];

export const ACTIVE_CHANNEL_STATUSES = [
  {
    id: CHANNEL_STATUS_KEYS.LIVE,
    name: 'Live',
  },
  {
    id: CHANNEL_STATUS_KEYS.PAUSED,
    name: 'Paused',
  },
  {
    id: CHANNEL_STATUS_KEYS.READY,
    name: 'Ready',
  },
];

export const CHANNEL_STATUSES = [
  ...ACTIVE_CHANNEL_STATUSES,
  {
    id: CHANNEL_STATUS_KEYS.PENDING,
    name: 'Pending',
  },
];

export const BIDDER_STATUSES = [
  {
    id: true,
    name: 'On',
  },
  {
    id: false,
    name: 'Off',
  },
];

export const CAMPAIGN_STATUS_INDICATOR_COLORS = {
  [CAMPAIGN_STATUS_KEYS.RUNNING]: 'transparent',
  [CAMPAIGN_STATUS_KEYS.STOPPED]: '#C6C6C6',
};

export const INDICATOR_COLORS = {
  [CHANNEL_STATUS_KEYS.LIVE]: 'transparent',
  [CHANNEL_STATUS_KEYS.PAUSED]: '#C6C6C6',
  [CHANNEL_STATUS_KEYS.PENDING]: '#FDBF00',
  [CHANNEL_STATUS_KEYS.READY]: '#73BC24',
  ...CAMPAIGN_STATUS_INDICATOR_COLORS,
};

export const GAME_STATUS_COLORS = {
  [GAME_STATUS_KEYS.LIVE]: '#73BC24',
  [GAME_STATUS_KEYS.PAUSED]: '#8F8F8F',
  [GAME_STATUS_KEYS.ARCHIVED]: '#2D2D2D ',
};

export const GAME_DETAILS_LIST = {
  NETWORKS: 'networks',
  CAMPAIGNS: 'campaigns',
  GEOS: 'geos',
  SOURCES: 'sources',
};

export const CHANNEL_ICONS = {
  [CHANNELS.APPLOVIN]: AppLovinIcon,
  [CHANNELS.MINTEGRAL]: MintegralIcon,
  [CHANNELS.UNITY]: UnityIcon,
  [CHANNELS.ADJOE]: AdjoeIcon,
};

export const PLATFORM_ICONS = {
  [PLATFORMS.IOS]: AppleIcon,
  [PLATFORMS.ANDROID]: AndroidIcon,
};

export const BILLING_TYPES = {
  SOURCE: 'SOURCE',
};

export const GAME_SEARCH_FIELDS = [
  'appId',
  'name',
  'storeId',
];

export const GOAL_ROAS_PATHS = {
  NETWORK: 'network',
  CAMPAIGN: 'campaign',
  GEO: 'geo',
  SOURCE: 'source',
};

export const INFO_TOOLTIPS = {
  APP_STATUS: getTooltipText(GAME_STATUS_INFO),
  CHANNEL_STATUS: getTooltipText(CHANNEL_STATUS_INFO),
};

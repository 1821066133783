import { styled } from '@mui/system';


export const ListHeader = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 3),
  padding: theme.spacing(1.25, 3),
  backgroundColor: theme.palette.background.light,
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  maxWidth: 960,
}));

export const StyledIcon = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginRight: theme.spacing(2),
}));

export const StyledTitle = styled('p')(({ theme }) => ({
  color: theme.typography.title.color,
  marginRight: theme.spacing(4),
  fontWeight: 700,
}));

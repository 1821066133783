import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

// Polyfills
import 'core-js/web/url-search-params';
import 'core-js/es/array/includes';

import App from 'containers/app';


Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'), // eslint-disable-line no-undef
);


import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types } from './actions';
import persistConfig from './persist';


const defaultState = {
  isFetching: false,
  token: null,
  isTokenValid: false,
  redirectUrl: null,

  isRecoverPasswordFetching: false,
  isResetPasswordFetching: false,
};

const reducer = handleActions({
  [combineActions(
    types.LOAD_AUTH,
    types.SIGN_IN,
    types.SIGN_IN_WITH_OKTA,
  )]: (state) => (
    update(state, {
      isFetching: { $set: true },
    })
  ),

  [combineActions(
    resolved(types.LOAD_AUTH),
    resolved(types.SIGN_IN),
    types.SIGN_IN_WITH_OKTA,
  )]: (state, { payload: { token } }) => (
    update(state, {
      isFetching: { $set: false },
      token: { $set: token },
      isTokenValid: { $set: true },
    })
  ),

  [types.LOGOUT]: () => defaultState,

  [combineActions(
    rejected(types.LOAD_AUTH),
    rejected(types.SIGN_IN),
  )]: (state, { payload: { redirectUrl } = {} } = {}) => ({
    ...defaultState,
    redirectUrl: redirectUrl || state.redirectUrl,
  }),

  [types.AUTH_SUCCESS]: (state) => (
    update(state, {
      redirectUrl: { $set: null },
    })
  ),

  [types.RECOVER_PASSWORD]: (state) => (
    update(state, {
      isRecoverPasswordFetching: { $set: true },
    })
  ),
  [resolved(types.RECOVER_PASSWORD)]: (state) => (
    update(state, {
      isRecoverPasswordFetching: { $set: false },
    })
  ),
  [rejected(types.RECOVER_PASSWORD)]: (state) => (
    update(state, {
      isRecoverPasswordFetching: { $set: false },
    })
  ),

  [types.RESET_PASSWORD]: (state) => (
    update(state, {
      isResetPasswordFetching: { $set: true },
    })
  ),
  [resolved(types.RESET_PASSWORD)]: (state) => (
    update(state, {
      isResetPasswordFetching: { $set: false },
    })
  ),
  [rejected(types.RESET_PASSWORD)]: (state) => (
    update(state, {
      isResetPasswordFetching: { $set: false },
    })
  ),
}, defaultState);


export default persistReducer(persistConfig, reducer);

import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingsActions from 'core/settings/actions';
import { getConfig, getIsConfigFetching } from 'core/settings/selectors';
import usersActions from 'core/users/actions';
import AnalyticsService from 'services/analytics';

import { withParams } from 'hoc/with-params';

import Loader from 'components/common/loader';
import UsersList from 'components/settings/users-list';

import { Wrapper, ListWrapper } from './styles';


const SettingsPermissions = memo(() => {
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const isConfigFetching = useSelector(getIsConfigFetching);

  useEffect(() => {
    dispatch(settingsActions.fetchConfig());
    dispatch(usersActions.fetchUsers());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.SETTINGS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      {isConfigFetching || !config ? (
        <Loader />
      ) : (
        <ListWrapper>
          <UsersList />
        </ListWrapper>
      )}
    </Wrapper>
  );
});


SettingsPermissions.propTypes = {

};

SettingsPermissions.defaultProps = {

};


export default withParams(SettingsPermissions);

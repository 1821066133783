import React, { memo, useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LoopIcon from '@mui/icons-material/Loop';

import { isValueChanged } from 'utils';
import { formatNumber } from 'utils/formatters';

import EditableTextField from 'components/common/editable-text-field';
import BidOverrideModal from 'components/bids/bid-override-modal';
import CommentPopper from 'components/games/comment-popper';

import { RESET_VALUE } from './constants';
import { validator } from './validation';
import { getIsOverrideVisible, getOverrideText } from './utils';
import { Wrapper, OverrideIndicator } from './styles';


const ExecutedBidField = memo(({ override, onSubmit, initialValue, propsToSubmit, disabled, withoutComment, ...otherProps }) => {
  const wrapperRef = useRef();

  const [value, setValue] = useState(initialValue);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [overrideData, setOverrideData] = useState(null);
  const [isCommentPopperOpen, setIsCommentPopperOpen] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);

  const handleCommentPopperSubmit = useCallback((nextComment, options = {}) => {
    const { useOptions } = options;

    const values = useOptions ? options : {
      isResetMode,
      value,
    };

    setIsCommentPopperOpen(false);
    setIsConfirmationModalOpen(true);
    setOverrideData({
      ...propsToSubmit,
      bidOverride: values.isResetMode ? String(RESET_VALUE) : values.value,
      comment: nextComment,
    });
  }, [propsToSubmit, value, isResetMode]);

  const handleChange = useCallback((nextValue) => {
    setValue(nextValue);
    if (withoutComment) {
      handleCommentPopperSubmit(null, {
        useOptions: true,
        value: nextValue,
      });
    } else {
      setIsCommentPopperOpen(true);
    }
  }, [handleCommentPopperSubmit, withoutComment]);

  const handleReset = useCallback(() => {
    setIsResetMode(true);

    if (withoutComment) {
      handleCommentPopperSubmit(null, {
        useOptions: true,
        isResetMode: true,
      });
    } else {
      setIsCommentPopperOpen(true);
    }
  }, [handleCommentPopperSubmit, withoutComment]);

  const handleSubmit = useCallback(() => {
    setIsConfirmationModalOpen(false);
    onSubmit(overrideData.bidOverride, { comment: overrideData.comment });
    setOverrideData(null);
  }, [overrideData, onSubmit]);

  const handleCancel = useCallback(() => {
    setValue(propsToSubmit.prevBidOverride);
    setIsConfirmationModalOpen(false);
    setOverrideData(null);
  }, [propsToSubmit]);

  const handleCommentPopperCancel = useCallback(() => {
    setIsCommentPopperOpen(false);
    setValue(initialValue);
  }, [initialValue]);

  const isOverrideVisible = getIsOverrideVisible(override);

  const beforeElement = useMemo(() => {
    if (!isOverrideVisible) {
      return null;
    }

    const tooltipText = override ? getOverrideText(override) : undefined;

    return (
      <Tooltip
        placement="top"
        title={tooltipText}
        disableFocusListener
        disableTouchListener
        arrow
      >
        <OverrideIndicator />
      </Tooltip>

    );
  }, [override, isOverrideVisible]);

  const afterElement = useMemo(() => {
    if (!initialValue) {
      return null;
    }

    const button = (
      <IconButton
        aria-label="Reset"
        size="small"
        onClick={handleReset}
        disabled={disabled}
      >
        <LoopIcon />
      </IconButton>
    );

    if (disabled) {
      return button;
    }

    return (
      <Tooltip
        placement="top"
        title="Reset"
        disableFocusListener
        disableTouchListener
        arrow
      >
        {button}
      </Tooltip>
    );
  }, [handleReset, initialValue, disabled]);

  return (
    <Wrapper ref={wrapperRef}>
      <EditableTextField
        type="number"
        validationSchema={validator}
        isValueChanged={isValueChanged}
        afterElement={afterElement}
        beforeElement={beforeElement}
        onSubmit={handleChange}
        initialValue={value}
        valueFormatter={formatNumber}
        disabled={disabled}
        {...otherProps}
      />

      <BidOverrideModal
        data={overrideData}
        isOpen={isConfirmationModalOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />

      {
        isCommentPopperOpen && (
          <CommentPopper
            anchorRef={wrapperRef.current}
            isOpen={isCommentPopperOpen}
            title={isResetMode ? 'Describe the reason for the reset override' : 'Describe the reason for the override'}
            onCancel={handleCommentPopperCancel}
            onSubmit={handleCommentPopperSubmit}
          />
        )
      }
    </Wrapper>
  );
});


ExecutedBidField.propTypes = {
  override: PropTypes.shape({
    manager: PropTypes.string,
    createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    parentType: PropTypes.string,
    parentGoal: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  propsToSubmit: PropTypes.shape({
    bidKey: PropTypes.string,
    channel: PropTypes.string,
    campaign: PropTypes.string,
    country: PropTypes.string,
    prevBidOverride: PropTypes.number,
  }).isRequired,
  initialValue: PropTypes.number,
  disabled: PropTypes.bool,
  withoutComment: PropTypes.bool,
};

ExecutedBidField.defaultProps = {
  override: null,
  initialValue: null,
  disabled: false,
  withoutComment: false,
};


export default ExecutedBidField;

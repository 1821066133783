import { styled } from '@mui/system';


export const BUTTON_MIN_WIDTH = '163px';
export const BUTTON_HEIGHT = '38px';

export const StyledButton = styled('button')(({ theme }) => ({
  textTransform: 'capitalize',
  fontWeight: theme.typography.fontWeightBolder,
  fontSize: theme.typography.body2.fontSize,
  borderRadius: 8,
  boxShadow: 'none',
  minWidth: BUTTON_MIN_WIDTH,
  height: BUTTON_HEIGHT,
}));

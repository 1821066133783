import { combineReducers } from 'redux';

import auth from './auth/reducer';
import games from './games/reducer';
import managers from './managers/reducer';
import networks from './networks/reducer';
import settings from './settings/reducer';
import campaigns from './campaigns/reducer';
import users from './users/reducer';
import bids from './bids/reducer';
import gamesV4 from './v4/games/reducer';
import campaignsV4 from './v4/campaigns/reducer';
import geosV4 from './v4/geos/reducer';
import networksV4 from './v4/networks/reducer';


const appReducer = combineReducers({
  auth,
  games,
  managers,
  networks,
  settings,
  campaigns,
  users,
  bids,
  gamesV4,
  campaignsV4,
  geosV4,
  networksV4,
});


export default appReducer;

import { styled } from '@mui/system';


export const Wrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3.5),
}));

export const StyledButton = styled('div')(({ theme }) => ({
  height: 24,
  minWidth: 'auto',
  textAlign: 'center',
  borderColor: theme.palette.primary.light,
  borderWidth: 1,
  borderStyle: 'solid',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 16,

  '& + &': {
    marginLeft: theme.spacing(1.5),
  },
}));

export const StyledText = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
}));

import React from 'react';

import { extractRowKey } from 'utils/extracters';

import { GOAL_ROAS_PATHS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import DataGridEditableField from 'components/common/data-grid-editable-field';
import AdaptiveText from 'components/common/adaptive-text';
import GridHeaderWithWeekRangeTooltip from 'components/games/grid-header-with-week-range-tooltip';
import GoalRoasField from 'components/games/goal-roas-field';
import ChannelIcon from 'components/games/channel-icon';

import { getNumberFormatter, getPriceFormatter } from 'utils/formatters';

import { FIELDS } from './constants';


export const getConfig = (data, onSubmit, onGoalRoasUpdate, isGameArchived) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.CAMPAIGN_NAME,
      headerName: 'Campaign Name',
      flex: 3,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CAMPAIGN_TYPE,
      headerName: 'Campaign Type',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.BILLING_TYPE,
      headerName: 'Billing Type',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.COUNTRY_FULL,
      headerName: 'Geo Name',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.COUNTRY,
      headerName: 'Geo 2LC',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.SOURCE_NAME,
      headerName: 'Source',
      flex: 2,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.WEEK_SPEND,
      headerName: 'Last 7 days spend',
      flex: 1,
      type: 'number',
      valueFormatter: getPriceFormatter(),
      headerAlign: 'left',
      align: 'left',
      renderHeader: ({ colDef }) => (
        <GridHeaderWithWeekRangeTooltip
          headerName={colDef.headerName}
        />
      ),
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.WEEKLY_INSTALLS,
      headerName: 'Last 7 days installs',
      flex: 1,
      type: 'number',
      valueFormatter: getNumberFormatter(0),
      headerAlign: 'left',
      align: 'left',
      renderHeader: ({ colDef }) => (
        <GridHeaderWithWeekRangeTooltip
          headerName={colDef.headerName}
        />
      ),
      renderCell: ({ formattedValue, value }) => (
        <AdaptiveText
          value={value}
          size={AdaptiveText.SIZE.SM}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal ROAS',
      flex: 1,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          key={extractRowKey(row, FIELDS.GOAL_ROAS)}
          valueKey={FIELDS.GOAL_ROAS}
          onSubmit={onGoalRoasUpdate}
          component={GoalRoasField}
          propsToSubmit={{
            id: row.id,
            channelId: row.channelId,
            channel: row.channel,
            campaignId: row.campaignId,
            countryCode: row.country,
            source: row.sourceName,
            goalRoas: row?.override.parentGoal,
            path: GOAL_ROAS_PATHS.SOURCE,
          }}
          componentProps={{
            initialValue: value,
            override: row.override,
            disabled: isGameArchived,
            category: AnalyticsService.CATEGORIES.SOURCES,
          }}
        />
      ),
    },
  ],
});

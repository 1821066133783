import { createSelector } from 'reselect';


const getLocalState = (state) => state.campaigns;
const getIsCampaignUpdateFetchingState = (state) => getLocalState(state).isCampaignUpdateFetching;
const getIsCampaignsUpdateFetchingState = (state) => getLocalState(state).isCampaignsUpdateFetching;
const getCampaingsListState = (state) => getLocalState(state).list;
const getIsListFetchingState = (state) => getLocalState(state).isFetching;


export const getIsCampaignUpdateFetching = createSelector(
  getIsCampaignUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsCampaignsUpdateFetching = createSelector(
  getIsCampaignsUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsListFetching = createSelector(
  getIsListFetchingState,
  (isFetching) => isFetching,
);

export const getCampaingsList = createSelector(
  getCampaingsListState,
  (list) => list,
);

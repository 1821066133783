import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'bids',
  storage,
  blacklist: [
    'isFetching',
    'isOverrideFetching',
    'params',
    'list',
    'total',
    'isHistoricalBidsFetching',
    'isPushHistoricalBidsFetching',
  ],
};


export default persistConfig;

import { styled } from '@mui/system';

import { autocompleteClasses } from '@mui/material/Autocomplete';
import { inputBaseClasses } from '@mui/material/InputBase';


export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',

  [`.${autocompleteClasses.root}`]: {
    [`.${inputBaseClasses.root}`]: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const FieldWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center',
}));

export const StyledButton = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const InfoIcon = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: theme.spacing(1.25),
}));

export const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}));

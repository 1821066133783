import { v4 as uuidv4 } from 'uuid';

import { CHANNELS_KEYS, CHANNEL_STATUS_KEYS, PLATFORMS, CHANNELS } from 'core/games/constants';


const generateEmptyChannel = (channel) => ({
  channel,
  goalRoas: null,
  id: uuidv4(),
  link: null,
  status: CHANNEL_STATUS_KEYS.PENDING,
  weekInstalls: null,
  weekSpend: null,
  isEmpty: true,
  isFilled: false,
  newAccountId: null,
});

export const getListWithAllChannels = (list, game) => {
  const existingChannels = list.map(({ channel }) => channel);

  if (existingChannels.length === CHANNELS_KEYS.length) {
    return list;
  }

  const emptyChannelsIds = CHANNELS_KEYS.filter((channel) => {
    if (game?.platform === PLATFORMS.IOS && channel === CHANNELS.ADJOE) {
      return false;
    }

    return !existingChannels.includes(channel);
  });

  return list.concat(emptyChannelsIds.map(generateEmptyChannel));
};

import { createTheme } from '@mui/material/styles';

import { COLORS, TRANSITION, BASE, MENU } from './constants';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),

    body1: {
      fontSize: '16px',
      color: COLORS.PRIMARY,
    },

    body2: {
      fontSize: '12px',
      color: COLORS.PRIMARY,
    },

    smallTitle: {
      fontSize: '16px',
      color: COLORS.PRIMARY,
      fontWeight: 700,
    },

    title: {
      fontSize: '24px',
      color: COLORS.SECONDARY,
    },

    fontWeightBolder: 600,
  },

  palette: {
    primary: {
      main: COLORS.PRIMARY,
      contrastText: COLORS.BASE,
      light: COLORS.GREY,
    },

    secondary: {
      dark: COLORS.GREY,
      main: COLORS.SECONDARY,
      contrastText: COLORS.BASE,
      light: COLORS.BASE,
    },

    error: {
      main: COLORS.RED,
      customLight: COLORS.RED_LIGHT,
      contrastText: COLORS.BASE,
    },

    textPrimary: {
      dark: COLORS.GREY_DARK,
      main: COLORS.PRIMARY,
      light: COLORS.GREY,
      secondary: COLORS.SECONDARY,
      green: COLORS.GREEN,
      red: COLORS.RED,
    },

    textSecondary: {
      main: COLORS.BASE,
    },

    linkPrimary: {
      main: COLORS.SECONDARY,
    },

    linkSecondary: {
      main: COLORS.BASE,
    },

    background: {
      main: COLORS.BACKGROUND,
      lighter: COLORS.BACKGROUND_LIGHT,
      light: COLORS.BASE,
      hover: COLORS.HOVER,
    },

    input: {
      main: COLORS.GREY,
      dark: COLORS.GREY_DARK,
      active: COLORS.SECONDARY,
    },

    tonalOffset: 0, // TODO: is it correct ?
  },

  spacing: BASE.PADDING,

  shape: {
    borderRadius: BASE.BORDER_RADIUS,
  },

  transitions: {
    duration: {
      standard: TRANSITION.DURATION,
    },

    easing: {
      easeInOut: TRANSITION.EASING,
    },
  },

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
          fontSize: '10px',
          whiteSpace: 'break-spaces',
          maxWidth: '350px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:hover': {
            backgroundColor: '#004fe5',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          height: 48,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          minHeight: 48,
          alignItems: 'center',
          maxWidth: 1300,
        },
        icon: {
          padding: 0,
        },
        message: {
          padding: 0,
        },
        action: {
          paddingTop: 0,
        },
      },
    },
  },

  utils: {
    menu: {
      itemHeight: MENU.ITEM_HEIGHT,
    },
  },
});

export default theme;

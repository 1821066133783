import { put, call, takeLatest, select, take } from 'redux-saga/effects';

import { resolvedAction, rejectedAction, resolved } from 'utils/actions';

import SnackbarService from 'services/snackbar';

import { waitForUserSession } from 'core/app/sagas';

import BidsService from './service';
import { getParams } from './selectors';
import { types as bidsTypes, actions as bidsActions } from './actions';

/*
 * Sagas
 */

function* fetchBidsList ({ payload }) {
  try {
    yield call(waitForUserSession);

    const { total, bids } = yield call(BidsService.fetchList, payload);

    yield put(resolvedAction(bidsTypes.FETCH_LIST, { total, bids }));
  } catch ({ message }) {
    yield put(rejectedAction(bidsTypes.FETCH_LIST, { message }));
  }
}

function* overrideBid ({ payload, meta }) {
  try {
    const { refetchBidsList } = meta;

    const data = yield call(BidsService.override, payload);

    if (refetchBidsList) {
      const params = yield select(getParams);

      yield put(bidsActions.fetchList(params, { useIsFetching: false }));

      yield take(resolved(bidsTypes.FETCH_LIST));
    }

    yield put(resolvedAction(bidsTypes.OVERRIDE, { data }));
    SnackbarService.showSuccess('Bid has been updated successfully'); // TODO: move text somewhere
  } catch ({ message }) {
    yield put(rejectedAction(bidsTypes.OVERRIDE, { message }));
    SnackbarService.showError(message || 'Something went wrong'); // TODO: move text somewhere
  }
}

function* fetchHistoricalBids ({ payload }) {
  try {
    const { total, bids } = yield call(BidsService.fetchHistoricalBids, payload);

    yield put(resolvedAction(bidsTypes.FETCH_HISTORICAL_BIDS, { total, bids }));
  } catch ({ message }) {
    yield put(rejectedAction(bidsTypes.FETCH_HISTORICAL_BIDS, { message }));
  }
}

function* pushHistoricalBids ({ payload }) {
  try {
    yield call(BidsService.pushHistoricalBids, payload);

    yield put(resolvedAction(bidsTypes.PUSH_HISTORICAL_BIDS));

    SnackbarService.showSuccess('The Bidder is pushing bids to networks. Summary email will come after the finish.'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(bidsTypes.PUSH_HISTORICAL_BIDS, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

/*
 * Watchers
 */


function* fetchBidsListWatcher () {
  yield takeLatest(bidsTypes.FETCH_LIST, fetchBidsList);
}

function* overrideBidWatcher () {
  yield takeLatest(bidsTypes.OVERRIDE, overrideBid);
}

function* fetchHistoricalBidsWatcher () {
  yield takeLatest(bidsTypes.FETCH_HISTORICAL_BIDS, fetchHistoricalBids);
}

function* pushHistoricalBidsWatcher () {
  yield takeLatest(bidsTypes.PUSH_HISTORICAL_BIDS, pushHistoricalBids);
}


export default [
  fetchBidsListWatcher,
  overrideBidWatcher,
  fetchHistoricalBidsWatcher,
  pushHistoricalBidsWatcher,
];

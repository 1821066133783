import { styled } from '@mui/system';


export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const Title = styled('p')(({ theme }) => ({
  ...theme.typography.smallTitle,
  textAlign: 'center',
  maxWidth: 500,
  marginBottom: theme.spacing(6),
}));

export const StyledButton = styled('div')(({ theme }) => ({
  '& + &': {
    marginLeft: theme.spacing(3.5),
  },
}));

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

import { styled } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { formControlLabelClasses } from '@mui/material/FormControlLabel';


export const TableHeadLabel = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 600,
}));

export const TableCellLabel = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
}));

export const StyledTable = styled('table')(({ theme }) => ({
  [`.${tableCellClasses.root}`]: {
    borderBottom: '1px solid',
    borderColor: theme.palette.background.main,
    padding: theme.spacing(2),
  },

  [`.${tableRowClasses.root}`]: {
    '&:last-child': {
      [`.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
        borderBottom: 'none',
      },
    },
  },

  [`.${tableCellClasses.root} + .${tableCellClasses.root}`]: {
    borderLeft: '1px solid',
    borderColor: theme.palette.background.main,
  },

  [`.${formControlLabelClasses.root}`]: {
    margin: 0,
    width: 'auto',
  },
}));

export const StyledTableContainer = styled('div')(({ theme }) => ({
  maxHeight: '60vh',
  overflow: 'scroll',
  border: '1px solid',
  borderColor: theme.palette.background.main,
  width: '100%',
}));

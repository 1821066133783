import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'USERS';

export const types = createConstants([
  'FETCH_AUTHORIZED_USER',
  'ADD_USER',
  'APPROVE_NEW_USER',
  'FETCH_USERS',
  'UPDATE_USER',
  'DELETE_USER',
  'FETCH_ACTIVITY_LOG',
], PREFIX);

export const actions = createActions({
  [types.FETCH_AUTHORIZED_USER]: () => null,
  [types.ADD_USER]: ({ email, name, coverLetter }) => ({ email, name, coverLetter }),
  [types.APPROVE_NEW_USER]: ({ email, password, code }) => ({ email, password, code }),
  [types.FETCH_USERS]: [
    () => null,
    (payload, { useIsFetching = true } = {}) => ({ useIsFetching }),
  ],
  [types.UPDATE_USER]: ({ id, data }) => ({ id, data }), // TODO: use desctructuring instead of 'data' ?
  [types.DELETE_USER]: ({ id }) => ({ id }),
  [types.FETCH_ACTIVITY_LOG]: ({ from, to } = {}) => ({ from, to }),
}, {
  prefix: PREFIX,
});


export default actions;

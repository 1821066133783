import { all, put, call, take, takeLatest, select } from 'redux-saga/effects';

import { resolved, resolvedAction, rejectedAction } from 'utils/actions';

import { types as appTypes } from 'core/app/actions';
import usersActions from 'core/users/actions';

import { authorizeRequests } from 'services/lionstudios-api';
import SnackbarService from 'services/snackbar';
import AnalyticsService from 'services/analytics';

import history from 'containers/router/history';
import { ROUTES } from 'containers/router/constants';
import { isAuthRoutes } from 'containers/router/utils';

import { extractUrlFromHistoryLocation } from 'utils/history';

import AuthService from './service';
import authActions, { types as authTypes } from './actions';
import { getToken, getRedirectUrl } from './selectors';
import { isTokenValid } from './utils';

/*
 * Sagas
 */

function* init () {
  yield all([
    put(authActions.loadAuth()),
  ]);
}

function* loadAuth () {
  try {
    const token = yield select(getToken);

    if (!isTokenValid(token)) {
      throw new Error('Authorization has expired');
    }

    yield put(resolvedAction(authTypes.LOAD_AUTH, { token }));
  } catch ({ message }) {
    const redirectUrl = extractUrlFromHistoryLocation();
    yield put(rejectedAction(authTypes.LOAD_AUTH, {
      message,
      redirectUrl: isAuthRoutes(redirectUrl) ? null : redirectUrl,
    }));
  }
}

function* logout () {
  try {
    AnalyticsService.setUserId(null);

    yield put(resolvedAction(authTypes.LOGOUT));
  } catch (error) {
    yield put(rejectedAction(authTypes.LOGOUT, { message: error.message }));
  }
}

function* handleSuccessLogin ({ payload: { withRedirect } = {} } = {}) {
  try {
    const token = yield select(getToken);
    const redirectUrl = yield select(getRedirectUrl);

    authorizeRequests(token);

    yield put(authActions.authSuccess());
    yield put(usersActions.fetchAuthorizedUser());

    if (withRedirect) {
      history.replace(redirectUrl || `/${ROUTES.GAMES}`);
    }
  } catch (error) {
    yield put(authActions.logout());
  }
}

function* signIn ({ payload }) {
  try {
    const { token, user } = yield call(AuthService.signIn, payload);

    yield put(resolvedAction(authTypes.SIGN_IN, { token, user, withRedirect: true }));
  } catch ({ message }) {
    yield put(rejectedAction(authTypes.SIGN_IN, { message }));
    SnackbarService.showError(
      'Invalid email or password', // TODO: move text somewhere
      {
        vertical: 'bottom',
        horizontal: 'left',
      },
    );
  }
}

function* recoverPassword ({ payload }) {
  try {
    yield call(AuthService.recoverPassword, payload);

    yield put(resolvedAction(authTypes.RECOVER_PASSWORD));
    SnackbarService.showSuccess('Reset password link has been sent successfully. Please, check you email for further actions'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(authTypes.RECOVER_PASSWORD, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* resetPassword ({ payload }) {
  try {
    yield call(AuthService.resetPassword, payload);

    yield put(resolvedAction(authTypes.RESET_PASSWORD));
    SnackbarService.showSuccess('Your password has been reset successfully. Please, sign in with the new password'); // TODO: move text somewhere
    history.replace(`/${ROUTES.SIGN_IN}`);
  } catch (error) {
    yield put(rejectedAction(authTypes.RESET_PASSWORD, { message: error.message }));
    SnackbarService.showError(
      'Your reset password link is invalid or expired', // TODO: move text somewhere
      {
        vertical: 'bottom',
        horizontal: 'left',
      },
    );
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(appTypes.INIT);
  yield call(init);
}

function* loadAuthWatcher () {
  yield takeLatest(authTypes.LOAD_AUTH, loadAuth);
}

function* signInWatcher () {
  yield takeLatest(authTypes.SIGN_IN, signIn);
}

function* logoutWatcher () {
  yield takeLatest(authTypes.LOGOUT, logout);
}

function* recoverPasswordWatcher () {
  yield takeLatest(authTypes.RECOVER_PASSWORD, recoverPassword);
}

function* resetPasswordWatcher () {
  yield takeLatest(authTypes.RESET_PASSWORD, resetPassword);
}

function* handleSuccessLoginWatcher () {
  yield takeLatest([
    resolved(authTypes.LOAD_AUTH),
    resolved(authTypes.SIGN_IN),
    authTypes.SIGN_IN_WITH_OKTA,
  ], handleSuccessLogin);
}


export default [
  initWatcher,
  loadAuthWatcher,
  logoutWatcher,
  handleSuccessLoginWatcher,
  signInWatcher,
  recoverPasswordWatcher,
  resetPasswordWatcher,
];

import { isString } from 'utils';
import { getUTCDateFromTimestamp } from 'utils/date';


export const approveNewUserRequestModel = ({
  email,
  password,
}) => ({
  email,
  password,
});

export const userModel = ({
  id,
  isSuperAdmin,
  isManager,
  emailAddress,
  fullName,
}) => ({
  id,
  isAdmin: isSuperAdmin,
  isManager,
  email: emailAddress,
  name: fullName,
  // TODO: add other fields if needed
});

export const inviteUserRequestModel = ({
  email,
  name,
  coverLetter,
}) => ({
  email,
  coverLetter,
  fullName: name,
});

export const fetchActivityLogModel = ({
  from,
  to,
}) => ({
  from: getUTCDateFromTimestamp(from),
  to: getUTCDateFromTimestamp(to, true),
});

export const activityLogModel = (response) => response.map(({
  channel,
  element,
  game,
  new_value: nextValue,
  old_value: prevValue,
  path,
  timestamp,
  type,
  user,
}, index) => ({
  channel,
  element,
  game,
  nextValue,
  prevValue,
  path: isString(path) ? path : {
    channel: path?.campaign_set || null,
    campaign: path?.campaign || null,
    geo: path?.geo || null,
    source: path?.source || null,
  },
  timestamp,
  type,
  user,
  id: index,
})).sort((itemA, itemB) => itemB.timestamp - itemA.timestamp);

import { styled } from '@mui/system';


export const StyledText = styled('p')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const WrapperButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

import PropTypes from 'prop-types';


export const bidPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  campaign: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired, // TODO: use oneOf
  country: PropTypes.string.isRequired, // TODO: use oneOf
  dateRange: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  delta: PropTypes.number.isRequired,
  deltaPct: PropTypes.number.isRequired,
  game: PropTypes.string.isRequired,
  installs: PropTypes.number.isRequired,
  rpi: PropTypes.number.isRequired,
  spend: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired, // TODO: use oneOf
  targetBid: PropTypes.number,
  originalBid: PropTypes.number,
  growthManager: PropTypes.string,
  channelStatus: PropTypes.string.isRequired, // TODO: use oneOf
  lionAppId: PropTypes.number,
});


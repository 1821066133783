import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import authActions from 'core/auth/actions';
import { getIsFetching, getIsAuthorized } from 'core/auth/selectors';

import { ROUTES } from 'containers/router/constants';

import useFormik from 'hooks/use-formik';

import Button from 'components/common/button';
import Input from 'components/common/input';
import PasswordInput from 'components/common/password-input';
import AuthLayout from 'components/auth/layout';
import FormField from 'components/auth/form-field';
import LinkButton from 'components/auth/link-button';

import { FORM_KEYS } from 'pages/auth/constants';
import { SignInValidationSchema } from 'pages/auth/validation';

import Background from 'assets/images/sign-in-bg.png';


const SignIn = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthorized = useSelector(getIsAuthorized);
  const isFetching = useSelector(getIsFetching);

  const handleFormSubmit = useCallback((data) => {
    dispatch(authActions.signIn(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { handleSubmit, handleBlur, values, errors, isValid, touched, handleChangeAndTrimValue } = useFormik({
    initialValues: {
      [FORM_KEYS.EMAIL]: '',
      [FORM_KEYS.PASSWORD]: '',
    },
    onSubmit: handleFormSubmit,
    validationSchema: SignInValidationSchema,
  });

  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthLayout
      image={Background}
      title="Sign in to your Account"
    >
      <form
        onSubmit={handleSubmit}
      >
        <FormField>
          <Input
            name={FORM_KEYS.EMAIL}
            value={values[FORM_KEYS.EMAIL]}
            error={touched[FORM_KEYS.EMAIL] && errors[FORM_KEYS.EMAIL]}
            onChange={handleChangeAndTrimValue}
            onBlur={handleBlur}
            label="Email address"
          />
        </FormField>

        <FormField>
          <PasswordInput
            name={FORM_KEYS.PASSWORD}
            value={values[FORM_KEYS.PASSWORD]}
            error={touched[FORM_KEYS.PASSWORD] && errors[FORM_KEYS.PASSWORD]}
            onChange={handleChangeAndTrimValue}
            onBlur={handleBlur}
            label="Password"
          />
        </FormField>

        <FormField
          className="buttons-wrapper"
        >
          <Button
            type="submit"
            disabled={!isValid}
            isFetching={isFetching}
            fullWidth
          >
            Sign In
          </Button>
        </FormField>

        <FormField>
          <LinkButton
            to={`/${ROUTES.RECOVER_PASSWORD}`}
          >
            Forgot your password?
          </LinkButton>
        </FormField>
      </form>
    </AuthLayout>
  );
});


export default SignIn;

import { DEFAULT_LAST_PUSH_TIME_TIMESTAMP } from './constants';


export const configModel = ({
  minimum_bid_threshold: minimumBidThreshold,
  minimum_daily_installs: totalInstalls,
  roas_change_warning: roasChangingWarning,
  minimum_daily_sources_installs: minimumDailySourcesInstalls,
  bid_expires_days: bidExpiresDays,
}) => ({
  minimumBidThreshold,
  totalInstalls,
  roasChangingWarning: Math.floor(roasChangingWarning * 100),
  minimumDailySourcesInstalls,
  bidExpiresDays,
});

export const serverConfigModel = ({
  minimumBidThreshold,
  totalInstalls,
  roasChangingWarning,
  minimumDailySourcesInstalls,
  bidExpiresDays,
}) => ({
  minimum_bid_threshold: Number(minimumBidThreshold),
  minimum_daily_installs: Number(totalInstalls),
  roas_change_warning: Number(roasChangingWarning) / 100,
  minimum_daily_sources_installs: Number(minimumDailySourcesInstalls),
  bid_expires_days: Number(bidExpiresDays),
});

export const statusModel = ({
  lastPush,
  nextPush,
  status,
  text,
}) => ({
  lastPush: lastPush || DEFAULT_LAST_PUSH_TIME_TIMESTAMP,
  nextPush,
  status,
  text,
});

export const createPushFrequencyModel = ({
  channel,
  campaignType,
  cooldownLimit,
}) => ({
  CHANNEL: channel,
  CAMPAIGN_TYPE: campaignType,
  COOLDOWN_LIMIT: cooldownLimit,
});

export const createPushFrequencyResponseModel = ({
  CHANNEL: channel,
  CAMPAIGN_TYPE: campaignType,
  COOLDOWN_LIMIT: cooldownLimit,
}) => ({
  channel,
  campaignType,
  cooldownLimit,
});

export const createChangeLimitModel = ({
  channel,
  campaignType,
  limitType,
  filterList,
}) => ({
  CHANNEL: channel,
  CAMPAIGN_TYPE: campaignType,
  LIMIT_TYPE: limitType,
  FILTER_LIST: filterList,
});

export const createChangeLimitResponseModel = ({
  CHANNEL: channel,
  CAMPAIGN_TYPE: campaignType,
  LIMIT_TYPE: limitType,
  FILTER_LIST: filterList,
}) => ({
  channel,
  campaignType,
  limitType,
  filterList,
});

import { useSearchParams } from 'react-router-dom';

import { parseParams } from './utils';


export function useQueryParams (params) {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = parseParams(searchParams, params);

  // TODO: memoize params somehow ?

  return [query, setSearchParams];
}

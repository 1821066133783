import React from 'react';
import { DateTime } from 'luxon';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { EMAIL_STATUSES } from 'core/settings/constants';

import SelectField from 'components/common/select-field';
import Switch from 'components/common/switch';
import AdaptiveText from 'components/common/adaptive-text';
import DataGridEditableField from 'components/common/data-grid-editable-field';
import Indicator from 'components/settings/status-indicator';

import { getStatusInitialValue } from 'utils/grid';

import { FIELDS } from './constants';


export const getConfig = (data, onSubmit, onDelete) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.EMAIL,
      headerName: 'Email',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.EMAIL_STATUS,
      headerName: 'Status',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'data-grid__editable-cell',
      options: EMAIL_STATUSES,
      renderCell: ({ row }) => {
        const { id } = row;
        const value = getStatusInitialValue(EMAIL_STATUSES, row, FIELDS.EMAIL_STATUS);

        return (
          <DataGridEditableField
            key={id}
            valueKey={FIELDS.EMAIL_STATUS}
            onSubmit={onSubmit}
            component={SelectField}
            handlerKey="onChange"
            propsToSubmit={{ id }}
            componentProps={{
              options: EMAIL_STATUSES,
              indicator: Indicator,
              initialValue: value?.id,
              readOnly: true,
            }}
          />
        );
      },
    },
    {
      field: FIELDS.LAST_LOGIN_TIME,
      headerName: 'Last Page View Time',
      flex: 2,
      type: 'string',
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value ? DateTime.fromJSDate(new Date(value), { zone: 'UTC' }).toLocaleString(DateTime.DATETIME_FULL) : null}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.IS_SUPER_ADMIN,
      headerName: 'Is Admin',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          valueKey={FIELDS.IS_SUPER_ADMIN}
          onSubmit={onSubmit}
          component={Switch}
          handlerKey="onChange"
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
          }}
        />
      ),
    },
    {
      field: FIELDS.IS_MANAGER,
      headerName: 'Is Manager',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          valueKey={FIELDS.IS_MANAGER}
          onSubmit={onSubmit}
          component={Switch}
          handlerKey="onChange"
          propsToSubmit={{
            id: row.id,
          }}
          componentProps={{
            initialValue: value,
          }}
        />
      ),
    },
    {
      field: FIELDS.ACTIONS,
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => onDelete(row.id)}
        >
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
  ],
});

import * as yup from 'yup';

import { KEYS } from './constants';


const REQUIRED_TEXT = 'One of fields is required';
const INVALID_DATE = 'Invalid Date';
const FROM_DATE_ERROR = 'From should be earlier or equal To';

export const ValidationSchema = yup.object().shape({
  [KEYS.FROM]: yup.date().nullable().typeError(INVALID_DATE)
    .when(
      [KEYS.TO],
      (to, schema) => to && !Number.isNaN(Date.parse(to)) ? schema.max(to, FROM_DATE_ERROR) : schema,
    ).when([KEYS.TO], {
      is: (date) => !date,
      then: (schema) => schema.required(REQUIRED_TEXT),
      otherwise: (schema) => schema,
    }),
  [KEYS.TO]: yup.date().nullable().typeError(INVALID_DATE),
});

import LionstudiosApi from 'services/lionstudios-api';

import { userModel, inviteUserRequestModel, approveNewUserRequestModel, fetchActivityLogModel, activityLogModel } from './models';
import { USERS_URL, INVITE_URL, ACTIVITY_LOG_URL } from './constants';


const UsersService = {
  fetchAuthorizedUser: async () => {
    const response = await LionstudiosApi.get(`${USERS_URL}/me`);

    return userModel(response.data);
  },

  addUser: async (data) => {
    const payload = inviteUserRequestModel(data);
    const response = await LionstudiosApi.post(`${USERS_URL}/${INVITE_URL}`, payload);

    return response.data;
  },

  approveNewUser: async ({ code, ...data }) => {
    const payload = approveNewUserRequestModel(data);
    const response = await LionstudiosApi.post(`${USERS_URL}/${INVITE_URL}/${code}`, payload);

    return response.data;
  },

  fetchUsers: async () => {
    const response = await LionstudiosApi.get(USERS_URL);

    return response.data;
  },

  updateUser: async (id, data) => {
    const response = await LionstudiosApi.post(`${USERS_URL}/${id}`, data);

    return response.data;
  },

  deleteUser: async (id) => {
    const response = await LionstudiosApi.delete(`${USERS_URL}/${id}`);

    return response.data;
  },

  fetchActivityLog: async (payload) => {
    const params = fetchActivityLogModel(payload);
    const response = await LionstudiosApi.get(`${USERS_URL}/${ACTIVITY_LOG_URL}`, {
      params,
    });

    return activityLogModel(response.data);
  },

  fetchActivityLogsCsv: async () => {
    const response = await LionstudiosApi.get(`${USERS_URL}/${ACTIVITY_LOG_URL}/export`, {
      responseType: 'blob',
    });

    return new Blob([response.data]);
  },
};


export default UsersService;

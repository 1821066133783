import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal';

import PushFrequencyForm from 'components/settings/push-frequency/form';


const PushFrequencyModal = memo(({
  onCancel,
  isOpen,
  ...formProps
}) => (
  <Modal
    isOpen={isOpen}
    onCancel={onCancel}
  >
    <PushFrequencyForm
      {...formProps}
      onCancel={onCancel}
    />
  </Modal>
));


PushFrequencyModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

PushFrequencyModal.defaultProps = {

};


export default PushFrequencyModal;

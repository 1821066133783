import { createSelector } from 'reselect';


const getLocalState = (state) => state.managers;
const getIsListFetchingState = (state) => getLocalState(state).isListFetching;
const getListState = (state) => getLocalState(state).list;


export const getIsManagersFetching = createSelector(
  getIsListFetchingState,
  (isFetching) => isFetching,
);

export const getManagersList = createSelector(
  getListState,
  (list) => list,
);


import styled from 'styled-components';


export const Wrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(5),
}));

export const Button = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledIcon = styled('div')(({ theme, $rotate = 0, $withoutMargin }) => ({
  marginRight: $withoutMargin ? 0 : theme.spacing(1.5),
  color: theme.palette.primary.light,
  transform: `rotate(${$rotate}deg)`,
}));

export const Title = styled('p')(({ theme }) => ({
  color: theme.palette.background.main,
  minWidth: '70px',
}));

export const PopperContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  padding: theme.spacing(1.25, 2),
  width: '350px',
}));

export const KeyText = styled('p')(({ theme }) => ({
  color: theme.palette.textPrimary.light,
  fontSize: '12px',
}));

export const ValueText = styled('p')(({ theme }) => ({
  color: theme.palette.background.main,
  fontSize: '12px',
  fontWeight: '600',
  width: '50%',
  whiteSpace: 'nowrap',
}));

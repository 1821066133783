import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

import Input from 'components/common/input';

import { TRANSITION } from 'containers/theme-provider/constants';

import { PopperContainer, PopperStyles } from './styles';


const CommentPopper = memo(({ anchorRef, isOpen, onSubmit, onCancel, title }) => {
  const [value, setValue] = useState('');

  const isValueEmpty = !value.trim().length;

  const handleChange = useCallback((event) => {
    setValue(event.nativeEvent.target.value);
  }, []);

  const handleCancel = useCallback(() => {
    setValue('');
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    if (isValueEmpty) {
      return;
    }

    onSubmit(value);
    setValue('');
  }, [onSubmit, value, isValueEmpty]);

  const handleKeyPress = useCallback((event) => {
    switch (event.keyCode) {
      case 13:
        handleSubmit();
        event.preventDefault();
        break;

      case 27:
        event.preventDefault();
        handleCancel();
        break;

      default:
        break;
    }

    event.stopPropagation();
  }, [handleSubmit, handleCancel]);

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef}
      placement="top"
      transition
      style={PopperStyles}
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={TRANSITION.DURATION}
        >
          <PopperContainer>
            <Input
              value={value}
              onChange={handleChange}
              placeholder={title}
              autoFocus
              onKeyDown={handleKeyPress}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      aria-label="save"
                      size="small"
                      onClick={handleSubmit}
                      disabled={isValueEmpty}
                    >
                      <SaveIcon
                        fontSize="inherit"
                        color={isValueEmpty ? 'grey' : 'secondary'}
                      />
                    </IconButton>

                    <IconButton
                      aria-label="save"
                      size="small"
                      onClick={handleCancel}
                    >
                      <ClearIcon
                        fontSize="inherit"
                        color="grey"
                      />
                    </IconButton>
                  </>
                ),
              }}
            />
          </PopperContainer>
        </Fade>
      )}
    </Popper>
  );
});


CommentPopper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  anchorRef: PropTypes.shape({}),
};

CommentPopper.defaultProps = {
  anchorRef: null,
};


export default CommentPopper;

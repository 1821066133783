import { capitalize } from 'utils';

import { CHANNELS_OPTIONS as CORE_CHANNELS_OPTIONS, CHANNEL_STATUS_KEYS } from 'core/games/constants';
import { FILTERS_KEYS, DEFAULT_FILTER_OPERATOR, OPERATORS, EXECUTE_STATUS_KEYS, FIELDS, BIDDER_CAMPAIGN_STATUS_KEYS } from 'core/bids/constants';

import { TYPES } from 'hooks/use-query-params';


export const COLUMN_WIDTH = {
  SM: 100,
  MD: 140,
  LG: 200,
};

export const CHANNELS_OPTIONS = CORE_CHANNELS_OPTIONS.map(({ name, ...rest }) => ({
  ...rest,
  name: capitalize(name),
}));

export const COMMON_INPUT_PROPS = {
  placeholder: 'Type here...',
};

export const COMMON_CHIP_PROPS = {
  size: 'small',
};

export const SELECT_DEFAULT_VALUE = [];

export const DEFAULT_CONTROL_VALUE = {
  operator: null,
  value: null,
};

export const DEFAULT_FILTER_VALUE = {
  operator: DEFAULT_FILTER_OPERATOR,
  value: null,
};

const DEFAULT_STATUS_FILTER_VALUE = {
  ...DEFAULT_CONTROL_VALUE,
  value: [EXECUTE_STATUS_KEYS.READY],
};

const DEFAULT_CHANNEL_STATUS_FILTER_VALUE = {
  ...DEFAULT_CONTROL_VALUE,
  value: [CHANNEL_STATUS_KEYS.READY, CHANNEL_STATUS_KEYS.LIVE],
};

const DEFAULT_INSTALLS_FILTER_VALUE = {
  operator: OPERATORS.IS_GREATER_OR_EQUAL,
  value: '175',
};

const DEFAULT_DELTA_FILTER_VALUE = {
  operator: OPERATORS.IS_NOT_EQUAL,
  value: '0',
};

const DEFAULT_BIDDER_CAMPAIGN_STATUS_FILTER_VALUE = {
  ...DEFAULT_CONTROL_VALUE,
  value: BIDDER_CAMPAIGN_STATUS_KEYS.ENABLED,
};

export const DEFAULT_FILTERS_VALUES = {
  [FILTERS_KEYS.PAGE]: 0,
  [FILTERS_KEYS.LIMIT]: 50, // TODO: use grid default value
  [FILTERS_KEYS.STORE_ID]: DEFAULT_CONTROL_VALUE,
  [FILTERS_KEYS.CAMPAIGN]: DEFAULT_CONTROL_VALUE,
  [FILTERS_KEYS.COUNTRY]: DEFAULT_CONTROL_VALUE,
  [FILTERS_KEYS.CHANNEL]: DEFAULT_CONTROL_VALUE,
  [FILTERS_KEYS.GROWTH_MANAGER]: DEFAULT_CONTROL_VALUE,
  [FILTERS_KEYS.SPEND]: DEFAULT_FILTER_VALUE,
  [FILTERS_KEYS.STATUS]: DEFAULT_STATUS_FILTER_VALUE,
  [FILTERS_KEYS.INSTALLS]: DEFAULT_INSTALLS_FILTER_VALUE,
  [FILTERS_KEYS.DELTA]: DEFAULT_DELTA_FILTER_VALUE,
  [FILTERS_KEYS.CHANNEL_STATUS]: DEFAULT_CHANNEL_STATUS_FILTER_VALUE,
  [FILTERS_KEYS.BID_OVERRIDE]: DEFAULT_FILTER_VALUE,
  [FILTERS_KEYS.BIDDER_CAMPAIGN_STATUS]: DEFAULT_BIDDER_CAMPAIGN_STATUS_FILTER_VALUE,
  [FILTERS_KEYS.PLATFORM]: DEFAULT_CONTROL_VALUE,
};

export const FIELDS_TO_FILTER_KEYS = {
  [FIELDS.DAY]: FILTERS_KEYS.DAY,
  [FIELDS.GAME]: FILTERS_KEYS.GAME,
  [FIELDS.CHANNEL]: FILTERS_KEYS.CHANNEL,
  [FIELDS.GROWTH_MANAGER]: FILTERS_KEYS.GROWTH_MANAGER,
  [FIELDS.CAMPAIGN]: FILTERS_KEYS.CAMPAIGN,
  [FIELDS.EXECUTE_STATUS]: FILTERS_KEYS.STATUS,
  [FIELDS.COUNTRY]: FILTERS_KEYS.COUNTRY,
  [FIELDS.SPEND]: FILTERS_KEYS.SPEND,
  [FIELDS.INSTALLS]: FILTERS_KEYS.INSTALLS,
  [FIELDS.YESTERDAY_BID]: FILTERS_KEYS.BID,
  [FIELDS.TARGET_BID]: FILTERS_KEYS.TARGET_BID,
  [FIELDS.EXECUTED_BID]: FILTERS_KEYS.EXECUTED_BID,
  [FIELDS.BID_OVERRIDE]: FILTERS_KEYS.BID_OVERRIDE,
  [FIELDS.DELTA]: FILTERS_KEYS.DELTA,
  [FIELDS.DELTA_PCT]: FILTERS_KEYS.DELTA_PCT,
  [FIELDS.RPI]: FILTERS_KEYS.RPI,
  [FIELDS.ROAS_DELTA]: FILTERS_KEYS.ROAS_DELTA,
  [FIELDS.GOAL_ROAS]: FILTERS_KEYS.GOAL_ROAS,
  [FIELDS.STORE_ID]: FILTERS_KEYS.STORE_ID,
  [FIELDS.DATE_RANGE]: FILTERS_KEYS.DATE_RANGE,
  [FIELDS.OVERRIDE_MANAGER]: FILTERS_KEYS.OVERRIDE_MANAGER,
  [FIELDS.OVERRIDE_DATE]: FILTERS_KEYS.OVERRIDE_DATE,
  [FIELDS.OVERRIDE_EXPIRES]: FILTERS_KEYS.OVERRIDE_EXPIRES,
  [FIELDS.CHANNEL_STATUS]: FILTERS_KEYS.CHANNEL_STATUS,
  [FIELDS.LION_APP_ID]: FILTERS_KEYS.LION_APP_ID,
  [FIELDS.BIDDER_CAMPAIGN_STATUS]: FILTERS_KEYS.BIDDER_CAMPAIGN_STATUS,
  [FIELDS.PLATFORM]: FILTERS_KEYS.PLATFORM,
};

export const QUERY_PARAMS = {
  [FILTERS_KEYS.PAGE]: 'p',
  [FILTERS_KEYS.LIMIT]: 'l',
  [FILTERS_KEYS.STORE_ID]: 'str_id',
  [FILTERS_KEYS.CAMPAIGN]: 'cmp',
  [FILTERS_KEYS.COUNTRY]: 'cntr',
  [FILTERS_KEYS.CHANNEL]: 'chnl',
  [FILTERS_KEYS.GROWTH_MANAGER]: 'gr_mngr',
  [FILTERS_KEYS.SPEND]: 'spnd',
  [FILTERS_KEYS.STATUS]: 'stts',
  [FILTERS_KEYS.INSTALLS]: 'instls',
  [FILTERS_KEYS.DELTA]: 'dlt',
  [FILTERS_KEYS.CHANNEL_STATUS]: 'chnl_stts',
  [FILTERS_KEYS.BID_OVERRIDE]: 'b_ovrd',
  [FILTERS_KEYS.BIDDER_CAMPAIGN_STATUS]: 'b_cmp_stts',
  [FILTERS_KEYS.PLATFORM]: 'plt',
};

export const EXTENDED_QUERY_PARAMS = {
  ...QUERY_PARAMS,
  [FILTERS_KEYS.SORT]: 'sort',
};

export const QUERY_PARAMS_WITH_TYPES = {
  [QUERY_PARAMS[FILTERS_KEYS.PAGE]]: TYPES.NUMBER,
  [QUERY_PARAMS[FILTERS_KEYS.LIMIT]]: TYPES.NUMBER,
  [QUERY_PARAMS[FILTERS_KEYS.STORE_ID]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.CAMPAIGN]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.COUNTRY]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.CHANNEL]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.GROWTH_MANAGER]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.SPEND]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.STATUS]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.INSTALLS]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.DELTA]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.CHANNEL_STATUS]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.BID_OVERRIDE]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.BIDDER_CAMPAIGN_STATUS]]: TYPES.STRING,
  [QUERY_PARAMS[FILTERS_KEYS.PLATFORM]]: TYPES.STRING,
  [EXTENDED_QUERY_PARAMS[FILTERS_KEYS.SORT]]: TYPES.STRING,
};

import { DEFAULT_PLATFORM } from 'core/games/constants';
import { findObjectByProp, isValueExists } from 'utils';

import { KEYS } from './constants';


export const getTitle = (isEdit, isGameArchived) => {
  if (isGameArchived) {
    return 'Archived Game';
  }

  if (isEdit) {
    return 'Edit Game';
  }

  return 'Add Game';
};

export const getInitialValues = (values, managersList) => ({
  [KEYS.NAME]: values?.[KEYS.NAME] || '',
  [KEYS.STORE_ID]: values?.[KEYS.STORE_ID] || '',
  [KEYS.PLATFORM]: values?.[KEYS.PLATFORM] || DEFAULT_PLATFORM.id,
  [KEYS.LION_APP]: values?.lionAppId ? {
    name: values.lionAppName,
    id: values.lionAppId,
  } : null,
  [KEYS.STATUS]: values?.status || null,
  [KEYS.GROWTH_MANAGER]: isValueExists(values?.[KEYS.GROWTH_MANAGER]) ? findObjectByProp(managersList, 'id', values[KEYS.GROWTH_MANAGER]) : null,
});

import { createActions } from 'redux-actions';
import { createConstants } from 'utils';


export const PREFIX = 'V4_GEOS';

export const types = createConstants([
  'FETCH_LIST',
], PREFIX);

export const actions = createActions({
  [types.FETCH_LIST]: (payload) => payload,
}, {
  prefix: PREFIX,
});


export default actions;

import { styled } from '@mui/system';


export const NewLionAppTitle = styled('span')(({ theme }) => ({
  color: theme.palette.linkPrimary.main,
}));

export const OptionName = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
}));

export const OptionId = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.textPrimary.dark,
}));

export const ListBoxStyles = {
  maxHeight: '192px',
};

import React, { memo } from 'react';
import { GridOverlay } from '@mui/x-data-grid-pro';

import NoRowsImage from 'assets/images/no-rows.svg';

import Text from 'components/common/text';

import {
  NoRowsWrapper,
  NoRowsTitle,
} from './styles';


const NoRows = memo(() => (
  <NoRowsWrapper as={GridOverlay}>
    <img
      src={NoRowsImage}
      alt=""
    />

    <NoRowsTitle as={Text}>There are no data here, but don&apos;t be upset!</NoRowsTitle>
  </NoRowsWrapper>
));


export default NoRows;

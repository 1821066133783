import { put, call, takeLatest, all, take } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from 'utils/actions';

import { CYCLE_RUNNING_ERROR } from 'constants/text';

import SnackbarService from 'services/snackbar';

import { waitForUserSession, subscribeSagaToUserSession } from 'core/app/sagas';
import { types as appTypes } from 'core/app/actions';

import { APP_STATUSES } from './constants';
import SettingsService from './service';
import settingsActions, { types as settingsTypes } from './actions';


/*
 * Sagas
 */

function* init () {
  yield all([
    call(subscribeSagaToUserSession, fetchAppStatus),
    call(subscribeSagaToUserSession, fetchCountriesList),
    call(subscribeSagaToUserSession, fetchConfig),
  ]);
}

function* fetchCountriesList () {
  yield put(settingsActions.fetchCountries());
}

function* fetchConfig () {
  try {
    yield call(waitForUserSession);

    const data = yield call(SettingsService.fetchConfig);

    yield put(resolvedAction(settingsTypes.FETCH_CONFIG, { data }));
  } catch (error) {
    yield put(rejectedAction(settingsTypes.FETCH_CONFIG, { message: error.message }));
  }
}

function* updateConfig ({ payload: { data } }) {
  try {
    yield call(SettingsService.updateConfig, data);

    yield put(resolvedAction(settingsTypes.UPDATE_CONFIG, { data }));
    SnackbarService.showSuccess('Config has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.UPDATE_CONFIG, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* fetchAppStatus () {
  try {
    const data = yield call(SettingsService.fetchAppStatus);

    yield put(resolvedAction(settingsTypes.FETCH_APP_STATUS, { data }));
    if (data.status !== APP_STATUSES.READY) {
      SnackbarService.showError(CYCLE_RUNNING_ERROR, undefined, null);
    }
  } catch (error) {
    yield put(rejectedAction(settingsTypes.FETCH_APP_STATUS, { message: error.message }));
  }
}

function* fetchCountries () {
  try {
    yield call(waitForUserSession);

    const data = yield call(SettingsService.fetchCountries);

    yield put(resolvedAction(settingsTypes.FETCH_COUNTRIES, { data }));
  } catch (error) {
    yield put(rejectedAction(settingsTypes.FETCH_COUNTRIES, { message: error.message }));
  }
}

function* fetchPushFrequencies () {
  try {
    yield call(waitForUserSession);

    const data = yield call(SettingsService.fetchPushFrequencies);

    yield put(resolvedAction(settingsTypes.FETCH_PUSH_FREQUENCIES, { data }));
  } catch (error) {
    yield put(rejectedAction(settingsTypes.FETCH_PUSH_FREQUENCIES, { message: error.message }));
  }
}

function* createPushFrequency ({ payload }) {
  try {
    yield call(SettingsService.createPushFrequency, payload);

    const data = yield call(SettingsService.fetchPushFrequencies);

    yield put(resolvedAction(settingsTypes.FETCH_PUSH_FREQUENCIES, { data }));

    SnackbarService.showSuccess('Push Frequency has been created successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.CREATE_PUSH_FREQUENCY, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* deletePushFrequency ({ payload: { id } }) {
  try {
    yield call(SettingsService.deletePushFrequency, id);

    yield put(resolvedAction(settingsTypes.DELETE_PUSH_FREQUENCY));

    const data = yield call(SettingsService.fetchPushFrequencies);

    yield put(resolvedAction(settingsTypes.FETCH_PUSH_FREQUENCIES, { data }));

    SnackbarService.showSuccess('Push Frequency has been deleted successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.DELETE_PUSH_FREQUENCY, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* updatePushFrequency ({ payload: { id, data } }) {
  try {
    yield call(SettingsService.updatePushFrequency, id, data);

    yield put(resolvedAction(settingsTypes.UPDATE_PUSH_FREQUENCY, { data }));
    SnackbarService.showSuccess('Push Frequency has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.UPDATE_PUSH_FREQUENCY, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* fetchChangeLimit () {
  try {
    yield call(waitForUserSession);

    const data = yield call(SettingsService.fetchChangeLimit);

    yield put(resolvedAction(settingsTypes.FETCH_CHANGE_LIMIT, { data }));
  } catch (error) {
    yield put(rejectedAction(settingsTypes.FETCH_CHANGE_LIMIT, { message: error.message }));
  }
}

function* createChangeLimit ({ payload }) {
  try {
    yield call(SettingsService.createChangeLimit, payload);

    const data = yield call(SettingsService.fetchChangeLimit);

    yield put(resolvedAction(settingsTypes.FETCH_CHANGE_LIMIT, { data }));

    SnackbarService.showSuccess('Change Limit has been created successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.CREATE_CHANGE_LIMIT, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* deleteChangeLimit ({ payload: { id } }) {
  try {
    yield call(SettingsService.deleteChangeLimit, id);

    yield put(resolvedAction(settingsTypes.DELETE_CHANGE_LIMIT));

    const data = yield call(SettingsService.fetchChangeLimit);

    yield put(resolvedAction(settingsTypes.FETCH_CHANGE_LIMIT, { data }));

    SnackbarService.showSuccess('Change Limit has been deleted successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.DELETE_CHANGE_LIMIT, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* updateChangeLimit ({ payload: { id, data } }) {
  try {
    yield call(SettingsService.updateChangeLimit, id, data);

    // yield put(resolvedAction(settingsTypes.UPDATE_CHANGE_LIMIT, { data }));

    const fetchData = yield call(SettingsService.fetchChangeLimit);
    yield put(resolvedAction(settingsTypes.FETCH_CHANGE_LIMIT, { data: fetchData }));

    SnackbarService.showSuccess('Change Limit has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(settingsTypes.UPDATE_CHANGE_LIMIT, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(appTypes.INIT);
  yield call(init);
}

function* fetchConfigWatcher () {
  yield takeLatest(settingsTypes.FETCH_CONFIG, fetchConfig);
}

function* updateConfigWatcher () {
  yield takeLatest(settingsTypes.UPDATE_CONFIG, updateConfig);
}

function* fetchAppStatusWatcher () {
  yield takeLatest(settingsTypes.FETCH_APP_STATUS, fetchAppStatus);
}

function* fetchCountriesWatcher () {
  yield takeLatest(settingsTypes.FETCH_COUNTRIES, fetchCountries);
}

function* fetchPushFrequenciesWatcher () {
  yield takeLatest(settingsTypes.FETCH_PUSH_FREQUENCIES, fetchPushFrequencies);
}

function* createPushFrequencyWatcher () {
  yield takeLatest(settingsTypes.CREATE_PUSH_FREQUENCY, createPushFrequency);
}

function* deletePushFrequencyWatcher () {
  yield takeLatest(settingsTypes.DELETE_PUSH_FREQUENCY, deletePushFrequency);
}

function* updatePushFrequencyWatcher () {
  yield takeLatest(settingsTypes.UPDATE_PUSH_FREQUENCY, updatePushFrequency);
}

function* fetchChangeLimitWatcher () {
  yield takeLatest(settingsTypes.FETCH_CHANGE_LIMIT, fetchChangeLimit);
}

function* createChangeLimitWatcher () {
  yield takeLatest(settingsTypes.CREATE_CHANGE_LIMIT, createChangeLimit);
}

function* deleteChangeLimitWatcher () {
  yield takeLatest(settingsTypes.DELETE_CHANGE_LIMIT, deleteChangeLimit);
}

function* updateChangeLimitWatcher () {
  yield takeLatest(settingsTypes.UPDATE_CHANGE_LIMIT, updateChangeLimit);
}


export default [
  initWatcher,
  fetchConfigWatcher,
  updateConfigWatcher,
  fetchAppStatusWatcher,
  fetchCountriesWatcher,
  fetchPushFrequenciesWatcher,
  createPushFrequencyWatcher,
  deletePushFrequencyWatcher,
  updatePushFrequencyWatcher,
  fetchChangeLimitWatcher,
  createChangeLimitWatcher,
  deleteChangeLimitWatcher,
  updateChangeLimitWatcher,
];

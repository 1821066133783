import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getGameById } from 'core/games/selectors';

import GridHeaderWithTooltip from 'components/common/grid-header-with-tooltip';

import { getDateRangeFromDates } from 'utils/date';


const GridHeaderWithWeekRangeTooltip = memo(({ headerName, ...props }) => {
  const { gameId } = useParams();
  const { syncRange: { start, end } } = useSelector(getGameById(gameId));

  const title = getDateRangeFromDates(start, end);

  return (
    <GridHeaderWithTooltip
      headerName={headerName}
      title={title}
      {...props}
    />
  );
});


GridHeaderWithWeekRangeTooltip.propTypes = {
  headerName: PropTypes.string.isRequired,
};

export default GridHeaderWithWeekRangeTooltip;

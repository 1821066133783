import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

import Modal from 'components/common/modal';
import Text from 'components/common/text';
import Button from 'components/common/button';
import BidOverrideTable from 'components/bids/bid-override-table';

import { Title, ButtonsWrapper, StyledButton } from './styles';


const BidOverrideModal = memo(({ data, isOpen, onCancel, onSubmit }) => (
  <Modal
    isOpen={isOpen}
    onCancel={onCancel}
  >
    <Title
      as={Text}
    >
      New Override Bid
    </Title>

    <BidOverrideTable
      data={data}
    />

    <ButtonsWrapper
      as={Stack}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <StyledButton
        as={Button}
        variant="outlined"
        color="error"
        onClick={onCancel}
      >
        Cancel
      </StyledButton>

      <StyledButton
        as={Button}
        onClick={onSubmit}
        type="submit"
      >
        Confirm
      </StyledButton>
    </ButtonsWrapper>
  </Modal>
));


BidOverrideModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    campaign: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    bidOverride: PropTypes.string.isRequired,
    prevBidOverride: PropTypes.number,
  }),
};

BidOverrideModal.defaultProps = {
  data: {},
};


export default BidOverrideModal;

export const updateCampaignModel = ({
  bidderStatus,
}) => ({
  ENABLED: bidderStatus ? 1 : 0,
});

export const listModel = (response) => response
  .map(({
    CAMPAIGN_ID: id,
    CAMPAIGN_NAME: name,
  }) => ({
    id,
    name,
  }));

import React, { memo, useState, Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import MuiTabs from '@mui/material/Tabs';

import Paper from 'components/common/paper';

import Tab from './tab';
import { StyledTabs } from './styles';


const Tabs = memo(({ config, onTabChange, initialTab, ...otherProps }) => {
  const [value, setValue] = useState(() => initialTab);

  const handleChange = useCallback((nextValue) => {
    setValue(nextValue);
    onTabChange(nextValue);
  }, [onTabChange]);

  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);

  return (
    <>
      <StyledTabs
        as={MuiTabs}
        value={value}
        onChange={handleChange}
        {...otherProps}
      >
        {config.map(({ title, renderIcon, selectedCount, onSelectionChange, color, key }, index) => (
          <Tab
            key={key}
            tabKey={key}
            value={index}
            onClick={handleChange}
            label={title}
            isActive={index === value}
            renderIcon={renderIcon}
            selectedCount={selectedCount}
            onSelectionChange={onSelectionChange}
            color={color}
          />
        ))}
      </StyledTabs>

      <Paper>
        {config.map(({ content }, index) => (
          <Fragment
            key={index} // eslint-disable-line react/no-array-index-key
          >
            {value === index ? content : null}
          </Fragment>
        ))}
      </Paper>
    </>
  );
});


Tabs.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    renderIcon: PropTypes.func,
    content: PropTypes.element,
  })).isRequired,
  onTabChange: PropTypes.func,
  initialTab: PropTypes.number,
};

Tabs.defaultProps = {
  onTabChange: () => {},
  initialTab: 0,
};


export default Tabs;

import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


export const rootPersistConfig = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'appState',
    'auth',
    'games',
    'managers',
    'networks',
    'settings',
    'campaigns',
    'users',
    'campaignsV4',
    'geosV4',
    'gamesV4',
    'networksV4',
  ],
  storage,
};

import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import appActions from 'core/app/actions';


const AppStateProvider = memo(({ init, children }) => {
  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
});


AppStateProvider.propTypes = {
  init: PropTypes.func.isRequired,
  children: PropTypes.node,
};

AppStateProvider.defaultProps = {
  children: null,
};


const mapDispatchToProps = {
  init: appActions.init,
};


export default connect(null, mapDispatchToProps)(AppStateProvider);

import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import NotFoundImage from 'assets/images/404.svg';

import Text from 'components/common/text';
import Button from 'components/common/button';

import { ROUTES } from 'containers/router/constants';

import { Wrapper, StyledText, ContentWrapper } from './styles';


const NotFound = memo(() => {
  const navigate = useNavigate();

  const handleBackHomeClick = useCallback(() => {
    navigate(`/${ROUTES.GAMES}`, { replace: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <ContentWrapper>
        <img
          src={NotFoundImage}
          alt="404"
        />

        <StyledText as={Text}>Oops! We can’t find that page! </StyledText>

        <Button onClick={handleBackHomeClick}>Back Home</Button>
      </ContentWrapper>
    </Wrapper>
  );
});

export default NotFound;

import React from 'react';

import { extractRowKey } from 'utils/extracters';
import { getNumberFormatter, formatNumberToPercentage } from 'utils/formatters';

import { ROUTES } from 'containers/router/constants';

import { FILTERS_KEYS, EXECUTE_STATUS_OPTIONS, FIELDS, BIDDER_CAMPAIGN_STATUS_OPTIONS } from 'core/bids/constants';
import { GAME_DETAILS_LIST, CHANNEL_STATUSES, CHANNELS, PLATFORMS_OPTIONS, PLATFORM_ICONS } from 'core/games/constants';

import Link from 'components/common/link';
import AdaptiveText from 'components/common/adaptive-text';
import ChannelIcon from 'components/games/channel-icon';
import TextWithIndicator from 'components/games/text-with-indicator';
import Indicator from 'components/games/status-indicator';
import DeltaSpendText from 'components/bids/delta-spend-text';
import ExecutedBidField from 'components/bids/executed-bid-field';
import DataGridEditableField from 'components/common/data-grid-editable-field';
import DataGridFilters from 'components/common/data-grid-filter';
import Autocomplete from 'components/common/autocomplete';
import Select from 'components/common/uncontrolled-select';
import Input from 'components/common/input';
import DataGridFilterField from 'components/common/data-grid-filter-field';
import GridHeaderWithTooltip from 'components/common/grid-header-with-tooltip';

import { QUERY_PARAMS } from 'pages/games/details/constants';
import { getTabIndexByKey } from 'components/games/details-tabs/utils';


import {
  getSelectedOptions,
  getSelectedOptionsForSubmit,
  getSelectedGamesOptionsForSubmit,
  renderSelectedArrayValue,
  renderMultiSelectValue,
  renderSelectValue,
  extractInputValue,
  renderInputValue,
  extractKey,
  extractGameKey,
  getSelectProps,
  getHandleMultiSelectChange,
  getGameOptionLabel,
  renderSelectedGamesValue,
  getSelectedOption,
} from './utils';
import {
  COLUMN_WIDTH,
  CHANNELS_OPTIONS,
  SELECT_DEFAULT_VALUE,
  COMMON_INPUT_PROPS,
  COMMON_CHIP_PROPS,
} from './constants';


export const getConfig = (data, onBidUpdate, isAppStatusReady, handleGameLinkClick, handleCampaignLinkClick) => ({
  // TODO: add types for columns
  rows: data || [],
  columns: [
    {
      field: FIELDS.INDEX,
      headerName: 'Row #',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: COLUMN_WIDTH.SM,
      renderCell: ({ value }) => value + 1,
    },
    {
      field: FIELDS.DAY,
      headerName: 'Day',
      align: 'center',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      renderHeader: ({ colDef }) => (
        <GridHeaderWithTooltip
          headerName={colDef.headerName}
          title="The final day of the Bid is included in the calculation. Calculation Day in its turn was a day after that day."
        />
      ),
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.GAME,
      headerName: 'Game Name',
      headerAlign: 'center',
      flex: 1,
      minWidth: COLUMN_WIDTH.MD,
      renderCell: ({ value, row }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          disableTooltip
        >
          <Link
            to={`/${ROUTES.GAMES}/${row.gameId}`}
            onClick={handleGameLinkClick}
          >
            {value}
          </Link>
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.PLATFORM,
      headerName: 'Platform',
      minWidth: COLUMN_WIDTH.SM,
      headerAlign: 'center',
      align: 'center',
      options: PLATFORMS_OPTIONS,
      renderCell: ({ value }) => {
        if (!value) {
          return null;
        }

        const Icon = PLATFORM_ICONS[value];

        return (
          <Icon />
        );
      },
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      width: COLUMN_WIDTH.SM,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.CAMPAIGN,
      headerName: 'Campaign Name',
      flex: 1,
      headerAlign: 'center',
      minWidth: COLUMN_WIDTH.MD,
      renderCell: ({ value, row }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          disableTooltip
        >
          <Link
            to={`/${ROUTES.GAMES}/${row.gameId}?${QUERY_PARAMS.TAB}=${getTabIndexByKey(GAME_DETAILS_LIST.CAMPAIGNS)}&${QUERY_PARAMS.SELECTION_CAMPAIGNS}=${row.campaignId}`}
            onClick={handleCampaignLinkClick}
          >
            {value}
          </Link>
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.COUNTRY,
      headerName: 'Country',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.SPEND,
      headerName: 'Spend',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: getNumberFormatter(),
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.INSTALLS,
      headerName: 'Installs',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: getNumberFormatter(0),
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.YESTERDAY_BID,
      headerName: 'Yesterdays Bid',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: getNumberFormatter(),
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.TARGET_BID,
      headerName: 'Target Bid',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: getNumberFormatter(),
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.EXECUTED_BID,
      headerName: 'Executed Bid',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      type: 'number',
      valueFormatter: getNumberFormatter(),
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.BID_OVERRIDE,
      headerName: 'Bid Override',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      type: 'number',
      cellClassName: 'data-grid__editable-cell data-grid__editable-cell--with-text-field',
      renderCell: ({ value, row }) => (
        <DataGridEditableField
          key={extractRowKey(row, FIELDS.BID_OVERRIDE)}
          valueKey={FIELDS.BID_OVERRIDE}
          onSubmit={onBidUpdate}
          component={ExecutedBidField}
          propsToSubmit={{
            bidKey: row.bidKey,
            channel: row.channel,
            campaign: row.campaign,
            country: row.country,
            prevBidOverride: row[FIELDS.BID_OVERRIDE],
          }}
          componentProps={{
            override: row.override,
            initialValue: value,
            disabled: !isAppStatusReady,
            withoutComment: row.channel === CHANNELS.APPLOVIN,
          }}
          passSubmitPropsToComponent
        />
      ),
    },
    {
      field: FIELDS.DELTA,
      headerName: 'Delta $',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      renderCell: ({ value }) => (
        <DeltaSpendText
          value={value}
        />
      ),
    },
    {
      field: FIELDS.DELTA_PCT,
      headerName: 'Delta %',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: formatNumberToPercentage,
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.RPI,
      headerName: 'D0 RPI',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: getNumberFormatter(),
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal D0 ROAS',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      valueFormatter: formatNumberToPercentage,
      renderCell: ({ value, formattedValue }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {formattedValue}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.DATE_RANGE,
      headerName: 'Date Range',
      headerAlign: 'center',
      width: COLUMN_WIDTH.LG,
      renderHeader: ({ colDef }) => (
        <GridHeaderWithTooltip
          headerName={colDef.headerName}
          title="When the cycle starts, the Bidder takes bids data for the last 7 days, not including the actual day when the cycle run. It's expected if you see the DATE RANGE as the day before yesterday."
        />
      ),
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.ROAS_DELTA,
      headerName: 'D0 ROAS Delta',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CHANNEL_STATUS,
      headerName: 'Channel Status',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ row }) => (
        <TextWithIndicator
          value={row[FIELDS.CHANNEL_STATUS]}
          indicator={Indicator}
        />
      ),
    },
    {
      field: FIELDS.CAMPAIGN_STATUS,
      headerName: 'Campaign Status',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ row }) => (
        <TextWithIndicator
          value={row[FIELDS.CAMPAIGN_STATUS]}
          indicator={Indicator}
        />
      ),
    },
    {
      field: FIELDS.BIDDER_CAMPAIGN_STATUS,
      headerName: 'Bidder Campaign Status',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      type: 'string',
      renderCell: ({ value }) => {
        const { name } = getSelectedOption(value, BIDDER_CAMPAIGN_STATUS_OPTIONS);

        return (
          <AdaptiveText size={AdaptiveText.SIZE.SM}>{name}</AdaptiveText>
        );
      },
    },
    {
      field: FIELDS.GROWTH_MANAGER,
      headerName: 'Growth Manager',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.OVERRIDE_MANAGER,
      headerName: 'Override Manager',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.OVERRIDE_DATE,
      headerName: 'Override Date Key',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.OVERRIDE_EXPIRES,
      headerName: 'Override Expires Key',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.STORE_ID,
      headerName: 'Store ID',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.LION_APP_ID,
      headerName: 'LionApp ID',
      headerAlign: 'center',
      width: COLUMN_WIDTH.SM,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.EXECUTE_STATUS,
      headerName: 'Bid Status',
      headerAlign: 'center',
      width: COLUMN_WIDTH.MD,
      type: 'string',
      cellClassName: 'data-grid__editable-cell',
      renderCell: ({ row }) => (
        <TextWithIndicator
          value={row[FIELDS.EXECUTE_STATUS]}
          indicator={Indicator}
        />
      ),
    },
  ],
});

export const getFiltersConfig = ({
  games,
  campaigns,
  countries,
  managers,
  isGamesFetching,
  isCampaignsFetching,
  isCountriesFetching,
  isManagersFetching,
}) => ([
  {
    key: FILTERS_KEYS.GROWTH_MANAGER,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Growth Manager',
    extractFilterValue: (value) => getSelectedOptionsForSubmit(value, managers),
    renderSelectedValue: ({ value }, operator, defaultPlaceholders) => renderSelectedArrayValue(value, managers, defaultPlaceholders),
    renderControl: ({ onChange, filterValue: { value } }) => {
      const selected = getSelectedOptions(value, managers);

      return (
        <DataGridFilterField
          component={Autocomplete}
          options={managers}
          initialValue={selected}
          loading={isManagersFetching}
          staticProps={{
            onSubmit: onChange,
            extractKey,
            blurOnSelect: false,
            multiple: true,
            useCheckboxes: true,
            disableCloseOnSelect: true,
            useVirtualization: true,
            inputProps: COMMON_INPUT_PROPS,
            ChipProps: COMMON_CHIP_PROPS,
          }}
        />
      );
    },
  },

  {
    key: FILTERS_KEYS.STORE_ID,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Game Name',
    extractFilterValue: (value) => getSelectedGamesOptionsForSubmit(value, games),
    renderSelectedValue: ({ value }, operator, { defaultPrefix }) => renderSelectedGamesValue(value, games, defaultPrefix),
    renderControl: ({ onChange, filterValue: { value } }) => {
      const selected = getSelectedOptions(value, games);

      return (
        <DataGridFilterField
          component={Autocomplete}
          options={games}
          initialValue={selected}
          loading={isGamesFetching}
          staticProps={{
            extractKey: extractGameKey,
            onSubmit: onChange,
            blurOnSelect: false,
            multiple: true,
            useCheckboxes: true,
            disableCloseOnSelect: true,
            useVirtualization: true,
            inputProps: COMMON_INPUT_PROPS,
            ChipProps: COMMON_CHIP_PROPS,
            getOptionLabel: getGameOptionLabel,
          }}
        />
      );
    },
  },

  {
    key: FILTERS_KEYS.PLATFORM,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Platform',
    renderSelectedValue: renderMultiSelectValue,
    renderFilterButton: ({ onChange, onSubmit, placeholder, filterValue: { value } }) => (
      <DataGridFilterField
        component={Select}
        selectProps={getSelectProps(placeholder, onSubmit)}
        value={value || SELECT_DEFAULT_VALUE}
        staticProps={{
          onChange: getHandleMultiSelectChange(onChange),
          options: PLATFORMS_OPTIONS,
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.CHANNEL,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Channel',
    renderSelectedValue: renderMultiSelectValue,
    renderFilterButton: ({ onChange, onSubmit, placeholder, filterValue: { value } }) => (
      <DataGridFilterField
        component={Select}
        selectProps={getSelectProps(placeholder, onSubmit)}
        value={value || SELECT_DEFAULT_VALUE}
        staticProps={{
          onChange: getHandleMultiSelectChange(onChange),
          options: CHANNELS_OPTIONS,
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.CAMPAIGN,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Campaign Name',
    extractFilterValue: (value) => getSelectedOptionsForSubmit(value, campaigns),
    renderSelectedValue: ({ value }, operator, defaultPlaceholders) => renderSelectedArrayValue(value, campaigns, defaultPlaceholders),
    renderControl: ({ onChange, filterValue: { value } }) => {
      const selected = getSelectedOptions(value, campaigns);

      return (
        <DataGridFilterField
          component={Autocomplete}
          options={campaigns}
          initialValue={selected}
          loading={isCampaignsFetching}
          staticProps={{
            onSubmit: onChange,
            extractKey,
            blurOnSelect: false,
            multiple: true,
            useCheckboxes: true,
            disableCloseOnSelect: true,
            useVirtualization: true,
            inputProps: COMMON_INPUT_PROPS,
            ChipProps: COMMON_CHIP_PROPS,
          }}
        />
      );
    },
  },

  {
    key: FILTERS_KEYS.COUNTRY,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Country',
    renderSelectedValue: ({ value }, operator, defaultPlaceholders) => renderSelectedArrayValue(value, countries, defaultPlaceholders),
    renderControl: ({ onChange, filterValue: { value } }) => {
      const selected = getSelectedOptions(value, countries);

      return (
        <DataGridFilterField
          component={Autocomplete}
          options={countries}
          initialValue={selected}
          loading={isCountriesFetching}
          staticProps={{
            onSubmit: onChange,
            extractKey,
            blurOnSelect: false,
            multiple: true,
            useCheckboxes: true,
            disableCloseOnSelect: true,
            useVirtualization: true,
            inputProps: COMMON_INPUT_PROPS,
            ChipProps: COMMON_CHIP_PROPS,
          }}
        />
      );
    },
  },

  {
    key: FILTERS_KEYS.CHANNEL_STATUS,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Channel Status',
    renderSelectedValue: renderMultiSelectValue,
    renderFilterButton: ({ onChange, onSubmit, placeholder, filterValue: { value } }) => (
      <DataGridFilterField
        component={Select}
        selectProps={getSelectProps(placeholder, onSubmit)}
        value={value || SELECT_DEFAULT_VALUE}
        staticProps={{
          onChange: getHandleMultiSelectChange(onChange),
          options: CHANNEL_STATUSES,
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.BIDDER_CAMPAIGN_STATUS,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Bidder Campaign Status',
    renderSelectedValue: ({ value }, operator, defaultPlaceholders) => renderSelectValue(value, BIDDER_CAMPAIGN_STATUS_OPTIONS, defaultPlaceholders),
    renderFilterButton: ({ onChange, onSubmit, placeholder, filterValue }) => {
      const { value } = filterValue;

      return (
        <DataGridFilterField
          component={Select}
          selectProps={getSelectProps(placeholder, null, false)}
          value={value}
          staticProps={{
            onChange: ({ target: { value: nextValue } }) => {
              onChange(nextValue);
              onSubmit({
                ...filterValue,
                value: nextValue,
              });
            },
            options: BIDDER_CAMPAIGN_STATUS_OPTIONS,
          }}
        />
      );
    },
  },

  {
    key: FILTERS_KEYS.STATUS,
    type: DataGridFilters.TYPES.CONTROL,
    label: 'Bid Status',
    renderSelectedValue: renderMultiSelectValue,
    renderFilterButton: ({ onChange, onSubmit, placeholder, filterValue: { value } }) => (
      <DataGridFilterField
        component={Select}
        selectProps={getSelectProps(placeholder, onSubmit)}
        value={value || SELECT_DEFAULT_VALUE}
        staticProps={{
          onChange: getHandleMultiSelectChange(onChange),
          options: EXECUTE_STATUS_OPTIONS,
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.SPEND,
    type: DataGridFilters.TYPES.FILTER,
    label: 'Spend',
    extractControlValue: extractInputValue,
    renderSelectedValue: renderInputValue,
    renderControl: ({ onChange, filterValue: { value } }) => (
      <DataGridFilterField
        component={Input}
        value={value || ''}
        staticProps={{
          onChange,
          type: 'number',
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.INSTALLS,
    type: DataGridFilters.TYPES.FILTER,
    label: 'Installs',
    extractControlValue: extractInputValue,
    renderSelectedValue: renderInputValue,
    renderControl: ({ onChange, filterValue: { value } }) => (
      <DataGridFilterField
        component={Input}
        value={value || ''}
        staticProps={{
          onChange,
          type: 'number',
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.DELTA,
    type: DataGridFilters.TYPES.FILTER,
    label: 'Delta $',
    extractControlValue: extractInputValue,
    renderSelectedValue: renderInputValue,
    renderControl: ({ onChange, filterValue: { value } }) => (
      <DataGridFilterField
        component={Input}
        value={value || ''}
        staticProps={{
          onChange,
          type: 'number',
        }}
      />
    ),
  },

  {
    key: FILTERS_KEYS.BID_OVERRIDE,
    type: DataGridFilters.TYPES.FILTER,
    label: 'Bid Override',
    extractControlValue: extractInputValue,
    renderSelectedValue: renderInputValue,
    renderControl: ({ onChange, filterValue: { value } }) => (
      <DataGridFilterField
        component={Input}
        value={value || ''}
        staticProps={{
          onChange,
          type: 'number',
        }}
      />
    ),
  },
]);

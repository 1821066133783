/* eslint-disable class-methods-use-this */

import { MODES, DEFAULT_ERROR_MESSAGE } from './constants';


class SnackbarService {
  constructor () {
    this.subscriber = null;
  }

  /**
   * Public methods
   */

  showError (message, position, autoHideDuration) {
    this.show({
      mode: MODES.ERROR,
      message: message || DEFAULT_ERROR_MESSAGE,
      position,
      autoHideDuration,
    });
  }

  showSuccess (message, position, autoHideDuration) {
    this.show({
      mode: MODES.SUCCESS,
      message,
      position,
      autoHideDuration,
    });
  }

  showWarning ({ message, position, component, autoHideDuration }) {
    this.show({
      mode: MODES.WARNING,
      message,
      position,
      autoHideDuration,
      component,
    });
  }

  show (config) {
    return new Promise((resolve) => {
      this.dispatchChanges(config, resolve);
    });
  }

  subscribe (callback) {
    this.subscriber = callback;

    return () => {
      this.subscriber = null;
    };
  }

  /**
   * Private methods
   */

  dispatchChanges (...params) {
    if (!this.subscriber) {
      throw new Error('There is no subscriber');
    }

    this.subscriber(...params);
  }
}


const service = new SnackbarService();

export default service;

import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import { types } from './actions';
import persistConfig from './persist';


const defaultState = {
  list: [],
  total: 0,
  isFetching: false,
};

const reducer = handleActions({
  [types.FETCH_LIST]: (state) => (
    update(state, {
      isFetching: { $set: true },
    })
  ),
  [resolved(types.FETCH_LIST)]: (state, { payload: { geos, total } }) => (
    update(state, {
      list: { $set: geos },
      total: { $set: total },
      isFetching: { $set: false },
    })
  ),
  [rejected(types.FETCH_LIST)]: (state) => (
    update(state, {
      isFetching: { $set: false },
    })
  ),

  [authTypes.LOGOUT]: () => defaultState,
}, defaultState);


export default persistReducer(persistConfig, reducer);

import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import AutocompleteField from 'components/v4/common/autocomplete-field';
import ConfirmationModal from 'components/v4/modals/confirmation';
import { RETURN_WINDOW_OPTIONS } from './constants';


const ReturnWindowField = memo(({ initialValue, onSubmit }) => {
  const [value, setValue] = useState(initialValue);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const handleFieldSubmit = useCallback((nextValue) => {
    setValue(nextValue);
    setIsConfirmationOpen(true);
  }, []);
  const handleConfirmSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(value);
    }
    setIsConfirmationOpen(false);
  }, [value, onSubmit]);
  const handleConfirmCancel = useCallback(() => {
    setValue(initialValue);
    setIsConfirmationOpen(false);
  }, [initialValue]);

  return (
    <>
      <AutocompleteField
        options={RETURN_WINDOW_OPTIONS}
        initialValue={RETURN_WINDOW_OPTIONS.find((v) => v.id === value)}
        onSubmit={handleFieldSubmit}
        disableClearable
      />
      {
        isConfirmationOpen && (
          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onConfirm={handleConfirmSubmit}
            onCancel={handleConfirmCancel}
            title="You are changing the Return Window of the campaign"
            description={(
              <span>
                This action will impact the LTV estimate and result in new bid/ROAS Target Settings
                <br />
                During the new calculation process, user will not be able to push bids/ROAS Targets for this campaign
                <br />
                Do you wish to submit changes?
              </span>
            )}
          />
        )
      }
    </>
  );
});

ReturnWindowField.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ReturnWindowField.defaultProps = {
  initialValue: null,
};

export default ReturnWindowField;

import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import { CYCLE_RUNNING_ERROR } from 'constants/text';

import { getIsGameUpdateFetching, getIsGoalRoasUpdateFetching, getGamesList, getIsGamesFetching } from 'core/games/selectors';
import { getManagersList } from 'core/managers/selectors';
import { getIsNetworkUpdateFetching } from 'core/networks/selectors';
import { getIsAppStatusReady } from 'core/settings/selectors';
import { getAuthorizedUser } from 'core/users/selectors';
import { GOAL_ROAS_PATHS, CHANNEL_STATUS_KEYS } from 'core/games/constants';
import gamesActions from 'core/games/actions';
import networksActions from 'core/networks/actions';

import AnalyticsService from 'services/analytics';

import { getUniqueElementsByProp, isArray, isValueExists } from 'utils';

import Button from 'components/common/button';
import GamesList from 'components/games/list';
import GameModal from 'components/games/game-modal';
import GamesSelectedRowsActions from 'components/games/games-selected-rows-actions';

import { getGamesbyIds } from './utils';

import { ButtonWrapper } from './styles';


const Games = memo(() => {
  const dispatch = useDispatch();
  const data = useSelector(getGamesList);
  const managersList = useSelector(getManagersList);
  const user = useSelector(getAuthorizedUser);

  const isFetching = useSelector(getIsGamesFetching);
  const isGameUpdateFetching = useSelector(getIsGameUpdateFetching);
  const isNetworkUpdateFetching = useSelector(getIsNetworkUpdateFetching);
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);
  const isAppStatusReady = useSelector(getIsAppStatusReady);
  const isUpdateFetching = isGameUpdateFetching || isNetworkUpdateFetching || isGoalRoasUpdateFetching;

  const [isGameModalOpen, setIsGameModalOpen] = useState(false);
  const [selectedRowsActionsAnchor, setSelectedRowsActionsAnchor] = useState(null);
  const [selectedGamesIds, setSelectedGamesIds] = useState([]);
  const [lastEditedRows, setLastEditedRows] = useState([]);

  const addToLastEditedRows = useCallback((rows, shouldSetEditedRows = true) => {
    if (!shouldSetEditedRows) {
      return;
    }

    setLastEditedRows((prevRows) => {
      const nextRows = isArray(rows) ? rows : [rows];

      return [...prevRows, ...nextRows];
    });
  }, []);

  const handleAddNewGameClick = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.NEW_GAME_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    setIsGameModalOpen(true);
  }, []);

  const handleAddNewGameCancel = useCallback(() => {
    setIsGameModalOpen(false);
  }, []);

  const selectedRowsActionsClick = useCallback((event) => {
    setSelectedRowsActionsAnchor(event.currentTarget);
  }, []);

  const selectedRowsActionsClose = useCallback(() => {
    setSelectedRowsActionsAnchor(null);
  }, []);

  const handleGameUpdate = useCallback((nextData, shouldSetEditedRows = true) => {
    const { id } = nextData;

    let eventName;
    if (isValueExists(nextData.status)) {
      eventName = AnalyticsService.NAMES.CHANGE_APP_STATUS;
    } else if (isValueExists(nextData.name)) {
      eventName = AnalyticsService.NAMES.CHANGE_APP_NAME;
    } else {
      eventName = AnalyticsService.NAMES.CHANGE_GROWTH_MANAGER;
    }

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: eventName,
      action: shouldSetEditedRows ? AnalyticsService.ACTIONS.CHANGE : AnalyticsService.ACTIONS.BULK_CHANGE,
    });

    dispatch(gamesActions.updateGame(nextData, { refetchGamesList: true }));
    addToLastEditedRows(id, shouldSetEditedRows);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNetworkUpdate = useCallback((nextData, shouldSetEditedRows = true) => {
    const { rowId } = nextData;

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.CHANGE_CHANNEL_STATUS,
      action: shouldSetEditedRows ? AnalyticsService.ACTIONS.CHANGE : AnalyticsService.ACTIONS.BULK_CHANGE,
    });

    dispatch(networksActions.updateNetwork(nextData, { refetchGamesList: true, refetchGame: false }));
    addToLastEditedRows(rowId, shouldSetEditedRows);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoalRoasUpdate = useCallback((nextData, fromBulkActions = false) => {
    const { rowId } = nextData;

    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      path: GOAL_ROAS_PATHS.NETWORK,
    }, {
      refetchGame: false,
      refetchGamesList: true,
      fromBulkActions,
    }));

    if (!fromBulkActions) {
      addToLastEditedRows(rowId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMultiChangeSubmit = useCallback((key, value, { isChannel, isChannelStatus, isGoalRoas, comment }) => {
    const gamesByIds = getGamesbyIds(selectedGamesIds, data);
    const selectedGames = isChannel ? gamesByIds : getUniqueElementsByProp(gamesByIds, 'appId');

    const editedRows = selectedGames.map(({ id, appId }) => isChannel ? id : appId);

    addToLastEditedRows(editedRows);

    selectedGames.forEach(({ appId, id, channelId, channel, goalRoas }) => {
      if (isChannel && !channel) {
        return;
      }

      if (isGoalRoas) {
        handleGoalRoasUpdate({
          channelId,
          gameId: appId,
          rowId: id,
          channel,
          [key]: value,
          comment,
          initialValue: goalRoas,
        }, true);
        return;
      }

      if (isChannel) {
        if (isChannelStatus && !isValueExists(goalRoas) && value === CHANNEL_STATUS_KEYS.LIVE) {
          return;
        }

        handleNetworkUpdate({
          id: channelId,
          gameId: appId,
          rowId: id,
          [key]: value,
        }, false);
        return;
      }

      handleGameUpdate({
        id: appId,
        [key]: value,
      }, false);
    });
  }, [selectedGamesIds, data, handleGameUpdate, handleNetworkUpdate, handleGoalRoasUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLastEditedRows([]);
  }, [data]);

  useEffect(() => {
    dispatch(gamesActions.fetchList());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.GAMES,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buttons = useMemo(() => {
    const BulkActionsButtonIcon = selectedRowsActionsAnchor ? ArrowDropUpIcon : ArrowDropDownIcon;

    let addNewGameButton;
    if (isAppStatusReady) {
      addNewGameButton = (
        <Button
          onClick={handleAddNewGameClick}
          startIcon={<AddIcon />}
        >
          New Game
        </Button>
      );
    } else {
      addNewGameButton = (
        <Tooltip
          placement="left"
          title={CYCLE_RUNNING_ERROR}
          disableFocusListener
          disableTouchListener
          arrow
        >
          <span>
            <Button
              onClick={handleAddNewGameClick}
              startIcon={<AddIcon />}
              disabled
            >
              New Game
            </Button>
          </span>

        </Tooltip>
      );
    }

    return (
      <ButtonWrapper
        as={Stack}
        direction="row"
        justifyContent="space-between"
      >
        <Button
          onClick={selectedRowsActionsClick}
          variant="outlined"
          disabled={!selectedGamesIds.length || isUpdateFetching}
          endIcon={<BulkActionsButtonIcon />}
        >
          Bulk Actions
        </Button>

        {addNewGameButton}
      </ButtonWrapper>
    );
  }, [selectedGamesIds.length, selectedRowsActionsAnchor, isUpdateFetching, isAppStatusReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const gamesSelectedRowsActionsStyles = useMemo(() => ({
    mt: 0.5,
  }), []);

  const gamesSelectedRowsActions = useMemo(() => (
    <GamesSelectedRowsActions
      anchor={selectedRowsActionsAnchor}
      managersList={managersList}
      close={selectedRowsActionsClose}
      onSubmit={handleMultiChangeSubmit}
      sx={gamesSelectedRowsActionsStyles}
      isAppStatusReady={isAppStatusReady}
    />
  ), [selectedRowsActionsAnchor, managersList, handleMultiChangeSubmit, isAppStatusReady]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {buttons}

      <GamesList
        data={data}
        managersList={managersList}
        isFetching={isFetching}
        isGameUpdateFetching={isGameUpdateFetching}
        isNetworkUpdateFetching={isNetworkUpdateFetching}
        isGoalRoasUpdateFetching={isGoalRoasUpdateFetching}
        selectedGamesIds={selectedGamesIds}
        lastEditedRows={lastEditedRows}
        onSelectedGamesIdsChange={setSelectedGamesIds}
        onGameUpdate={handleGameUpdate}
        onNetworkUpdate={handleNetworkUpdate}
        onGoalRoasUpdate={handleGoalRoasUpdate}
        isAppStatusReady={isAppStatusReady}
        user={user}
      />

      <GameModal
        isOpen={isGameModalOpen}
        onCancel={handleAddNewGameCancel}
      />

      {gamesSelectedRowsActions}
    </>
  );
});


export default Games;

import { cloneElement } from 'react';


export const renderRow = (props) => {
  const { data, index, style } = props;
  const { top } = style;
  const inlineStyle = {
    ...style,
    top,
  };

  return cloneElement(data[index], { style: inlineStyle });
};

import React, { memo, useCallback, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

import ClickAwayListener from '@mui/base/ClickAwayListener';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Text from 'components/common/text';

import { TRANSITION } from 'containers/theme-provider/constants';
import { ROUTES } from 'containers/router/constants';
import { POPPER_OPTIONS } from './constants';

import {
  Wrapper,
  Button,
  StyledIcon,
  Title,
  PopperContainer,
  StyledLink,
} from './styles';


const CommonMenuItem = memo(() => {
  const wrapperRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleClickAway = useCallback(() => setIsOpen(false), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper
        ref={wrapperRef}
      >
        <Button
          onClick={toggleIsOpen}
        >

          <Title as={Text}>
            App Settings
          </Title>

          <StyledIcon
            $withoutMargin
            as={isOpen ? ArrowDropUpIcon : ArrowDropDownIcon}
          />
        </Button>

        <Popper
          open={isOpen}
          anchorEl={wrapperRef.current}
          popperOptions={POPPER_OPTIONS}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              timeout={TRANSITION.DURATION}
            >
              <PopperContainer>

                <Stack
                  flexDirection="row"
                  alignItems="center"
                  width="50%"
                  mb={2}
                >
                  <StyledLink
                    to={`/${ROUTES.SETTINGS}`}
                    as={NavLink}
                  >
                    Legacy Settings
                  </StyledLink>
                </Stack>


                <Stack
                  flexDirection="row"
                  alignItems="center"
                  width="50%"
                  mb={2}
                >
                  <StyledLink
                    to={`/${ROUTES.SETTINGS_PUSH_FREQUENCY}`}
                    as={NavLink}
                  >
                    Push Frequency
                  </StyledLink>
                </Stack>


                <Stack
                  flexDirection="row"
                  alignItems="center"
                  width="50%"
                  mb={2}
                >
                  <StyledLink
                    to={`/${ROUTES.SETTINGS_CHANGE_LIMITS}`}
                    as={NavLink}
                  >
                    Change Limits
                  </StyledLink>
                </Stack>


                <Stack
                  flexDirection="row"
                  alignItems="center"
                  width="50%"
                >
                  <StyledLink
                    to={`/${ROUTES.SETTINGS_PERMISSIONS}`}
                    as={NavLink}
                  >
                    Permissions
                  </StyledLink>
                </Stack>


              </PopperContainer>
            </Fade>
          )}
        </Popper>

      </Wrapper>
    </ClickAwayListener>
  );
});

CommonMenuItem.propTypes = {

};

CommonMenuItem.defaultProps = {

};


export default CommonMenuItem;

import { put, call, takeLatest, take } from 'redux-saga/effects';

import { resolved, rejected, resolvedAction, rejectedAction } from 'utils/actions';

import { waitForUserSession } from 'core/app/sagas';

import history from 'containers/router/history';
import { ROUTES } from 'containers/router/constants';
import NotionLink from 'components/common/notion-link';

import SnackbarService from 'services/snackbar';

import GamesService from './service';
import gamesActions, { types as gamesTypes } from './actions';
import { filterOverrideListByPath } from './utils';


/*
 * Sagas
 */


function* refetchList () {
  yield put(gamesActions.fetchList({ useIsFetching: false }));
  yield take(resolved(gamesTypes.FETCH_LIST)); // TODO: do we need it ?
}

function* fetchList () {
  try {
    yield call(waitForUserSession);

    const data = yield call(GamesService.fetchList);

    yield put(resolvedAction(gamesTypes.FETCH_LIST, { data }));
  } catch (error) {
    yield put(rejectedAction(gamesTypes.FETCH_LIST, { message: error.message }));
  }
}

function* fetchGame ({ payload, meta }) {
  try {
    const { id } = payload;

    yield call(waitForUserSession);

    const data = yield call(GamesService.fetchGame, id);

    yield put(resolvedAction(gamesTypes.FETCH_GAME, { data }));
  } catch (error) {
    yield put(rejectedAction(gamesTypes.FETCH_GAME, { message: error.message }));
    const { redirectOnFail } = meta;
    if (redirectOnFail) {
      history.push(`/${ROUTES.GAMES}`);
    }
  }
}

function* fetchGames ({ payload }) {
  try {
    const { ids } = payload;

    yield call(waitForUserSession);

    const data = yield call(GamesService.fetchGames, ids);

    yield put(resolvedAction(gamesTypes.FETCH_GAMES, { data }));
  } catch (error) {
    yield put(rejectedAction(gamesTypes.FETCH_GAMES, { message: error.message }));
  }
}

function* updateGame ({ payload, meta }) {
  try {
    const { id, ...rest } = payload;
    const { redirectToList, refetchGame, refetchGamesList } = meta;

    const data = yield call(GamesService.updateGame, id, rest);
    // TODO: rebuild to parallel async calls
    if (refetchGamesList) {
      yield put(gamesActions.fetchList({ useIsFetching: false }));
      yield take([resolved(gamesTypes.FETCH_LIST), rejected(gamesTypes.FETCH_LIST)]);
    }

    if (refetchGame) {
      yield put(gamesActions.fetchGame({ id, useIsFetching: true }));
      yield take([resolved(gamesTypes.FETCH_GAME), rejected(gamesTypes.FETCH_GAME)]);
    }

    yield put(resolvedAction(gamesTypes.UPDATE_GAME, { data }));

    if (redirectToList) {
      history.push(`/${ROUTES.GAMES}`);
    }
    SnackbarService.showSuccess('Game has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(gamesTypes.UPDATE_GAME, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* createGame ({ payload }) {
  try {
    const data = yield call(GamesService.createGame, payload);

    yield put(gamesActions.fetchList({ useIsFetching: false }));

    yield put(resolvedAction(gamesTypes.CREATE_GAME, { data }));

    history.push(`/${ROUTES.GAMES}/${data.id}`);

    SnackbarService.showSuccess('Game has been created successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(gamesTypes.CREATE_GAME, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* deleteGame ({ payload }) {
  try {
    const { id } = payload;

    const data = yield call(GamesService.deleteGame, id);

    yield call(refetchList);

    yield put(resolvedAction(gamesTypes.DELETE_GAME, { data }));

    history.replace(`/${ROUTES.GAMES}`);
    SnackbarService.showSuccess('Game has been archived successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(gamesTypes.DELETE_GAME, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* updateGoalRoas ({ payload, meta }) {
  try {
    const { refetchGame, refetchGamesList, fromBulkActions } = meta;
    const { gameId, channelId, path, goalRoas, campaignId, comment, isAppLovin } = payload;

    const data = yield call(GamesService.updateGoalRoas, payload);

    if (refetchGame) {
      yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));
      yield take([resolved(gamesTypes.FETCH_GAME), rejected(gamesTypes.FETCH_GAME)]);
    }

    if (refetchGamesList) {
      yield put(gamesActions.fetchList({ useIsFetching: false }));
      yield take([resolved(gamesTypes.FETCH_LIST), rejected(gamesTypes.FETCH_LIST)]);
    }

    let filteredOverrideList = null;

    if (!fromBulkActions && !isAppLovin) {
      const overrideList = yield call(GamesService.fetchOverrideList, {
        gameId,
        channelId,
        goalRoas,
        campaignId,
        comment,
      });
      filteredOverrideList = filterOverrideListByPath(overrideList, path);
    }

    yield put(resolvedAction(gamesTypes.UPDATE_GOAL_ROAS, { data, overrideList: filteredOverrideList }));

    if (isAppLovin) {
      SnackbarService.showWarning({ component: NotionLink, autoHideDuration: null });
    } else {
      SnackbarService.showSuccess('Goal ROAS has been updated successfully'); // TODO: move text somewhere
    }
  } catch (error) {
    yield put(rejectedAction(gamesTypes.UPDATE_GOAL_ROAS, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* resetOverrideGoalRoasList ({ payload, meta }) {
  try {
    const { refetchGame } = meta;
    const { list } = payload;

    yield call(GamesService.resetOverrideGoalRoasList, list);

    if (refetchGame) {
      const [{ gameId }] = list;
      yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));
      yield take([resolved(gamesTypes.FETCH_GAME), rejected(gamesTypes.FETCH_GAME)]);
    }

    yield put(resolvedAction(gamesTypes.RESET_OVERRIDE_GOAL_ROAS_LIST));
    SnackbarService.showSuccess('Goal ROAS has been reseted successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(gamesTypes.RESET_OVERRIDE_GOAL_ROAS_LIST, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

function* resetOverrideGoalRoas ({ payload, meta }) {
  try {
    const { refetchGame } = meta;
    const { gameId, channelId, path, goalRoas, campaignId, comment, isAppLovin } = payload;

    yield call(GamesService.resetOverrideGoalRoas, payload);

    if (refetchGame) {
      yield put(gamesActions.fetchGame({ id: gameId, useIsFetching: false }));
      yield take([resolved(gamesTypes.FETCH_GAME), rejected(gamesTypes.FETCH_GAME)]);
    }

    let filteredOverrideList = [];
    if (!isAppLovin) {
      const overrideList = yield call(GamesService.fetchOverrideList, {
        gameId,
        channelId,
        goalRoas,
        campaignId,
        comment,
      });

      filteredOverrideList = filterOverrideListByPath(overrideList, path);
    }

    yield put(resolvedAction(gamesTypes.RESET_OVERRIDE_GOAL_ROAS, { overrideList: filteredOverrideList }));
    SnackbarService.showSuccess('Goal ROAS has been reseted successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(gamesTypes.RESET_OVERRIDE_GOAL_ROAS, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}

/*
 * Watchers
 */

function* fetchListWatcher () {
  yield takeLatest(gamesTypes.FETCH_LIST, fetchList);
}

function* fetchGameWatcher () {
  yield takeLatest(gamesTypes.FETCH_GAME, fetchGame);
}

function* fetchGamesWatcher () {
  yield takeLatest(gamesTypes.FETCH_GAMES, fetchGames);
}

function* updateGameWatcher () {
  yield takeLatest(gamesTypes.UPDATE_GAME, updateGame);
}

function* deleteGameWatcher () {
  yield takeLatest(gamesTypes.DELETE_GAME, deleteGame);
}

function* createGameWatcher () {
  yield takeLatest(gamesTypes.CREATE_GAME, createGame);
}

function* updateGoalRoasWatcher () {
  yield takeLatest(gamesTypes.UPDATE_GOAL_ROAS, updateGoalRoas);
}

function* resetOverrideGoalRoasListWatcher () {
  yield takeLatest(gamesTypes.RESET_OVERRIDE_GOAL_ROAS_LIST, resetOverrideGoalRoasList);
}

function* resetOverrideGoalRoasWatcher () {
  yield takeLatest(gamesTypes.RESET_OVERRIDE_GOAL_ROAS, resetOverrideGoalRoas);
}


export default [
  fetchListWatcher,
  updateGameWatcher,
  fetchGameWatcher,
  deleteGameWatcher,
  createGameWatcher,
  updateGoalRoasWatcher,
  fetchGamesWatcher,
  resetOverrideGoalRoasListWatcher,
  resetOverrideGoalRoasWatcher,
];

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';


import theme from './styles';
import globalStyles from './global-styles';


const ThemeProvider = memo(({ children }) => (
  <>
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
    <GlobalStyles styles={globalStyles} />
  </>
));


ThemeProvider.propTypes = {
  children: PropTypes.node,
};

ThemeProvider.defaultProps = {
  children: null,
};


export default ThemeProvider;

import { styled } from '@mui/system';
import { gridClasses } from '@mui/x-data-grid-pro';
import { checkboxClasses } from '@mui/material/Checkbox';

import { HORIZONTAL_PADDING_DATA_GRID_CELL } from 'constants/layout';


export const HEADER_HEIGHT = 56;
export const HEADER_WITH_FILTERS_HEIGHT = 90;
export const GRID_ROW_FETCHING_CLASS = 'data-grid__row--fetching';
export const NO_ROWS_OVERLAY_HEIGHT = 400;
export const ROW_HEIGHT = 34;
export const MIN_NUMBER_OF_ROWS = 10;

export const SelectedRowsActionsWrapper = styled('div')(() => ({
  height: 0,
  width: 0,
  overflow: 'hidden',
}));

export const Wrapper = styled('div')(({ theme, $hasSelectedRowsActions, $rowsExist }) => ({
  display: 'flex',
  minHeight: $rowsExist ? 'auto' : NO_ROWS_OVERLAY_HEIGHT,

  [`.${GRID_ROW_FETCHING_CLASS}`]: {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.primary.light,
      zIndex: 9999,
      opacity: 0.5,
    },
  },

  [`.${gridClasses.columnHeadersInner}`]: {
    alignItems: 'stretch',
  },

  [`.${gridClasses.columnHeaderCheckbox}`]: {
    position: 'relative',
    justifyContent: 'center',
    transform: $hasSelectedRowsActions ? `translate(${theme.spacing(-0.75)})` : 'none',

    [SelectedRowsActionsWrapper]: {
      height: '100%',
      width: '100%',
      overflow: 'visible',
      position: 'absolute',
      right: theme.spacing(-0.75),
      color: theme.palette.textPrimary.dark,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    [`.${checkboxClasses.root}`]: {
      padding: theme.spacing($hasSelectedRowsActions ? 0.25 : 1),
    },
  },

  '&.data-grid--with-filters': {
    [`.${gridClasses.columnHeaders}`]: {
      alignItems: 'stretch',
    },

    [`.${gridClasses.columnHeader}`]: {
      padding: 0,
      alignItems: 'flex-start',
    },

    [`.${gridClasses.columnHeaderCheckbox}`]: {
      alignItems: 'center',
    },

    [`.${gridClasses.columnHeaderTitleContainer}`]: {
      padding: 0,
      position: 'relative',
    },

    [`.${gridClasses.iconButtonContainer}`]: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1.25),
    },
  },
}));

export const HeaderCellWrapper = styled('div')({});

export const InputWrapper = styled('div')(({ theme }) => ({
  lineHeight: 1.4,
  color: theme.palette.primary.light,
  borderTop: `1px solid ${theme.palette.background.main}`,
  padding: theme.spacing(0, 0, 0, 3.75),
  position: 'relative',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(1),
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 5,
}));

export const StyledInput = styled('div')({
  lineHeight: 1.4,
});

export const StyledDataGrid = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  borderRadius: theme.shape.borderRadius,
  border: 0,
  color: theme.palette.textPrimary.main,
  fontSize: theme.typography.body2.fontSize,

  [HeaderCellWrapper]: {
    width: '100%',

    [`.${gridClasses.columnHeaderTitle}`]: {
      position: 'relative',
      lineHeight: 1.4,
      padding: theme.spacing(2, 1),
    },
  },

  [`.${gridClasses.row}`]: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.hover,

      '&:hover': {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`.${gridClasses.cell}`]: {
    borderBottom: `1px solid ${theme.palette.background.main}`,
    borderLeft: `1px solid ${theme.palette.background.main}`,
    padding: theme.spacing(0, HORIZONTAL_PADDING_DATA_GRID_CELL),

    '&:first-child': {
      borderLeft: 'none',
    },

    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },

  [`.${gridClasses.columnSeparator}`]: {
    display: 'none',
  },

  [`.${gridClasses.columnHeader}`]: {
    borderLeft: `1px solid ${theme.palette.background.main}`,

    '&:first-child': {
      borderLeft: 'none',
    },

    [`.${gridClasses.columnHeaderTitle}`]: {
      fontWeight: theme.typography.fontWeightBolder,
    },

    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },

  [`.${gridClasses.columnHeaders}`]: {
    borderBottom: `1px solid ${theme.palette.background.main}`,
  },

  [`.${gridClasses.overlay}`]: {
    zIndex: 5,
  },

  [`.${gridClasses.virtualScroller}`]: {
    minHeight: MIN_NUMBER_OF_ROWS * ROW_HEIGHT,
  },

  '.data-grid__editable-cell': {
    padding: 0,
    position: 'relative',

    '&.data-grid__editable-cell--with-text-field': {
      '& > div': {
        height: '100%',
        maxWidth: '100%',
      },
    },
  },
}));

export const NoRowsWrapper = styled('div')(() => ({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: NO_ROWS_OVERLAY_HEIGHT,
}));

export const NoRowsTitle = styled('p')(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.textPrimary.secondary,
  marginTop: theme.spacing(3.75),
}));

export const FilterPanelWrapper = styled('div')(() => ({

}));

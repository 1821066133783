import { styled } from '@mui/system';


export const ControlWrapper = styled('div')(({ theme }) => ({
  '& + &': {
    marginLeft: theme.spacing(2.5),
  },
}));

export const StyledButton = styled('button')(() => ({
  width: 72,
  minWidth: 'auto',
}));

export const StyledText = styled('button')(({ theme }) => ({
  maxWidth: 320,
  marginLeft: theme.spacing(1.25),
}));

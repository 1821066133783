import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/common/loader';

import { IFrame as BaseIFrame, Wrapper } from './styles';


const IFrame = memo(({ title, src, ...otherProps }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Wrapper>
      <BaseIFrame
        title={title}
        src={src}
        onLoad={handleLoad}
        {...otherProps}
      />

      {!isLoaded && (
        <Loader />
      )}
    </Wrapper>
  );
});


IFrame.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

IFrame.defaultProps = {

};


export default IFrame;

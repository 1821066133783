import { DateTime } from 'luxon';

/* eslint-disable class-methods-use-this */

// TODO: replace luxon with moment js

export class DateFormatService {
  formatStringToDate (dateString, format) {
    if (!dateString) {
      throw new Error('Date string should be provided to the DateFormatService.formatStringToDate method');
    }

    const defaultFormat = 'LL/dd/y';

    return DateTime.fromFormat(dateString, format || defaultFormat);
  }

  formatToJSDate (luxonDate) {
    if (!luxonDate || !(luxonDate instanceof DateTime)) {
      throw new Error('Luxon date object should be provided to the DateFormatService.formatToJSDate method');
    }

    return luxonDate.setZone('utc', { keepLocalTime: true }).toJSDate();
  }

  format (date, format) {
    if (!date) {
      throw new Error('Date object should be provided to the DateFormatService.formateDate method');
    }

    if (!(date instanceof Date)) {
      throw new Error('Value provided to the DateFormatService.format method should be a JS Date');
    }

    return DateTime.fromJSDate(date).toFormat(format);
  }
}

export default new DateFormatService();

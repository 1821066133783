import React, { memo, useState, useMemo, useCallback } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Input from 'components/common/input';

import { IconWrapper } from './styles';


const PasswordInput = memo(({ ...otherProps }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleIsPasswordVisible = useCallback(() => {
    setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
  }, []);

  const inputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        <IconWrapper
          as={IconButton}
          onClick={toggleIsPasswordVisible}
          edge="end"
        >
          {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconWrapper>
      </InputAdornment>
    ),
  }), [isPasswordVisible, toggleIsPasswordVisible]);

  return (
    <Input
      {...otherProps}
      InputProps={inputProps}
      type={isPasswordVisible ? 'text' : 'password'}
    />
  );
});


PasswordInput.propTypes = {

};

PasswordInput.defaultProps = {

};


export default PasswordInput;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Wrapper, CloseButton } from './styles';


const Modal = memo(({
  onCancel,
  isOpen,
  children,
  useCloseButton,
  modalStyles,
}) => (
  <MuiModal
    open={isOpen}
    onClose={onCancel}
  >
    <Wrapper
      as={Box}
      sx={modalStyles}
    >
      {useCloseButton && (
        <CloseButton
          as={IconButton}
          aria-label="close"
          onClick={onCancel}
        >
          <CloseIcon
            fontSize="inherit"
          />
        </CloseButton>
      )}

      {children}
    </Wrapper>
  </MuiModal>
));


Modal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  useCloseButton: PropTypes.bool,
  modalStyles: PropTypes.shape({}),
};

Modal.defaultProps = {
  useCloseButton: true,
  modalStyles: null,
};


export default Modal;

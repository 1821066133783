import { styled } from '@mui/system';


export const StyledLink = styled('div')(({ theme, $isInline }) => ({
  fontSize: theme.typography.body1.fontSize,
  borderBottom: 0,

  ...($isInline ? {} : {
    width: '100%',
    textAlign: 'center',
  }),

  '&:hover': {
    textDecoration: 'underline',
  },
}));

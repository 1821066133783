import { createAction } from 'redux-actions';


export const resolved = (type) => `${type}_SUCCESS`;

export const rejected = (type) => `${type}_FAILED`;

export const resolvedAction = (type, payload, meta) => createAction(
  resolved(type),
  (payloadData) => payloadData,
  () => ({ ...meta }),
)(payload);

export const rejectedAction = (type, payload, meta) => createAction(
  rejected(type),
  (payloadData) => payloadData,
  () => ({ ...meta }),
)(payload);

export const getAsyncAction = (action, payload) => new Promise((onSuccess, onError) => action({ ...payload, onSuccess, onError })); // eslint-disable-line no-promise-executor-return

import React from 'react';
import { DateTime } from 'luxon';

import { CHANNELS_OPTIONS } from 'core/games/constants';

import AdaptiveText from 'components/common/adaptive-text';
import ChannelIcon from 'components/games/channel-icon';
import Path from 'components/activity-logs/path';

import { getOptionsFromArray, isString } from 'utils';

import { FIELDS } from './constants';


export const getConfig = (data, initialData) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.TIMESTAMP,
      headerName: 'Date',
      flex: 0.8,
      type: 'string',
      sortable: true,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value ? DateTime.fromJSDate(new Date(value), { zone: 'UTC' }).toLocaleString(DateTime.DATETIME_MED) : null}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.USER,
      headerName: 'User',
      flex: 1.3,
      type: 'string',
      sortable: true,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.GAME,
      headerName: 'Game',
      flex: 1,
      type: 'string',
      sortable: true,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      options: CHANNELS_OPTIONS,
      flex: 0.7,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.PATH,
      headerName: 'Path',
      flex: 2.5,
      align: 'left',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ value, row }) => isString(row.path) ? value : (
        <Path
          path={row.path}
          pathText={value}
        />
      ),
    },
    {
      field: FIELDS.ELEMENT,
      headerName: 'Element',
      flex: 0.8,
      type: 'string',
      options: getOptionsFromArray(initialData, FIELDS.ELEMENT),
      sortable: true,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.STATUS,
      headerName: 'Status',
      flex: 0.8,
      type: 'string',
      sortable: true,
      options: getOptionsFromArray(initialData, FIELDS.STATUS),
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.VALUE,
      headerName: 'Value From/To',
      flex: 1,
      type: 'string',
      sortable: false,
      renderCell: ({ value }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          value={value}
        >
          {value}
        </AdaptiveText>
      ),
    },
  ],
});

import PropTypes from 'prop-types';

import { TYPES } from 'components/common/data-grid-filter/constants';


export const dataGridFiltersPropTypes = PropTypes.shape({
  key: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  label: PropTypes.string.isRequired,
  renderSelectedValue: PropTypes.func.isRequired,
  renderControl: PropTypes.func,
  extractControlValue: PropTypes.func,
  renderFilterButton: PropTypes.func,
});


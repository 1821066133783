import { styled } from '@mui/system';


export const StyledButton = styled('button')(({ theme }) => ({
  marginLeft: theme.spacing(3),

  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
  },
}));

export const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  width: '100%',
}));

export const StyledPaper = {
  paddingBottom: 5,
};

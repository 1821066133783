import PropTypes from 'prop-types';


export const configPropTypes = PropTypes.shape({
  minimumBidThreshold: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  totalInstalls: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  roasChangingWarning: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  minimumDailySourcesInstalls: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  bidExpiresDays: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
});

export const userPropTypes = PropTypes.shape({
  createdAt: PropTypes.number.isRequired,
  updatedAt: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  emailAddress: PropTypes.string.isRequired,
  emailStatus: PropTypes.string.isRequired,
  emailChangeCandidate: PropTypes.string,
  password: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  passwordResetToken: PropTypes.string,
  passwordResetTokenExpiresAt: PropTypes.number,
  emailProofToken: PropTypes.string,
  emailProofTokenExpiresAt: PropTypes.number,
  lastSeenAt: PropTypes.number.isRequired,
  lastLoginTime: PropTypes.string.isRequired,
});

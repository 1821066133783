import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import UncontrolledAutocomplete from 'components/common/uncontrolled-autocomplete';


const Autocomplete = memo(({
  initialValue,
  onSubmit,
  ...otherProps
}) => {
  const [value, setValue] = useState(() => initialValue);

  const handleChange = useCallback((nextValue, event, props, option) => {
    setValue(option);
    onSubmit(nextValue || null, event, props, option);
  }, [onSubmit]);

  return (
    <UncontrolledAutocomplete
      {...otherProps}
      onSubmit={handleChange}
      value={value}
    />
  );
});


Autocomplete.propTypes = {
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  inputProps: PropTypes.shape({}),
  autocompleteProps: PropTypes.shape({}),
  extractKey: PropTypes.func,
  renderNoOptions: PropTypes.func,
};

Autocomplete.defaultProps = {
  onSubmit: () => null,
  getOptionValue: (option) => option?.id,
  getOptionLabel: (option) => option?.name,
  initialValue: null,
  inputProps: {},
  autocompleteProps: {},
  extractKey: null,
  renderNoOptions: null,
};


export default Autocomplete;

import { styled } from '@mui/system';
import { buttonClasses } from '@mui/material/Button';


export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),

  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
  },
}));

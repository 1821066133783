import React from 'react';

import { ROUTES } from 'containers/router/constants';

import { FIELDS } from 'core/bids/constants';
import { GAME_DETAILS_LIST } from 'core/games/constants';

import Link from 'components/common/link';
import AdaptiveText from 'components/common/adaptive-text';
import ChannelIcon from 'components/games/channel-icon';
import DeltaSpendText from 'components/bids/delta-spend-text';

import { QUERY_PARAMS } from 'pages/games/details/constants';
import { getTabIndexByKey } from 'components/games/details-tabs/utils';



export const getConfig = (data) => ({
  rows: data || [],
  columns: [
    {
      field: FIELDS.GAME,
      headerName: 'Game Name',
      headerAlign: 'center',
      flex: 2,
      renderCell: ({ value, row }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          disableTooltip
        >
          <Link
            to={`/${ROUTES.GAMES}/${row.gameId}`}
          >
            {value}
          </Link>
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.CHANNEL,
      headerName: 'Channel',
      flex: 0.7,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        <ChannelIcon channel={value} />
      ),
    },
    {
      field: FIELDS.CAMPAIGN,
      headerName: 'Campaign Name',
      headerAlign: 'center',
      flex: 3,
      renderCell: ({ value, row }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
          disableTooltip
        >
          <Link
            to={`/${ROUTES.GAMES}/${row.gameId}?${QUERY_PARAMS.TAB}=${getTabIndexByKey(GAME_DETAILS_LIST.CAMPAIGNS)}&${QUERY_PARAMS.SELECTION_CAMPAIGNS}=${row.campaignId}`}
          >
            {value}
          </Link>
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.COUNTRY,
      headerName: 'Country',
      headerAlign: 'center',
      flex: 0.7,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.YESTERDAY_BID,
      headerName: 'Yesterdays Bid',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.TARGET_BID,
      headerName: 'Target Bid',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ value }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
        >
          {value}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.EXECUTED_BID,
      headerName: 'Executed Bid',
      headerAlign: 'center',
      flex: 1,
      type: 'number',
      renderCell: ({ value }) => (
        <AdaptiveText
          size={AdaptiveText.SIZE.SM}
        >
          {value}
        </AdaptiveText>
      ),
    },
    {
      field: FIELDS.BID_OVERRIDE,
      headerName: 'Bid Override',
      headerAlign: 'center',
      flex: 1,
      type: 'number',
      align: 'left',
    },
    {
      field: FIELDS.DELTA,
      headerName: 'Delta $',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ value }) => (
        <DeltaSpendText
          value={value}
        />
      ),
    },
    {
      field: FIELDS.DELTA_PCT,
      headerName: 'Delta %',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.RPI,
      headerName: 'D0 RPI',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
    {
      field: FIELDS.GOAL_ROAS,
      headerName: 'Goal D0 ROAS',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ value }) => (
        <AdaptiveText size={AdaptiveText.SIZE.SM}>{value}</AdaptiveText>
      ),
    },
  ],
});

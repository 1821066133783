import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getCampaingsList, getCampaingsListTotal, getIsListFetching, getIsCampaignUpdateFetching } from 'core/v4/campaigns/selectors';
import { getIsGoalRoasUpdateFetching, getIsGoalRoasResetFetching } from 'core/games/selectors';
import campaignsActions from 'core/v4/campaigns/actions';
// import campaignsActionsV3 from 'core/campaigns/actions';
import gamesActions from 'core/games/actions';

import { GAME_DETAILS_LIST, GOAL_ROAS_PATHS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import DataGrid from 'components/common/data-grid';

import { useQueryParams } from 'hooks/use-query-params';
import useIsDataInited from 'hooks/use-is-data-inited';

import { isObjectEmpty } from 'utils';
import { /* getFilteredList, */ getQueryParamsByFilter, getFilterByQueryParams } from 'utils/grid';
import { getDateRangeFromDates } from 'utils/date';

import { MERGED_QUERY_PARAMS_WITH_TYPES, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS } from 'pages/v4/games/list/constants';
import { ACTIVE_FILTERS, DEFAULT_FILTER, DEFAULT_FILTERS_VALUES, FILTERS_KEYS } from './constants';
import { getConfig } from './config';
import { StyledDataGrid } from './styles';
import { getFiltersByQueryParams, getFiltersValueForSubmit, getSortingByQueryParams } from './utils';


const GameCampaignsList = memo(({ onSelectionChange, selection, type, selected, isAppStatusReady }) => {
  const dispatch = useDispatch();
  const list = useSelector(getCampaingsList);
  const total = useSelector(getCampaingsListTotal);

  const isDataInited = useIsDataInited(list);
  const isFetching = useSelector(getIsListFetching);
  const isCampaignUpdateFetching = useSelector(getIsCampaignUpdateFetching);
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);
  const isGoalRoasResetFetching = useSelector(getIsGoalRoasResetFetching);
  // const { syncRange: { start, end } } = useSelector(getGameById(gameId));
  // TODO: start & end for Last 7 days tooltip
  const start = null;
  const end = null;
  // TODO: v4 changes
  // const gameId = 0;
  const isGameArchived = false;

  const [queryParams, setQueryParams] = useQueryParams(MERGED_QUERY_PARAMS_WITH_TYPES);

  const selectedGameIds = useMemo(() => selected[GAME_DETAILS_LIST.NETWORKS], [selected]);

  const [filtersState, setFiltersState] = useState(() => ({
    ...DEFAULT_FILTERS_VALUES,
    ...getFiltersByQueryParams(queryParams),
  }));
  const [sorting, setSorting] = useState(() => getSortingByQueryParams(queryParams));

  const [lastEditedRow, setLastEditedRow] = useState(null);
  const [listFilter, setListFilter] = useState(() => {
    const queryFilters = getFilterByQueryParams(queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS);
    const isEmptyQueryFilters = isObjectEmpty(queryFilters);

    if (isEmptyQueryFilters) {
      setQueryParams(
        getQueryParamsByFilter(DEFAULT_FILTER, queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS),
        { replace: true },
      );
      return DEFAULT_FILTER;
    }
    return queryFilters;
  });
  // const [data, setData] = useState(() => getFilteredList(list, listFilter));
  // const [data] = useState(() => getFilteredList(list, listFilter));
  const data = useMemo(() => list, [list]);

  /*
  useEffect(() => {
    const queryFilters = getFilterByQueryParams(queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS);
    const isEmptyQueryFilters = isObjectEmpty(queryFilters);

    if (isEmptyQueryFilters) {
      setListFilter(DEFAULT_FILTER);
      setQueryParams(
        getQueryParamsByFilter(DEFAULT_FILTER, queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS),
        { replace: true },
      );
    } else {
      setListFilter(queryFilters);
    }

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.CAMPAIGNS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  */

  const fetchPayload = useMemo(() => ({
    ...filtersState,
    ...getFiltersValueForSubmit(listFilter, sorting),
    [FILTERS_KEYS.GAME_IDS]: selectedGameIds,
  }), [filtersState, listFilter, sorting, selectedGameIds]);

  useEffect(() => {
    dispatch(campaignsActions.fetchList(fetchPayload));
  }, [fetchPayload]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleListFilterChange = useCallback((filter) => {
    setListFilter(filter);
    setQueryParams(
      getQueryParamsByFilter(filter, queryParams, CAMPAIGNS_LIST_QUERY_PARAMS_BY_FIELDS),
      { replace: true },
    );
  }, [queryParams, setQueryParams]);

  const handleRowUpdate = useCallback((nextData) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CAMPAIGNS,
      name: AnalyticsService.NAMES.CHANGE_BIDDER_STATUS,
      action: AnalyticsService.ACTIONS.CHANGE,
    });

    setLastEditedRow(nextData.id);
    dispatch(campaignsActions.updateCampaign({
      ...nextData,
    }, {
      refetchCampaignsPayload: fetchPayload,
    }));
  }, [dispatch, fetchPayload]);

  const handleGoalRoasUpdate = useCallback((nextData) => {
    setLastEditedRow(nextData.id);
    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      path: GOAL_ROAS_PATHS.CAMPAIGN,
    }));
  }, [dispatch]);

  const handleGoalRoasReset = useCallback((nextData, { comment, initialValue }) => {
    setLastEditedRow(nextData.id);
    dispatch(gamesActions.resetOverrideGoalRoas({
      ...nextData,
      path: GOAL_ROAS_PATHS.CAMPAIGN,
      comment,
      initialValue,
    }));
  }, [dispatch]);

  const handleRowLinkPress = useCallback((link) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.CAMPAIGNS,
      name: AnalyticsService.NAMES.OPEN_CAMPAIGN_LINK,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    window.open(link, '_blank');
  }, []);

  const tooltipDateRange = useMemo(() => getDateRangeFromDates(start, end), [start, end]);

  const config = useMemo(() => getConfig(
    data,
    handleRowUpdate,
    handleRowLinkPress,
    handleGoalRoasUpdate,
    list,
    tooltipDateRange,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ), [
    data,
    handleRowUpdate,
    handleRowLinkPress,
    handleGoalRoasUpdate,
    list,
    tooltipDateRange,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ]);

  // const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);
  const handleSelectionChange = useCallback((ids) => {
    if (isDataInited) {
      onSelectionChange(ids, type);
    }
  }, [onSelectionChange, type, isDataInited]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isCampaignUpdateFetching || isGoalRoasUpdateFetching || isGoalRoasResetFetching) && id === lastEditedRow
  ), [isCampaignUpdateFetching, isGoalRoasUpdateFetching, isGoalRoasResetFetching, lastEditedRow]);

  /*
  useEffect(() => {
    setData(getFilteredList(list, listFilter));
  }, [list, listFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  */



  /*
  useEffect(() => {
    dispatch(campaignsActions.fetchList({
      ...filtersState,
      ...getFiltersValueForSubmit(listFilter, sorting),
      [FILTERS_KEYS.GAME_IDS]: selectedGameIds,
    }));
  }, [filtersState, listFilter, sorting, selectedGameIds]); // eslint-disable-line react-hooks/exhaustive-deps
  */

  const handlePaginationStateChange = useCallback((key, value) => {
    if (value === filtersState[key]) {
      return;
    }

    setFiltersState({
      ...filtersState,
      [key]: value,
    });
  }, [filtersState]);

  const handlePageChange = useCallback((value) => {
    handlePaginationStateChange(FILTERS_KEYS.PAGE, value);
  }, [handlePaginationStateChange]);

  const handlePageSizeChange = useCallback((value) => {
    handlePaginationStateChange(FILTERS_KEYS.LIMIT, value);
  }, [handlePaginationStateChange]);

  const handleSortingChange = useCallback((nextSorting) => {
    setSorting(nextSorting);
  }, []);

  return (
    <StyledDataGrid
      as={DataGrid}
      config={config}
      isFetching={isFetching}
      onSelectionModelChange={handleSelectionChange}
      selectionModel={selection}
      getIsRowFetching={getIsRowFetching}
      filter={listFilter}
      onFilterChange={handleListFilterChange}
      useFilters
      activeFilters={ACTIVE_FILTERS}
      isServerPagination
      rowCount={total}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      sortModel={sorting}
      sortingMode="server"
      onSortModelChange={handleSortingChange}
    />
  );
});


GameCampaignsList.propTypes = {
  // gameId: PropTypes.string.isRequired,
  // list: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    networks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.string).isRequired,
    geos: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  // isGameArchived: PropTypes.bool.isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
};

GameCampaignsList.defaultProps = {
  // list: [],
};


export default GameCampaignsList;

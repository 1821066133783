import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { StyledLink } from './styles';


const Link = memo(({ children, to, isExternal, element, ...otherProps }) => (
  isExternal ? (
    <StyledLink
      href={to}
      target="_blank"
      {...otherProps}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledLink
      to={to}
      as={element}
      {...otherProps}
    >
      {children}
    </StyledLink>
  )
));


Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
};

Link.defaultProps = {
  isExternal: false,
  element: NavLink,
  to: '',
};


export default Link;

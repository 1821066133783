import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingsActions from 'core/settings/actions';
import { getConfig, getIsConfigFetching } from 'core/settings/selectors';
import AnalyticsService from 'services/analytics';

import { withParams } from 'hoc/with-params';

import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';

import Loader from 'components/common/loader';
import PushFrequencyList from 'components/settings/push-frequency/list';
import Button from 'components/common/button';
import Modal from 'components/settings/push-frequency/modal';

import { Wrapper, ListWrapper, ButtonWrapper } from './styles';


const PushFrequency = memo(() => {
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const isConfigFetching = useSelector(getIsConfigFetching);

  const [isGameModalOpen, setIsGameModalOpen] = useState(false);

  const handleAddNewGameClick = useCallback(() => {
    setIsGameModalOpen(true);
  }, []);

  const handleAddNewGameCancel = useCallback(() => {
    setIsGameModalOpen(false);
  }, []);

  useEffect(() => {
    dispatch(settingsActions.fetchPushFrequencies());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.SETTINGS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Wrapper>
      {isConfigFetching || !config ? (
        <Loader />
      ) : (
        <>
          <ButtonWrapper
            as={Stack}
            direction="row"
            justifyContent="end"
          >
            <Button
              onClick={handleAddNewGameClick}
              startIcon={<AddIcon />}
            >
              Add Push Frequency Limit
            </Button>
          </ButtonWrapper>
          <ListWrapper>
            <PushFrequencyList />
          </ListWrapper>

          <Modal
            isOpen={isGameModalOpen}
            onCancel={handleAddNewGameCancel}
          />
        </>
      )}
    </Wrapper>
  );
});


PushFrequency.propTypes = {

};

PushFrequency.defaultProps = {

};


export default withParams(PushFrequency);

import { put, call, takeLatest, takeEvery, take } from 'redux-saga/effects';

import { resolvedAction, rejectedAction, resolved, rejected } from 'utils/actions';
import SnackbarService from 'services/snackbar';

import { waitForUserSession } from 'core/app/sagas';

import CampaignsService from './service';
import campaignsActions, { types as campaignsTypes } from './actions';


/*
 * Sagas
 */

function* fetchList ({ payload }) {
  try {
    yield call(waitForUserSession);

    const { campaigns, total } = yield call(CampaignsService.fetchList, payload);

    yield put(resolvedAction(campaignsTypes.FETCH_LIST, { campaigns, total }));
  } catch (error) {
    yield put(rejectedAction(campaignsTypes.FETCH_LIST, { message: error.message }));
  }
}

function* updateCampaign ({ payload, meta }) {
  try {
    const { id, ...rest } = payload;
    const { refetchCampaigns, refetchCampaignsPayload } = meta;

    const data = yield call(CampaignsService.updateCampaign, id, rest);

    if (refetchCampaigns) {
      yield put(campaignsActions.fetchList(refetchCampaignsPayload));
      yield take([resolved(campaignsTypes.FETCH_LIST), rejected(campaignsTypes.FETCH_LIST)]);
    }

    yield put(resolvedAction(campaignsTypes.UPDATE_CAMPAIGN, { data }));

    SnackbarService.showSuccess('Campaign has been updated successfully'); // TODO: move text somewhere
  } catch (error) {
    yield put(rejectedAction(campaignsTypes.UPDATE_CAMPAIGN, { message: error.message }));
    SnackbarService.showError(error.message);
  }
}


/*
 * Watchers
 */

function* fetchListWatcher () {
  yield takeLatest(campaignsTypes.FETCH_LIST, fetchList);
}

function* updateCampaignWatcher () {
  yield takeEvery(campaignsTypes.UPDATE_CAMPAIGN, updateCampaign);
}

export default [
  fetchListWatcher,
  updateCampaignWatcher,
];

import { capitalize } from 'utils';
import { getFilteredList } from 'utils/grid';

import { FIELDS } from './constants';


export const renderMultipleFilterValue = (values) => values && values.map(capitalize).join(', ');

export const getDefaultGrowthManagerFilter = (data, user) => {
  if (user.isAdmin) {
    return {};
  }

  const filter = {
    [FIELDS.GROWTH_MANAGER]: user.email,
  };

  const filteredData = getFilteredList(data, filter);
  if (filteredData.length) {
    return filter;
  }

  return [];
};

import { styled } from '@mui/system';


export const StyledButton = styled('div')(() => ({
  fontSize: 18,
  width: 280,
  fontWeight: 500,
  height: 56,
  alignSelf: 'center',
  margin: '0 auto',
  display: 'flex',
}));

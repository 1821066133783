import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledIndicator } from './styles';


const Indicator = memo(({ status, colors }) => (
  <StyledIndicator
    $status={status}
    $colors={colors}
  />
));


Indicator.propTypes = {
  status: PropTypes.string,
  colors: PropTypes.shape({}).isRequired,
};

Indicator.defaultProps = {
  status: null,
};


export default Indicator;

import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'managers',
  storage,
  blacklist: [
    'isListFetching',
  ],
};


export default persistConfig;

import { isValueExists, isArray } from 'utils';


export const ALL_OPTION = {
  id: 'ALL',
  name: 'All',
};

export const NOT_EMPTY_OPTION = {
  id: 'NOT_EMPTY',
  name: 'Not empty',
};

export const getStatusInitialValue = (statuses, row, fieldType) => (statuses || []).find((status) => isValueExists(row) && status.id === row[fieldType]);
export const getManagersOptionLabel = (option) => option?.name;
export const getManagersInitialValue = (managers, row, fieldType) => (managers || []).find((manager) => isValueExists(row) && manager.name === row[fieldType]);

const isFieldIncludesValue = (field, value) => {
  const fieldValue = (String(field) || '').toLowerCase(); // TODO: add type checking ?
  const filterValue = (value || '').toLowerCase();

  return fieldValue.includes(filterValue); // TODO: use RegEx ?
};

export const getFilteredList = (list, filter) => {
  const filterEntries = Object.entries(filter);

  if (!filterEntries.length) {
    return list;
  }

  return list.filter((game) => filterEntries.reduce((result, [key, value]) => {
    if (!result) {
      return result;
    }

    const fieldValue = game[key];

    switch (value) {
      case ALL_OPTION.id:
        return true;
      case NOT_EMPTY_OPTION.id:
        return isValueExists(fieldValue);
      default: {
        if (!isArray(value)) {
          return isFieldIncludesValue(fieldValue, value);
        }

        if (!value.length) {
          return true;
        }

        return value.reduce((isIncludes, filterArrayItem) => {
          if (isIncludes) {
            return isIncludes;
          }

          return isFieldIncludesValue(fieldValue, filterArrayItem);
        }, false);
      }
    }
  }, true));
};

export const getQueryParamsByFilter = (filter, prevQueryParams, queryParamsByFields) => {
  const nextQueryParams = Object.entries(queryParamsByFields).reduce((result, [filterKey, paramKey]) => {
    const filterValue = filter[filterKey];

    if (!filterValue) {
      return result;
    }

    return {
      ...result,
      [paramKey]: filterValue,
    };
  }, {});

  const filteredPrevQueryParams = Object.entries(prevQueryParams).reduce((result, [paramKey, paramValue]) => {
    const isFilterQueryParam = Object.entries(queryParamsByFields).find((entry) => entry[1] === paramKey);

    if (!isFilterQueryParam || nextQueryParams[paramKey]) {
      return {
        ...result,
        [paramKey]: paramValue,
      };
    }

    return result;
  }, {});

  return {
    ...filteredPrevQueryParams,
    ...nextQueryParams,
  };
};

export const getFilterByQueryParams = (params, queryParamsByFields) => Object.entries(params).reduce((result, [key, value]) => {
  const filterEntry = Object.entries(queryParamsByFields).find((entry) => entry[1] === key);
  const [filter] = filterEntry || [];

  if (!filter) {
    return result;
  }

  return {
    ...result,
    [filter]: value,
  };
}, {});

export const suggestedRoasSortComparator = (itemA, itemB) => {
  const { diff: diffA } = itemA;
  const { diff: diffB } = itemB;

  if (diffA === null) {
    return -1;
  }

  if (diffB === null) {
    return 1;
  }

  return diffA - diffB;
};

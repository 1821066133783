import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'containers/router/constants';

import gamesActions from 'core/games/actions';
import { getIsGameFetching, getGameById } from 'core/games/selectors';
import { getIsAppStatusReady } from 'core/settings/selectors';
import { GAME_STATUS_KEYS } from 'core/games/constants';

import { withParams } from 'hoc/with-params';

import { useQueryParams } from 'hooks/use-query-params';
import useIsDataInited from 'hooks/use-is-data-inited';

import { QUERY_PARAMS as ACTIVITY_LOGS_QUERY_PARAMS } from 'components/activity-logs/list/constants';


import Loader from 'components/common/loader';
import GameDetailsForm from 'components/games/details-form';
import GameDetailsTabs from 'components/games/details-tabs';

import { getFilteredLists, getDefaultSelected, isSelectedNotEqual, convertSelectionToQuery } from './utils';
import { QUERY_PARAMS, QUERY_PARAMS_BY_FIELDS, MERGED_QUERY_PARAMS_WITH_TYPES } from './constants';
import { Wrapper, TabsWrapper } from './styles';


const GameDetails = memo(({ params }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { gameId } = params;

  const isFetching = useSelector(getIsGameFetching);
  const data = useSelector(getGameById(Number(gameId)));
  const isAppStatusReady = useSelector(getIsAppStatusReady);

  const [queryParams, setQueryParams] = useQueryParams(MERGED_QUERY_PARAMS_WITH_TYPES);

  const isDataInited = useIsDataInited(data);
  const isGameArchived = data?.status === GAME_STATUS_KEYS.ARCHIVED;

  const isMounted = useRef(false);
  const [selected, setSelected] = useState(() => getDefaultSelected());
  const [commitedSelected, setCommitedSelected] = useState(selected);
  const [lists, setLists] = useState(() => getFilteredLists(data, selected).nextLists);
  const [initialTab, setInitialTab] = useState(0);

  const handleTabChange = useCallback((tab) => {
    setQueryParams({
      ...queryParams,
      [QUERY_PARAMS.TAB]: tab,
    });
  }, [queryParams, setQueryParams]);

  const handleSelectionChange = useCallback((ids, key) => {
    const queryParamKey = QUERY_PARAMS_BY_FIELDS[key];

    setSelected((prevSelected) => update(prevSelected, {
      [key]: { $set: ids },
    }));

    setQueryParams(
      update(queryParams, {
        [queryParamKey]: { $set: ids },
      }),
      { replace: true },
    );
  }, [queryParams, setQueryParams]);

  const handleActivityLogsClick = useCallback(() => {
    navigate(`/${ROUTES.ACTIVITY_LOG}?${ACTIVITY_LOGS_QUERY_PARAMS.GAME}=${encodeURIComponent(data.name)}`);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // TODO: find a way to avoid second render
    const { nextLists, nextSelected } = getFilteredLists(data, selected);
    const isNotEqual = isSelectedNotEqual(nextSelected, selected);

    if (isNotEqual) {
      setSelected(nextSelected);
      setQueryParams(
        convertSelectionToQuery(nextSelected),
        { replace: true },
      );
    }

    setLists(nextLists);
    setCommitedSelected(nextSelected);
  }, [selected, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(gamesActions.fetchGame({ id: Number(gameId) }, { redirectOnFail: true }));
  }, [gameId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialTab(queryParams[QUERY_PARAMS.TAB] || 0);
  }, [queryParams[QUERY_PARAMS.TAB]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataInited) {
      setSelected(getDefaultSelected(queryParams));
    }
  }, [isDataInited]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted.current) {
      const nextSelected = getDefaultSelected();
      setSelected(nextSelected);
      setCommitedSelected(nextSelected);
      setQueryParams({});
      return;
    }

    isMounted.current = true;
  }, [gameId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (queryParams[QUERY_PARAMS.TAB] !== initialTab) {
      setInitialTab(queryParams[QUERY_PARAMS.TAB]);
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      {(isFetching || !data) ? (
        <Loader />
      ) : (
        <>
          <GameDetailsForm
            data={data}
          />

          <TabsWrapper>
            <GameDetailsTabs
              // TODO: use isFetching instead of global spinner
              gameId={gameId}
              lists={lists}
              selected={commitedSelected}
              onSelectionChange={handleSelectionChange}
              onTabChange={handleTabChange}
              initialTab={initialTab}
              onActivityLogsClick={handleActivityLogsClick}
              isGameArchived={isGameArchived}
              isAppStatusReady={isAppStatusReady}
              isMounted={isMounted.current}
            />
          </TabsWrapper>
        </>
      )}
    </Wrapper>
  );
});


GameDetails.propTypes = {
  params: PropTypes.shape({
    gameId: PropTypes.string.isRequired,
  }).isRequired,
};


export default withParams(GameDetails);

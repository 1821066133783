import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import gamesActions from 'core/games/actions';
import { getOverrideList, getIsGoalRoasResetFetching } from 'core/games/selectors';
import AnalyticsService from 'services/analytics';

import Modal from 'components/common/modal';

import GoalRoasOverrideForm from 'components/games/goal-roas-override-form';


const GoalRoasOverrideModal = memo(() => {
  const dispatch = useDispatch();
  const overrideList = useSelector(getOverrideList);
  const isFetching = useSelector(getIsGoalRoasResetFetching);

  const handleCancel = useCallback(() => {
    dispatch(gamesActions.cancelOverrideGoalRoas());

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.DECLINE_OVERRIDE_LIST_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });
  }, [dispatch]);

  const handleSubmit = useCallback((list) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.CONFIRM_OVERRIDE_LIST_GOAL_ROAS_MODAL,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    dispatch(gamesActions.resetOverrideGoalRoasList({ list }));
  }, [dispatch]);

  return (
    <Modal
      isOpen={!!overrideList}
      onCancel={handleCancel}
    >
      <GoalRoasOverrideForm
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        overrideList={overrideList}
        isFetching={isFetching}
      />
    </Modal>
  );
});


export default GoalRoasOverrideModal;

import { AUTH_ROUTES } from './constants';


export const isAuthRoutes = (url) => {
  for (let i = 0; i < AUTH_ROUTES.length; i++) {
    if (url.includes(AUTH_ROUTES[i])) {
      return true;
    }
  }

  return false;
};

import { createActions } from 'redux-actions';

import { createConstants } from 'utils';


export const PREFIX = 'SETTINGS';

export const types = createConstants([
  'FETCH_CONFIG',
  'UPDATE_CONFIG',
  'FETCH_APP_STATUS',
  'FETCH_COUNTRIES',
  'FETCH_PUSH_FREQUENCIES',
  'CREATE_PUSH_FREQUENCY',
  'DELETE_PUSH_FREQUENCY',
  'UPDATE_PUSH_FREQUENCY',
  'FETCH_CHANGE_LIMIT',
  'CREATE_CHANGE_LIMIT',
  'DELETE_CHANGE_LIMIT',
  'UPDATE_CHANGE_LIMIT',
], PREFIX);

export const actions = createActions({
  [types.FETCH_CONFIG]: null,
  [types.UPDATE_CONFIG]: ({ data }) => ({ data }),
  [types.FETCH_APP_STATUS]: null,
  [types.FETCH_COUNTRIES]: null,
  [types.FETCH_PUSH_FREQUENCIES]: ({ useIsFetching = true } = {}) => ({ useIsFetching }),
  [types.CREATE_PUSH_FREQUENCY]: ({ channel, campaignType, cooldownLimit }) => ({ channel, campaignType, cooldownLimit }),
  [types.DELETE_PUSH_FREQUENCY]: ({ id }) => ({ id }),
  [types.UPDATE_PUSH_FREQUENCY]: ({ id, data }) => ({ id, data }),
  [types.FETCH_CHANGE_LIMIT]: ({ useIsFetching = true } = {}) => ({ useIsFetching }),
  [types.CREATE_CHANGE_LIMIT]: ({ channel, campaignType, limitType, filterList }) => ({ channel, campaignType, limitType, filterList }),
  [types.DELETE_CHANGE_LIMIT]: ({ id }) => ({ id }),
  [types.UPDATE_CHANGE_LIMIT]: ({ id, data }) => ({ id, data }),
}, {
  prefix: PREFIX,
});


export default actions;

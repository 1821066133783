import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@mui/lab/LoadingButton';

import { StyledButton } from './styles';


const Button = memo(({ children, isFetching, ...otherProps }) => (
  <StyledButton
    as={MuiButton}
    variant="contained"
    color="secondary"
    loading={isFetching}
    {...otherProps}
  >
    {children}
  </StyledButton>
));


Button.propTypes = {
  children: PropTypes.node,
  isFetching: PropTypes.bool,
};

Button.defaultProps = {
  isFetching: false,
  children: null,
};


export default Button;

import { PLATFORMS } from 'core/games/constants';


export const KEYS = {
  GROWTH_MANAGER: 'growthManager',
  NAME: 'name',
  STORE_ID: 'storeId',
  PLATFORM: 'platform',
  LION_APP: 'lionApp',
  STATUS: 'status',
  ADJUST_APP_TOKEN: 'adjustAppToken',
  NETWORKS: 'networks',
};

export const LABELS = {
  [KEYS.GROWTH_MANAGER]: 'Growth Manager',
  [KEYS.NAME]: 'Name',
  [KEYS.STORE_ID]: 'Store ID',
  [KEYS.PLATFORM]: 'Platform',
  [KEYS.LION_APP]: 'Lion App',
  [KEYS.ADJUST_APP_TOKEN]: 'Adjust App Token',
  [KEYS.NETWORKS]: 'Networks',
};

export const STORE_ID_INPUT_PROPS = {
  [PLATFORMS.IOS]: {
    label: 'iOS App Store ID',
    description: '(number only, please do not begin this with id ex: id1938453 becomes 1938453)',
    type: 'number',
  },
  [PLATFORMS.ANDROID]: {
    label: 'Android Google Play Store Package Name',
    type: 'text',
  },
};

import { styled } from '@mui/system';

import { HORIZONTAL_PADDING_PAGE_LAYOUT } from 'constants/layout';


export const Wrapper = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const Content = styled('div')(({ theme, $isAuthorized }) => ({
  padding: $isAuthorized ? theme.spacing(2.5, HORIZONTAL_PADDING_PAGE_LAYOUT) : 0,
  backgroundColor: $isAuthorized ? theme.palette.background.main : theme.palette.background.light,
  flex: 1,
}));

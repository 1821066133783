import React from 'react';
import PropTypes from 'prop-types';
import { CHANNEL_COLOR } from 'core/v4/games/constants';


const IconApplovin = ({ color }) => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <use
      xlinkHref="#svg-applovin"
      style={{ color: CHANNEL_COLOR[color] }}
    />
  </svg>
);

IconApplovin.propTypes = {
  color: PropTypes.string,
};

IconApplovin.defaultProps = {
  color: 'grey',
};

export default IconApplovin;

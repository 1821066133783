import { FILTERS } from './constants';


export const getDatesFromFilter = (id) => {
  const to = new Date();
  const from = new Date();

  switch (id) { // eslint-disable-line default-case
    case FILTERS.THREE_DAYS:
      from.setDate(from.getDate() - 2);
      break;
    case FILTERS.WEEK:
      const day = from.getDay(); // eslint-disable-line no-case-declarations
      const diff = from.getDate() - day + (day === 0 ? -6 : 1); // eslint-disable-line no-case-declarations
      from.setDate(diff);
      break;
    case FILTERS.MONTH:
      from.setDate(1);
      break;
  }

  return { to, from };
};

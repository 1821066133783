import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/common/text';

import { StyledText } from './styles';



const TextWithIndicator = memo(({ value, indicator: IndicatorComponent, colors }) => (
  <>
    <IndicatorComponent
      status={value}
      colors={colors}
    />

    <StyledText as={Text}>{value}</StyledText>
  </>

));


TextWithIndicator.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  indicator: PropTypes.shape({}).isRequired,
  colors: PropTypes.shape({}),
};

TextWithIndicator.defaultProps = {
  colors: null,
};



export default TextWithIndicator;

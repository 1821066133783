const config = {
  MUI_PRO_LICENSE_KEY: process.env.REACT_APP_MUI_PRO_LICENSE_KEY,
  API_URL: process.env.REACT_APP_API_URL,
  LOOKER_URL: 'https://lionstudios.cloud.looker.com/embed/dashboards/96',
  DIRECT_LOOKER_URL: 'https://lionstudios.cloud.looker.com/dashboards/96',
  ANALYTICS_URL: process.env.REACT_APP_ANALYTICS_URL,
  ANALYTICS_SITE_ID: process.env.REACT_APP_ANALYTICS_SITE_ID,
  OKTA_LOGIN_URL: process.env.REACT_APP_OKTA_LOGIN_URL,
};


export default config;

// import { CAMPAIGN_STATUS_KEYS } from 'core/games/constants';


export const FIELDS = {
  ID: 'id',
  // BIDDER_STATUS: 'bidderStatus',
  ENABLED: 'enabled',
  GAME_NAME: 'gameName',
  GAME_PLATFORM: 'gamePlatform',
  CHANNEL: 'channel',
  NAME: 'name',
  STATUS: 'campaignStatus',
  TYPE: 'campaignType',
  WEEK_SPEND: 'weekSpend',
  WEEKLY_INSTALLS: 'weekInstalls',
  GOAL_ROAS: 'goalRoas',
  ACTIONS: 'actions',
  SUGGESTED_ROAS: 'suggestedRoas',
  BUDGET: 'budget',
  BIDDER_STATUS: 'bidderStatus',
  RETURN_WINDOW: 'returnWindow',
  RW_RPI_FORECAST: 'rwRpiForecast',
  RW_ROAS_TARGET: 'rwRoasTarget',
  RW_ROAS_TARGET_OVERRIDE_COUNT: 'rwRoasTargetOverrideCount',
  CPA_ROAS_SETTING_TYPE: 'cpaRoasSettingType',
  CPA_ROAS_SETTING: 'cpaRoasSetting',
  DELTA_CPA_ROAS_SETTING: 'deltaCpaRoasSetting',
  ACTIVITY: 'activity',
  LINKS: 'links',
};

export const CAMPAIGN_STATUSES = {
  STOPPED: 'STOPPED',
  CAMPAIGN_STOPPED: 'CAMPAIGN_STOPPED',
  RUNNING: 'RUNNING',
  OVER_DAILY_CAP: 'OVER_DAILY_CAP',
};

export const CAMPAIGN_STATUS_OPTIONS = Object.entries(CAMPAIGN_STATUSES).map(([id, name]) => ({
  id,
  name,
}));

export const CAMPAIGN_TYPES = {
  CPI: 'CPI',
  BLD_ROAS: 'BLD_ROAS',
  CPC: 'CPC',
  CPE: 'CPE',
  CPM: 'CPM',
  CPP_GOAL: 'CPP_GOAL',
  DCPM: 'DCPM',
  RET: 'RET',
  ROAS_AD_BASED: 'ROAS_AD_BASED',
  ROAS_IAP: 'ROAS_IAP',
  OCPI: 'OCPI',
};

export const CAMPAIGN_TYPES_OPTIONS = Object.entries(CAMPAIGN_TYPES).map(([id, name]) => ({
  id,
  name,
}));

export const ACTIVE_FILTERS = [
  FIELDS.NAME,
  FIELDS.STATUS,
  FIELDS.TYPE,
];

export const DEFAULT_FILTER = {
  // [FIELDS.STATUS]: [CAMPAIGN_STATUS_KEYS.RUNNING, CAMPAIGN_STATUS_KEYS.OVER_DAILY_CAP],
};

export const DEFAULT_CAMPAIGN_STATUS_FILTER_OPTION = [
  /*
  {
    id: CAMPAIGN_STATUS_KEYS.RUNNING,
    name: CAMPAIGN_STATUS_KEYS.RUNNING,
  },
  {
    id: CAMPAIGN_STATUS_KEYS.OVER_DAILY_CAP,
    name: CAMPAIGN_STATUS_KEYS.OVER_DAILY_CAP,
  },
  */
];

export const FILTERS_KEYS = {
  PAGE: 'page',
  LIMIT: 'limit',
  SORT: 'sort',
  GAME_IDS: 'game_ids',
  [FIELDS.ID]: 'CAMPAIGN_ID',
  [FIELDS.NAME]: 'CAMPAIGN_NAME',
  [FIELDS.STATUS]: 'STATUS',
  [FIELDS.TYPE]: 'CAMPAIGN_TYPE',
  [FIELDS.WEEK_SPEND]: 'SPEND_7D',
};

export const FILTERS_KEY_OPERATORS = {
  [FIELDS.NAME]: 'contains',
  [FIELDS.STATUS]: 'in',
  [FIELDS.TYPE]: '=',
};

export const DEFAULT_FILTERS_VALUES = {
  [FILTERS_KEYS.PAGE]: 0,
  [FILTERS_KEYS.LIMIT]: 50, // TODO: use grid default value
};

export const QUERY_PARAMS = {
  [FILTERS_KEYS.PAGE]: 'p',
  [FILTERS_KEYS.LIMIT]: 'l',
};

export const EXTENDED_QUERY_PARAMS = {
  ...QUERY_PARAMS,
  [FILTERS_KEYS.SORT]: 'sort',
};

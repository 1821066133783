export const sanitizeFilterList = (filterList) => {
  let newFilterList = filterList;

  if (filterList.length) {
    newFilterList = filterList.map((item) => {
      const result = item;
      if (item.operator === 'between') {
        result.value1 = [item?.value?.[0] ?? '0'];
        result.value2 = [item?.value?.[1] ?? '0'];
      }
      return result;
    });
  }

  return newFilterList;
};

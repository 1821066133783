import React, { memo } from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash.memoize';

import Tabs from 'components/common/tabs';

import { config } from './config';


const GamesTabs = memo(({
  onTabChange,
  initialTab,
  selected,
  onSelectionChange,
  isAppStatusReady,
  isMounted,
}) => {
  // START: variable for GameCampaignsList props (tab campaign)
  // const gameId = '1';
  // const selected = {
  //   networks: [],
  //   campaigns: [],
  //   geos: [],
  //   sources: [],
  // };
  // const onSelectionChange = () => true;
  // const isGameArchived = false;
  // END: variable for GameCampaignsList props (tab campaign)

  const tabsConfig = Object.entries(config)
    // .filter((entries) => !entries[1].hidden) // TODO: remove when Sources tab will become active
    .map(([key, {
      title,
      icon: IconComponent,
      list: ListComponent,
      color,
    }]) => ({
      title,
      renderIcon: memoize((fillColor) => <IconComponent fill={fillColor} />),
      selectedCount: selected[key].length,
      key,
      onSelectionChange,
      color,
      content: (
        <ListComponent
          onSelectionChange={onSelectionChange}
          selection={selected[key]}
          type={key}
          selected={selected}
          isAppStatusReady={isAppStatusReady}
          isMounted={isMounted}
        />
      ),
    }));

  const validatedInitialTab = (initialTab < tabsConfig.length && initialTab >= 0) ? initialTab : 0;

  return (
    <Tabs
      config={tabsConfig}
      onTabChange={onTabChange}
      initialTab={validatedInitialTab}
    />
  );
});


GamesTabs.propTypes = {
  onTabChange: PropTypes.func,
  initialTab: PropTypes.number,
  onSelectionChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    networks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.string).isRequired,
    geos: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
  isMounted: PropTypes.bool.isRequired,
};

GamesTabs.defaultProps = {
  onTabChange: () => {},
  initialTab: 0,
};


export default GamesTabs;

import LionstudiosApi from 'services/lionstudios-api';

import { networkModel, networkMultipleModel } from './models';
import { NETWORKS_URL } from './constants';


const NetworksService = {
  addNetwork: async (payload) => {
    const data = networkModel(payload);
    const response = await LionstudiosApi.post(NETWORKS_URL, data);

    return response.data;
  },

  updateNetwork: async (id, payload) => {
    const data = networkModel(payload);
    const response = await LionstudiosApi.post(`${NETWORKS_URL}/${id}`, data);

    return response.data;
  },

  deleteNetwork: async (id) => {
    const response = await LionstudiosApi.delete(`${NETWORKS_URL}/${id}`);

    return response.data;
  },

  addNetworkMultiple: async (payload) => {
    const data = networkMultipleModel(payload);
    const response = await LionstudiosApi.post(`${NETWORKS_URL}/add`, data);

    return response.data;
  },

  updateNetworkMultiple: async (payload) => {
    const data = networkMultipleModel(payload);
    const response = await LionstudiosApi.post(`${NETWORKS_URL}/update`, data);

    return response.data;
  },
};


export default NetworksService;

import { styled } from '@mui/system';


export const StyledButton = styled('button')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const ButtonWrapper = styled('div')(() => ({
  display: 'inline-block',
}));

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { isValueExists } from 'utils';
import { formatNumber } from 'utils/formatters';

import Text from 'components/common/text';

import { StyledTableContainer, TableHeadLabel, TableCellLabel, StyledTable } from './styles';


const BidOverrideTable = memo(({ data }) => {
  const { campaign, channel, country, prevBidOverride, bidOverride } = data;

  return (
    <StyledTableContainer as={TableContainer}>
      <StyledTable
        as={Table}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <TableHeadLabel
                as={Text}
              >
                Campaign Name
              </TableHeadLabel>
            </TableCell>

            <TableCell>
              <TableHeadLabel
                as={Text}
              >
                Channel
              </TableHeadLabel>
            </TableCell>

            <TableCell>
              <TableHeadLabel
                as={Text}
              >
                Geo
              </TableHeadLabel>
            </TableCell>

            <TableCell
              align="center"
            >
              <TableHeadLabel
                as={Text}
              >
                Original Value
              </TableHeadLabel>
            </TableCell>

            <TableCell
              align="center"
            >
              <TableHeadLabel
                as={Text}
              >
                New Value
              </TableHeadLabel>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <TableCellLabel>
                {campaign}
              </TableCellLabel>
            </TableCell>

            <TableCell
              size="small"
            >
              <TableCellLabel>
                {channel}
              </TableCellLabel>
            </TableCell>

            <TableCell
              size="small"
            >
              <TableCellLabel>
                {country}
              </TableCellLabel>
            </TableCell>

            <TableCell
              align="center"
              size="small"
            >
              <TableCellLabel>
                {isValueExists(prevBidOverride) && Number(prevBidOverride) !== 0 ? formatNumber(prevBidOverride) : '-'}
              </TableCellLabel>
            </TableCell>

            <TableCell
              align="center"
              size="small"
            >
              <TableCellLabel>
                {Number(bidOverride) !== 0 ? formatNumber(bidOverride) : '-'}
              </TableCellLabel>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
});


BidOverrideTable.propTypes = {
  data: PropTypes.shape({
    campaign: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    bidOverride: PropTypes.string.isRequired,
    prevBidOverride: PropTypes.number,
  }),
};

BidOverrideTable.defaultProps = {
  data: {},
};


export default BidOverrideTable;

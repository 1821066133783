import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { persistReducer } from 'redux-persist';

import { resolved, rejected } from 'utils/actions';

import { types as authTypes } from 'core/auth/actions';

import { types } from './actions';
import persistConfig from './persist';


const defaultState = {
  isNetworkUpdateFetching: false,
  isNetworkAddFetching: false,
  isNetworkDeleteFetching: false,
};

const reducer = handleActions({
  [types.ADD_NETWORK]: (state) => (
    update(state, {
      isNetworkAddFetching: { $set: true },
    })
  ),
  [resolved(types.ADD_NETWORK)]: (state) => (
    update(state, {
      isNetworkAddFetching: { $set: false },
    })
  ),
  [rejected(types.ADD_NETWORK)]: (state) => (
    update(state, {
      isNetworkAddFetching: { $set: false },
    })
  ),

  [types.UPDATE_NETWORK]: (state) => (
    update(state, {
      isNetworkUpdateFetching: { $set: true },
    })
  ),
  [resolved(types.UPDATE_NETWORK)]: (state) => (
    update(state, {
      isNetworkUpdateFetching: { $set: false },
    })
  ),
  [rejected(types.UPDATE_NETWORK)]: (state) => (
    update(state, {
      isNetworkUpdateFetching: { $set: false },
    })
  ),

  [types.DELETE_NETWORK]: (state) => (
    update(state, {
      isNetworkDeleteFetching: { $set: true },
    })
  ),
  [resolved(types.DELETE_NETWORK)]: (state) => (
    update(state, {
      isNetworkDeleteFetching: { $set: false },
    })
  ),
  [rejected(types.DELETE_NETWORK)]: (state) => (
    update(state, {
      isNetworkDeleteFetching: { $set: false },
    })
  ),

  [authTypes.LOGOUT]: () => defaultState,
}, defaultState);


export default persistReducer(persistConfig, reducer);

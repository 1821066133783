import { getPriceFormatter } from 'utils/formatters';
import { isArray } from 'utils';
import { EXTENDED_QUERY_PARAMS, FILTERS_KEYS, FILTERS_KEY_OPERATORS, QUERY_PARAMS } from './constants';


export const renderMultipleFilterValue = (values) => values && values.join(', ');

export const getFiltersByQueryParams = (queryParams) => Object.entries(queryParams).reduce((result, [queryParamKey, queryParamValue]) => {
  const [filterKey] = Object.entries(QUERY_PARAMS).find((queryParam) => queryParam[1] === queryParamKey) || [];

  if (!filterKey) {
    return result;
  }

  result[filterKey] = JSON.parse(queryParamValue); // eslint-disable-line no-param-reassign

  return result;
}, {});

export const getFiltersValueForSubmit = (listFilter, sorting) => {
  const filters = Object.entries(listFilter).reduce((result, [filterKey, filterValue]) => {
    if (isArray(filterValue) && filterValue.length < 1) { return result; }
    if (filterValue && filterKey in FILTERS_KEYS) {
      const operator = (filterKey in FILTERS_KEY_OPERATORS) ? FILTERS_KEY_OPERATORS[filterKey] : '=';
      result[FILTERS_KEYS[filterKey]] = { [operator]: filterValue }; // eslint-disable-line no-param-reassign
    }
    return result;
  }, {});

  if (sorting.length) {
    const [sort] = sorting;
    if (sort.field in FILTERS_KEYS) {
      filters[FILTERS_KEYS.SORT] = {
        [FILTERS_KEYS[sort.field]]: sort.sort,
      };
    }
  }

  return filters;
};

export const getSortingByQueryParams = (queryParams) => {
  const sortingParam = queryParams[EXTENDED_QUERY_PARAMS[FILTERS_KEYS.SORT]];

  if (!sortingParam) {
    return [];
  }

  return JSON.parse(sortingParam);
};


const priceFormatter = getPriceFormatter();
export const wrappedPriceFormatter = (value) => priceFormatter({ value });

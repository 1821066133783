import { styled } from '@mui/system';

import { ACTIVITY_LOG_COLORS } from 'core/users/constants';


export const Wrapper = styled('div')(({ theme }) => ({
  borderLeftWidth: 1,
  borderLeftColor: theme.palette.background.main,
  borderLeftStyle: 'solid',
  padding: theme.spacing(2, 7),
}));

export const Indicator = styled('div')(({ $key, theme }) => ({
  width: 20,
  height: 20,
  backgroundColor: ACTIVITY_LOG_COLORS[$key],
  marginRight: theme.spacing(2),
}));

export const Label = styled('p')(({ $key, theme }) => ({
  ...theme.typography.smallTitle,
  color: ACTIVITY_LOG_COLORS[$key],
}));

export const StyledStack = styled('div')(({ theme }) => ({
  '& + &': {
    marginTop: theme.spacing(1.5),
  },
}));

import { styled } from '@mui/system';
import { menuClasses } from '@mui/material/Menu';


export const Wrapper = styled('div')(() => ({
  [`& .${menuClasses.paper}`]: {
    maxHeight: '60vh',
  },
}));

export const StyledButton = styled('div')(() => ({
  minWidth: 0,
}));

import React, { memo } from 'react';

import Stack from '@mui/material/Stack';


import { ACTIVITY_LOG_PATHS, ACTIVITY_LOG_NAMES } from 'core/users/constants';

import Text from 'components/common/text';

import {
  Wrapper,
  Indicator,
  Label,
  StyledStack,
} from './styles';


const ActivityLogsLegend = memo(() => (
  <Wrapper>
    {
      Object.values(ACTIVITY_LOG_PATHS).map((key) => (
        <StyledStack
          as={Stack}
          key={key}
          flexDirection="row"
        >
          <Indicator $key={key} />

          <Label
            as={Text}
            $key={key}
          >
            {ACTIVITY_LOG_NAMES[key]}
          </Label>
        </StyledStack>
      ))
    }
  </Wrapper>
));


export default ActivityLogsLegend;

import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import BaseSelect from 'components/common/uncontrolled-select';


const Select = memo(({ initialValue, onChange, disabledText, selectProps, options, ...otherProps }) => {
  const [value, setValue] = useState(() => initialValue);

  const handleChange = useCallback((event) => {
    const { target: { value: nextValue } } = event;
    const option = options.find(({ id }) => id === nextValue);

    setValue(nextValue);
    onChange(nextValue, event, selectProps, option);
  }, [onChange, selectProps, options]);

  return (
    <BaseSelect
      value={value}
      onChange={handleChange}
      selectProps={selectProps}
      options={options}
      {...otherProps}
    />
  );
});


Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}).isRequired,
    ]),
    name: PropTypes.string.isRequired,
  })).isRequired,
  renderItem: PropTypes.func,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  inputProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({}),
  disabledText: PropTypes.string,
};

Select.defaultProps = {
  renderItem: null,
  initialValue: null,
  inputProps: {},
  selectProps: {},
  disabledText: null,
};


export default Select;

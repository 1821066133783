import { useRef, useEffect } from 'react';


export function useResetCache (data) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

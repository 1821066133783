import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import { getIsGameCreateFetching, getLionApps, getStoreIds, getIsGameDeleteFetching, getIsGameUpdateFetching } from 'core/games/selectors';
import { getManagersList } from 'core/managers/selectors';
import { GAME_STATUSES, GAME_STATUS_KEYS, PLATFORMS_OPTIONS } from 'core/games/constants';
import gamesActions from 'core/games/actions';
import AnalyticsService from 'services/analytics';

import useFormik from 'hooks/use-formik';

import Title from 'components/common/title';
import Input from 'components/common/input';
import Select from 'components/common/uncontrolled-select';
import LionAppAutocomplete from 'components/games/lion-app-autocomplete';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import ConfirmationModal from 'components/modals/confirmation';
import UncontrolledAutocomplete from 'components/common/uncontrolled-autocomplete';
import Text from 'components/common/text';

import { isValueExists, getDifference } from 'utils';
import { getManagersOptionLabel } from 'utils/grid';

import { KEYS, LABELS, STORE_ID_INPUT_PROPS } from './constants';
import { getValidationSchema } from './validation';
import { getTitle, getInitialValues } from './utils';
import {
  FieldWrapper,
  StyledButton,
  Wrapper,
  InfoIcon,
  TitleWrapper,
} from './styles';


const GameForm = memo(({ initialValues, isEdit, tooltipText, withDeletion }) => {
  const dispatch = useDispatch();
  const isCreateFetching = useSelector(getIsGameCreateFetching);
  const isDeleteFetching = useSelector(getIsGameDeleteFetching);
  const isUpdateFetching = useSelector(getIsGameUpdateFetching);
  const lionApps = useSelector(getLionApps);
  const storeIds = useSelector(getStoreIds);
  const managersList = useSelector(getManagersList);

  const isGameArchived = initialValues?.[KEYS.STATUS] === GAME_STATUS_KEYS.ARCHIVED;

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const handleFormSubmit = useCallback((formData) => {
    if (isEdit) {
      const transformedInitialValues = getInitialValues(initialValues, managersList);
      const dataToSubmit = getDifference(formData, transformedInitialValues);

      AnalyticsService.trackEvent({
        category: AnalyticsService.CATEGORIES.GAMES,
        name: AnalyticsService.NAMES.UPDATE_GAME,
        action: AnalyticsService.ACTIONS.SUBMIT,
      });

      dispatch(gamesActions.updateGame(
        {
          id: initialValues.id,
          ...dataToSubmit,
          [KEYS.GROWTH_MANAGER]: isValueExists(dataToSubmit[KEYS.GROWTH_MANAGER]?.id) ? dataToSubmit[KEYS.GROWTH_MANAGER]?.id : undefined,
        },
        { refetchGame: true },
      ));
      return;
    }

    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.GAMES,
      name: AnalyticsService.NAMES.ADD_NEW_GAME,
      action: AnalyticsService.ACTIONS.SUBMIT,
    });

    dispatch(gamesActions.createGame({
      ...formData,
      [KEYS.GROWTH_MANAGER]: isValueExists(formData[KEYS.GROWTH_MANAGER]?.id) ? formData[KEYS.GROWTH_MANAGER]?.id : null,
    }));
  }, [isEdit, managersList]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    isValid,
    touched,
    handleBlurAndTrimValue,
  } = useFormik({
    initialValues: getInitialValues(initialValues, managersList),
    onSubmit: handleFormSubmit,
    validationSchema: getValidationSchema(storeIds, isEdit),
  });

  const handleDelete = useCallback(() => {
    setIsDeleteConfirmationModalOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    dispatch(gamesActions.deleteGame({ id: initialValues.id }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleAutocompleteChange = useCallback((value, event, { name }, option) => {
    setFieldTouched(name);
    setFieldValue(name, option);
  }, [setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const lionAppAutocompleteProps = useMemo(() => ({
    name: KEYS.LION_APP,
  }), []);

  const lionAppAutocompleteInputProps = useMemo(() => ({
    label: 'Lion App',
    error: touched[KEYS.LION_APP] && errors[KEYS.LION_APP],
  }), [touched, errors]);

  const growthManagerAutocompleteProps = useMemo(() => ({
    name: KEYS.GROWTH_MANAGER,
  }), []);

  const growthManagerAutocompleteInputProps = useMemo(() => ({
    label: LABELS[KEYS.GROWTH_MANAGER],
    error: touched[KEYS.GROWTH_MANAGER] && errors[KEYS.GROWTH_MANAGER],
  }), [touched, errors]);

  useEffect(() => {
    if (!isEdit) {
      setFieldValue(KEYS.STORE_ID, '');
    }
  }, [values[KEYS.PLATFORM]]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Wrapper as={Paper}>
        <TitleWrapper>
          <Title>
            {getTitle(isEdit, isGameArchived)}
          </Title>

          {
            tooltipText && !isGameArchived && (
              <Tooltip
                title={tooltipText}
                placement="right"
                arrow
              >
                <InfoIcon
                  as={InfoOutlinedIcon}
                  color="secondary"
                  size="small"
                />
              </Tooltip>
            )
          }
        </TitleWrapper>

        <form onSubmit={handleSubmit}>
          {
            !isGameArchived && (
              <FieldWrapper>
                <UncontrolledAutocomplete
                  name={KEYS.GROWTH_MANAGER}
                  value={values[KEYS.GROWTH_MANAGER]}
                  options={managersList}
                  onSubmit={handleAutocompleteChange}
                  inputProps={growthManagerAutocompleteInputProps}
                  getOptionLabel={getManagersOptionLabel}
                  autocompleteProps={growthManagerAutocompleteProps}
                  disabled={isGameArchived}
                />
              </FieldWrapper>
            )
          }

          <FieldWrapper>
            <Select
              name={KEYS.PLATFORM}
              value={values[KEYS.PLATFORM]}
              options={PLATFORMS_OPTIONS}
              onChange={handleChange}
              label="Platform"
              disabled={isEdit || isGameArchived}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Input
              name={KEYS.NAME}
              value={values[KEYS.NAME]}
              error={touched[KEYS.NAME] && errors[KEYS.NAME]}
              onBlur={handleBlurAndTrimValue}
              onChange={handleChange}
              label="App Name"
              disabled={isGameArchived}
            />
          </FieldWrapper>

          {
            isEdit && !isGameArchived && (
              <FieldWrapper>
                <Select
                  name={KEYS.STATUS}
                  value={values[KEYS.STATUS]}
                  options={GAME_STATUSES}
                  onChange={handleChange}
                  label="App Status"
                  disabled={isGameArchived}
                />
              </FieldWrapper>
            )
          }

          <FieldWrapper>
            <Input
              name={KEYS.STORE_ID}
              value={values[KEYS.STORE_ID]}
              onChange={handleChange}
              onBlur={handleBlurAndTrimValue}
              error={touched[KEYS.STORE_ID] && errors[KEYS.STORE_ID]}
              disabled={isEdit || isGameArchived}
              {...STORE_ID_INPUT_PROPS[values[KEYS.PLATFORM]]}
              {...(isEdit ? { description: null } : {})}
            />
          </FieldWrapper>

          <FieldWrapper>
            <LionAppAutocomplete
              autocompleteProps={lionAppAutocompleteProps}
              inputProps={lionAppAutocompleteInputProps}
              onSubmit={handleAutocompleteChange}
              options={lionApps}
              initialValue={values[KEYS.LION_APP]}
              disabled={isGameArchived}
            />
          </FieldWrapper>

          {
            !isGameArchived && (
              <FieldWrapper>
                <StyledButton
                  as={Button}
                  type="submit"
                  disabled={!isValid}
                  isFetching={isEdit ? isUpdateFetching : isCreateFetching}
                >
                  {isEdit ? 'Save' : 'Add Game'}
                </StyledButton>

                {
                  withDeletion && (
                    <StyledButton
                      as={Button}
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                      isFetching={isDeleteFetching}
                    >
                      Archive
                    </StyledButton>
                  )
                }

                {
                  !isEdit && (
                    <Text variant="body2">
                      You still need to set your App Status to &quot;Live&quot; from the status &quot;Paused&quot; after you create it. Remember to change the channel status for the newly added channel from &quot;Pending&quot; to &quot;Live&quot; after the end of the first cycle.
                    </Text>
                  )
                }
              </FieldWrapper>
            )
          }
        </form>
      </Wrapper>

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        title="Game Archive Confirmation"
        description="Please, confirm that you want to archive this Game"
      />
    </>
  );
});


GameForm.propTypes = {
  isEdit: PropTypes.bool,
  tooltipText: PropTypes.string,
  initialValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lionAppName: PropTypes.string,
    lionAppId: PropTypes.number,
    status: PropTypes.oneOf(Object.values(GAME_STATUS_KEYS)).isRequired,
  }),
  withDeletion: PropTypes.bool,
};

GameForm.defaultProps = {
  isEdit: false,
  tooltipText: null,
  initialValues: null,
  withDeletion: false,
};


export default GameForm;

import { createSelector } from 'reselect';


const getLocalState = (state) => state.users;

const getAuthorizedUserState = (state) => getLocalState(state).user;
const getUsersState = (state) => getLocalState(state).list;
const getActivityLogState = (state) => getLocalState(state).activityLog;

const getIsUserAddFetchingState = (state) => getLocalState(state).isUserAddFetching;
const getIsApproveNewUserFetchingState = (state) => getLocalState(state).isApproveNewUserFetching;
const getIsUsersFetchingState = (state) => getLocalState(state).isUsersFetching;
const getIsUserUpdateFetchingState = (state) => getLocalState(state).isUserUpdateFetching;
const getIsUserDeleteFetchingState = (state) => getLocalState(state).isUserDeleteFetching;
const getIsActivityLogFetchingState = (state) => getLocalState(state).isActivityLogFetching;

export const getAuthorizedUser = createSelector(
  getAuthorizedUserState,
  (user) => user,
);

export const getIsAuthorizedUserAdmin = createSelector(
  getAuthorizedUser,
  (user) => Boolean(user?.isAdmin),
);

export const getIsUserAddFetching = createSelector(
  getIsUserAddFetchingState,
  (isFetching) => isFetching,
);

export const getIsApproveNewUserFetching = createSelector(
  getIsApproveNewUserFetchingState,
  (isFetching) => isFetching,
);

export const getUsers = createSelector(
  getUsersState,
  (users) => users,
);

export const getActivityLog = createSelector(
  getActivityLogState,
  (activityLog) => activityLog,
);

export const getIsUsersFetching = createSelector(
  getIsUsersFetchingState,
  (isFetching) => isFetching,
);

export const getIsUserUpdateFetching = createSelector(
  getIsUserUpdateFetchingState,
  (isFetching) => isFetching,
);

export const getIsUserDeleteFetching = createSelector(
  getIsUserDeleteFetchingState,
  (isFetching) => isFetching,
);

export const getIsActivityLogFetching = createSelector(
  getIsActivityLogFetchingState,
  (isFetching) => isFetching,
);

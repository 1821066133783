import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getIsGameCreateFetching, getIsGameUpdateFetching } from 'core/games/selectors';
import { CHANNELS_OPTIONS, CAMPAIGN_TYPES_OPTIONS, CHANNELS_LIMITS_CAMPAIGN_TYPES } from 'core/games/constants';
import settingsActions from 'core/settings/actions';

import useFormik from 'hooks/use-formik';

import Title from 'components/common/title';
import Select from 'components/common/uncontrolled-select';
import Paper from 'components/common/paper';
import Button from 'components/common/button';
import FilterList from 'components/settings/change-limits/filter-list';

import { getDifference } from 'utils';

import { KEYS, LIMIT_TYPE_OPTIONS, UPDATE_KEYS } from './constants';
import { getValidationSchema } from './validation';
import { getInitialValues, renameKeys, getTitle } from './utils';
import {
  FieldWrapper,
  StyledButton,
  Wrapper,
  TitleWrapper,
  DataGridFilterWrapper,
} from './styles';


const ChangeLimitForm = memo(({ initialValues, isEdit, onCancel }) => {
  const dispatch = useDispatch();
  const isCreateFetching = useSelector(getIsGameCreateFetching);
  const isUpdateFetching = useSelector(getIsGameUpdateFetching);
  const [campaignTypesOptions, setCampaignTypesOptions] = useState(() => {
    if (isEdit) {
      return CAMPAIGN_TYPES_OPTIONS;
    }
    return [];
  });

  const [detectModalShow, setDetectModalShow] = useState(false);

  const handleFormSubmit = useCallback((formData) => {
    const transformedInitialValues = getInitialValues(initialValues);
    const dataToSubmit = getDifference(formData, transformedInitialValues);
    let mapFilterList = formData.filterList;

    mapFilterList = mapFilterList.map((item) => {
      const result = item;
      if (item.operator === 'between') {
        result.value = [item?.value1 ?? '', item?.value2 ?? ''];
      } else {
        result.value = [item?.value ?? ''];
      }
      delete result.value1;
      delete result.value2;

      return result;
    });

    dataToSubmit.filterList = JSON.stringify(mapFilterList);

    if (isEdit) {
      const { id } = initialValues;
      let flipKeys = Object.entries(UPDATE_KEYS).map(([key, value]) => [value, key]);
      flipKeys = Object.fromEntries(flipKeys);
      const cleanDataToSubmit = renameKeys(dataToSubmit, flipKeys);

      dispatch(settingsActions.updateChangeLimit({
        id,
        data: cleanDataToSubmit,
      }));
      onCancel();
      return;
    }

    dispatch(settingsActions.createChangeLimit({
      ...dataToSubmit,
    }));
    onCancel();
  }, [isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isValid,
    touched,
    handleBlurAndTrimValue,
  } = useFormik({
    initialValues: getInitialValues(initialValues),
    onSubmit: handleFormSubmit,
    validationSchema: getValidationSchema(),
  });

  const [filterValues, setFilterValues] = useState(() => {
    if ((isEdit) && (initialValues.filterList.length)) {
      return initialValues.filterList;
    }
    return [
      {
        operator: '',
        value: '',
        max: '',
      },
    ];
  });

  const removeFormFields = (i) => {
    const newFilterValues = [...filterValues];
    newFilterValues.splice(i, 1);
    setFilterValues(newFilterValues);

    delete values.filterList;
    values.filterList = [...newFilterValues];
  };

  const addFormFields = () => {
    setFilterValues([...filterValues, { operator: '', value: '', max: '' }]);
  };

  const propsFilterList = {
    values, handleChange, errors, isValid, touched, handleBlurAndTrimValue, filterValues, removeFormFields, addFormFields,
  };

  useEffect(() => {
    setDetectModalShow(true);
    const { channel } = values;

    if (channel.length) {
      if (detectModalShow) {
        values.campaignType = '';
      }

      const specificCampaignType = CAMPAIGN_TYPES_OPTIONS.filter((item) => CHANNELS_LIMITS_CAMPAIGN_TYPES[channel].includes(item.id));
      setCampaignTypesOptions(specificCampaignType);
    }
  }, [values.channel]);

  useEffect(() => {
    const { filterList } = values;

    if (filterList !== undefined) {
      let newFilterValues = [...filterValues];
      newFilterValues = newFilterValues.map((item, index) => {
        const result = item;
        result.operator = values?.filterList?.[index]?.operator ?? '';
        result.max = values?.filterList?.[index]?.max ?? '';
        if (item.operator === 'between') {
          result.value1 = values?.filterList?.[index]?.value1 ?? '';
          result.value2 = values?.filterList?.[index]?.value2 ?? '';
        } else {
          result.value = values?.filterList?.[index]?.value ?? '';
        }

        return result;
      });
      setFilterValues(newFilterValues);
    }
  }, [values.filterList]);

  return (

    <Wrapper as={Paper}>
      <TitleWrapper>
        <Title>
          {getTitle(isEdit)}
        </Title>
      </TitleWrapper>

      <form onSubmit={handleSubmit}>

        <FieldWrapper>
          <Select
            name={KEYS.CHANNEL_NAME}
            value={values[KEYS.CHANNEL_NAME]}
            options={CHANNELS_OPTIONS}
            onChange={handleChange}
            label="Channel Name"
            error={touched[KEYS.CHANNEL_NAME] && errors[KEYS.CHANNEL_NAME]}
            disabled={isEdit}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Select
            name={KEYS.CAMPAIGN_TYPE}
            value={values[KEYS.CAMPAIGN_TYPE]}
            options={campaignTypesOptions}
            error={touched[KEYS.CAMPAIGN_TYPE] && errors[KEYS.CAMPAIGN_TYPE]}
            onChange={handleChange}
            label="Campaign Type"
          />
        </FieldWrapper>

        <FieldWrapper>
          <Select
            name={KEYS.LIMIT_TYPE}
            value={values[KEYS.LIMIT_TYPE]}
            options={LIMIT_TYPE_OPTIONS}
            error={touched[KEYS.LIMIT_TYPE] && errors[KEYS.LIMIT_TYPE]}
            onChange={handleChange}
            label="Limit Type"
            disabled={isEdit}
          />
        </FieldWrapper>

        <DataGridFilterWrapper>
          <FilterList {...propsFilterList} />
        </DataGridFilterWrapper>

        <FieldWrapper>
          <StyledButton
            as={Button}
            type="submit"
            disabled={!isValid}
            isFetching={isEdit ? isUpdateFetching : isCreateFetching}
          >
            {isEdit ? 'Save' : 'Submit'}
          </StyledButton>
        </FieldWrapper>

      </form>

    </Wrapper>

  );
});


ChangeLimitForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
    filterList: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onCancel: PropTypes.func.isRequired,
};

ChangeLimitForm.defaultProps = {
  isEdit: false,
  initialValues: null,
};


export default ChangeLimitForm;

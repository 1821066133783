import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'containers/router/constants';

import { isObjectEmpty } from 'utils';
import { getFilteredList, getQueryParamsByFilter, getFilterByQueryParams } from 'utils/grid';

import { gamePropTypes } from 'models/game';
import { managerPropTypes } from 'models/manager';

import { useQueryParams } from 'hooks/use-query-params';
import useIsDataInited from 'hooks/use-is-data-inited';

import DataGrid from 'components/common/data-grid';
import { QUERY_PARAMS as ACTIVITY_LOGS_QUERY_PARAMS } from 'components/activity-logs/list/constants';

import { QUERY_PARAMS_BY_FIELDS, QUERY_PARAMS_WITH_TYPES, ACTIVE_FILTERS, DEFAULT_FILTER } from './constants';
import { getConfig } from './config';
import { getDefaultGrowthManagerFilter } from './utils';


const GamesList = memo(({
  isFetching,
  data,
  managersList,
  isGameUpdateFetching,
  isNetworkUpdateFetching,
  isGoalRoasUpdateFetching,
  selectedGamesIds,
  onSelectedGamesIdsChange,
  onGameUpdate,
  onNetworkUpdate,
  onGoalRoasUpdate,
  lastEditedRows,
  isAppStatusReady,
  user,
  onEdit,
  onDelete,
}) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState({});
  const [shouldApplyQueryFilters, setShouldApplyQueryFilters] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAMS_WITH_TYPES);

  const isListInited = useIsDataInited(list);

  const handleRowUpdate = useCallback((nextData) => {
    onEdit(nextData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = useCallback((nextData) => {
    onDelete(nextData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleListFilterChange = useCallback((filter) => {
    if (!isListInited) {
      return;
    }

    setListFilter(filter);
    setQueryParams(
      getQueryParamsByFilter(filter, queryParams, QUERY_PARAMS_BY_FIELDS),
      { replace: true },
    );
    setShouldApplyQueryFilters(false);
  }, [setQueryParams, queryParams, isListInited]);

  const handleSelectedGamesIdsChange = useCallback((ids) => {
    if (!isListInited) {
      return;
    }

    onSelectedGamesIdsChange(ids);
    /*
    setQueryParams(
      {
        ...queryParams,
        [QUERY_PARAMS.SELECTION]: ids,
      },
      { replace: true },
    );
    */
  }, [isListInited, onSelectedGamesIdsChange]);

  const handleActivityLogsClick = useCallback((row) => {
    navigate(`/${ROUTES.ACTIVITY_LOG}?${ACTIVITY_LOGS_QUERY_PARAMS.GAME}=${encodeURIComponent(row.name)}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const config = useMemo(() => getConfig(
    list,
    onGameUpdate,
    onNetworkUpdate,
    onGoalRoasUpdate,
    isAppStatusReady,
    handleRowUpdate,
    handleDelete,
    handleActivityLogsClick,
    managersList,
  ), [list, onGameUpdate, onNetworkUpdate, onGoalRoasUpdate, isAppStatusReady, handleRowUpdate, handleDelete, handleActivityLogsClick, managersList]);

  const getIsRowFetching = useCallback(({ row: { appId, id } }) => {
    if (isGameUpdateFetching) {
      return lastEditedRows.includes(appId);
    }

    if (isNetworkUpdateFetching || isGoalRoasUpdateFetching) {
      return lastEditedRows.includes(id);
    }

    return null;
  }, [isGameUpdateFetching, isNetworkUpdateFetching, isGoalRoasUpdateFetching, lastEditedRows]);

  useEffect(() => {
    setList(getFilteredList(data, listFilter));

    if (!isObjectEmpty(listFilter) && shouldApplyQueryFilters) {
      setShouldApplyQueryFilters(false);
    }
  }, [data, listFilter, shouldApplyQueryFilters]);

  useEffect(() => {
    if (!isListInited) {
      return;
    }

    const queryFilters = getFilterByQueryParams(queryParams, QUERY_PARAMS_BY_FIELDS);

    // onSelectedGamesIdsChange(queryParams[QUERY_PARAMS.SELECTION] || []);
    setListFilter({
      ...DEFAULT_FILTER,
      ...getDefaultGrowthManagerFilter(data, user),
      ...queryFilters,
    });

    if (!isObjectEmpty(queryFilters)) {
      setShouldApplyQueryFilters(true);
    }
  }, [isListInited]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DataGrid
      config={config}
      isFetching={isFetching}
      getIsRowFetching={getIsRowFetching}
      onFilterChange={handleListFilterChange}
      selectionModel={selectedGamesIds}
      onSelectionModelChange={handleSelectedGamesIdsChange}
      filter={listFilter}
      activeFilters={ACTIVE_FILTERS}
      useFilters
    />
  );
});


GamesList.propTypes = {
  data: PropTypes.arrayOf(gamePropTypes),
  isFetching: PropTypes.bool.isRequired,
  managersList: PropTypes.arrayOf(managerPropTypes).isRequired,
  isGameUpdateFetching: PropTypes.bool.isRequired,
  isGoalRoasUpdateFetching: PropTypes.bool.isRequired,
  isNetworkUpdateFetching: PropTypes.bool.isRequired,
  selectedGamesIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  onSelectedGamesIdsChange: PropTypes.func.isRequired,
  onGameUpdate: PropTypes.func.isRequired,
  onNetworkUpdate: PropTypes.func.isRequired,
  onGoalRoasUpdate: PropTypes.func.isRequired,
  lastEditedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

GamesList.defaultProps = {
  data: [],
  user: null,
};


export default GamesList;

import React, { useCallback, useState } from 'react';

import UsersService from 'core/users/service';

import SnackbarService from 'services/snackbar';
import { downloadBlobFile } from 'utils/files';

import Button from 'components/common/button';

import { getCsvName } from './utils';


const CsvButton = () => {
  const [isSaveFetching, setIsSaveFetching] = useState(false);

  const handleClick = useCallback(async () => {
    setIsSaveFetching(true);

    try {
      const blob = await UsersService.fetchActivityLogsCsv();

      downloadBlobFile(blob, getCsvName());
    } catch (error) {
      SnackbarService.showError('Something went wrong');
    } finally {
      setIsSaveFetching(false);
    }
  }, []);

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      isFetching={isSaveFetching}
    >
      Save CSV
    </Button>
  );
};


export default CsvButton;

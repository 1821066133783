export const FIELDS = {
  ACTIONS: 'actions',
  ID: 'ID',
  CHANNEL: 'CHANNEL',
  CAMPAIGN_TYPE: 'CAMPAIGN_TYPE',
  LIMIT_TYPE: 'LIMIT_TYPE',
  FILTER_LIST: 'FILTER_LIST',
};


export const ACTIVE_FILTERS = [FIELDS.CHANNEL];

import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'campaigns',
  storage,
  blacklist: [
    'isFetching',
    'isCampaignUpdateFetching',
    'isCampaignUpdateFetching',
  ],
};


export default persistConfig;

import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import MuiSwitch from '@mui/material/Switch';

import { StyledSwitch } from './styles';


const Switch = memo(({ initialValue, onChange, ...otherProps }) => {
  const [isChecked, setIsChecked] = useState(() => initialValue);

  const handleChange = useCallback(({ target: { checked } }) => {
    setIsChecked(checked);
    onChange(checked);
  }, [onChange]);

  useEffect(() => {
    setIsChecked(initialValue);
  }, [initialValue]);

  return (
    <StyledSwitch
      as={MuiSwitch}
      checked={isChecked}
      onChange={handleChange}
      {...otherProps}
    />
  );
});


Switch.propTypes = {
  initialValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  initialValue: false,
};


export default Switch;

export const OPERATORS = {
  IS_LESS: '<',
  IS_LESS_OR_EQUAL: '<=',
  IS_GREATER: '>',
  IS_GREATER_OR_EQUAL: '>=',
  IS_EQUAL: '=',
  IS_NOT_EQUAL: '!=',
  IS_IN: 'in',
  IS_BETWEEN: 'between',
};

export const OPERATORS_LIST = [
  {
    id: OPERATORS.IS_GREATER_OR_EQUAL,
    name: 'is >=',
  },
  {
    id: OPERATORS.IS_GREATER,
    name: 'is >',
  },
  {
    id: OPERATORS.IS_LESS_OR_EQUAL,
    name: 'is <=',
  },
  {
    id: OPERATORS.IS_LESS,
    name: 'is <',
  },
  {
    id: OPERATORS.IS_EQUAL,
    name: 'is equal',
  },
  {
    id: OPERATORS.IS_NOT_EQUAL,
    name: 'is not equal',
  },
  {
    id: OPERATORS.IS_BETWEEN,
    name: 'is between',
  },
];

export const KEYS = {
  CHANNEL_NAME: 'channel',
  CAMPAIGN_TYPE: 'campaignType',
  LIMIT_TYPE: 'limitType',
  FILTER_LIST: 'filterList',
};

export const CPI_LIST = ['CPI', 'OCPI'];
export const ROAS_LIST = ['BLD_ROAS', 'ROAS_AD_BASED', 'ROAS_IAP'];
export const CPE_LIST = ['CPE'];

import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'settings',
  storage,
  blacklist: [
    'isConfigFetching',
    'isConfigUpdateFetching',
    'isAppStatusFetching',
    'isCountriesFetching',
  ],
};


export default persistConfig;

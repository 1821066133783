import React, { memo, useCallback, useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/base/ClickAwayListener';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import settingsActions from 'core/settings/actions';

import Text from 'components/common/text';

import { TRANSITION } from 'containers/theme-provider/constants';

import { POPPER_OPTIONS } from './constants';
import { getUTCTimeString, getLocalizedTimeString, getNextPushInTimeString } from './utils';
import {
  Wrapper,
  Button,
  StyledIcon,
  Title,
  PopperContainer,
  KeyText,
  ValueText,
} from './styles';


const StatusClock = memo(({ lastPush, nextPush }) => {
  const wrapperRef = useRef();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [countdowns, setCountdowns] = useState({
    serverTime: '',
    nextPushIn: '',
  });

  const lastPushUTCString = useMemo(() => getUTCTimeString(lastPush), [lastPush]);
  const nextPushUTCString = useMemo(() => getUTCTimeString(nextPush), [nextPush]);
  const lastPushLocalizedString = useMemo(() => getLocalizedTimeString(lastPush), [lastPush]);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleClickAway = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const interval = setInterval(() => {
      const serverTime = getUTCTimeString();
      const { isPast, nextPushIn } = getNextPushInTimeString(nextPush);

      if (isPast) {
        dispatch(settingsActions.fetchAppStatus());
      }

      setCountdowns({
        serverTime,
        nextPushIn,
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [nextPush]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper
        ref={wrapperRef}
      >
        <Button
          onClick={toggleIsOpen}
        >
          <StyledIcon
            as={AccessAlarmIcon}
          />

          <Title as={Text}>
            {countdowns.nextPushIn}
          </Title>

          <StyledIcon
            $withoutMargin
            as={isOpen ? ArrowDropUpIcon : ArrowDropDownIcon}
          />

        </Button>

        <Popper
          open={isOpen}
          anchorEl={wrapperRef.current}
          popperOptions={POPPER_OPTIONS}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              timeout={TRANSITION.DURATION}
            >
              <PopperContainer>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  mb={2}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    width="50%"
                  >
                    <StyledIcon
                      as={ArrowRightAltIcon}
                      $rotate={-90}
                    />

                    <KeyText as={Text}>Next Cycle Start</KeyText>
                  </Stack>

                  <ValueText as={Text}>{nextPushUTCString}</ValueText>
                </Stack>

                <Stack
                  flexDirection="row"
                  alignItems="center"
                  mb={2}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    width="50%"
                  >
                    <StyledIcon as={HistoryToggleOffIcon} />

                    <KeyText as={Text}>Last Push UTC</KeyText>
                  </Stack>

                  <ValueText as={Text}>{lastPushUTCString}</ValueText>
                </Stack>

                <Stack
                  flexDirection="row"
                  alignItems="center"
                  mb={2}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    width="50%"
                  >
                    <StyledIcon as={HistoryToggleOffIcon} />

                    <KeyText as={Text}>Last Push Local</KeyText>
                  </Stack>

                  <ValueText as={Text}>{lastPushLocalizedString}</ValueText>
                </Stack>

                <Stack
                  flexDirection="row"
                  alignItems="center"
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    width="50%"
                  >
                    <StyledIcon as={AccessTimeIcon} />

                    <KeyText as={Text}>Server Time</KeyText>
                  </Stack>

                  <ValueText as={Text}>{countdowns.serverTime}</ValueText>
                </Stack>
              </PopperContainer>

            </Fade>
          )}
        </Popper>
      </Wrapper>
    </ClickAwayListener>
  );
});


StatusClock.propTypes = {
  lastPush: PropTypes.number.isRequired,
  nextPush: PropTypes.number.isRequired,
};

StatusClock.defaultProps = {

};


export default StatusClock;

import { styled } from '@mui/system';

import { formControlClasses } from '@mui/material/FormControl';
import { inputBaseClasses } from '@mui/material/InputBase';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';


export const StyledInput = styled('div')(({ theme }) => ({
  [`.${formControlClasses.root}`]: {
    position: 'relative',
  },

  [`.${inputBaseClasses.root}`]: {
    ...theme.typography.body2,
    lineHeight: 1.5,
    borderRadius: 4,
    paddingRight: theme.spacing(1),
  },

  [`.${inputBaseClasses.input}`]: {
    boxSizing: 'border-box',
    height: '38px',
    padding: theme.spacing(1),
    position: 'relative',
    zIndex: 2,

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&[type=number]': {
      MozAppearance: 'textfield',
    },

    '&:-webkit-autofill': {
      '&, &:hover, &:focus, &:active': {
        '-webkit-background-clip': 'text',
      },
    },
  },

  [`textarea.${inputBaseClasses.input}`]: {
    padding: theme.spacing(0),
  },

  '&:focus, &:focus-within, &:focus-visible': {
    outline: 'none',
  },

  [`.${outlinedInputClasses.notchedOutline}, .${inputBaseClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 1,
    borderColor: theme.palette.input.main,
    zIndex: 1,
  },

  [`.${inputBaseClasses.disabled}`]: {
    color: theme.palette.input.dark,
  },

  [`.${inputBaseClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
    backgroundColor: theme.palette.background.lighter,
  },

  [`.${inputBaseClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 1,
  },

  [`.${formHelperTextClasses.root}`]: {
    position: 'absolute',
    top: '100%',
    marginTop: 0,
    marginLeft: 0,
  },
}));

export const Label = styled('label')(({ theme }) => ({
  ...theme.typography.body1,
  display: 'block',
  fontWeight: theme.typography.fontWeightBolder,
  marginBottom: theme.spacing(1),
}));

export const Description = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: theme.spacing(1),
}));

export const Wrapper = styled('div')(() => ({
  width: '100%',
}));

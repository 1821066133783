import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styles';


const FormField = memo(({ children, ...otherProps }) => (
  <Wrapper {...otherProps}>
    {children}
  </Wrapper>
));


FormField.propTypes = {
  children: PropTypes.node.isRequired,
};

FormField.defaultProps = {

};


export default FormField;

import { styled } from '@mui/system';
import { HORIZONTAL_PADDING_DATA_GRID_CELL, HORIZONTAL_PADDING_PAGE_LAYOUT } from 'constants/layout';
import { BASE } from 'containers/theme-provider/constants';


export const DEFAULT_PADDING_ABSOLUTE_TEXT = 0.5;
export const PAGE_OFFSET = HORIZONTAL_PADDING_DATA_GRID_CELL + HORIZONTAL_PADDING_PAGE_LAYOUT;

export const Wrapper = styled('div')(({ $isFullTextVisible }) => ({
  maxWidth: '100%',
  width: '100%',
  overflow: $isFullTextVisible ? 'visible' : 'hidden',
  textOverflow: $isFullTextVisible ? 'none' : 'ellipsis',
}));

export const AbsoluteText = styled('p')(({ theme, $offset }) => ({
  position: 'absolute',
  zIndex: 5,
  backgroundColor: theme.palette.background.light,
  padding: theme.spacing(DEFAULT_PADDING_ABSOLUTE_TEXT),
  marginTop: theme.spacing(-DEFAULT_PADDING_ABSOLUTE_TEXT),
  marginLeft: theme.spacing(-($offset / BASE.PADDING)),
  boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
  borderRadius: `${theme.shape.borderRadius / 3}px`,
  cursor: 'pointer',
}));

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import config from 'config';

import bidsActions from 'core/bids/actions';
import { getBidsList, getTotal, getIsBidsFetchingFetching, getIsOverrideFetching, getHiddenGridColumns } from 'core/bids/selectors';
import gamesActions from 'core/games/actions';
import { getGamesList, getIsGamesFetching } from 'core/games/selectors';
import { getUniqueGamesList } from 'core/games/utils';
import campaignsActions from 'core/campaigns/actions';
import { getIsListFetching, getCampaingsList } from 'core/campaigns/selectors';
import { getCountries, getIsCountriesFetching, getIsAppStatusReady } from 'core/settings/selectors';
import { getManagersList, getIsManagersFetching } from 'core/managers/selectors';
import { getIsAuthorizedUserAdmin } from 'core/users/selectors';

import AnalyticsService from 'services/analytics';

import BidsList from 'components/bids/list';
import HistoricalBidsModal from 'components/historical-bids/modal';


const Bids = memo(() => {
  const dispatch = useDispatch();
  const bids = useSelector(getBidsList);
  const total = useSelector(getTotal);
  const isFetching = useSelector(getIsBidsFetchingFetching);
  const isOverrideFetching = useSelector(getIsOverrideFetching);
  const games = useSelector(getGamesList);
  const isGamesFetching = useSelector(getIsGamesFetching);
  const campaigns = useSelector(getCampaingsList);
  const isCampaignsFetching = useSelector(getIsListFetching);
  const countries = useSelector(getCountries);
  const isCountriesFetching = useSelector(getIsCountriesFetching);
  const managers = useSelector(getManagersList);
  const isManagersFetching = useSelector(getIsManagersFetching);
  const hiddenGridColumns = useSelector(getHiddenGridColumns);
  const isAdmin = useSelector(getIsAuthorizedUserAdmin);
  const isAppStatusReady = useSelector(getIsAppStatusReady);

  const [isHistoricalBidsModalOpen, setIsHistoricalBidsModalOpen] = useState(false);

  const uniqueGames = useMemo(() => getUniqueGamesList(games), [games]);

  const handleFiltersAndSortingStateChange = useCallback((data) => {
    dispatch(bidsActions.fetchList(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const overrideBid = useCallback((data) => {
    dispatch(bidsActions.override(data, { refetchBidsList: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGridColumnsVisibilityChange = useCallback((hiddenColumns) => {
    dispatch(bidsActions.setHiddenGridColumns(hiddenColumns));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLookerClick = useCallback(() => {
    window.open(config.DIRECT_LOOKER_URL, '_blank').focus();
  }, []);

  const handlePushHistoricalBidsClick = useCallback(() => {
    setIsHistoricalBidsModalOpen(true);
  }, []);

  const handleHistoricalBidsModalClose = useCallback(() => {
    setIsHistoricalBidsModalOpen(false);
  }, []);

  useEffect(() => {
    dispatch(gamesActions.fetchList());
    dispatch(campaignsActions.fetchList());

    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.BIDS,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BidsList
        data={bids}
        total={total}
        games={uniqueGames}
        campaigns={campaigns}
        countries={countries}
        managers={managers}
        isGamesFetching={isGamesFetching}
        isCampaignsFetching={isCampaignsFetching}
        isCountriesFetching={isCountriesFetching}
        isManagersFetching={isManagersFetching}
        onFiltersAndSortingStateChange={handleFiltersAndSortingStateChange}
        onBidOverride={overrideBid}
        isFetching={isFetching}
        isOverrideFetching={isOverrideFetching}
        hiddenColumns={hiddenGridColumns}
        onColumnVisibilityChange={handleGridColumnsVisibilityChange}
        onLookerClick={handleLookerClick}
        onPushHistoricalBidsClick={handlePushHistoricalBidsClick}
        isAdmin={isAdmin}
        isAppStatusReady={isAppStatusReady}
      />

      {
        isHistoricalBidsModalOpen && (
          <HistoricalBidsModal
            isOpen={isHistoricalBidsModalOpen}
            onCancel={handleHistoricalBidsModalClose}
          />
        )
      }
    </>

  );
});

export default Bids;

import * as yup from 'yup';


const BASE_TYPE_TEXT = 'Account id can\'t contain characters: # ^ ! @ $ % & * ) ( [ ] + = . , _ - / \\';
const MINTEGRAL_TYPE_TEXT = 'Mintegral account id should be a number';
const APPLOVIN_TYPE_TEXT = 'Applovin account id should be an email';

export const baseAccountIdValidator = yup.string().test(
  'baseAccountIdValidator',
  BASE_TYPE_TEXT,
  (value) => !value.match(/[#^!@$%&*)([\]+=.,\_\-\/\\]/gi), // eslint-disable-line no-useless-escape
);

export const mintegralAccountIdValidator = baseAccountIdValidator.test(
  'integerAccountIdValidator',
  MINTEGRAL_TYPE_TEXT,
  (value) => value.match(/^\d+$/),
);

export const applovinAccountIdValidator = yup.string().email(APPLOVIN_TYPE_TEXT);

export const adjoeAccountIdValidator = yup.string();

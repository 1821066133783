import { styled } from '@mui/system';


export const Wrapper = styled('div')({
  height: '100%',
});

export const ListWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}));

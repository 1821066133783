import LionstudiosApi from 'services/lionstudios-api';

import {
  listModel,
  updateGameModel,
  gameModel,
  createGameModel,
  createGameResponseModel,
  updateGoalRoasModel,
  overrideListModel,
  resetOverrideGoalRoasModel,
  fetchOverrideListModel,
} from './models';
import { GAMES_URL, GOAL_ROAS_URL } from './constants';


const GamesService = {
  fetchList: async () => {
    const response = await LionstudiosApi.get(`${GAMES_URL}/list`);

    return listModel(response.data);
  },

  fetchGame: async (id) => {
    const response = await LionstudiosApi.get(`${GAMES_URL}/${id}`);

    return gameModel(response.data);
  },

  fetchGames: async (ids) => {
    const response = await Promise.all(ids.map(GamesService.fetchGame));

    return response;
  },

  updateGame: async (id, payload) => {
    const data = updateGameModel(payload);

    const response = await LionstudiosApi.post(`${GAMES_URL}/${id}`, data);

    return response;
  },

  deleteGame: async (id) => {
    await LionstudiosApi.delete(`${GAMES_URL}/${id}`);

    return null;
  },

  createGame: async (payload) => {
    const data = createGameModel(payload);

    const response = await LionstudiosApi.post(`${GAMES_URL}`, data);

    return createGameResponseModel(response.data);
  },

  updateGoalRoas: async (payload) => {
    const data = updateGoalRoasModel(payload);

    await LionstudiosApi.post(GOAL_ROAS_URL, data);

    return null;
  },

  fetchOverrideList: async ({ gameId, channelId, campaignId, goalRoas, comment }) => {
    const params = fetchOverrideListModel({ channelId, campaignId });
    const response = await LionstudiosApi.get(`${GAMES_URL}/${gameId}/overrides`, {
      params,
    });

    return overrideListModel(response.data, goalRoas, comment);
  },

  resetOverrideGoalRoasList: async (list) => {
    await Promise.all(list.map((item) => GamesService.resetOverrideGoalRoas({
      ...item,
      initialValue: item.goalRoasOverride,
    })));
    return true;
  },

  resetOverrideGoalRoas: async (payload) => {
    const body = resetOverrideGoalRoasModel(payload);
    await LionstudiosApi.post(`${GOAL_ROAS_URL}/reset`, body);

    return true;
  },
};


export default GamesService;

import theme from './styles';


// TODO: include all global styles here instead of `theme` folder

// TODO: use @global instead ?
export default {
  body: {
    '.MuiAutocomplete-popper': {
      '.MuiAutocomplete-option': {
        width: '100%',
        padding: theme.spacing(0, 1),
        fontSize: theme.typography.body2.fontSize,
        color: theme.typography.body2.color,

        '&.Mui-focused, &[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
          backgroundColor: theme.palette.background.hover,
        },
      },

      '.MuiAutocomplete-paper': {
        borderRadius: 0,
        boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.08)',
      },

      '.MuiAutocomplete-listbox': {
        paddingTop: theme.spacing(0.25),
        paddingBottom: 0,
      },

      '.MuiAutocomplete-loading': {
        fontSize: '12px',
      },
    },

    '.MuiAutocomplete-noOptions': {
      fontSize: theme.typography.body2.fontSize,
    },

    '.MuiPopover-paper': {
      borderRadius: 0,
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.08)',
    },

    '.MuiMenu-root': {
      '.MuiMenuItem-root': {
        height: theme.utils.menu.itemHeight,
        padding: theme.spacing(0, 1),
        fontSize: theme.typography.body2.fontSize,
        color: theme.typography.body2.color,

        '&:hover': {
          backgroundColor: theme.palette.background.hover,
        },

        '&.Mui-focused, &[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
          backgroundColor: theme.palette.background.hover,
        },
      },

      '.MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    '#root': {
      '.MuiAutocomplete-root': {
        '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
          right: '4px',
        },
      },
    },
  },

  '.MuiDataGrid-root': {
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
      padding: 0,
    },
  },

  '.MuiTooltip-popper a': {
    color: '#fff',
  },
};

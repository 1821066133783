import { styled } from '@mui/system';

import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';


export const HEADER_MIN_HEIGHT = '80px';

export const Wrapper = styled('header')(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  backgroundColor: theme.palette.primary.main,
  padding: '21px 16px',
  color: theme.palette.textSecondary.main,
  minHeight: HEADER_MIN_HEIGHT,
  position: 'relative',
  zIndex: 3,
}));

export const DescriptionText = styled('p')(({ theme }) => ({
  color: theme.palette.textPrimary.light,
  marginLeft: theme.spacing(5),

  '& svg': {
    marginRight: theme.spacing(1),
  },
}));

export const Navigation = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StyledLink = styled('div')(({ theme }) => ({
  color: theme.palette.textSecondary.main,
  marginLeft: '26px',
  textDecoration: 'none',
  borderBottom: '2px solid transparent',
  transition: 'border 350ms ease-out',
  cursor: 'pointer',

  '&.active, &:hover': {
    borderBottomColor: theme.palette.textSecondary.main,
  },

  '&:first-child': {
    marginLeft: 0,
  },
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  minWidth: 250,
  position: 'relative',
  zIndex: 1,
  marginRight: theme.spacing(5),

  [`.${inputBaseClasses.formControl}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: theme.shape.borderRadius / 3,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body1.fontSize,

    [`.${outlinedInputClasses.notchedOutline}`]: {
      border: 0,
    },
  },
}));

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';

import DatePicker from 'components/common/date-picker';

import { Label } from 'components/common/input/styles';

import { KEYS, MIN_DATE } from './constants';
import { ValidationSchema } from './validation';


const PushHistoricalBidsFilterForm = memo(({ initialValues, onSubmit, isFetching }) => {
  const handleFormSubmit = useCallback((nextValues) => {
    onSubmit({
      timePeriod: nextValues[KEYS.TIME_PERIOD]?.ts || null,
    });
  }, [onSubmit]);

  const { handleSubmit, handleBlur, values, errors, touched, submitForm, setFieldValue, setFieldTouched } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: ValidationSchema,
  });

  const handleDatePickerChange = useCallback((nextValue, name) => {
    setFieldTouched(nextValue);
    setFieldValue(name, nextValue);

    if (values[KEYS.TIME_PERIOD]?.ts !== nextValue?.ts) {
      submitForm();
    }
  }, [setFieldTouched, setFieldValue, submitForm, values]);

  return (
    <form onSubmit={handleSubmit}>
      <Label>Pick time period</Label>

      <DatePicker
        value={values[KEYS.TIME_PERIOD]}
        onChange={handleDatePickerChange}
        error={touched[KEYS.TIME_PERIOD] && errors[KEYS.TIME_PERIOD]}
        onInputBlur={handleBlur}
        name={KEYS.TIME_PERIOD}
        inputFormat="LL/dd/yyyy"
        allowSameDateSelection
        mask="__/__/____"
        disableFuture
        views={['day']}
        resetTimeOnSubmit
        minDate={DateTime.fromJSDate(MIN_DATE)}
        placeholder="MM/DD/YYYY"
        loading={isFetching}
      />
    </form>
  );
});


PushHistoricalBidsFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};


export default PushHistoricalBidsFilterForm;

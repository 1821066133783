import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { GAME_STATUS_KEYS } from 'core/games/constants';

import Text from 'components/common/text';

import { Status } from './styles';


const GameStatus = memo(({ status }) => (
  <Status
    as={Text}
    variant="smallTitle"
    $status={status}
  >
    {status?.toLowerCase()}
  </Status>
));


GameStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(GAME_STATUS_KEYS)).isRequired,
};


export default GameStatus;

import appSagas from './app/sagas';
import authSagas from './auth/sagas';
import gamesSagas from './games/sagas';
import managersSagas from './managers/sagas';
import networksSagas from './networks/sagas';
import settingsSagas from './settings/sagas';
import campaignsSagas from './campaigns/sagas';
import usersSagas from './users/sagas';
import bidsSagas from './bids/sagas';
import gamesSagasV4 from './v4/games/sagas';
import campaignsSagasV4 from './v4/campaigns/sagas';
import geosSagasV4 from './v4/geos/sagas';
import networksSagasV4 from './v4/networks/sagas';


const sagas = [
  ...appSagas,
  ...authSagas,
  ...gamesSagas,
  ...managersSagas,
  ...networksSagas,
  ...settingsSagas,
  ...campaignsSagas,
  ...usersSagas,
  ...bidsSagas,
  ...gamesSagasV4,
  ...campaignsSagasV4,
  ...geosSagasV4,
  ...networksSagasV4,
];


export default sagas;

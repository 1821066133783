import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import AnalyticsService from 'services/analytics';

import { getIsGamesFetching, getGamesList } from 'core/games/selectors';
import { getUniqueGamesList } from 'core/games/utils';
import { PLATFORM_ICONS } from 'core/games/constants';

import { ROUTES } from 'containers/router/constants';

import Input from 'components/common/input';
import Link from 'components/common/link';

import NoOptions from './no-options';

import { Wrapper, StyledAutocomplete, Option, LinkText, IconWrapper } from './styles';


const GamesSearch = () => {
  const navigate = useNavigate();

  const isFetching = useSelector(getIsGamesFetching);
  const data = useSelector(getGamesList);

  const [value] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const uniqueData = useMemo(() => getUniqueGamesList(data), [data]);

  const renderInput = useCallback((params) => {
    params.InputProps.startAdornment = ( // eslint-disable-line no-param-reassign
      <SearchIcon />
    );
    params.InputProps.endAdornment = null; // eslint-disable-line no-param-reassign

    return (
      <Input
        placeholder="Search..."
        {...params}
      />
    );
  }, []);

  const renderOption = useCallback((props, option) => {
    const Icon = PLATFORM_ICONS[option.platform];
    return (
      <Option
        {...props}
        as={Stack}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        key={option.appId}
      >
        <LinkText
          as={Link}
          element="span"
        >
          {option.name}
        </LinkText>

        <IconWrapper>
          <Icon />
        </IconWrapper>
      </Option>
    );
  }, []);

  const handleFocus = useCallback(() => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.SEARCH,
      name: AnalyticsService.NAMES.SEARCH_FOCUS,
      action: AnalyticsService.ACTIONS.CLICK,
    });
  }, []);

  const handleChange = useCallback((event, option) => {
    AnalyticsService.trackEvent({
      category: AnalyticsService.CATEGORIES.SEARCH,
      name: AnalyticsService.NAMES.OPEN_GAME_DETAILS,
      action: AnalyticsService.ACTIONS.CLICK,
    });

    AnalyticsService.trackSiteSearch({
      keyword: option.name,
    });

    navigate(`/${ROUTES.GAMES}/${option.appId}`);
  }, [navigate]);

  const handleInputChange = useCallback((event, nextInputValue) => {
    setInputValue(nextInputValue);
  }, []);

  return (
    <Wrapper>
      <StyledAutocomplete
        as={Autocomplete}
        value={value}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={renderInput}
        renderOption={renderOption}
        onFocus={handleFocus}
        getOptionLabel={(option) => option.name}
        options={uniqueData}
        noOptionsText={<NoOptions searchText={inputValue} />}
        loading={isFetching}
        disablePortal
        blurOnSelect
        clearOnBlur
      />
    </Wrapper>
  );
};


export default GamesSearch;

import { styled } from '@mui/system';

import { autocompleteClasses } from '@mui/material/Autocomplete';


export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',

  [`.${autocompleteClasses.option}`]: {
    padding: theme.spacing(0, 1),

    ...theme.typography.body2,

    '&.Mui-focused, &[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
      backgroundColor: theme.palette.background.hover,
    },
  },
}));

export const StyledAutocomplete = styled('div')(({ theme }) => ({
  background: 'inherit',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  padding: 0,

  [`.${autocompleteClasses.inputRoot}`]: {
    paddingLeft: theme.spacing(1.75),
  },

  [`.${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
    padding: 0,
  },
}));

export const OptionText = styled('span')(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'block',
}));

export const CheckboxContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

import { createActions } from 'redux-actions';

import { createConstants } from 'utils';

import { FILTERS_KEYS } from './constants';


export const PREFIX = 'BIDS';

export const types = createConstants([
  'FETCH_LIST',
  'OVERRIDE',
  'SET_HIDDEN_GRID_COLUMNS',
  'FETCH_HISTORICAL_BIDS',
  'PUSH_HISTORICAL_BIDS',
], PREFIX);

export const actions = createActions({
  [types.FETCH_LIST]: [
    (payload) => Object.values(FILTERS_KEYS).reduce((result, key) => ({
      ...result,
      [key]: payload[key],
    }), {}),
    (payload, { useIsFetching = true } = {}) => ({ useIsFetching }),
  ],

  [types.OVERRIDE]: [
    ({ bidKey, channel, bidOverride, comment }) => ({ bidKey, channel, bidOverride, comment }),
    (payload, { refetchBidsList = false } = {}) => ({ refetchBidsList }),
  ],

  [types.SET_HIDDEN_GRID_COLUMNS]: (columns) => columns || [],
  [types.FETCH_HISTORICAL_BIDS]: ({ timePeriod }) => ({ timePeriod }),
  [types.PUSH_HISTORICAL_BIDS]: ({ timePeriod }) => ({ timePeriod }),
}, {
  prefix: PREFIX,
});


export default actions;

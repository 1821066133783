import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import gamesActions from 'core/games/actions';
import { getIsGoalRoasUpdateFetching, getIsGoalRoasResetFetching } from 'core/games/selectors';

import { GOAL_ROAS_PATHS } from 'core/games/constants';
import AnalyticsService from 'services/analytics';

import DataGrid from 'components/common/data-grid';

import { StyledDataGrid } from './styles';
import { getConfig } from './config';


const GameGeosList = memo(({ list, gameId, onSelectionChange, selection, type, isGameArchived, isAppStatusReady }) => {
  const dispatch = useDispatch();
  const isGoalRoasUpdateFetching = useSelector(getIsGoalRoasUpdateFetching);
  const isGoalRoasResetFetching = useSelector(getIsGoalRoasResetFetching);

  const [lastEditedRow, setLastEditedRow] = useState(null);

  const handleRowUpdate = useCallback((nextRow) => {
    console.log(nextRow); // eslint-disable-line no-console

    // TODO: call API here
  }, []);


  const handleGoalRoasUpdate = useCallback((nextData) => {
    dispatch(gamesActions.updateGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.GEO,
    }));

    setLastEditedRow(nextData.id);
  }, [dispatch, gameId]);

  const handleGoalRoasReset = useCallback((nextData, { comment, initialValue }) => {
    setLastEditedRow(nextData.id);
    dispatch(gamesActions.resetOverrideGoalRoas({
      ...nextData,
      gameId,
      path: GOAL_ROAS_PATHS.GEO,
      comment,
      initialValue,
    }));
  }, [dispatch, gameId]);

  const config = useMemo(() => getConfig(
    list,
    handleRowUpdate,
    handleGoalRoasUpdate,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ), [
    list,
    handleRowUpdate,
    handleGoalRoasUpdate,
    handleGoalRoasReset,
    isGameArchived,
    isAppStatusReady,
  ]);

  const handleSelectionChange = useCallback((ids) => onSelectionChange(ids, type), [onSelectionChange, type]);

  const getIsRowFetching = useCallback(({ row: { id } }) => (
    (isGoalRoasUpdateFetching || isGoalRoasResetFetching) && id === lastEditedRow
  ), [isGoalRoasUpdateFetching, isGoalRoasResetFetching, lastEditedRow]);

  useEffect(() => {
    AnalyticsService.trackPageView({
      documentTitle: AnalyticsService.PAGES.GEOS,
    });
  }, []);

  return (
    <StyledDataGrid
      as={DataGrid}
      config={config}
      onSelectionModelChange={handleSelectionChange}
      selectionModel={selection}
      getIsRowFetching={getIsRowFetching}
    />
  );
});


GameGeosList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectionChange: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  gameId: PropTypes.string.isRequired,
  isGameArchived: PropTypes.bool.isRequired,
  isAppStatusReady: PropTypes.bool.isRequired,
};

GameGeosList.defaultProps = {
  list: [],
};


export default GameGeosList;

import { GAME_DETAILS_LIST, BILLING_TYPES } from 'core/games/constants';

import { QUERY_PARAMS_BY_FIELDS } from './constants';


export const getDefaultSelected = (queryParams) => Object.values(GAME_DETAILS_LIST).reduce((res, value) => {
  res[value] = (queryParams && queryParams[QUERY_PARAMS_BY_FIELDS[value]]) || [];
  return res;
}, {});

const getFilteredCampaignsList = (campaigns, networkIds) => {
  if (!campaigns) {
    return [];
  }

  if (!networkIds || !networkIds.length) {
    return campaigns;
  }

  return campaigns.filter(({ networkId }) => networkIds.includes(networkId));
};

const getFilteredGeosList = (geos, campaignIds, networkIds) => {
  if (!geos) {
    return [];
  }

  if (!campaignIds.length && !networkIds.length) {
    return geos;
  }

  return geos.filter(({ campaignId, channelId }) => {
    let result = true;
    if (campaignIds.length && !campaignIds.includes(campaignId)) {
      result = false;
    }

    if (networkIds.length && !networkIds.includes(channelId)) {
      result = false;
    }

    return result;
  });
};

const getFilteredSourcesList = (sources, geoIds, selectedGeosWithBillingTypeSource, campaignIds, networkIds) => {
  if (!sources) {
    return [];
  }

  if (!geoIds.length && !campaignIds.length && !networkIds.length) {
    return sources;
  }

  return sources.filter(({ campaignId, channelId, country }) => {
    let result = true;
    if (campaignIds.length && !campaignIds.includes(campaignId)) {
      result = false;
    }

    if (networkIds.length && !networkIds.includes(channelId)) {
      result = false;
    }

    if (geoIds.length) {
      result = selectedGeosWithBillingTypeSource.some((geos) => (
        geos.country === country
        && geos.campaignId === campaignId
        && geos.channelId === channelId
      ));
    }

    return result;
  });
};

const getSelectedGeosWithBillingTypeSource = (geos, selected) => {
  const geosCollection = geos.reduce((res, { id, ...geo }) => {
    res[id] = geo;
    return res;
  }, {});

  return selected.map((id) => geosCollection[id]).filter(({ billingType }) => billingType === BILLING_TYPES.SOURCE);
};

const getFilteredSelection = (list, selectedList) => selectedList
  .filter((itemId) => list.find(({ id }) => id === itemId));

export const getFilteredLists = (data, selected) => {
  const selection = data || {};

  const networks = selection[GAME_DETAILS_LIST.NETWORKS] || [];
  const campaigns = selection[GAME_DETAILS_LIST.CAMPAIGNS] || [];
  const geos = selection[GAME_DETAILS_LIST.GEOS] || [];
  const sorces = selection[GAME_DETAILS_LIST.SOURCES] || [];

  const nextSelected = { ...selected };

  const nextLists = {
    [GAME_DETAILS_LIST.NETWORKS]: networks,
  };
  nextSelected[GAME_DETAILS_LIST.NETWORKS] = getFilteredSelection(nextLists[GAME_DETAILS_LIST.NETWORKS], selected[GAME_DETAILS_LIST.NETWORKS]);

  nextLists[GAME_DETAILS_LIST.CAMPAIGNS] = getFilteredCampaignsList(campaigns, selected[GAME_DETAILS_LIST.NETWORKS]);
  nextSelected[GAME_DETAILS_LIST.CAMPAIGNS] = getFilteredSelection(nextLists[GAME_DETAILS_LIST.CAMPAIGNS], selected[GAME_DETAILS_LIST.CAMPAIGNS]);

  nextLists[GAME_DETAILS_LIST.GEOS] = getFilteredGeosList(geos, nextSelected[GAME_DETAILS_LIST.CAMPAIGNS], selected[GAME_DETAILS_LIST.NETWORKS]);
  nextSelected[GAME_DETAILS_LIST.GEOS] = getFilteredSelection(nextLists[GAME_DETAILS_LIST.GEOS], selected[GAME_DETAILS_LIST.GEOS]);

  const selectedGeosWithBillingTypeSource = getSelectedGeosWithBillingTypeSource(geos, nextSelected[GAME_DETAILS_LIST.GEOS]);

  nextLists[GAME_DETAILS_LIST.SOURCES] = getFilteredSourcesList(sorces, nextSelected[GAME_DETAILS_LIST.GEOS], selectedGeosWithBillingTypeSource, nextSelected[GAME_DETAILS_LIST.CAMPAIGNS], selected[GAME_DETAILS_LIST.NETWORKS]);
  nextSelected[GAME_DETAILS_LIST.SOURCES] = getFilteredSelection(nextLists[GAME_DETAILS_LIST.SOURCES], selected[GAME_DETAILS_LIST.SOURCES]);

  return { nextLists, nextSelected };
};

export const isSelectedNotEqual = (nextSelected, selected) => Object.entries(nextSelected).some(([key, arr]) => arr.length !== selected[key].length);

export const convertSelectionToQuery = (selection) => Object.entries(selection).reduce((res, [key, arr]) => {
  res[QUERY_PARAMS_BY_FIELDS[key]] = arr;
  return res;
}, {});

export const ROUTES = {
  GAMES: 'games',
  GAMES_V3: 'v3/games',
  GAME_DETAILS: ':gameId',
  BIDS: 'bids',
  OLD_BIDS: 'old-bids',
  SETTINGS: 'settings',
  SETTINGS_PUSH_FREQUENCY: 'push-frequency',
  SETTINGS_CHANGE_LIMITS: 'change-limits',
  SETTINGS_PERMISSIONS: 'permissions',
  ACTIVITY_LOG: 'activity-log',

  SIGN_IN: 'fallback/sign-in',
  SIGN_IN_OKTA: '/sign-in',
  SIGN_IN_OKTA_REDIRECT: '/login/okta',
  SIGN_UP: 'sign-up',
  RECOVER_PASSWORD: 'recover-password',
  RESET_PASSWORD: 'reset-password',

  NOT_FOUND: 'not-found',
};

export const AUTH_ROUTES = [ROUTES.SIGN_IN, ROUTES.SIGN_UP, ROUTES.SIGN_IN_OKTA, ROUTES.SIGN_IN_OKTA_REDIRECT];

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { CHANNELS } from 'core/games/constants';

import EditableTextField from 'components/common/editable-text-field';

import { mintegralAccountIdValidator, applovinAccountIdValidator, baseAccountIdValidator, adjoeAccountIdValidator } from './validation';


const AccountIdField = memo(({ channel, ...otherProps }) => {
  const validationSchema = useMemo(() => {
    switch (channel) {
      case CHANNELS.MINTEGRAL:
        return mintegralAccountIdValidator;

      case CHANNELS.APPLOVIN:
        return applovinAccountIdValidator;

      case CHANNELS.ADJOE:
        return adjoeAccountIdValidator;

      case CHANNELS.UNITY:
      default:
        return baseAccountIdValidator;
    }
  }, [channel]);

  return (
    <EditableTextField
      type="text"
      validationSchema={validationSchema}
      {...otherProps}
    />
  );
});


AccountIdField.propTypes = {
  channel: PropTypes.oneOf(Object.values(CHANNELS)),
};

AccountIdField.defaultProps = {
  channel: null,
};


export default AccountIdField;
